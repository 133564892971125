import React from "react";
import styles from "./styles.module.scss";
import { getAssignmentCandidateStatus } from "../../../../../utils/helper";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";

const SummaryTable = ({ data, id, activetab, hasCandidateAccess }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.assignedtable}>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" className={styles.thName}>
              Name
            </th>
            {/* <th scope="col" className={styles.thDesignation}>
              Current Designation
            </th> */}
            <th scope="col" className={styles.thStatus}>
              Current Status
            </th>
            <th scope="col" className={styles.thAssigned}>
              Assigned On
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.candidates?.map((data, index) => (
            <tr key={index}>
              <td>
                <b>{`${data?.talentId?.firstName} ${data?.talentId?.lastName}  `}</b>
                <td
                  className={styles.designationStyles}
                >{`${data?.currentDesignation}`}</td>
              </td>
              <td>
                <b>{getAssignmentCandidateStatus(data?.status)}</b>
              </td>
              <td>{`${moment(data?.updatedAt).format(" DD MMMM YYYY")}`}</td>{" "}
            </tr>
          ))}
        </tbody>
        <div
          className={styles.tableFooter}
          onClick={() => {
            data?.candidates.length !== 0
              ? navigate(
                  `/assignments/overview/${id}?tab=assignedcandidate&activetab=${activetab}`
                )
              : navigate(
                  `/assignments/overview/${id}?tab=addcandidates&activetab=${activetab}`
                );
          }}
        >
          {hasCandidateAccess && (
            <>
              <a className={styles.tableFooterTxt}>
                {data?.candidates?.length !== 0
                  ? "See all candidates"
                  : "Add Candidates "}
              </a>
              <EastIcon
                sx={{
                  height: "17px",
                  width: "15px",
                  color: "#011cf5",
                  marginLeft: "6px",
                  // marginTop: "1px",
                  fontSize: "bold",
                }}
              />
            </>
          )}
        </div>
      </table>
    </div>
  );
};
export default SummaryTable;
