import axios from "axios";
import { toast } from "react-toastify";
import { handleLogout } from "../redux/actions/auth";
import { dispatchAction, store } from "../redux/store";
import { BASE_URL } from "./endpoints";
import ToastMessage from "components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { ROUTES } from "router/routes";

const defaultOptions = () => ({
  headers: {
    Authorization: `Bearer ${store?.getState()?.auth?.data?.accessToken}`,
  },
  transformResponse: axios.defaults.transformResponse.concat((response) => {
    if (response?.statusCode === 401) {
      dispatchAction(handleLogout(true));
    }
    return response;
  }),
});

export const axiosInstance = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
    ...defaultOptions(),
  });
  instance.interceptors.response.use(
    (response) => response,

    (error) => {
      if (error?.response?.status === 401) {
        console.log(error.config.url);
        {
          error.config.url !== "/admin/notifications" &&
            toast.warning(
              <ToastMessage
                title={"Alert"}
                message={
                  error?.response?.data?.message ||
                  error?.message ||
                  TOAST_MESSAGE.SOMETHING_WENT_WRONG
                }
              />
            );
        }
      } else if (
        error?.response?.status === 404 &&
        error?.response?.data?.responseType === "NOT_FOUND"
      ) {
        console.log(error.config.url);
        window.location.href = ROUTES.ERROR_PAGE;
      } else {
        console.log(error.config.url);
        {
          error.config.url !== "/admin/notifications" &&
            toast.error(
              <ToastMessage
                title={"Error"}
                message={
                  error?.response?.data?.message ||
                  error?.message ||
                  TOAST_MESSAGE.SOMETHING_WENT_WRONG
                }
              />
            );
        }
      }
      throw error;
    }
  );
  return instance;
};
