export const IMAGES = {
  LOGO_WHITE: "/images/logos/logo-white.svg",
  LOGO_WHITENEW: "/images/logos/logo_new.svg",
  LOGO_WHITEBLACK: "/images/logos/logo_black.svg",
  LOGO_BLUE: "/images/logos/logo-blue.svg",

  LOGO_DOWNARROW: "/images/login/downArrow.svg",
  USER_PROFILE: "/images/login/user.jpg",
  UP_IMG: "/images/login/up.png",

  USER_LOGOUT: "/images/icons/logout.svg",
  SORT_FILLTER: "/images/icons/sort.svg",
  SOCIAL_LINKDIN: "/images/icons/linkedin.svg",
  SOCIAL_TWITTER: "/images/icons/twitter.svg",
  CROSS_ICON: "/images/icons/croos.svg",
  IC_TWO: "/images/icons/IcTwotoneWork.svg",
  BI_CLOUD: "/images/icons/BiCloudUploadFill.svg",
  FILE_PDF: "/images/icons/MdiFilePdfBox.svg",
  FILE_PDF_WHITE: "/images/icons/MdiFilePdfBoxWhite.svg",
  SEARCH_ICON: "/images/icons/search.svg",
  FILTER_ICON: "/images/icons/filters.svg",
  MID_EMAIL: "/images/icons/MdiEmailFast.svg",
  MID_MOBILE: "/images/icons/AntDesignMobileTwotone.svg",
  EMAIL_ICON: "/images/icons/MdiEmailMultipleOutline.svg",
  SAVE_ICON: "/images/icons/FluentDocumentCopy24Regular.svg",

  PRIME_USER: "/images/new-images/PrimeUsers.svg",
  EDIT_ICON: "/images/new-images/BytesizeEdit.svg",
  CALENDER_ICON: "/images/new-images/ClarityCalendarOutlineBadged.svg",
  USER_ICON: "/images/new-images/LaUserAltSlash.svg",
  CALL_ICON: "/images/new-images/MaterialSymbolsCall.svg",
  DOTSS_ICON: "/images/new-images/MdiDotsHorizontal.svg",
  TIE_ICON: "/images/new-images/IcOutlineWorkspacePremium.svg",
  CHART_ICON: "/images/new-images/MaterialSymbolsMultilineChartRounded.svg",
  LINK_ICON: "/images/new-images/TablerExternalLink.svg",
  CERTIFICATE: "/images/new-images/FluentCertificate24Filled.svg",
  PUBLICATION: "/images/new-images/HealthiconsICertificatePaperOutline.svg",
  TIME_ZONE: "/images/new-images/PhClockClockwise.svg",
  BOX_LINE: "/images/new-images/RiEditBoxLine.svg",
  ACCOUNT_IDIT: "/images/new-images/MdiAccountEdit.svg",
  ADD_USER: "/images/new-images/AddUser.svg",
  ADD_DISABLED_USER: "/images/new-images/AddDisableUser.svg",
  VIEW: "/images/new-images/view.png",
  HIDE: "/images/new-images/hide.png",
  MAIL_ICON: "/images/new-images/DashiconsEmailAlt.svg",
  EYESLASH: "/images/new-images/EyeSlash.svg",
  EYE: "/images/new-images/Eye.svg",
  SEARCHFILTER: "/images/new-images/FilterIcon.svg",
  LINKEDINICON: "/images/new-images/LI-In-Bug.svg",
  NAVDROPARROW: "/images/nav-images/dropdownarrow.svg",
  ACTIVE_NAVDROPARROW: "/images/nav-images/activeArrow.svg",

  RIGHT_ARROW: "/images/new-images/rightarrow.svg",
  LEFT_ARROW: "/images/new-images/leftarrow.svg",
  TIP_USER_ICON: "/images/new-images/IonIosContact.svg",
  NEW_ADDITION: "/images/new-images/ButtonPlus.svg",
  WHITE_PLUS: "/images/new-images/WhitePlus.svg",
  EXTERNAL_LINK: "/images/new-images/TablerExternalLink.svg",
  COMMENT_ICON: "/images/new-images/MdiCommentProcessingOutline.svg",

  OVERVIEW: "/images/nav-images/overview.svg",
  SEARCH: "/images/nav-images/search.svg",
  ENABLE_SEARCH_ICON: "/images/icons/disabledSearchIcon.svg",

  DOWNLOAD_ICON: "/images/new-images/download.svg",

  TALENT: "/images/nav-images/talent.svg",
  CLIENTS: "/images/nav-images/client.svg",
  ASSIGNMENTS: "/images/nav-images/Assignment.svg",
  BELL: "/images/nav-images/bell.svg",
  USER: "/images/nav-images/IcBaselinePersonPin.svg",
  BELLICON: "/images/nav-images/BellIcon.svg",
  DOWNLOAD_FILE_ICON: "/images/icons/RiFileDownloadLine.svg",
  MOBILE_ADD_ICON: "/images/icons/mobileAdd.svg",
  MOBILE_DELETE_ICON: "/images/icons/mobileDelete.svg",
  MENTION_ICON: "/images/new-images/FluentCommentMention16Regular.svg",
  RI_DELETE_BIN: "/images/new-images/RiDeleteBinLine.svg",
  RI_DISABLE_DELETE_BIN: "/images/new-images/RiDisableBin.svg",
  RI_ACTIVE_DELETE_BIN: "/images/new-images/riActiveBin.svg",
  BACK_BUTTON_ICON: "/images/icons/backButtonArrow.svg",
  PHCALENDARCHECK: "/images/new-images/PhCalendarCheck.svg",
  ICOMOONFREEL2: "/images/new-images/IcomoonFreeLoop2.svg",
  ICROUNDACCESSTIME: "/images/new-images/IcRoundAccessTime.svg",
  PHBELLRINGING: "/images/new-images/PhBellRingingDuotone.svg",
  USER_IMAGE: "/images/new-images/user.png",
  USER_PLACEHOLDER_IMAGE: "/images/new-images/UserPlaceholderImage.svg",
  ACTIVE_CALENDER: "/images/new-images/ActiveCalender.svg",
  DISABLE_CALENDER: "/images/new-images/DisableCalender.svg",

  ////////overView Page Images////////
  NEW_TALENT: "/images/new-images/RiUserShared2Line.svg",

  OFFER_DROP: "/images/new-images/CiUserClose.svg",
  TOTAL_CONVERTED: "/images/new-images/CarbonUserAdmin.svg",
  ACTIVE_TOTAL_CONVERTED: "/images/new-images/ActiveCarbonUserAdmin.svg",
  IN_PIPLINE: "/images/new-images/LaUserCog.svg",
  OFF_LIMIT: "/images/new-images/LaUserAltSlash.svg",
  ACTIVE_OFF_LIMIT: "/images/new-images/activeUserAltSlash.svg",
  ARROW: "/images/new-images/IcRoundArrowRightAlt.svg",
  BLACK_BIN: "/images/new-images/blackBin.svg",

  /////////////
  INACTIVE_DROPDOWN: "/images/new-images/inActiveDropdownArrow.svg",
  ACTIVE_DROPDOWN: "/images/new-images/activeDropdownArrow.svg",

  DOUBLE_USER_IMAGE: "/images/nav-images/doubleUserImage.svg",

  ORGANIZATION_PLACEHOLDER: "/images/new-images/organizationPlaceHolder.svg",

  ADD_TALENTLAB_USER: "/images/profile-noti-images/AddUser.svg",

  ALERT_CAUTION: "/images/profile-noti-images/AlertCaution.svg",
  TABLE_MENU_EDIT: "/images/profile-noti-images/Edit.svg",
  TABLE_DISABLE_EDIT: "/images/profile-noti-images/DisableEdit.svg",
  TABLE_MENU_BLOCK: "/images/profile-noti-images/Block.svg",
  TABLE_MENU_UNBLOCK: "/images/profile-noti-images/Unblock.svg",
  TABLE_MENU_DELETE: "/images/profile-noti-images/Delete.svg",
  NOTI_BELL: "/images/profile-noti-images/Bell.svg",
  NOTI_FULL_BELL: "/images/profile-noti-images/bellIcon.png",
  PROFILE_EXTERNAL_LINK: "/images/new-images/externalLink.svg",
  BLACK_CROSS_BUTTON: "/images/new-images/CharmCross.svg",
  ACTIVE_DOTS: "/images/new-images/MdiActiveDotsHorizontal.svg",
  // I_ICON: "/images/new-images/iIcion.svg",

  I_ICON: "/images/new-images/noun-information-2119887.svg",
  DUPLICATE_ICON: "/images/icons/duplicateIcon.svg",
  DURATION: "/images/new-images/duration.svg",
  ACTIVE_PLUS_BUTTON: "/images/new-images/activeButton.svg",
  ACTIVE_COMP: "/images/new-images/activeCompe.svg",
  FILTER: "/images/new-images/IcRoundFilterList.svg",
  SEND_ICON: "/images/new-images/sendIcon.svg",
  SCROLL_IMAGE: "/images/new-images/MoveIcon.svg",

  SIMPLE_USER_ICON: "/images/icons/addUser.svg",
  LOGO_SVG: "/images/icons/logoSvg.svg",
  PASSWORD_EYE: "/images/icons/passwordEye.svg",
  RIGHT_ARROW: "/images/icons/rightArrow.svg",
  BLACK_ARROW: "/images/icons/blackArrow.svg",
  BACK_TO_LOGIN: "/images/icons/backtoLogin.svg",

  ROUND_PLUS: "/images/new-images/IcRoundPlus.svg",
};
