import React from "react";
import styles from "./styles.module.scss";
import CommentsSection from "../../../../components/commentSection";
import StatusToggle from "./statusToggle";
import { COMMENT_TYPE } from "../../../../constants/contants";

const Settings = ({
  id,
  tab,
  isArchive,
  // hasCommentWriteAccess,
  // hasCommentDeleteAccess,
  hasWriteAccess,
}) => {
  return (
    <div className={`tab-content ${styles.rightdetails}`}>
      {hasWriteAccess && (
        <StatusToggle activeTabType={3} isArchive={isArchive} />
      )}

      <div
        className={
          hasWriteAccess ? styles.bottomContainer : styles.upbottomContainer
        }
      >
        {tab !== "history" && (
          <CommentsSection
            hasWriteAccess={hasWriteAccess}
            // hasCommentWriteAccess={hasCommentWriteAccess}
            // hasCommentDeleteAccess={hasCommentDeleteAccess}
            id={id}
            type={COMMENT_TYPE.ASSIGNMENT}
            allowComment={!isArchive}
          />
        )}
      </div>
    </div>
  );
};

export default Settings;
