import { axiosInstance } from "./client";
import { useNavigate } from "react-router-dom";

export const addEditApi = (endpoint, payload) => {
  return axiosInstance()
    .post(endpoint, payload)
    .then((response) => response.data);
};

export const listApi = (endpoint, params) => {
  return axiosInstance()
    .get(endpoint, { params })
    .then((response) => response.data);
};
