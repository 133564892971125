import React from "react";
import styles from "./styles.module.scss";
import LinkButton from "components/buttons/linkButton";
import PrimaryButton from "components/buttons/primaryButton";
import { validationErrorMapping } from "utils/helper";

const Header = ({
  showDatatable,
  hasWriteAccess,
  setFieldValue,
  setAssignmentSelected,
  setShowDataTable,
  assignmentSelected,
  resetForm,
  compentancyData,
  errors,
  loading,
  dirty,
}) => {
  return (
    <div className={styles.edithead}>
      <div className={showDatatable ? styles.tableHeadinbg : styles.topheading}>
        <h2 className={styles.headingText}>
          Competency Mapping
          {showDatatable && `- ${assignmentSelected?.label}`}
        </h2>
        {showDatatable ? (
          <div className="d-flex">
            <LinkButton
              onClick={() => {
                resetForm({
                  compentancyMapping: compentancyData,
                });
                setFieldValue("name", "");
                setAssignmentSelected("");
                setShowDataTable(false);
              }}
            >
              Choose another assignment
            </LinkButton>

            {hasWriteAccess && (
              <PrimaryButton
                type="submit"
                onClick={() => validationErrorMapping(errors)}
                label={loading ? "Saving..." : "Save mapping"}
                disabled={loading || !dirty}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
