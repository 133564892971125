import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IMAGES } from "../../../constants/assets";
import { addEditApi, listApi } from "../../../api/commonApis";
import { APIS } from "../../../api/endpoints";
import styles from "../header/styles.module.scss";
import NotificationMenu from "./notificationMenu";
import ProfileMenu from "./profileMenu";
import DropDownMenu from "./dropDownMenu";
import { ristrictAccess } from "utils/helper";
import BellIcon from "./bellIcon";
import ProfileAvatar from "./profileAvatar";
import FollowUp from "./followUp";
import HeaderLink from "./HeaderLink";
import { refreshNotification } from "redux/actions/auth";

const Header = ({ showMenu }) => {
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const activetab = params?.get?.("activetab");
  const profileData = useSelector((state) => state?.auth?.data);
  const refresh = useSelector((state) => state?.auth?.refresh);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [notiEl, setNotiEl] = useState(null);
  const notiOpen = Boolean(notiEl);
  const [notiBadgeData, setNotiBadgeData] = useState([]);
  const [innerApiRefresh, setInnerApiRefresh] = useState(false);
  const [openModal, setOpenModal] = useState();
  const [isAssigActive, setIsAssigActive] = useState(false);
  const [commondata, setCommonData] = useState();

  /*Notification Badge APi Hit After a 50000ms time */

  useEffect(() => {
    const interval = setInterval(() => {
      notificationApiHandler();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  /* Notification Badge APi Hit Only Onecse at Starting*/

  useEffect(() => {
    notificationApiHandler();
  }, [refresh]);

  /* Hanlder For APi Defination */

  const notificationApiHandler = () => {
    listApi(APIS.LIST_NOTIFICATIONS).then((result) =>
      setNotiBadgeData(result?.data)
    );
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [_refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const marksAsReadHandler = () => {
    addEditApi(APIS.NOTIFICATION_ACTIONS, { action: 2 }).then((response) => {
      setRefresh((s) => !s);
      dispatch(refreshNotification());
    });
  };

  const handleNotiClick = (event) => {
    setInnerApiRefresh(!innerApiRefresh);
    setNotiEl(event.currentTarget);

    setTimeout(() => {
      marksAsReadHandler();
    }, 1000);
  };

  const handleNotiClose = () => {
    setNotiEl(null);
  };

  const onAssigmentNavLinkClick = () => {
    navigate("/assignments?activetab=open-assignments");
  };

  const isMenuActive = (path) => {
    if (path === pathname) {
      return true;
    } else if (pathname?.startsWith(path)) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (pathname === "/assignments/add-assignment") {
      setIsAssigActive(true);
    } else {
      setIsAssigActive(false);
    }
  }, [pathname]);

  return (
    <section className={`d-flex align-items-center ${styles.header}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid pe-5 ps-3">
                <img
                  src={IMAGES.LOGO_WHITENEW}
                  className={styles.logoStyle}
                  alt="logo"
                />

                {showMenu && (
                  <HeaderLink
                    isMenuActive={isMenuActive}
                    setOpenModal={setOpenModal}
                    activetab={activetab}
                    isAssigActive={isAssigActive}
                    onAssigmentNavLinkClick={onAssigmentNavLinkClick}
                    DropDownMenu={DropDownMenu}
                    openModal={openModal}
                  />
                )}

                <div className={styles.profilehead}>
                  <ul className="mb-2 navbar-nav me-auto mb-lg-0 align-items-center">
                    {!ristrictAccess() && (
                      <FollowUp notiBadgeData={notiBadgeData} />
                    )}
                    <BellIcon
                      handleNotiClick={handleNotiClick}
                      notiBadgeData={notiBadgeData}
                      notiOpen={notiOpen}
                    />
                    <ProfileAvatar
                      handleMenuClick={handleMenuClick}
                      menuOpen={menuOpen}
                      profileData={profileData}
                    />
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <ProfileMenu
        profileData={profileData}
        open={menuOpen}
        anchorEl={anchorEl}
        handleClose={handleMenuClose}
      />
      <NotificationMenu
        innerApiRefresh={innerApiRefresh}
        open={notiOpen}
        anchorEl={notiEl}
        handleClose={handleNotiClose}
      />
    </section>
  );
};

export default Header;
