import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import FormInput from "../../../../components/forms/formInput";
import { talentCompensationSchema } from "../../../../constants/formSchema";
import { listApi, addEditApi } from "../../../../api/commonApis";
import styles from "./styles.module.scss";
import { APIS } from "../../../../api/endpoints";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../router/routes";
import currencies from "../../../../constants/currencies";
import CompensationTable from "../../../../components/table/compensationTable";
import { saveViewButtonLabels } from "../../../../constants/contants";
import LinkButton from "../../../../components/buttons/linkButton";
import { validationError } from "../../../../utils/helper";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import { toast } from "react-toastify";
import ToastMessage from "../../../../components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";

const initialValues = {
  currency: "₹",
  askingCompensation: {
    fixed: "",
    variable: "",
    esops: "",
  },
  currentCompensation: {
    fixed: "",
    variable: "",
    esops: "",
  },
};

const Compensation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const compensationId = searchParams.get("compensationId");
  const [allCompensations, setCompensations] = useState([]);
  const [compensationData, setCompensationData] = useState({});

  useEffect(() => {
    setLoading(true);
    listApi(APIS.TALENT_DETAILS_TYPE_WISE, {
      talentId: id,
      type: 7,
    })
      .then((result) => setCompensations(result?.data))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (compensationId) {
      setLoading(true);
      listApi(APIS.TALENT_DETAILS_TYPE_WISE, {
        talentId: id,
        type: 8,
      })
        .then((result) => setCompensationData(result?.data))
        .finally(() => setLoading(false));
    }
  }, [compensationId]);

  const onSubmit = (payload) => {
    setLoading(true);
    let data = {
      currency: payload?.currency,

      askingCompensation: {
        fixed: parseInt(payload?.askingCompensation?.fixed),
        variable: parseInt(payload?.askingCompensation?.variable),
        esops: parseInt(payload?.askingCompensation?.esops),
      },
      currentCompensation: {
        fixed: parseInt(payload?.currentCompensation?.fixed),
        variable: parseInt(payload?.currentCompensation?.variable),
        esops: parseInt(payload?.currentCompensation?.esops),
      },
      talentId: id,
    };

    addEditApi(APIS.ADD_EDIT_TALENT_PROFILE, data)
      .then((response) => {
        navigate(`${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=compensation`);
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.COMPENSATION_SAVED}
          />
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={`tab-content ${styles.tabcontent}`}>
      <div className="fade show active">
        <Formik
          onSubmit={onSubmit}
          initialValues={compensationId ? compensationData : initialValues}
          validationSchema={talentCompensationSchema}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            dirty,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.editprofile}>
                <div className={styles.edithead}>
                  <div className={styles.topheading}>
                    <h3>Add Compensation Details</h3>
                    <div className="d-flex">
                      <LinkButton
                        onClick={() =>
                          navigate(
                            `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=compensation`
                          )
                        }
                      >
                        Back to compensation
                      </LinkButton>
                      <PrimaryButton
                        onClick={() => validationError(errors)}
                        label={saveViewButtonLabels.compansationSave}
                        disabled={!dirty}
                      />
                    </div>
                  </div>
                </div>
                <hr className={styles.cardDivider} />
                <div className={styles.middleCard}>
                  <FormInput
                    label="Currency"
                    type="select"
                    options={currencies}
                    value={currencies?.find(x=>x?.currency?.symbol ===(values?.currency || "₹"))}
                    onChange={(e) =>
                      setFieldValue("currency", e?.currency?.symbol)
                    }
                    getOptionValue={(currency) => `${currency?.currency?.symbol}`}
                    getOptionLabel={(currency) =>
                      `${currency?.currency?.name}  -  ${currency?.currency?.symbol}`
                    }
                    error={touched?.currency && errors?.currency}
                  />

                  <div className={styles.formname}>
                    <h3>Expected Compensation </h3>
                  </div>
                </div>
                <hr className={styles.cardDivider} />
                <div className={styles.middleCard}>
                  <FormInput
                    name="askingCompensation.fixed"
                    label="Fixed Component"
                    value={values?.askingCompensation?.fixed}
                    onChange={handleChange}
                    error={
                      touched?.askingCompensation?.fixed &&
                      errors?.askingCompensation?.fixed
                    }
                  />
                  <FormInput
                    name="askingCompensation.variable"
                    label="Variable Bonuses"
                    value={values?.askingCompensation?.variable}
                    onChange={handleChange}
                    error={
                      touched?.askingCompensation?.variable &&
                      errors?.askingCompensation?.variable
                    }
                  />
                  <FormInput
                    label="ESOPs"
                    name="askingCompensation.esops"
                    value={values?.askingCompensation?.esops}
                    onChange={handleChange}
                    error={
                      touched?.askingCompensation?.esops &&
                      errors?.askingCompensation?.esops
                    }
                  />

                  <div className={styles.formname}>
                    <h3>Current Compensation </h3>
                  </div>
                </div>

                <hr className={styles.cardDivider} />
                <div className={styles.middleCard}>
                  <FormInput
                    name="currentCompensation.fixed"
                    label="Fixed Component"
                    value={values?.currentCompensation?.fixed}
                    onChange={handleChange}
                    error={
                      touched?.currentCompensation?.fixed &&
                      errors?.currentCompensation?.fixed
                    }
                  />
                  <FormInput
                    name="currentCompensation.variable"
                    label="Variable Bonuses"
                    value={values?.currentCompensation?.variable}
                    onChange={handleChange}
                    error={
                      touched?.currentCompensation?.variable &&
                      errors?.currentCompensation?.variable
                    }
                  />
                  <FormInput
                    name={"currentCompensation.esops"}
                    label="ESOPs"
                    value={values?.currentCompensation?.esops}
                    onChange={handleChange}
                    error={
                      touched?.currentCompensation?.esops &&
                      errors?.currentCompensation?.esops
                    }
                  />
                  <div className={styles.formname}>
                    <h3>Compensation History </h3>
                  </div>
                </div>
                <hr className={styles.cardDivider} />
                <div className={styles.middleCard}>
                  <CompensationTable
                    data={allCompensations}
                    HeadingShow={true}
                  />
                </div>
                <hr className={styles.cardDivider} />
                <div className={styles.bottomFotter}>
                  <div className="d-flex">
                    <LinkButton
                      onClick={() =>
                        navigate(
                          `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=compensation`
                        )
                      }
                    >
                      Back to compensation
                    </LinkButton>

                    <PrimaryButton
                      onClick={() => validationError(errors)}
                      label={saveViewButtonLabels.compansationSave}
                      disabled={!dirty}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Compensation;
