import { Formik, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { listApi, addEditApi } from "../../../../api/commonApis";
import { APIS } from "../../../../api/endpoints";
import FormInput from "../../../../components/forms/formInput";
import { addAssignmnetSchema } from "../../../../constants/formSchema";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../constants/assets";
import debounce from "lodash/debounce";
import CountryStateCityPicker from "../../../../components/countryStateCityPicker";
import { useNavigate, useParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import ToastMessage from "../../../../components/layout/toast";
import { toast } from "react-toastify";
import { validationError, order } from "../../../../utils/helper";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import { TOAST_MESSAGE } from "constants/toastMessage";

const initialValues = {
  title: "",
  companyId: "",
  city: null,
  state: null,
  country: { label: "India", value: "India" },
  contactPerson: "",
  contactPersonDesignation: "",
  contactPersonEmail: "",
  contactPersonPhoneNumber: [""],
  director: "",
  consultant: [],
};

const AddAssignment = () => {
  const [assignment, setAssignment] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    if (id) {
      setLoading(true);
      listApi(APIS.LIST_ASSIGNMENT_OVERVIEW, { assignmentId: id })
        .then((result) => {
          let tempData = cloneDeep(result?.data);
          tempData.companyId = {
            label: tempData?.companyId?.name,
            value: tempData?.companyId?._id,
          };
          tempData.consultant = tempData?.consultant?.map((item) => ({
            label: `${item?.name} ${item?.lastName}`,
            value: item?._id,
          }));

          if (tempData?.director === undefined) {
            tempData.director = "";
          } else {
            tempData.director = {
              label: `${tempData?.director?.name} ${tempData?.director?.lastName}`,
              value: tempData?.director?._id,
            };
          }

          tempData.country = {
            label: tempData?.country,
            value: tempData?.country,
          };
          if (tempData?.state === undefined) {
            tempData.state = null;
          } else {
            tempData.state = { label: tempData?.state, value: tempData?.state };
          }

          if (tempData?.city === undefined) {
            tempData.city = null;
          } else {
            tempData.city = { label: tempData?.city, value: tempData?.city };
          }

          setAssignment(tempData);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  const onSubmit = (payload) => {
    setLoading(true);

    let data = {
      title: payload?.title,
      companyId: payload?.companyId?.value,
      country: payload?.country?.label || "",
      city: payload?.city?.label || "",
      state: payload?.state?.label || "",
      consultant: payload?.consultant.map(({ value }) => value),
    };
    if (
      payload?.director?.value === undefined ||
      payload?.director?.value === ""
    ) {
      data.director = "";
    } else {
      data.director = payload?.director?.value;
    }

    // }
    // if (payload?.contactPerson) {
    data.contactPerson = payload?.contactPerson;
    // }
    // if (payload?.contactPersonDesignation) {
    data.contactPersonDesignation = payload?.contactPersonDesignation;
    // }
    // if (payload?.contactPersonEmail) {
    data.contactPersonEmail = payload?.contactPersonEmail;
    // }
    // if (payload?.contactPersonPhoneNumber) {
    data.contactPersonPhoneNumber = payload?.contactPersonPhoneNumber;
    // }

    if (id) {
      data.assginmentId = id;
    }

    addEditApi(APIS.ADD_EDIT_ASSIGNMNET, data)
      .then((result) => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={
              id
                ? TOAST_MESSAGE.ASSIGNMENT_UPDATE
                : TOAST_MESSAGE.ASSIGNMENT_ADD
            }
          />
        );
        navigate(`/assignments/overview/${result?.data._id}`);
      })
      .finally(() => setLoading(false));
  };

  const loadOptions = (inputValue, resolve) => {
    listApi(APIS.LIST_GET_ADMIN, { search: inputValue }).then((result) => {
      const only_admins = result?.data.filter(
        (a) =>
          a.talentLabRole !== "Talent" &&
          a.talentLabRole !== "Organizations" &&
          (a.selfSignUp === false || a.selfSignUp === undefined)
      );
      console.log(only_admins, "only_admins");
      const filter = only_admins
        ?.filter((x) => {
          if (Array.isArray(selectedValue)) {
            const ids = selectedValue?.map((i) => i?.value);
            return !ids?.includes(x?._id);
          } else return x?._id !== selectedValue?.value;
        })
        ?.map((item) => ({
          label: `${item?.name} ${item?.lastName}`,
          value: item?._id,
        }))
        .sort(order);

      resolve(filter);
    });
  };

  const loadClientOption = (type, inputValue, resolve) => {
    listApi(APIS.LIST_CLIENT, { search: inputValue }).then((result) => {
      const filter = result?.data?.data
        ?.map((item) => ({
          label: item?.name,
          value: item?._id,
        }))
        .sort(order);

      resolve(filter);
    });
  };

  const loaddirector = (inputValue, resolve) =>
    loadOptions(inputValue, resolve);

  const loadclient = (inputValue, resolve) =>
    loadClientOption(1, inputValue, resolve);

  const loadDirector = debounce(loaddirector, 300);
  const loadConsultant = debounce(loaddirector, 300);
  const loadClient = debounce(loadclient, 300);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={id ? assignment : initialValues}
      validationSchema={addAssignmnetSchema}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleSubmit,
        getFieldProps,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.editprofile}>
            <div className={styles.edithead}>
              <div className={styles.topheading}>
                <h3>{id ? "Edit assignment details" : "Assignment details"}</h3>
                <PrimaryButton
                  onClick={() => validationError(errors)}
                  label={
                    loading
                      ? "saving..."
                      : id
                      ? "Save and View"
                      : "Save and Create"
                  }
                  disabled={loading || !dirty}
                />
              </div>
            </div>
            <hr className={styles.cardDivider} />
            <div className={styles.formname}>
              <div className={styles.middleCard}>
                <FormInput
                  label="Assignment Title"
                  {...getFieldProps("title")}
                  error={touched?.title && errors?.title}
                />
                <FormInput
                  defaultOptions
                  label="Organization Name"
                  type="async-select"
                  value={values?.companyId}
                  loadOptions={loadClient}
                  onChange={(data) => setFieldValue("companyId", data)}
                  error={touched?.companyId && errors?.companyId}
                />

                <h3>Location Details</h3>
              </div>
              <hr className={styles.cardDivider} />

              <div className={styles.middleCard}>
                <CountryStateCityPicker
                  isEdit={!!id}
                  country={values?.country}
                  state={values?.state}
                  city={values?.city}
                  onCountyChange={(value) => {
                    setFieldValue("country", value);
                    setFieldValue("state", null);
                    setFieldValue("city", null);
                  }}
                  onStateChange={(value) => {
                    setFieldValue("city", null);
                    setFieldValue("state", value);
                  }}
                  onCityChange={(value) => {
                    setFieldValue("city", value);
                  }}
                  countryError={touched?.country && errors?.country}
                  stateError={touched?.state && errors?.state}
                  cityError={touched?.city && errors?.city}
                />

                <h3>Contact Person Details</h3>
              </div>
              <hr className={styles.cardDivider} />
              <div className={styles.middleCard}>
                <FormInput
                  label="Contact Person Name"
                  {...getFieldProps("contactPerson")}
                  error={touched?.contactPerson && errors?.contactPerson}
                />
                <FormInput
                  label="Contact Person Designation"
                  {...getFieldProps("contactPersonDesignation")}
                  error={
                    touched?.contactPersonDesignation &&
                    errors?.contactPersonDesignation
                  }
                />
                <FormInput
                  label="Contact Person Email"
                  {...getFieldProps("contactPersonEmail")}
                  error={
                    touched?.contactPersonEmail && errors?.contactPersonEmail
                  }
                />

                <FieldArray
                  name="contactPersonPhoneNumber"
                  render={(arrayHelpers) => (
                    <div>
                      {values?.contactPersonPhoneNumber?.map((phone, index) => (
                        <div key={index} className={styles.mobileRow}>
                          <div className={styles.mobileInput}>
                            <FormInput
                              maxLength={20}
                              label="Contact Person Mobile Number"
                              {...getFieldProps(
                                `contactPersonPhoneNumber.${index}`
                              )}
                              error={
                                touched?.contactPersonPhoneNumber?.[index] &&
                                errors?.contactPersonPhoneNumber?.[index]
                              }
                            />
                          </div>
                          <div className={styles.mobilebuttons}>
                            {index !== 0 && (
                              <img
                                alt=""
                                className={`${styles.documentList}`}
                                src={IMAGES.MOBILE_DELETE_ICON}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            )}
                            <img
                              alt=""
                              className={` ${styles.documentList}`}
                              src={IMAGES.MOBILE_ADD_ICON}
                              onClick={() => arrayHelpers.insert(index + 1, "")}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />

                <h3 className={styles.assTextStyle}>
                  Assignment Directors And Consultant{" "}
                </h3>
              </div>
              <hr className={styles.cardDivider} />
              <div className={styles.middleCard}>
                <FormInput
                  defaultOptions
                  key={`${selectedValue}-director`}
                  label="Assignment Director"
                  type="async-select"
                  isClearable
                  value={values?.director}
                  loadOptions={loadDirector}
                  // onChange={(data) => setFieldValue("director", data)}
                  onChange={(data) => {
                    setSelectedValue(data);
                    setFieldValue("director", data);
                    const ids = values?.consultant?.filter(
                      (x) => x?.value !== data?.value
                    );
                    setFieldValue("consultant", ids);
                  }}
                  error={touched?.director && errors?.director}
                />

                <FormInput
                  isMulti
                  defaultOptions
                  key={`${selectedValue?.label}-consultant`}
                  label="Consultant"
                  type="async-select"
                  value={values?.consultant}
                  loadOptions={loadConsultant}
                  onChange={(data) => {
                    const hasMangerSame = data?.map(
                      (item) => item?.value === values?.director?.value
                    );
                    if (hasMangerSame.includes(true)) {
                      setFieldValue("director", "");
                    }
                    setSelectedValue(data);
                    setFieldValue("consultant", data || []);
                  }}
                  error={touched?.consultant && errors?.consultant}
                />
              </div>
              <div className={styles.bottomFotter}>
                <div className="d-flex ">
                  <PrimaryButton
                    onClick={() => validationError(errors)}
                    label={
                      loading
                        ? "saving..."
                        : id
                        ? "Save and View"
                        : "Save and Create"
                    }
                    disabled={loading || !dirty}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddAssignment;
