import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../constants/assets";
import Ageing from "../../../../components/Ageing";
import { Link } from "react-router-dom";

const CustomTable = ({ list }) => {
  return (
    <>
      <div className={`${styles.tableHeading} ${styles.tableHeadingFont}`}>
        <div className={styles.tableHeadingDataName}>Name</div>

        <div className={styles.tableHeadingDataActions}>Ageing</div>
      </div>
      <hr className={styles.tableDivider} />

      <div className={styles.tableDataOuterContainer}>
        {list.length ? (
          list?.map((data) => (
            <>
              <div
                className={`${styles.tableDataContainer} ${styles.TableDataFontStyle}`}
              >
                <div className={styles.tableDataNameSize}>
                  {data?.title}
                  {data?.title && (
                    <Link
                      to={`/assignments/overview/${data?._id}?tab=overview&activetab=open-assignments`}
                      target="_blank"
                    >
                      <img
                        alt="link"
                        src={IMAGES.EXTERNAL_LINK}
                        className={styles.LinkIcon}
                      />
                    </Link>
                  )}
                </div>
                <div className={styles.tableDataActionsSize}>
                  <Ageing assignmentDate={data?.createdAt} />
                </div>
              </div>
              <hr className={styles.cardDivider} />
            </>
          ))
        ) : (
          <div className={styles.noDataFound}>
            You are currently not part of any assignment.
          </div>
        )}
      </div>
    </>
  );
};
export default CustomTable;
