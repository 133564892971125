import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import { addEditApi, listApi } from "../../../../../../api/commonApis";
import { APIS } from "../../../../../../api/endpoints";
import { assignmentStatus } from "../../../../../../constants/contants";
import { useEffect } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { toast } from "react-toastify";
import Skeleton from "../../../../../../components/skeleton";
import ToastMessage from "../../../../../../components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";

const AssignmentStatusBox = ({ isArchive }) => {
  const { id } = useParams();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [assignmentData, setAssignmentData] = useState({});

  useEffect(() => {
    setLoading(false);
    listApi(APIS.LIST_ASSIGNMENT_OVERVIEW, {
      assignmentId: id,
    })
      .then((result) => {
        setAssignmentData(result?.data);
      })
      .finally(() => setLoading(false));
  }, [success]);

  const assignmentStatusHandler = (event) => {
    let status = event.target.value;
    addEditApi(APIS.ADD_EDIT_ASSIGNMNET, {
      assginmentId: id,
      status: status,
      statusFrom: assignmentStatus.find(
        (o) => o.value === assignmentData?.status
      )?.label,
      statusName: assignmentStatus.find((o) => o.value === status)?.label,
    }).then((result) => {
      toast.success(
        <ToastMessage title={"Success"} message={TOAST_MESSAGE.UPDATE_STATUS} />
      );
      setSuccess((prev) => !prev);
    });
  };
  return (
    <div className={`${styles.offlimitToggle} `}>
      <h2
        className={`${styles.statustext}
        `}
      >
        Assignment Status
      </h2>

      {assignmentData?.status ? (
        <Select
          size="small"
          variant="standard"
          className={styles.changeSelect}
          disabled={isArchive ? true : false}
          value={assignmentData?.status}
          inputProps={{ "aria-label": "Without label" }}
          onChange={(event) => assignmentStatusHandler(event)}
          IconComponent={MoreHorizIcon}
          sx={{
            "&	.MuiSelect-select": {
              padding: "5px 0px 5px 10px",
              fontSize: "12px",
              fontWeight: "bold",
              backgroundColor: "#dedede",
              borderRadius: "6px",
              ":focus": {
                backgroundColor: "#dedede",
                borderRadius: "6px",
              },
              ":hover": {
                backgroundColor: "#d9dffc",
              },
            },
          }}
        >
          {assignmentStatus.map((item, index) => (
            <MenuItem
              key={index}
              value={item?.value}
              className={styles.selectItem}
            >
              {item?.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Skeleton height="25px" marginBottom="0px" />
      )}
    </div>
  );
};

export default AssignmentStatusBox;
