import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DeleteUserSection from "./deleteUserSection";
import ButtonContainer from "./buttonContainer";
import Header from "./header";
import AddTalentSection from "./addTalentSection";

export const searchByFields = (data, searchValue) => {
  return (
    searchValue === null ||
    searchValue === "" ||
    (data &&
      data?.some((i) => i?.toLowerCase().includes(searchValue?.toLowerCase())))
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "48%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid white",
  boxShadow: 24,
  borderRadius: "4px",
  p: 4,
};
const singlOuterList = {
  color: "black",
  fontSize: "12px",
  fontWeight: "normal",
  paddingLeft: "17px",
  paddingRight: "17px",
  paddingBottom: "14px",
};
const ConfirmationModal = ({
  transferId,
  setTransferId,
  list,
  open,
  closeModal,
  onChangeHanlder,
  title,
  data,
  description,
  archive,
  block,
  status,
  blockUser,
  deleteTalentLabUser,
  deleteTransferTitle,
  reuqestDelete,
  addTalent,
  assignmentSearch,
  setAssignmentSearch,
  setAssignmentId,
  assignmentId,
  assigedAssignment,
  remove,
}) => {
  const [enable, setEnable] = useState(false);
  const [search, setSearch] = useState("");

  const hanlderInput = (e) => {
    if (archive) {
      if (e.target.value === "ARCHIVE") {
        setEnable(true);
      } else {
        setEnable(false);
      }
    } else if (remove) {
      if (e.target.value === "REMOVE") {
        setEnable(true);
      } else {
        setEnable(false);
      }
    } else {
      if (e.target.value === "DELETE") {
        setEnable(true);
      } else {
        setEnable(false);
      }
    }
  };

  useEffect(() => {
    setEnable(false);
    if (transferId) {
      setTransferId("");
    }
  }, [open]);

  useEffect(() => {
    if (assignmentId) {
      setEnable(true);
    }
  }, [assignmentId]);

  return (
    <div>
      <Modal
        disableAutoFocus={true}
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.container}>
          {!addTalent && (
            <Header
              remove={remove}
              addTalent={addTalent}
              block={block}
              archive={archive}
              data={data}
              description={description}
              blockUser={blockUser}
              deleteTalentLabUser={deleteTalentLabUser}
              reuqestDelete={reuqestDelete}
              status={status}
            />
          )}

          {/* TalentLAb user Tansfer Deleted Record to other  */}

          {deleteTalentLabUser && !block && (
            <DeleteUserSection
              deleteTransferTitle={deleteTransferTitle}
              transferId={transferId}
              setTransferId={setTransferId}
              singlOuterList={singlOuterList}
              search={search}
              setSearch={setSearch}
              list={list}
              searchByFields={searchByFields}
            />
          )}

          {addTalent && (
            <AddTalentSection
              assigedAssignment={assigedAssignment}
              title={title}
              assignmentId={assignmentId}
              setAssignmentId={setAssignmentId}
              singlOuterList={singlOuterList}
              search={assignmentSearch}
              setSearch={setAssignmentSearch}
              list={list}
            />
          )}

          {/* It Will Not Work in case of the Dataset and TalenLab user Block State */}

          {!addTalent && !block && (
            <>
              <div className={styles.titleCss}>{title}</div>
              <input
                className={styles.confirmationInput}
                onChange={(e) => hanlderInput(e)}
              />
            </>
          )}

          <ButtonContainer
            addTalent={addTalent}
            closeModal={closeModal}
            deleteTalentLabUser={deleteTalentLabUser}
            block={block}
            enable={enable}
            transferId={transferId}
            onChangeHanlder={onChangeHanlder}
            archive={archive}
            status={status}
            remove={remove}
          />
        </Box>
      </Modal>
    </div>
  );
};
export default ConfirmationModal;
