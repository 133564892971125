import React, { useState } from "react";
import styles from "./styles.module.scss";
import OffLimitModal from "./OffLimitModal";
import ReminderModal from "../../../../../components/modal/reminderModal";
import CalenderComponent from "../../../../../components/overviewRightHeaderBar/calenderCoponent";
import OffLimitComponent from "../../../../../components/overviewRightHeaderBar/offlimitComponent";
import { ristrictAccess } from "utils/helper";
const StatusToggles = ({
  talentData,
  isModalOpened,
  refreshdata,
  activeTabType,
}) => {
  const isOffLimit = talentData?.status === 4;
  const [offLimitAnchorEl, setOffLimitAnchorEl] = useState(null);
  const [offLimitBackDrop, setOffLimitBackDrop] = useState(false);
  const [offLimitActive, setOffLimitActive] = useState(false);
  const offLimitOpen = Boolean(offLimitAnchorEl);
  const [reminderAnchorEl, setReminderAnchorEl] = useState(null);
  const [openRminderBackDrop, setOpenReminderBackDrop] = useState(false);
  const [reminderActive, setReminderActive] = useState(false);
  const openReminder = Boolean(reminderAnchorEl);
  const reminderId = openReminder ? "reminder-modal" : undefined;
  const [aria, setAria] = useState("");
  const divRef = React.useRef();

  /**
   * OffLimit open modal
   * @param {} event
   */
  const openOffLimitModal = (event) => {
    setOffLimitActive(!offLimitActive);
    setAria("offLimit-popover");
    setReminderAnchorEl(null);
    setOpenReminderBackDrop(false);

    if (!offLimitActive) {
      setOffLimitAnchorEl(divRef.current);
      setOffLimitBackDrop(!offLimitOpen);
      setReminderActive(false);
    } else {
      setOffLimitAnchorEl(null);
      setOffLimitBackDrop(false);
    }
  };
  /**
   * OffLimit close modal
   */
  const closeOffLimitrModal = () => {
    setOffLimitAnchorEl(null);
    setOffLimitBackDrop(false);
    setOffLimitActive(!offLimitActive);
    refreshdata((prev) => !prev);
  };

  const openPopoverModal = (event) => {
    setReminderActive(!reminderActive);
    setAria("reminder-popover");
    setOffLimitAnchorEl(null);
    setOffLimitBackDrop(false);
    if (!reminderActive) {
      setReminderAnchorEl(divRef.current);
      setOpenReminderBackDrop(!openReminder);
      setOffLimitActive(false);
    } else {
      setReminderAnchorEl(null);
      setOpenReminderBackDrop(false);
    }
  };
  /**
   * Reminder close modal
   */
  const closeReminderModal = () => {
    setReminderAnchorEl(null);
    setOpenReminderBackDrop(false);
    setReminderActive(!reminderActive);
    refreshdata((prev) => !prev);
  };

  return (
    <>
      <div
        className={`${
          ristrictAccess() ? styles.hideContainer : styles.offlimitToggle
        } ${isModalOpened ? styles.activeModal : ""}`}
        style={{
          position: offLimitActive || reminderActive ? "relative" : "unset",
        }}
        aria-describedby={reminderId}
        ref={divRef}
      >
        <CalenderComponent
          openPopoverModal={openPopoverModal}
          text="Add Reminder/Follow Up"
          reminderActive={reminderActive}
          disableCalender={offLimitOpen}
          isOffLimit={isOffLimit}
        />
        <OffLimitComponent
          openOffLimitModal={openOffLimitModal}
          isOffLimit={isOffLimit}
          reminderActive={reminderActive}
        />
      </div>

      <ReminderModal
        activeTabType={activeTabType}
        open={openReminder}
        openBackDrop={openRminderBackDrop}
        anchorEl={reminderAnchorEl}
        closeModal={closeReminderModal}
        idd={aria}
      />

      <OffLimitModal
        open={offLimitOpen}
        idd={aria}
        closeModal={closeOffLimitrModal}
        openBackDrop={offLimitBackDrop}
        anchorEl={offLimitAnchorEl}
        talent={talentData}
      />
    </>
  );
};

export default StatusToggles;
