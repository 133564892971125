import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";
import { Menu, MenuItem } from "@mui/material";
import { useEffect } from "react";
import { getState } from "redux/store";
import { isSuperAdmin } from "utils/helper";

const ITEM_HEIGHT = 48;

const DeleteCommentMenu = ({
  setOpenConfirmation,
  setAnchorEl,
  anchorEl,
  open,
  addedById,
}) => {
  const [hover, setHover] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const currentLoginId = getState()?.auth?.data?._id;
  const [superAdmin, setSuperAdmin] = useState(false);

  useEffect(() => {
    if (addedById === currentLoginId) {
      setIsActive(true);
    }
    setSuperAdmin(isSuperAdmin());
  }, []);

  return (
    <>
      {(isActive || superAdmin) && (
        <div className={`${styles.dotss} ${hover && styles.activeDotsBack}`}>
          <img
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            aria-label="more"
            id="menu"
            aria-haspopup="true"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            src={hover ? IMAGES.ACTIVE_DOTS : IMAGES.DOTSS_ICON}
            alt="dotssicon"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          />
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            sx={{
              "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
                "&:hover": {
                  backgroundColor: "#e7e9fe",
                },
              },
            }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "12ch",
              },
            }}
          >
            <MenuItem
              className={styles.menuItemStyle}
              onClick={() => {
                setAnchorEl(null);
                setOpenConfirmation(true);
              }}
            >
              <img
                src={IMAGES.TABLE_MENU_DELETE}
                className={styles.iconstyle}
              />
              Delete
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  );
};

export default DeleteCommentMenu;
