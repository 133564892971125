import React, { useState, useEffect, useMemo } from "react";
import styles from "./styles.module.scss";
import FilterButton from "../../../components/buttons/filterButton";
import FilterSelect from "../../filters/filterSelect";

const ClientFilter = ({
  onChange,
  appliedFilters,
  onReset,
  isApplied,
  filterData,
  onFilterChange,
  debouncedResults,
  handleChange,
  state,
  city,
  country,
}) => {
  return (
    <section>
      <div className="row mt-4">
        <div className="col-md-12 d-flex justify-content-between">
          <div className={`d-flex gap-2 ${styles.selectboxes}`}>
            <div className={styles.select}>
              <FilterSelect
                isMulti
                name="sector"
                placeholder="Sector"
                isSearchAble
                searchPlaceholder="Search for industries"
                value={appliedFilters?.sector}
                options={filterData?.sector}
                onChange={onFilterChange}
                onChangeText={debouncedResults}
              />
            </div>
            <div className={styles.select}>
              <FilterSelect
                locationFilter
                name="locations"
                state={state}
                city={city}
                country={country}
                isMulti
                isSearchAble
                searchPlaceholder="Search for locations"
                value={appliedFilters?.locations}
                onChange={onFilterChange}
                placeholder="Location"
                options={filterData?.locations}
              />
            </div>
            <div className={styles.select}>
              <FilterSelect
                name="director"
                isMulti
                onChange={onFilterChange}
                options={filterData?.director}
                value={appliedFilters?.director}
                placeholder="Account Director "
                searchPlaceholder="Search for account director"
                isSearchAble
                onChangeText={debouncedResults}
              />
            </div>
            <div className={styles.select}>
              <FilterSelect
                name="manager"
                isMulti
                onChange={onFilterChange}
                placeholder="Account Owner "
                isSearchAble
                searchPlaceholder="Search for account owner"
                value={appliedFilters?.manager}
                options={filterData?.manager}
                onChangeText={debouncedResults}
              />
            </div>
            <div className={`d-flex ${styles.toogleButtonCss}`}>
              <label className="me-3" style={{ fontWeight: "bold" }}>
                Active Assignments
              </label>
              <label class={styles.switch}>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    onChange((prev) => ({
                      ...prev,
                      activeAssignments: e?.target?.checked,
                    }))
                  }
                  checked={appliedFilters?.activeAssignments}
                />
                <span
                  class={`slider round ${styles.slider} ${styles.round}`}
                ></span>
              </label>
            </div>
          </div>
          <div className={styles.resetButtonContainer}>
            <FilterButton filterReset onClick={onReset} disabled={!isApplied} />

            {/* <PrimaryButton
          label="Reset Filters"
          onClick={onReset}
          disabled={!isApplied}
        /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientFilter;
