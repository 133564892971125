import React from "react";
import styles from "./styles.module.scss";
import RoundEditIcon from "pages/talent/components/actionIcons/roundEditIcon";
import RoundDeleteIcon from "pages/talent/components/actionIcons/roundDeleteIcon";
import { noDataLabels } from "constants/contants";
import { useNavigate } from "react-router-dom";

const EducationRow = ({
  data,
  hasWriteAccess,
  ristrictAccess,
  id,
  hasDeleteAccess,
  setDeleteId,
  setOpenModal,
}) => {
  const navigate = useNavigate();

  /* RETURN  */

  return (
    <>
      {data?.length === 0 ? (
        <div className={styles.noDataFoundContainer}>
          <h4 className={styles.dataNotFound}>{noDataLabels.educationList}</h4>
        </div>
      ) : (
        data?.map((data) => (
          <div className={styles.newdetailscontent}>
            <div className={styles.newdetailborder}>
              <div className={styles.flexContainer}>
                <div className={styles.roundIconContainer}>
                  {hasWriteAccess || ristrictAccess() ? (
                    <RoundEditIcon
                      onClick={() =>
                        navigate(
                          `/talent/education/${id}?educationId=${data?._id}&source=overview`
                        )
                      }
                    />
                  ) : (
                    ""
                  )}

                  {hasDeleteAccess && (
                    <RoundDeleteIcon
                      onClick={() => {
                        setDeleteId(data?._id);
                        setOpenModal(true);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={styles.detailsheading}>
                <h2 className={styles.capitalText}>{data?.universityName}</h2>
                <h3 className={styles.capitalText}>{data?.degree}</h3>

                <h4 className={styles.capitalText}>
                  {data?.joinDateMonth ? data?.joinDateMonth : ""}
                  {data?.joinDateYear ? ` ${data?.joinDateYear}` : ""}
                  {(data?.joinDateYear || data?.joinDateMonth) &&
                    (data?.currentlyStudy ||
                    data?.endDateMonth ||
                    data?.endDateYear
                      ? "-"
                      : "")}
                  {data?.currentlyStudy
                    ? ` Present`
                    : `${data?.endDateMonth ? ` ${data?.endDateMonth}` : ""} ${
                        data?.endDateYear ? ` ${data?.endDateYear}` : ""
                      }`}
                </h4>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};
export default EducationRow;
