import React from "react";
import { useDropzone } from "react-dropzone";
import { IMAGES } from "../../../constants/assets";
import { formatBytes } from "../../../utils/helper";
import styles from "./styles.module.scss";

const Dropzone = ({
  open,
  onDrop,
  accept,
  handleText,
  zoneSize,
  error,
  multiple = true,
  files = [],
}) => {
  const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
    useDropzone({
      accept,
      onDrop,
      multiple,
    });

  const filesRender = files?.map((file) => (
    <tr key={file?.path} className={styles.tableContainer}>
      <td className={styles.dropfileName}>
        <div>
          {file?.path ? file?.path : ""} {file?.fileName ? file?.fileName : ""}
        </div>
        <div style={{ fontSize: "10px" }}>{formatBytes(file?.size)}</div>
      </td>
    </tr>
  ));

  return (
    <>
      {error && <div className={styles.errorText}>{error}</div>}

      <div
        {...getRootProps()}
        className={
          zoneSize === "small"
            ? `${styles.smalldragattach} ${isDragActive ? styles.active : ""}`
            : `${styles.dragattach} ${isDragActive ? styles.active : ""}`
        }
      >
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center">
          <div
            className={
              zoneSize === "small"
                ? styles.smallOuterCircle
                : styles.bigOuterCircle
            }
          >
            <img src={IMAGES.BI_CLOUD} />
          </div>
          {isDragActive ? (
            <h5 className="dropzone-content">Release to drop the files here</h5>
          ) : (
            <h5>
              {filesRender?.length === 0 ? (
                <>
                  Drag and drop files here, or
                  <span style={{ color: "#011cf5" }} onClick={open}>
                    {" "}
                    Browse
                  </span>
                </>
              ) : (
                ""
              )}
            </h5>
          )}
        </div>
        <p className={styles.paraStyle}>
          doc, docx, pdf, jpg, png, txt, ppt, pptx, xls, xlsx and csv
        </p>
        <aside>{filesRender}</aside>
      </div>
    </>
  );
};
export default Dropzone;

{
  /* {filesRender?.length === 0 ? (
            handleText === "pdf" ? (
              <p className={styles.paraStyle}>PDF files only</p>
            ) : (
              <p className={styles.paraStyle}>DOC and PDF files only</p>
            )
          ) : (
            ""
          )} */
}
