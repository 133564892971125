import React from "react";
import styles from "./styles.module.scss";
import Checkbox from "components/table/checkbox";
import SwitchButton from "components/forms/switchButton";
import StatusSelect from "./statusSelect";
import DeleteComponent from "./deleteComponent";

const CandidateTable = ({
  statusChecked,
  candidates,
  allStatusCheckHandler,
  greenBackDropDown,
  hasWriteAccess,
  assignment,
  onStatusChange,
  hasDeleteAccess,
  deleteClickHanlder,
  statusCheckBoxHandler,
}) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col" className={styles.thCheckbox}>
            <Checkbox
              id="allselect"
              checked={
                statusChecked?.length === candidates?.length &&
                candidates?.length !== 0
              }
              onChange={allStatusCheckHandler}
            />
          </th>
          <th scope="col" className={styles.thName}>
            Name
          </th>
          <th scope="col" className={styles.thStatus}>
            Current Status
          </th>
          <th scope="col" className={styles.thRefCheck}>
            Ref. Check
          </th>
          <th scope="col" className={styles.thPotCheck}>
            Potential Hire
          </th>
          <th scope="col" className={styles.thDelete}></th>
        </tr>
      </thead>

      {!!candidates?.length !== 0 && (
        <tbody>
          {candidates?.map((data, index) => {
            return (
              <tr
                key={index}
                className={
                  statusChecked.includes(data?.talentId?._id)
                    ? styles.checkedRow
                    : ""
                }
              >
                <td style={{ verticalAlign: "middle" }}>
                  <Checkbox
                    id={data?.talentId?._id}
                    checked={statusChecked.includes(data?.talentId?._id)}
                    onChange={statusCheckBoxHandler}
                  />
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <div className={styles.tablecnames}>
                    <h2>{`${data?.talentId?.firstName} ${data?.talentId?.lastName}`}</h2>
                    <p>{data?.talentId?.recentWork?.designation || ""}</p>
                  </div>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <StatusSelect
                    greenBackDropDown={greenBackDropDown}
                    data={data}
                    hasWriteAccess={hasWriteAccess}
                    assignment={assignment}
                    onStatusChange={onStatusChange}
                  />
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <SwitchButton
                    checked={data?.refCheck}
                    disabled={!hasWriteAccess || !!assignment?.isArchive}
                    onChange={(e) =>
                      onStatusChange([data?.talentId?._id], "refCheck", e, true)
                    }
                  />
                </td>

                <td style={{ verticalAlign: "middle" }}>
                  <SwitchButton
                    disabled={!hasWriteAccess || !!assignment?.isArchive}
                    checked={data?.potentialHire}
                    onChange={(e) =>
                      onStatusChange(
                        [data?.talentId?._id],
                        "potentialHire",
                        e,
                        true
                      )
                    }
                  />
                </td>

                <td className={styles.iconTdContaner}>
                  {hasDeleteAccess && (
                    <DeleteComponent
                      assignment={assignment}
                      data={data}
                      deleteClickHanlder={deleteClickHanlder}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
};
export default CandidateTable;
