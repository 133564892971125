import React, { Fragment } from "react";
import styles from "./styles.module.scss";

const WorkBlock = ({ talent, hasWorkReadAccess, setParams }) => {
  return (
    <>
      <div className={styles.textBlock}>
        <h3>Work Summary</h3>
        {talent?.work?.map(
          (work, index) =>
            index < 2 && (
              <Fragment key={index}>
                <p>
                  {work?.designation}, {work?.role}
                </p>
                <h4>
                  {work?.companyName}
                  {work?.joinDateYear && `, ${work?.joinDateYear}`}
                  {work?.joinDateYear && (
                    <span className="text-lowercase">{` to `}</span>
                  )}
                  {work?.currentlyWork && ` Present`}
                  {!work?.currentlyWork &&
                    work?.endDateYear &&
                    ` ${work?.endDateYear}`}
                </h4>
              </Fragment>
            )
        )}

        {talent?.work?.length > 2 && hasWorkReadAccess && (
          <div
            className={styles.view_css}
            onClick={() => setParams({ tab: "work" })}
          >
            View All
          </div>
        )}
      </div>
      <hr className={styles.horizontalLine} />
    </>
  );
};

export default WorkBlock;
