import React, { useState } from "react";
import styles from "./styles.module.scss";
import DropButton from "./dropButton";
import DocumentTable from "../../documentTable";

const DocumentContainer = ({
  deleteType,
  buttonLabel,
  cardNumber,
  dataObj,
  heading,
  deleteClick,
  hasDeleteAccess,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ marginTop: "21px", marginBottom: "21px" }}>
      <div>
        <DropButton
          label={buttonLabel}
          onClick={() => setOpen(!open)}
          open={open}
        />
      </div>
      {!!open &&
        (cardNumber || !!dataObj.length > 0 ? (
          <div className={styles.tableBlock}>
            {cardNumber && (
              <div className={styles.infoHeading}>
                {`${heading} - `}
                <b style={{ color: "#2f2f2f" }}>{cardNumber}</b>
              </div>
            )}
            {!!dataObj.length > 0 && (
              <DocumentTable
                type={deleteType}
                data={dataObj}
                deleteClick={deleteClick}
                accessControl={hasDeleteAccess}
              />
            )}
          </div>
        ) : (
          <div className={`${styles.tableBlock} ${styles.noDataFound}`}>
            {deleteType === 1
              ? "No gst details added yet."
              : "No pan details added yet."}
          </div>
        ))}
    </div>
  );
};

export default DocumentContainer;
