import React from "react";
import { IMAGES } from "../../../constants/assets";
import styles from "./styles.module.scss";

const CapsuleFilter = ({ isActive = false, onClick, label }) => {
  return (
    <button
      className={` me-1 ms-1 ${styles.capsule} ${
        isActive ? styles.btnactive : ""
      }`}
      onClick={onClick}
    >
      {label}
      <img
        alt="icon"
        src={IMAGES.CROSS_ICON}
        className={styles.socialIcon}
        style={{ visibility: isActive ? "" : "hidden" }}
      />
    </button>
  );
};

export default CapsuleFilter;
