export const PERMISSIONS = {
  READ: "read",
  WRITE: "write",
  DELETE: "delete",
  ARCHIVE: "archive",
};

export const MODULES = {
  TALENT: "Talent",
  ORGANIZATIONS: "Organizations",
  ASSIGNMENT: "Assignment",
  OVERVIEW: "Overview",
  DATA_SETS: "Data Sets",
  // SEARCH: "Search",
};

export const SUB_MODULES = {
  OVERVIEW_OVERVIEW: "overviewOverView",
  // SEARCH_OVERVIEW: "SearchOverView",

  TALENT_OVERVIEW: "talentOverView",
  WORK: "work",
  EDUCATION: "education",
  COMPENSATION: "compensation",
  DOCUMENTS: "documents",
  CERT_PUBLICATIONS: "certsAndPublications",
  COMPENTENCY_MAPPING: "compentencyMapping",
  CANDIDATE_DOSSIER: "candidateDossier",
  CANDIDATE_HISTORY: "candidateHistory",

  ASSIGNMENTS: "assignments",
  CONTRACTS: "contracts",
  BILLING: "billing",
  CLIENT_HISTORY: "clientHistory",
  ORGANIZATION_OVERVIEW: "organizationOverView",
  POINT_OF_CONTACTS: "pointOfContact",

  ASSIGNED_CANDIDATE: "assignedCandidates",
  ADD_CANDIDATE: "addCandidates",
  ACTIVITY_LOG: "activityLog",
  ASSIGNMENT_HISTORY: "assignmentHistory",
  ASSIGNMENT_OVERVIEW: "assignmentOverView",

  COMPETENCIES: "competencies",
  FUNCTIONAL_AREAS: "functionalAreas",
  INDUSTRY_TYPE: "industryTypes",
  SECTORS: "sectors",
  SKILLS_TAGS: "skillsTags",
  USER_MANAGMENT: "userManagment",
  EDUCATION_TYPE: "educationTypes",
};
