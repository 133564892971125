import React, { useState } from "react";
import styles from "./styles.module.scss";

const PrimaryButton = ({
  label,
  onClick,
  disabled,
  className = "",
  danger = false,
  icon,
  smallSize,
  visible = true,
  leftIcon: LIcon,
  rightIcon: RIcon,
  rightImageClassName = "",
  leftImageClassName = "",
  ...rest
}) => {
  const [hoverActive, setHoverActive] = useState(false);

  return (
    <button
      className={`${styles.btn} ${className}  ${
        disabled ? "" : styles.hoverEffect
      } `}
      style={{ visibility: visible ? "visible" : "hidden" }}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={() => setHoverActive(true)}
      onMouseLeave={() => setHoverActive(false)}
      {...rest}
    >
      <div className={styles.iconContainer}>
        {LIcon && (
          <LIcon
            color={hoverActive ? "#001cf5" : "#ffff"}
            className={leftImageClassName}
          />
        )}
        {label}
        {RIcon && (
          <RIcon
            color={hoverActive ? "#001cf5" : "#ffff"}
            className={rightImageClassName}
          />
        )}
      </div>
    </button>
  );
};

export default PrimaryButton;
