import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../constants/assets";
import CustomTable from "./customTable";
import { listApi } from "../../../api/commonApis";
import { APIS } from "../../../api";
import PrimaryButton from "components/buttons/primaryButton";

const Assignment = () => {
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);

  /*Table  Assignment Api Data */

  useEffect(() => {
    listApi(APIS.LIST_ASSIGNMENT, { search, sendOwnAssignment: true }).then(
      (result) => {
        setTableData(result?.data?.data);
      }
    );
  }, [search]);

  //////////// HANDLER FOR SEARCHING ///////////////
  const SearchedData = (event) => {
    setSearch(event?.target?.value);
  };

  const searchHandler = debounce(SearchedData, 300);

  return (
    <div className={`tab-content ${styles.tabcontent}`} id="v-pills-tabContent">
      <div className={styles.edithead}>
        <div className={styles.topheading}>
          <h3 className={styles.h3heading}>Assignments</h3>
          <div className="d-flex">
            <PrimaryButton
              className={styles.hidbutton}
              leftImageClassName={styles.addTalentUser}
            />
          </div>
        </div>
      </div>
      <hr className={styles.cardDivider} />

      <div className={styles.middleCard}>
        <div className={styles.searchInputContainer}>
          <input
            type="text"
            className={styles.searchInputDataSets}
            placeholder="Search"
            onChange={searchHandler}
          />

          <img
            alt="search"
            src={IMAGES.SEARCH_ICON}
            className={styles.searchIcon}
          />
        </div>
      </div>
      <CustomTable list={tableData} />
    </div>
  );
};

export default Assignment;
