import React from "react";
import Documents from "../../addEdit/documents";
import styles from "./styles.module.scss";
import { ristrictAccess } from "utils/helper";

const DocumentsOverview = () => {
  return (
    <div
      className={
        ristrictAccess() ? styles.ristrictContainer : styles.talentdetails
      }
    >
      <Documents fromOverview />
    </div>
  );
};

export default DocumentsOverview;
