import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../constants/assets";
const CalenderComponent = ({
  idd,
  openPopoverModal,
  text,
  reminderActive,
  disableCalender,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <>
      <div
        className={styles.calender}
        aria-describedby={idd}
        onClick={!disableCalender && openPopoverModal}
      >
        <img
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          src={
            disableCalender
              ? IMAGES.DISABLE_CALENDER
              : reminderActive
              ? IMAGES.ACTIVE_CALENDER
              : hover
              ? IMAGES.ACTIVE_CALENDER
              : IMAGES.CALENDER_ICON
          }
          alt="calender"
          aria-disabled
          className={styles.icon}
        />
        <h2
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          className={`${styles.text} 
          
          ${
            reminderActive
              ? styles.hoverEffect
              : disableCalender
              ? hover
                ? styles.disabletext
                : styles.disabletext
              : ""
          }
          ${hover ? styles.hoverEffect : ""} 
          
         `}
        >
          {text}
        </h2>
      </div>
    </>
  );
};

export default CalenderComponent;
