import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import Skeleton from "../../../../components/skeleton";
import { APIS } from "../../../../api/endpoints";
import { listApi } from "../../../../api/commonApis";
import { emptyTableText } from "../../../../constants/contants";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../../constants/accessControl";
import AssignmentsTable from "./table/assignmentsTable";
import ClientHeader from "components/pageHeader/clientHeader";
import { hasPermission } from "utils/helper";

const Assingnments = ({ client }) => {
  const [tableData, setTableData] = useState([]);
  const { id } = useParams();
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasAssignmentAccess, setHasAssignmentAccess] = useState(false);

  //////// GETTING PERMISSION FOR SAME COMPONENT//////
  useEffect(() => {
    setHasAssignmentAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_OVERVIEW,
        PERMISSIONS.READ
      )
    );
    setHasWriteAccess(
      hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.ASSIGNMENTS,
        PERMISSIONS.WRITE
      )
    );
  }, []);

  //////////// GETTING DATA LIST FROM API/////
  useEffect(() => {
    listApi(APIS.LIST_CLIENT_DETAILS, { clientId: id, type: 2 }).then(
      (result) => {
        setTableData(result?.data);
      }
    );
  }, []);

  return (
    <div className={styles.talentdetails}>
      {client ? (
        <>
          <ClientHeader
            client={client}
            hasWriteAccess={hasWriteAccess}
            currentLocation={window.location.href}
            id={id}
          />

          <div className={styles.tableContainer}>
            <div className={`mb-3 pb-1 ${styles.summary}`}>
              {tableData?.length === 0 ? (
                <div style={{ textAlign: "center" }}>
                  <h4 className={styles.dataNotFound}>
                    {emptyTableText.organisationAssignment}
                  </h4>
                </div>
              ) : (
                <AssignmentsTable
                  tableData={tableData}
                  hasAssignmentAccess={hasAssignmentAccess}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <Skeleton height="300px" marginBottom="30px" />
        </>
      )}
    </div>
  );
};
export default Assingnments;
