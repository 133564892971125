import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IMAGE_BASE_URL } from "constants/contants";
import { IMAGES } from "constants/assets";

const ProfileAvatar = ({ handleMenuClick, menuOpen, profileData }) => {
  const [dropDownHover, setDropDownHover] = useState(false);

  return (
    <li
      className={`ms-2 ${styles.navitem} `}
      onClick={handleMenuClick}
      aria-controls={menuOpen ? "account-menu" : undefined}
    >
      <div className={`d-flex ${styles.pointer}`}>
        <div className={`tl-talent-profile ${styles.dropdowntoggle}`}>
          {profileData?.imageUrl?.original ? (
            <img
              src={IMAGE_BASE_URL + profileData?.imageUrl?.original}
              className={styles.adminImage}
            />
          ) : (
            `${profileData?.name?.[0]}${profileData?.lastName?.[0]}`
          )}
        </div>
        <div className={styles.dropContainer}>
          <img
            onMouseLeave={() => setDropDownHover(false)}
            onMouseEnter={() => setDropDownHover(true)}
            src={
              dropDownHover ? IMAGES.ACTIVE_NAVDROPARROW : IMAGES.NAVDROPARROW
            }
            className={`nav-link dropdown-toggle `}
            alt="icon"
          />
        </div>
      </div>
    </li>
  );
};

export default ProfileAvatar;
