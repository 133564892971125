import React, { useCallback, useEffect, useState } from "react";
import { addEditApi, listApi } from "../../../../api/commonApis";
import { APIS } from "../../../../api/endpoints";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import Dropzone from "../../../../components/forms/dropzone";
import Skeleton from "../../../../components/skeleton";
import ConfirmationModal from "../../../../components/modal/confirmationModal";
import {
  DELETE_MODAL_CONFIRMATION,
  emptyTableText,
} from "../../../../constants/contants";
import { toast } from "react-toastify";
import ToastMessage from "../../../../components/layout/toast";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../../constants/accessControl";
import { hasPermission, isSuperAdmin } from "../../../../utils/helper";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import DocumentTable from "components/docTable/documentTable";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { AcceptedFormat } from "../../../../constants/contants";
import { getState } from "../../../../redux/store";

const documnetTableHeading = {
  th1: "File Name",
  th2: "Uploaded By",
  th3: "Uploaded On",
  th4: "Size",
  th5: "",
};

const Contracts = ({ fromOverview = false }) => {
  const { id } = useParams();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [documentId, setDocumentId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  /////// HANDLER FOR OPENEING MODAL////////

  const handleOpen = (id) => {
    setDocumentId(id);
    setOpen(true);
  };

  /////////// HANDLER FOR CLOSING A MODAL//////////\

  const handleClose = () => setOpen(false);

  ///// GETTING PERMISSIN FOR SAME COMPONNEt/////

  useEffect(() => {
    const user = getState()?.auth?.data?.talentLabRole;

    // check if the user is of Organizations role then give write permission or then check the user roles
    const permission =
      user === "Organizations"
        ? true
        : hasPermission(
            MODULES.ORGANIZATIONS,
            SUB_MODULES.CONTRACTS,
            PERMISSIONS.WRITE
          );

    setHasWriteAccess(permission);
    setHasDeleteAccess(isSuperAdmin());
  }, []);

  ////// GETTING LIST OF DATA///////

  useEffect(() => {
    setLoading(true);
    listApi(APIS.LIST_CLIENT_DETAILS, {
      clientId: id,
      type: 1,
    })
      .then((response) => {
        setUploadedFiles(response?.data);
      })
      .finally(() => setLoading(false));
  }, [success, refresh]);

  ///////////// HANLDRE FOR DELETING A DOCUMENTS/////////

  const handleDeleteDocument = () => {
    addEditApi(APIS.DELETE_DOCUMENT_DATA, { type: 2, id: documentId }).then(
      (result) => {
        setRefresh(!refresh);
        handleClose();
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.DELETE_DOCUMENT}
          />
        );
      }
    );
  };

  ////////// HANDLER FOR DROPING DOCX///////

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  //upload files
  const handleSubmit = () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("clientId", id);

    for (let i = 0; i < files?.length; i++) {
      formData.append("file", files[i]);
    }

    /// SET TIMEOUT FOR NOTIFY IF API WILL TAKE MORE THAN 5 SEC//

    const timeoutId = setTimeout(() => {
      toast.success(
        <ToastMessage
          title={"Success"}
          message={TOAST_MESSAGE.UPLOAD_PROGRESS}
        />
      );
    }, 5000);

    toast.success(
      <ToastMessage title={"Success"} message={TOAST_MESSAGE.UPLOAD_PROGRESS} />
    );

    addEditApi(APIS.ADD_EDIT_CLIENT_CONTRACTS, formData)
      .then(() => {
        if (!fromOverview) {
          // navigate(`${ROUTES.CLIENT_OVERVIEW}`);
        }
        setSuccess((s) => !s);
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.SAVE_DOCUMENT}
          />
        );
      })
      .finally(() => {
        setFiles([]);
        setUploading(false);
        clearTimeout(timeoutId);
      });
  };

  ////////// RETURN //////

  return (
    <div
      className={`tab-content ${styles.tabcontent} ${
        fromOverview ? styles.fullwidth : ""
      }`}
    >
      <div className={styles.overviewHeading}>
        <h2> Upload Contracts</h2>

        {hasWriteAccess && (
          <PrimaryButton
            label={uploading ? "Uploading..." : "Upload"}
            disabled={files.length === 0}
            onClick={handleSubmit}
          />
        )}
      </div>
      {hasWriteAccess && (
        <Dropzone files={files} onDrop={onDrop} accept={AcceptedFormat} />
      )}

      {loading ? (
        <Skeleton
          height="100px"
          marginLeft="20px"
          marginRight="20px"
          width={"unset"}
        />
      ) : (
        <>
          {uploadedFiles?.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              <h4 className={styles.dataNotFound}>
                {emptyTableText.organisationContract}
              </h4>
            </div>
          ) : (
            <>
              <div className={styles.edithead}>
                <h3>Uploaded Contracts</h3>
              </div>
              <div className={styles.tableContainer}>
                <DocumentTable
                  tableHeading={documnetTableHeading}
                  tableData={uploadedFiles}
                  accessControl={hasDeleteAccess}
                  deleteClick={handleOpen}
                />
              </div>
            </>
          )}
        </>
      )}
      <ConfirmationModal
        title={DELETE_MODAL_CONFIRMATION}
        open={open}
        closeModal={handleClose}
        onChangeHanlder={handleDeleteDocument}
        data="this document"
        description=""
      />
    </div>
  );
};

export default Contracts;
