import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../../constants/assets";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import moment from "moment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ITEM_HEIGHT = 48;

const CustomTable = ({
  activeTab,
  addEditHanlder,
  open,
  handleClose,
  handleClick,
  anchorEl,
  list,
  loading,
  openConfirmHanlder,
  status,
  filterHandler,
  filter,
  hasWriteAccess,
  hasDeleteAccess,
}) => {
  return (
    <>
      <div className={`${styles.tableHeading} ${styles.tableHeadingFont}`}>
        <div onClick={filterHandler} className={styles.tableHeadingDataName}>
          Name
          {filter === 2 ? (
            <ArrowDownwardIcon className={styles.arrowStyle} />
          ) : (
            <ArrowUpwardIcon className={styles.arrowStyle} />
          )}
        </div>
        <div className={styles.tableHeadingSpace}></div>
        {activeTab === "datasets-compentency" ? (
          <>
            <div className={styles.tableHeadingDesc}>Description</div>
            <div className={styles.tableHeadingSpace}></div>
          </>
        ) : (
          <div className={styles.tableHeadingSpace}></div>
        )}
        <div className={styles.tableHeadingSpace}></div>
        <div className={styles.tableHeadingDataCreated}>Date Created</div>
        <div className={styles.tableHeadingDataStatus}>Status</div>
        <div className={styles.tableHeadingDataActions}>Actions</div>
      </div>
      <hr className={styles.tableDivider} />

      <div className={styles.tableDataOuterContainer}>
        {loading
          ? "" //  <Skeleton height="120px" marginBottom="15px" />
          : list?.map((data) => (
              <>
                <div
                  className={`${styles.tableDataContainer} ${styles.TableDataFontStyle}`}
                >
                  <div className={`${styles.tableDataNameSize} `}>
                    {data?.name}
                  </div>
                  <div className={styles.tableDataSpace}></div>
                  {activeTab === "datasets-compentency" ? (
                    <>
                      <div className={styles.tableDataDesc}>
                        {data?.description}
                      </div>
                      <div className={styles.tableDataSpace}></div>
                    </>
                  ) : (
                    <div className={styles.tableDataSpace}></div>
                  )}
                  <div className={styles.tableDataSpace}></div>
                  <div className={`${styles.tableDataCreatedSize} `}>
                    {moment(data?.createdAt)?.fromNow?.()}
                  </div>
                  <div className={`${styles.tableDataStatusSize} `}>
                    <div
                      className={
                        data?.isBlocked ? styles.blackDot : styles.greenDot
                      }
                    ></div>
                    {data?.isBlocked ? "Blocked" : "Active"}
                  </div>
                  <div className={styles.tableDataActionsSize}>
                    {(hasWriteAccess || hasDeleteAccess) && (
                      <Tooltip title="Menu">
                        <IconButton
                          aria-label="menu"
                          onClick={(event) => handleClick(event, data)}
                        >
                          <MoreVertIcon
                            sx={{ transform: "rotate(90deg)" }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <hr className={styles.cardDivider} />
              </>
            ))}
      </div>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        sx={{
          "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
            "&:hover": {
              backgroundColor: "#e7e9fe",
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "12ch",

            // boxShadow: "0px 2px 10px -10px #c3c3c3",
          },
        }}
      >
        {hasWriteAccess && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={(handleClose, addEditHanlder)}
          >
            <img
              alt="edit"
              src={IMAGES.TABLE_MENU_EDIT}
              className={styles.iconstyle}
            />
            Edit
          </MenuItem>
        )}

        {hasWriteAccess && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={() => {
              handleClose();
              openConfirmHanlder("block");
            }}
          >
            <img
              alt="block "
              src={status ? IMAGES.TABLE_MENU_UNBLOCK : IMAGES.TABLE_MENU_BLOCK}
              className={styles.iconstyle}
            />
            {status ? "Unblock" : "Block"}
          </MenuItem>
        )}

        {hasDeleteAccess && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={() => {
              handleClose();
              openConfirmHanlder("delete");
            }}
          >
            <img
              alt="delete"
              src={IMAGES.TABLE_MENU_DELETE}
              className={styles.iconstyle}
            />
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
export default CustomTable;
