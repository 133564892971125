import React from "react";
import styles from "./styles.module.scss";
import moment from "moment";
import { globalStandardNumber } from "../../../utils/helper";
import { formatAmount, getAmountPostFix } from "../../../utils/helper";
import { style } from "@mui/system";
const CompensationTable = ({ data = [], HeadingShow }) => {
  return (
    <>
      {HeadingShow ? (
        ""
      ) : (
        <div className={styles.formname}>
          <div className="d-flex justify-content-between align-items-center">
            <h3>Compensation History </h3>
          </div>
        </div>
      )}

      <div className={styles.tablehead}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className={styles.tdwidth}>
                Revised On
              </th>
              <th scope="col" className={styles.tdwidth}>
                <div className={styles.numberDiv}>Current</div>
              </th>
              <th scope="col" className={styles.tdwidth}>
                <div className={styles.numberDiv}>Expected</div>
              </th>
              <th scope="col" className={styles.tdwidth}>
                Revised By
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((items) => (
              <tr key={items._id}>
                <td>{moment(items?.createdAt).format("DD MMM YYYY")}</td>
                <td>
                  <div className={styles.numberDiv}>
                    {`${items?.currency || '₹'} ${formatAmount(items?.current)} `}
                    <span className={styles.amountPostFix}>
                      {getAmountPostFix(items?.current)}
                    </span>
                  </div>
                </td>
                <td>
                  <div className={styles.numberDiv}>
                    {`${items?.currency || '₹'} ${formatAmount(items?.expected)} `}
                    <span className={styles.amountPostFix}>
                      {getAmountPostFix(items?.expected)}
                    </span>
                  </div>
                  {/* <div>{globalStandardNumber(items?.expected)}</div> */}
                </td>
                <td>{`${items?.revisedBy?.name} ${items?.revisedBy?.lastName}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CompensationTable;
