export default  [
    {
        name: "India",
        code: "IN",
        capital: "New Delhi",
        continent: "Asia",
        currency: {
            code: "INR",
            name: "Indian Rupee",
            name_plural: "Indian rupees",
            symbol: "₹",
            symbol_native: "₹",
            decimal_digits: 2,
            rounding: 0,
        },
    },

    {
        name: "United States",
        code: "US",
        capital: "Washington",
        continent: "North America",
        currency: {
            code: "USD",
            name: "US Dollar",
            name_plural: "US dollars",
            symbol: "$",
            symbol_native: "$",
            decimal_digits: 2,
            rounding: 0,
        },
    },

    {
        name: "United Kingdom",
        code: "GB",
        capital: "London",
        continent: "Europe",
        currency: {
            code: "GBP",
            name: "British Pound",
            name_plural: "British pounds sterling",
            symbol: "£",
            symbol_native: "£",
            decimal_digits: 2,
            rounding: 0,
        },
    },
    {
        name: "France",
        code: "FR",
        capital: "Paris",
        continent: "Europe",
        currency: {
            code: "EUR",
            name: "Euro",
            name_plural: "euros",
            symbol: "€",
            symbol_native: "€",
            decimal_digits: 2,
            rounding: 0,
        },
    },

]
