import {
  LOGIN,
  LOGOUT,
  SUCCESS,
  REQUEST,
  FAIL,
  GOOGLE_AUTH,
  REFRESH_NOTIFICATIONS,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: null,
  refresh: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN[REQUEST]:
      return {
        ...state,
        loading: true,
      };

    case LOGIN[SUCCESS]:
      return {
        ...state,
        loading: false,
        data: action?.data,
      };

    case LOGIN[FAIL]:
      return {
        ...state,
        loading: false,
        data: null,
      };

    case LOGOUT[SUCCESS]:
      return { ...initialState };

    case GOOGLE_AUTH[SUCCESS]:
      return {
        ...state,
        data: { ...state?.data, ...action?.payload },
      };

    case REFRESH_NOTIFICATIONS[SUCCESS]:
      return {
        ...state,
        refresh: !state?.refresh,
      };

    default:
      return state;
  }
};

export default authReducer;
