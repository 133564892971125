import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { ROUTES } from "../../../../router/routes";
import Skeleton from "../../../../components/skeleton";
import { APIS } from "../../../../api/endpoints";
import { listApi, addEditApi } from "../../../../api/commonApis";
import { useParams, useSearchParams } from "react-router-dom";
import BasicConfirmationModal from "../../../../components/modal/basicConfirmationModal";
import { emptyTableText } from "../../../../constants/contants";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../../constants/accessControl";
import SummaryBox from "./summaryBox";
import SummaryTable from "./summaryTable";
import ScrollIcon from "../../../../components/icons/scrollIcon";
import AssignmentHeader from "components/pageHeader/assignmentHeader";
import { ASSIGNMENT_SUMMARY_BOX } from "../../../../constants/contants";
import { hasPermission } from "utils/helper";
import moment from "moment";
import ContactBlock from "./contactBlock";
import ContactRightBlock from "./contactRightBlock";

const Overview = ({ refresh }) => {
  const [params, setParams] = useSearchParams();
  const { id } = useParams();
  const activetab = params?.get?.("activetab");
  const [loading, setLoading] = useState(true);
  const [assignment, setAssignment] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [onScroll, setOnScroll] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasCandidateAccess, setHasCandidateAccess] = useState(false);

  ////// PERMISSION////
  useEffect(() => {
    setHasWriteAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_OVERVIEW,
        PERMISSIONS.WRITE
      )
    );
    setHasCandidateAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNED_CANDIDATE,
        PERMISSIONS.READ
      )
    );
  }, []);

  //////// GETTING ASSIGNEMTN MAIN DATA FROM API///////

  useEffect(() => {
    setLoading(true);
    listApi(APIS.LIST_ASSIGNMENT_OVERVIEW, { assignmentId: id })
      .then((result) => {
        setAssignment(result?.data);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  /////////// MODAL HALNDEr FOR SUPLICATE ASSIGNEMTN ///////

  const duplicateAssignmentHandler = () => {
    setOpenConfirmation(true);
  };
  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  //////////CLONE CONFIRMATION HANLDER//////////

  const cloneConfirmHandler = () => {
    handleCloseConfirmation();
    addEditApi(APIS.CLONE_ASSIGNMENT, { assignmentId: id }).then((response) => {
      window.open(
        `${ROUTES.ASSIGNMENTS}${ROUTES.OVERVIEW}/${response?.data?._id}?tab=overview&activetab=${activetab}`,
        "_blank"
      );
    });
  };

  //////// RETURN //////////

  return (
    <>
      <div className={` ${styles.tabMainContent}`}>
        {loading ? (
          <Skeleton height="300px" />
        ) : (
          <>
            <AssignmentHeader
              hasWriteAccess={hasWriteAccess}
              duplicateAssignmentHandler={duplicateAssignmentHandler}
              id={id}
              assignment={assignment}
            />
            <div className={styles.createByStyles}>
              Assignment created by
              <b style={{ color: "#2f2f2f" }}>
                {` ${assignment?.addedBy?.name} ${assignment?.addedBy?.lastName} `}
              </b>
              | {moment(assignment?.createdAt).format("DD MMM	YY")}
            </div>

            <div className={styles.contactContainer}>
              {(assignment?.contactPerson ||
                assignment?.contactPersonEmail ||
                assignment?.contactPersonPhoneNumber?.length > 0) && (
                <ContactBlock assignment={assignment} />
              )}

              <ContactRightBlock assignment={assignment} />
            </div>
            <hr className={styles.divider} />
            <div>
              <div className="d-flex justify-content-between ">
                <h3 className={`${styles.candidateContainer}`}>
                  Candidates Summary
                </h3>
                {onScroll && <ScrollIcon />}
              </div>
              <div
                onScroll={() => setOnScroll(true)}
                className={styles.boxContainer}
              >
                {ASSIGNMENT_SUMMARY_BOX.map((item, index) => {
                  return (
                    <SummaryBox
                      title={item?.title}
                      value={assignment?.[item.value]}
                      denominator={
                        index === 0
                          ? false
                          : assignment?.totalCandidates &&
                            assignment?.totalCandidates
                      }
                    />
                  );
                })}
              </div>
            </div>
            <hr className={styles.divider} />
            <h3 className={`${styles.candidateContainer}`}>
              Candidates Assigned
            </h3>
            {assignment?.candidates?.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                <h4 className={styles.dataNotFound}>
                  {emptyTableText.assignmentOverview}
                </h4>
              </div>
            ) : (
              <SummaryTable
                data={assignment}
                id={id}
                activetab={activetab}
                hasCandidateAccess={hasCandidateAccess}
              />
            )}
          </>
        )}
      </div>
      <BasicConfirmationModal
        openConfirmation={openConfirmation}
        closeModal={handleCloseConfirmation}
        cloneConfirmHandler={cloneConfirmHandler}
        data="Are you sure want to clone this assignment?"
        buttonLabel="Clone"
      />
    </>
  );
};

export default Overview;
