import React, { useCallback, useEffect, useState } from "react";
import { addEditApi, listApi } from "../../../../api/commonApis";
import { APIS } from "../../../../api/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../router/routes";
import styles from "./styles.module.scss";
import Dropzone from "../../../../components/forms/dropzone";
import Skeleton from "../../../../components/skeleton";
import ConfirmationModal from "../../../../components/modal/confirmationModal";
import {
  DELETE_MODAL_CONFIRMATION,
  emptyTableText,
} from "../../../../constants/contants";
import { toast } from "react-toastify";
import ToastMessage from "../../../../components/layout/toast";
import {
  hasPermission,
  ristrictAccess,
  isSuperAdmin,
} from "../../../../utils/helper";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../constants/accessControl";
import DocumentTable from "components/docTable/documentTable";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { AcceptedFormat } from "../../../../constants/contants";

const documnetTableHeading = {
  th1: "File Name",
  th2: "Uploaded By",
  th3: "Uploaded On",
  th4: "Size",
  th5: "",
};

const Offerdoc = ({ fromOverview = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [documentId, setDocumentId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  //////// OPEN HANLDER//////

  const handleOpen = (id) => {
    setDocumentId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  //////// GETTIGNG PERMISSION FOR THE SAME COMPONENT////

  useEffect(() => {
    setHasWriteAccess(
      hasPermission(MODULES.TALENT, SUB_MODULES.DOCUMENTS, PERMISSIONS.WRITE)
    );
    setHasDeleteAccess(isSuperAdmin());
  }, []);

  const handleDeleteDocument = () => {
    addEditApi(APIS.DELETE_DOCUMENT_DATA, { type: 19, id: documentId }).then(
      (result) => {
        setRefresh(!refresh);
        handleClose();
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.DELETE_DOCUMENT}
          />
        );
      }
    );
  };

  useEffect(() => {
    setLoading(true);

    listApi(APIS.TALENT_DETAILS_TYPE_WISE, {
      talentId: id,
      type: 10,
    })
      .then((response) => {
        setUploadedFiles(response?.data);
      })
      .finally(() => setLoading(false));
  }, [success, refresh]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  //upload files

  const handleSubmit = () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("talentId", id);

    for (let i = 0; i < files?.length; i++) {
      formData.append("file", files[i]);
    }
    const timeoutId = setTimeout(() => {
      toast.success(
        <ToastMessage
          title={"Success"}
          message={TOAST_MESSAGE.UPLOAD_PROGRESS}
        />
      );
    }, 5000);

    toast.success(
      <ToastMessage title={"Success"} message={TOAST_MESSAGE.UPLOAD_PROGRESS} />
    );

    addEditApi(APIS.ADD_EDIT_TALENT_OFFER_DOCUMENTS, formData)
      .then((result) => {
        if (!fromOverview) {
          navigate(`${ROUTES.TALENT}/certs-publications/${id}`);
        }

        setSuccess((p) => !p);
        toast.success(
          <>
            <ToastMessage
              title={"Success"}
              message={TOAST_MESSAGE.SAVE_DOCUMENT}
            />
          </>
        );
      })
      .finally(() => {
        setFiles([]);
        setUploading(false);
        clearTimeout(timeoutId);
      });
  };

  return (
    <div
      className={`tab-content ${styles.tabcontent} ${
        fromOverview ? styles.fullwidth : ""
      }`}
    >
      <div className={styles.overviewHeading}>
        <h2> Attach Offer Letter Documents</h2>

        {hasWriteAccess || ristrictAccess() ? (
          <PrimaryButton
            label={uploading ? "Uploading..." : "Upload"}
            disabled={files.length === 0}
            onClick={handleSubmit}
          />
        ) : (
          ""
        )}
      </div>
      {hasWriteAccess || ristrictAccess() ? (
        <Dropzone files={files} onDrop={onDrop} accept={AcceptedFormat} />
      ) : (
        ""
      )}

      {loading ? (
        <Skeleton
          height="100px"
          marginLeft="20px"
          marginRight="20px"
          width={"unset"}
        />
      ) : (
        <>
          {uploadedFiles?.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              <h4 className={styles.dataNotFound}>
                {emptyTableText.talentDocument}
              </h4>
            </div>
          ) : (
            <>
              <div className={styles.edithead}>
                <h3>Uploaded Offer Letter Documents</h3>
              </div>
              <div className={styles.tableContainer}>
                <DocumentTable
                  tableHeading={documnetTableHeading}
                  tableData={uploadedFiles}
                  accessControl={hasDeleteAccess}
                  deleteClick={handleOpen}
                />
              </div>
            </>
          )}
        </>
      )}
      <ConfirmationModal
        title={DELETE_MODAL_CONFIRMATION}
        open={open}
        closeModal={handleClose}
        onChangeHanlder={handleDeleteDocument}
        data="this document"
        description=""
      />
    </div>
  );
};

export default Offerdoc;
