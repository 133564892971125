import React from "react";
import { getAssignmentStatus } from "../../../utils/helper";
import styles from "./styles.module.scss";

const AssignmentTable = ({ assignmentsData }) => {
  return (
    <div>
      <table className={`table ${styles.tableStyle}`}>
        <thead>
          <tr>
            <th
              className={`${styles.headingPosition} ${styles.headingFontStyle}`}
            >
              Position
            </th>
            <th className={styles.headMiddleDiv}></th>
            <th className={`${styles.headingStage} ${styles.headingFontStyle}`}>
              Stage
            </th>
            <th className={styles.headMiddleDiv}></th>
            <th
              className={`${styles.headingClient} ${styles.headingFontStyle}`}
            >
              Client
            </th>
          </tr>
        </thead>
      </table>
      {assignmentsData ? (
        assignmentsData?.map((data, index) => (
          <div className={styles.tableRowContaners}>
            <div className={styles.tableDataPosition}>{data?.title}</div>
            <div className={styles.middleDiv}></div>
            <div className={styles.tableDataStage}>
              {getAssignmentStatus(data?.status)}
            </div>
            <div className={styles.middleDiv}></div>
            <div className={styles.tableDateClient}>
              {data?.companyId?.name || "-"}
            </div>
          </div>
        ))
      ) : (
        <div className={styles.noAssignmentTableRow}>No Assignments </div>
      )}
    </div>
  );
};

export default AssignmentTable;
