import React from "react";
import styles from "./styles.module.scss";

const ToastMessage = ({ title, isMultiLine, message }) => {
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      <b>{title}</b> <br></br>
      {isMultiLine ? (
        message?.map((msg, index) => (
          <div className={styles.toastMessage}>{msg}</div>
        ))
      ) : (
        <div className={styles.toastMessage}>{message}</div>
      )}
    </div>
  );
};
export default ToastMessage;
