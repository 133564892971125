import React from "react";
import styles from "./styles.module.scss";
import { BASE_URL } from "api/endpoints";
import moment from "moment";
import { IMAGES } from "constants/assets";
import ActionButtons from "components/buttons/actionButtons";

const DocumentTable = ({ type, data, accessControl, deleteClick }) => {
  const handlerDownloadFile = (e, fileName) => {
    fetch(`${BASE_URL}/bucket/${fileName}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      });
    });
  };

  return (
    <div className={styles.detailTable}>
      <div className={styles.tableContainer}>
        <table class="table m-0">
          <thead>
            <tr>
              <th scope="col" className={styles.thFieldName}>
                File Name
              </th>
              <th scope="col" className={styles.thUploadBy}>
                Upload By
              </th>
              <th scope="col" className={styles.thUploadOn}>
                Upload On
              </th>
              <th scope="col" className={styles.thAction}></th>
            </tr>
          </thead>

          <tbody>
            {data?.map((document, index, arr) => {
              return (
                <tr
                  className={`${styles.documentList} ${styles.tableFileName}`}
                  // onClick={() =>
                  //   window.open(
                  //     `${BASE_URL}/bucket/${document?.fileUrl?.original}`,
                  //     "_blank"
                  //   )
                  // }
                >
                  <td>
                    <div className={styles.documentName}>
                      <img
                        src={IMAGES.FILE_PDF}
                        className={styles.pdfIcon}
                        alt=""
                      />
                      <span>{document?.fileName || " "}</span>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tData}>
                      {document?.uploadedBy?.name || "-"}{" "}
                      {document?.uploadedBy?.lastName || "-"}
                    </div>
                  </td>
                  <td className={styles.tData}>
                    {moment(document?.createdAt).format("DD MMM YYYY") || "-"}
                  </td>
                  <td
                    className={styles.tData}
                    onClick={(e) => e.stopPropagation(e)}
                  >
                    <div className={styles.tableLinkConatiner}>
                      <ActionButtons
                        generatedById={document?.uploadedBy?._id}
                        viewAction={() =>
                          window.open(
                            `/docs/${document?.fileUrl?.original}`,
                            "_blank"
                          )
                        }
                        DownloadAction={(e) =>
                          handlerDownloadFile(e, document?.fileName)
                        }
                        deleteAction={(e) =>
                          deleteClick(e, document?._id, type)
                        }
                        accessControl={accessControl}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DocumentTable;
