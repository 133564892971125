import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";
import { getAssignmentStatus } from "utils/helper";
import moment from "moment";
import { ROLES } from "constants/contants";
import PrimaryButton from "components/buttons/primaryButton";
import { ROUTES } from "router/routes";
import { useSelector } from "react-redux";
import Ageing from "components/Ageing";

const ExtendTable = ({
  userRole,
  tableHeading,
  archive,
  tableData,
  onClick,
}) => {
  const isLoggedIn = useSelector((state) => state?.auth?.data);

  return (
    <>
      <h3 className={styles.summaryHead + " mb-2"}>{tableHeading}</h3>
      <div className={styles.assignmentTable}>
        <table class="table">
          <thead>
            <tr>
              <th scope="col" className={styles.thPosition}>
                Position
              </th>
              {userRole === ROLES.TALENT && (
                <th scope="col" className={styles.thAgeing}>
                  Client
                </th>
              )}
              {userRole === ROLES.ORGANIZATIONS && (
                <th scope="col" className={styles.thAgeing}>
                  Ageing
                </th>
              )}

              {!archive && (
                <th scope="col" className={styles.thAgeing}>
                  Current Status
                </th>
              )}

              <th scope="col" className={styles.thAgeing}>
                {archive ? "Archive Date" : "Initiation Date"}
              </th>
              {userRole === ROLES.ORGANIZATIONS && (
                <th scope="col" className={styles.thAgeing}></th>
              )}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((data, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className={styles.tData + " capitalize"}
                  >
                    <b>{data?.title}</b>
                    {/* {userRole === ROLES.ORGANIZATIONS && (
                      <img
                        alt=""
                        src={IMAGES.EXTERNAL_LINK}
                        className={styles.imageIcon}
                        onClick={() => onClick(data?._id)}
                      />
                    )} */}
                  </td>
                  {userRole === ROLES.TALENT && (
                    <td
                      style={{ verticalAlign: "middle" }}
                      className={styles.contentStyle}
                    >
                      {data?.companyId?.name ? data?.companyId?.name : ""}
                    </td>
                  )}

                  {userRole === ROLES.ORGANIZATIONS && (
                    <td
                      style={{ verticalAlign: "middle" }}
                      className={styles.contentStyle}
                    >
                      <Ageing assignmentDate={data?.createdAt} />
                    </td>
                  )}

                  {!archive && (
                    <td
                      style={{ verticalAlign: "middle" }}
                      className={styles.contentStyle}
                    >
                      {getAssignmentStatus(data?.status)}
                    </td>
                  )}
                  <td
                    style={{ verticalAlign: "middle" }}
                    className={styles.contentStyle}
                  >
                    <td>{moment(data?.createdAt).format("DD MMM YYYY")}</td>
                  </td>
                  {userRole === ROLES.ORGANIZATIONS && (
                    <td>
                      <PrimaryButton
                        className={styles.smallBtn}
                        label="Open"
                        onClick={() =>
                          window.open(
                            `${ROUTES.CLIENTS}/overview/${isLoggedIn?.commonId}?tab=assignments-ext&activeId=${data?._id}`,
                            "_blank"
                          )
                        }
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ExtendTable;
