import React from "react";
import styles from "./styles.module.scss";
import { IMAGE_BASE_URL } from "../../../../constants/contants";
import { IMAGES } from "constants/assets";

const IntroBlock = ({ data }) => {
  return (
    <div className={styles.organization}>
      <div>
        <h3 className={styles.organizationName}>{data?.name}</h3>
        <p className={styles.category}>{data?.category || "-"}</p>
        <img
          className={styles.logo}
          src={
            data?.imageUrl?.original
              ? IMAGE_BASE_URL + data?.imageUrl?.original
              : IMAGES.ORGANIZATION_PLACEHOLDER
          }
          alt="logo"
        />
      </div>
    </div>
  );
};

export default IntroBlock;
