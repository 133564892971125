import React from "react";
import styles from "./styles.module.scss";

const ToggleButton = ({ label, className, ...rest }) => {
  return (
    <div className={` ${styles.selectinput} ${className}`}>
      <label>{label}</label>&nbsp;&nbsp;
      <label class={styles.switch}>
        <input type="checkbox" {...{ ...rest, checked: rest.value }} />
        <span class={`slider round ${styles.slider} ${styles.round}`}></span>
      </label>
    </div>
  );
};

export default ToggleButton;
