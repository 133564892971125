import React, { useState } from "react";
import Overview from "./overview";
import AddEdit from "./components/addEdit";
import { useEffect } from "react";

const DataSet = ({ activeTab }) => {
  const [activeOverview, setActiveOverview] = useState(true);
  const [selectedData, setSelectedData] = useState("");

  useEffect(() => {
    setActiveOverview(true);
    setSelectedData("");
  }, [activeTab]);

  const RowSelected = (rowData) => {
    setSelectedData(rowData);
  };

  const addEditHanlder = (event, add) => {
    if (add) {
      setSelectedData(""); //to make the selected Data empty when cliked on the add education
    }
    setActiveOverview(false);
  };

  const addEditSaveHanlder = () => {
    setActiveOverview(true);
  };

  const backButtonHandler = () => {
    setActiveOverview(true);
  };
  return (
    <>
      {activeOverview ? (
        <Overview
          activeTab={activeTab}
          addEditHanlder={addEditHanlder}
          RowSelected={RowSelected}
        />
      ) : (
        <AddEdit
          activeTab={activeTab}
          addEditSaveHanlder={addEditSaveHanlder}
          selectedData={selectedData}
          backButtonHandler={backButtonHandler}
        />
      )}
    </>
  );
};
export default DataSet;
