import React from "react";
import styles from "./styles.module.scss";

const SummaryBlock = ({ talent }) => {
  return (
    <>
      <div className={styles.textBlock}>
        <h3>Executive Summary</h3>

        <div
          dangerouslySetInnerHTML={{
            __html: talent?.executiveSummary,
          }}
        ></div>
      </div>
      <hr className={styles.horizontalLine} />
    </>
  );
};

export default SummaryBlock;
