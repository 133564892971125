import React, { useState, useEffect } from "react";
import CompensationTable from "../../../../components/table/compensationTable";
import styles from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatAmount,
  getAmountPostFix,
  ristrictAccess,
} from "../../../../utils/helper";
import { noDataLabels } from "../../../../constants/contants";
import { hasPermission } from "../../../../utils/helper";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../constants/accessControl";
import RoundEditIcon from "../../components/actionIcons/roundEditIcon";
import { getState } from "redux/store";

const Compensation = ({ compensationHistory, current, asking, currency }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [hasWriteAccess, setHasWriteAccess] = useState(false);

  let opacityPreviousStyle = {},
    opacityCurrentStyle = {};
  if (
    !!compensationHistory?.length &&
    !compensationHistory?.[0]?.previous?.fixed
  ) {
    opacityPreviousStyle = { opacity: "0.5" };
  }

  if (!current) {
    opacityCurrentStyle = { opacity: "0.5" };
  }

  ///////// GETTING PERMISSION FOR SAME PAGE///////

  useEffect(() => {
    const user = getState()?.auth?.data?.talentLabRole;
    setHasWriteAccess(
      user === "Talent"
        ? true
        : hasPermission(
            MODULES.TALENT,
            SUB_MODULES.EDUCATION,
            PERMISSIONS.WRITE
          )
    );
  }, []);

  /////// RETURN ////////

  return (
    <div
      className={`${styles.innerContainer} ${
        ristrictAccess() ? styles.ristrictContainer : styles.talentdetails
      } `}
    >
      <div className={styles.tabcontent}>
        <div className={styles.topheading}>
          <div className={styles.overviewHeading}>
            <h2> Compensation Details</h2>

            {hasWriteAccess || ristrictAccess() ? (
              <RoundEditIcon
                title={current ? "Edit" : "Add"}
                onClick={() =>
                  navigate(
                    `/talent/compensation/${id}?source=overview${
                      current ? `&compensationId=${id}` : ""
                    }`
                  )
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {!!current?.fixed ||
        !!current?.variable ||
        !!current?.esops ||
        !!asking?.esops ||
        !!asking?.fixed ||
        !!asking?.variable ? (
          <div className={styles.detailscontent}>
            {(asking?.esops || asking?.fixed || asking?.variable) && (
              <>
                <div className={`d-flex ${styles.boxContainer}`}>
                  {!!asking?.fixed && (
                    <div className="col-md-4">
                      <div className={styles.boxx} style={opacityPreviousStyle}>
                        <h5>Expected Fixed</h5>

                        <h1>
                          {`${currency || "₹"} ${formatAmount(asking?.fixed)} `}
                          <span className={styles.amountPostFix}>
                            {getAmountPostFix(asking?.fixed)}
                          </span>
                        </h1>
                      </div>
                    </div>
                  )}
                  {!!asking?.variable && (
                    <div className="col-md-4">
                      <div className={styles.boxx} style={opacityPreviousStyle}>
                        <h5>Expected Variable Bonuses</h5>

                        <h1>
                          {`${currency || "₹"} ${formatAmount(
                            asking?.variable
                          )} `}
                          <span className={styles.amountPostFix}>
                            {getAmountPostFix(asking?.variable)}
                          </span>
                        </h1>
                      </div>
                    </div>
                  )}
                  {!!asking?.esops && (
                    <div className="col-md-4">
                      <div className={styles.boxx} style={opacityPreviousStyle}>
                        <h5>Expected ESOPs</h5>
                        <h1>
                          {`${currency || "₹"} ${formatAmount(asking?.esops)} `}
                          <span className={styles.amountPostFix}>
                            {getAmountPostFix(asking?.esops)}
                          </span>
                        </h1>
                      </div>
                    </div>
                  )}
                </div>
                {(asking?.esops || asking?.fixed || asking?.variable) && (
                  <hr className={styles.dashedLine} />
                )}
              </>
            )}
            <div className="d-flex ">
              {!!current?.fixed && (
                <div className="col-md-4 ">
                  <div className={styles.boxx} style={opacityCurrentStyle}>
                    <h5>Current Fixed</h5>
                    <h1>
                      {`${currency || "₹"} ${formatAmount(current?.fixed)} `}
                      <span className={styles.amountPostFix}>
                        {getAmountPostFix(current?.fixed)}
                      </span>
                    </h1>
                  </div>
                </div>
              )}
              {!!current?.variable && (
                <div className="col-md-4 ">
                  <div className={styles.boxx} style={opacityCurrentStyle}>
                    <h5>Current Variable Bonuses</h5>
                    <h1>
                      {`${currency || "₹"} ${formatAmount(current?.variable)} `}
                      <span className={styles.amountPostFix}>
                        {getAmountPostFix(current?.variable)}
                      </span>
                    </h1>
                  </div>
                </div>
              )}
              {!!current?.esops && (
                <div className="col-md-4 ">
                  <div className={styles.boxx} style={opacityCurrentStyle}>
                    <h5>Current ESOPs</h5>
                    <h1>
                      {`${currency || "₹"} ${formatAmount(current?.esops)} `}
                      <span className={styles.amountPostFix}>
                        {getAmountPostFix(current?.esops)}
                      </span>
                    </h1>
                  </div>
                </div>
              )}
            </div>

            {(!!current?.fixed || !!current?.variable || !!current?.esops) && (
              <hr className={`mb-0 ${styles.dashedLine}`} />
            )}
            <CompensationTable data={compensationHistory} HeadingShow={false} />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h4 className={styles.dataNotFound}>{noDataLabels.compensation}</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default Compensation;
