import React from "react";

const LoginFooter = () => {
  return (
    <div style={{ marginTop: "60px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="285.41"
        height="53.984"
        viewBox="0 0 285.41 53.984"
      >
        <g
          id="Group_603"
          dataName="Group 603"
          transform="translate(-386 -674.016)"
        >
          <g
            id="Group_594"
            dataName="Group 594"
            transform="translate(-449 17.299)"
          >
            <path
              id="Path_1"
              dataName="Path 1"
              d="M.046,2.946A.045.045,0,0,1,.092,2.9H7.6c9.16,0,12.686,2.84,12.686,9.709,0,6.824-3.527,9.618-12.549,9.618H5.816a.045.045,0,0,0-.046.046V29.1a.045.045,0,0,1-.046.046H5.679A4.071,4.071,0,0,1,0,25.433ZM8.61,17.189c4.076,0,5.816-1.282,5.816-4.671,0-3.343-1.786-4.626-5.816-4.626H5.816a.045.045,0,0,0-.046.046v9.206a.045.045,0,0,0,.046.046Z"
              transform="translate(835 655.1)"
              fill="#fff"
            />
            <path
              id="Path_2"
              dataName="Path 2"
              d="M45,24.763C45,18.4,49.351,14,55.625,14c6.824,0,10.854,4.763,10.213,11.953a.045.045,0,0,1-.046.046H50.542a.045.045,0,0,0-.046.046c.137,3.343,2.153,5.267,5.45,5.267,2.336,0,3.893-.87,4.671-2.519l.046-.046,4.946.6c.046,0,.046.046.046.092-1.557,4.03-4.9,6.091-9.893,6.091C49.259,35.525,45,31.266,45,24.763Zm15.709-2.336c.046,0,.046-.046,0,0a4.632,4.632,0,0,0-4.992-4.534,4.752,4.752,0,0,0-5.129,4.442c0,.046,0,.046.046.046l10.076.046Z"
              transform="translate(810.61 649.083)"
              fill="#fff"
            />
            <path
              id="Path_3"
              dataName="Path 3"
              d="M92.1,24.763C92.1,17.3,97.367,14,102.817,14c5.4,0,10.671,3.3,10.671,10.763,0,7.511-5.267,10.809-10.671,10.809C97.413,35.617,92.1,32.32,92.1,24.763Zm15.938,0c0-3.893-2.153-6.183-5.267-6.183-3.023,0-5.221,2.29-5.221,6.183,0,3.939,2.153,6.229,5.267,6.229S108.038,28.7,108.038,24.763Z"
              transform="translate(785.081 649.083)"
              fill="#fff"
            />
            <path
              id="Path_4"
              dataName="Path 4"
              d="M142.537,14.412h5.4a.045.045,0,0,1,.046.046v3.069c0,.046.092.092.092,0,.962-2.015,3.389-3.527,6.549-3.527,4.763,0,9.16,3.252,9.16,10.717s-4.442,10.717-9.206,10.717a7.056,7.056,0,0,1-6.5-3.618c-.046-.046-.092-.046-.092,0v4.4a5.387,5.387,0,0,1-5.542,5.4h0a.045.045,0,0,1-.046-.046l.092-27.159C142.492,14.458,142.492,14.412,142.537,14.412Zm15.755,10.351c0-3.893-2.153-6.229-5.221-6.229S147.8,20.87,147.8,24.763s2.153,6.183,5.221,6.183C156.14,30.946,158.292,28.656,158.292,24.763Z"
              transform="translate(757.817 649.083)"
              fill="#fff"
            />
            <path
              id="Path_5"
              dataName="Path 5"
              d="M193.123.2h5.45a.045.045,0,0,1,.046.046V27.588a.045.045,0,0,1-.046.046h-5.45a.045.045,0,0,1-.046-.046V.246C193.032.246,193.078.2,193.123.2Z"
              transform="translate(730.36 656.564)"
              fill="#fff"
            />
            <path
              id="Path_6"
              dataName="Path 6"
              d="M208.7,24.763C208.7,18.4,213.051,14,219.325,14c6.824,0,10.854,4.763,10.213,11.953a.045.045,0,0,1-.046.046H214.287a.045.045,0,0,0-.046.046c.137,3.343,2.153,5.267,5.45,5.267,2.336,0,3.893-.87,4.672-2.519l.046-.046,4.946.6c.046,0,.046.046.046.092-1.511,3.984-4.855,6.045-9.847,6.045C212.959,35.525,208.7,31.266,208.7,24.763Zm15.709-2.336a.045.045,0,0,0,.046-.046,4.691,4.691,0,0,0-5.038-4.488,4.752,4.752,0,0,0-5.129,4.442c0,.046,0,.046.046.046l10.076.046Z"
              transform="translate(721.882 649.083)"
              fill="#fff"
            />
            <path
              id="Path_7"
              dataName="Path 7"
              d="M271.314,23.28H256.887a.045.045,0,0,0-.046.046L254.414,29.1l-.046.046h-3.023c-.046,0-.046-.046-.046-.092L262.658,2.946,262.7,2.9H265.5a.045.045,0,0,1,.046.046L276.9,29.051c0,.046,0,.092-.046.092h-3.023a.045.045,0,0,1-.046-.046l-2.427-5.771A.045.045,0,0,0,271.314,23.28Zm-1.053-2.519L264.124,6.335c0-.046-.092-.046-.092,0l-6.091,14.427c0,.046,0,.092.046.092h12.228C270.261,20.807,270.306,20.807,270.261,20.762Z"
              transform="translate(698.791 655.1)"
              fill="#fff"
            />
            <path
              id="Path_8"
              dataName="Path 8"
              d="M304.846,30.413l2.473-.779c.046,0,.046,0,.046.046,1.282,2.2,3.8,3.435,7.1,3.435,3.756,0,5.954-1.374,5.954-3.71,0-5.45-14.472-1.237-14.472-9.48,0-3.389,3.527-5.725,8.29-5.725,3.618,0,7.053,1.74,8.473,4.305,0,.046,0,.046-.046.092l-2.382.87h-.046a7.033,7.033,0,0,0-6-2.794c-3.389,0-5.5,1.282-5.5,3.343,0,5.45,14.518,1.145,14.518,9.389,0,3.8-3.435,6.274-8.793,6.274-4.443,0-8.152-2.015-9.664-5.129C304.8,30.459,304.8,30.413,304.846,30.413Z"
              transform="translate(669.793 648.975)"
              fill="#fff"
            />
            <path
              id="Path_9"
              dataName="Path 9"
              d="M345.846,30.413l2.473-.779c.046,0,.046,0,.046.046,1.282,2.2,3.8,3.435,7.1,3.435,3.756,0,5.954-1.374,5.954-3.71,0-5.45-14.472-1.237-14.472-9.48,0-3.389,3.526-5.725,8.29-5.725,3.618,0,7.053,1.74,8.473,4.305,0,.046,0,.046-.046.092l-2.382.87h-.046a7.033,7.033,0,0,0-6-2.794c-3.389,0-5.5,1.282-5.5,3.343,0,5.45,14.518,1.145,14.518,9.389,0,3.8-3.435,6.274-8.793,6.274-4.442,0-8.152-2.015-9.663-5.129C345.8,30.459,345.8,30.413,345.846,30.413Z"
              transform="translate(647.571 648.975)"
              fill="#fff"
            />
            <path
              id="Path_10"
              dataName="Path 10"
              d="M387.1,24.763C387.1,18.4,391.313,14,397.4,14c6.412,0,10.351,4.534,9.938,11.266a.045.045,0,0,1-.046.046H389.985a.045.045,0,0,0-.046.046c.137,4.763,3.069,7.648,7.694,7.648,3.3,0,5.4-1.237,6.732-3.572l.046-.046,2.519.55c.046,0,.046.046.046.092-1.786,3.527-4.855,5.45-9.343,5.45C391.222,35.525,387.1,31.266,387.1,24.763Zm17.449-1.832a.045.045,0,0,0,.046-.046c-.275-3.939-2.931-6.458-7.145-6.458a7.022,7.022,0,0,0-7.419,6.458c0,.046,0,.046.046.046Z"
              transform="translate(625.187 649.083)"
              fill="#fff"
            />
            <path
              id="Path_11"
              dataName="Path 11"
              d="M432.669,21.971V12.078a.045.045,0,0,0-.046-.046h-2.977a.045.045,0,0,1-.046-.046v-2.2a.045.045,0,0,1,.046-.046h2.977a.045.045,0,0,0,.046-.046V5.941c0-.046,0-.046.046-.046l2.794-.6c.046,0,.046,0,.046.046V9.7a.045.045,0,0,0,.046.046h6.366a.045.045,0,0,1,.046.046v2.2a.045.045,0,0,1-.046.046H435.6a.045.045,0,0,0-.046.046v9.664c0,4.946,1.191,6.6,4.717,6.6a8.877,8.877,0,0,0,1.6-.183c.046,0,.046,0,.046.046l.366,2.2c0,.046,0,.046-.046.046a14.375,14.375,0,0,1-2.336.229C434.867,30.718,432.669,28.337,432.669,21.971Z"
              transform="translate(602.151 653.798)"
              fill="#fff"
            />
            <path
              id="Path_12"
              dataName="Path 12"
              d="M456.65,3.031V1.291h-.55V.879h.55V.192l.5-.092V.833a.045.045,0,0,0,.046.046h1.1v.412h-1.1a.045.045,0,0,0-.046.046V2.985c0,.87.229,1.145.824,1.145a.681.681,0,0,0,.275-.046l.092.412a1.413,1.413,0,0,1-.412.046C457.016,4.588,456.65,4.176,456.65,3.031Z"
              transform="translate(587.788 656.617)"
              fill="#fff"
            />
            <path
              id="Path_13"
              dataName="Path 13"
              d="M461.5,1.692h.5v.824a1.143,1.143,0,0,1,1.145-.87,1.009,1.009,0,0,1,1.1.779c0,.046.092.046.092,0a1.277,1.277,0,0,1,1.282-.824c.962,0,1.328.6,1.328,1.924V5.31h-.5V3.569c0-1.008-.275-1.511-.962-1.511-.733,0-1.008.458-1.008,1.511V5.31h-.5V3.569c0-1.053-.229-1.511-.916-1.511-.733,0-1.008.5-1.008,1.557V5.31h-.55V1.692Z"
              transform="translate(584.861 655.803)"
              fill="#fff"
            />
            <path
              id="Path_14"
              dataName="Path 14"
              d="M503.6,16.117l.641-.137a1.964,1.964,0,0,0,1.924,1.053c.962,0,1.6-.412,1.6-1.1,0-1.695-3.893-.55-3.893-2.885,0-.962.87-1.649,2.107-1.649a2.339,2.339,0,0,1,2.382,1.42l-.6.137A1.811,1.811,0,0,0,505.982,12c-.87,0-1.466.412-1.466,1.053,0,1.74,3.939.5,3.939,2.885,0,1.053-.916,1.695-2.29,1.695A2.555,2.555,0,0,1,503.6,16.117Z"
              transform="translate(562.043 650.492)"
              fill="#fff"
            />
            <path
              id="Path_15"
              dataName="Path 15"
              d="M515.6,16.519A2.347,2.347,0,0,1,517.982,14a2.24,2.24,0,0,1,2.29,2.611h-4.03a1.683,1.683,0,0,0,1.786,1.786,1.562,1.562,0,0,0,1.557-.824l.6.137a2.435,2.435,0,0,1-4.58-1.191Zm4.076-.412a1.531,1.531,0,0,0-1.649-1.511,1.636,1.636,0,0,0-1.74,1.511Z"
              transform="translate(555.539 649.083)"
              fill="#fff"
            />
            <path
              id="Path_16"
              dataName="Path 16"
              d="M527.6,16.519A2.246,2.246,0,0,1,529.844,14a1.849,1.849,0,0,1,1.832,1.191v-1.1h.687V18.9h-.687V17.847a1.849,1.849,0,0,1-1.832,1.191A2.244,2.244,0,0,1,527.6,16.519Zm4.122,0a1.749,1.749,0,1,0-3.481,0,1.749,1.749,0,1,0,3.481,0Z"
              transform="translate(549.034 649.083)"
              fill="#fff"
            />
            <path
              id="Path_17"
              dataName="Path 17"
              d="M540.7,14.255h.641v.87a1.4,1.4,0,0,1,1.74-.87l-.092.641c-1.191-.229-1.649.458-1.649,1.695v2.473H540.7Z"
              transform="translate(541.934 648.966)"
              fill="#fff"
            />
            <path
              id="Path_18"
              dataName="Path 18"
              d="M546.1,16.519A2.337,2.337,0,0,1,548.436,14a2.138,2.138,0,0,1,2.2,1.511l-.6.137a1.583,1.583,0,0,0-1.6-1.1,1.728,1.728,0,0,0-1.695,1.924,1.728,1.728,0,0,0,1.695,1.924,1.62,1.62,0,0,0,1.6-1.1l.6.137a2.153,2.153,0,0,1-2.2,1.511A2.214,2.214,0,0,1,546.1,16.519Z"
              transform="translate(539.007 649.083)"
              fill="#fff"
            />
            <path
              id="Path_19"
              dataName="Path 19"
              d="M558.1,10.9h.641v2.565a1.559,1.559,0,0,1,1.649-1.1c1.328,0,2.015.779,2.015,2.336v2.565h-.641V14.747c0-1.191-.458-1.786-1.511-1.786s-1.466.687-1.466,1.924v2.382h-.641L558.1,10.9Z"
              transform="translate(532.503 650.763)"
              fill="#fff"
            />
            <path
              id="Path_20"
              dataName="Path 20"
              d="M505.486,30.241H503.7V29.6h4.259v.641h-1.786v5.45h-.687v-5.45Z"
              transform="translate(561.988 640.628)"
              fill="#fff"
            />
            <path
              id="Path_21"
              dataName="Path 21"
              d="M512.3,34.619a2.246,2.246,0,0,1,2.244-2.519,1.849,1.849,0,0,1,1.832,1.191v-1.1h.687V37h-.687V35.947a1.849,1.849,0,0,1-1.832,1.191A2.222,2.222,0,0,1,512.3,34.619Zm4.122,0a1.749,1.749,0,1,0-3.481,0,1.713,1.713,0,0,0,1.74,1.924A1.737,1.737,0,0,0,516.422,34.619Z"
              transform="translate(557.326 639.272)"
              fill="#fff"
            />
            <path
              id="Path_22"
              dataName="Path 22"
              d="M525.3,29h.687v6.366H525.3Z"
              transform="translate(550.281 640.953)"
              fill="#fff"
            />
            <path
              id="Path_23"
              dataName="Path 23"
              d="M528.9,34.619a2.347,2.347,0,0,1,2.382-2.519,2.24,2.24,0,0,1,2.29,2.611h-4.03a1.683,1.683,0,0,0,1.786,1.786,1.562,1.562,0,0,0,1.557-.824l.6.137a2.435,2.435,0,0,1-4.58-1.191Zm4.076-.412a1.531,1.531,0,0,0-1.649-1.511,1.636,1.636,0,0,0-1.74,1.511Z"
              transform="translate(548.329 639.272)"
              fill="#fff"
            />
            <path
              id="Path_24"
              dataName="Path 24"
              d="M541.3,32.292h.641V33.3a1.634,1.634,0,0,1,1.649-1.1c1.374,0,2.015.779,2.015,2.336V37.1h-.641V34.582c0-1.191-.458-1.786-1.511-1.786s-1.466.687-1.466,1.924V37.1h-.641l-.046-4.809Z"
              transform="translate(541.608 639.219)"
              fill="#fff"
            />
            <path
              id="Path_25"
              dataName="Path 25"
              d="M552.533,33.993V31.7H551.8v-.55h.733v-.916l.687-.137v1.053h1.511v.55H553.22v2.244c0,1.145.275,1.511,1.1,1.511a1.29,1.29,0,0,0,.367-.046l.092.55a2.421,2.421,0,0,1-.55.046C553.036,36.008,552.533,35.458,552.533,33.993Z"
              transform="translate(535.917 640.357)"
              fill="#fff"
            />
            <path
              id="Path_26"
              dataName="Path 26"
              d="M504.1,50.814a2.873,2.873,0,1,1,5.725,0,2.78,2.78,0,0,1-2.885,3.114C505.428,53.929,504.1,53.013,504.1,50.814Zm5.038,0c0-1.557-.824-2.473-2.153-2.473-1.374,0-2.2.916-2.2,2.473,0,1.6.824,2.519,2.2,2.519S509.138,52.371,509.138,50.814Z"
              transform="translate(561.772 630.817)"
              fill="#fff"
            />
            <path
              id="Path_27"
              dataName="Path 27"
              d="M518.9,50.555h.641v.87a1.4,1.4,0,0,1,1.74-.87l-.092.641c-1.191-.229-1.649.458-1.649,1.695v2.473H518.9Z"
              transform="translate(553.75 629.291)"
              fill="#fff"
            />
            <path
              id="Path_28"
              dataName="Path 28"
              d="M524.637,55.813l.641-.183a1.741,1.741,0,0,0,1.6.916c1.1,0,1.74-.55,1.74-1.924v-.458a1.792,1.792,0,0,1-1.832,1.191,2.224,2.224,0,0,1-2.29-2.427,2.191,2.191,0,0,1,2.244-2.427,1.792,1.792,0,0,1,1.832,1.191v-1.1h.687v3.985c0,1.786-.87,2.565-2.381,2.565A2.488,2.488,0,0,1,524.637,55.813Zm3.984-2.931A1.676,1.676,0,0,0,526.882,51a1.7,1.7,0,0,0-1.74,1.878,1.676,1.676,0,0,0,1.74,1.878A1.654,1.654,0,0,0,528.622,52.882Z"
              transform="translate(550.715 629.3)"
              fill="#fff"
            />
            <path
              id="Path_29"
              dataName="Path 29"
              d="M537.2,52.819a2.246,2.246,0,0,1,2.244-2.519,1.849,1.849,0,0,1,1.832,1.191v-1.1h.687V55.2h-.687V54.147a1.849,1.849,0,0,1-1.832,1.191A2.222,2.222,0,0,1,537.2,52.819Zm4.122,0a1.749,1.749,0,1,0-1.74,1.924A1.713,1.713,0,0,0,541.322,52.819Z"
              transform="translate(543.831 629.408)"
              fill="#fff"
            />
            <path
              id="Path_30"
              dataName="Path 30"
              d="M550.2,50.492h.641V51.5a1.633,1.633,0,0,1,1.649-1.1c1.374,0,2.015.779,2.015,2.336V55.3h-.641V52.782c0-1.191-.458-1.786-1.511-1.786s-1.466.687-1.466,1.924V55.3h-.641l-.046-4.809Z"
              transform="translate(536.784 629.355)"
              fill="#fff"
            />
            <path
              id="Path_31"
              dataName="Path 31"
              d="M561.7,47.666a.415.415,0,1,1,.412.366A.351.351,0,0,1,561.7,47.666Zm.091,1.145h.641V53.62h-.641Z"
              transform="translate(530.551 631.034)"
              fill="#fff"
            />
            <path
              id="Path_32"
              dataName="Path 32"
              d="M565.6,54.813l3.114-3.664h-3.069V50.6h3.893v.6l-3.114,3.664h3.069v.55H565.6Z"
              transform="translate(528.438 629.245)"
              fill="#fff"
            />
            <path
              id="Path_33"
              dataName="Path 33"
              d="M575.3,52.819a2.246,2.246,0,0,1,2.244-2.519,1.849,1.849,0,0,1,1.832,1.191v-1.1h.687V55.2h-.687V54.147a1.849,1.849,0,0,1-1.832,1.191A2.222,2.222,0,0,1,575.3,52.819Zm4.122,0a1.749,1.749,0,1,0-1.74,1.924A1.713,1.713,0,0,0,579.422,52.819Z"
              transform="translate(523.18 629.408)"
              fill="#fff"
            />
            <path
              id="Path_34"
              dataName="Path 34"
              d="M588.033,52.193V49.9H587.3v-.55h.733v-.916l.687-.137v1.053h1.511v.55H588.72v2.244c0,1.145.275,1.511,1.1,1.511a1.289,1.289,0,0,0,.366-.046l.092.55a2.42,2.42,0,0,1-.55.046C588.537,54.208,588.033,53.658,588.033,52.193Z"
              transform="translate(516.677 630.493)"
              fill="#fff"
            />
            <path
              id="Path_35"
              dataName="Path 35"
              d="M595.3,47.666a.415.415,0,1,1,.412.366A.334.334,0,0,1,595.3,47.666Zm.046,1.145h.641V53.62h-.641Z"
              transform="translate(512.341 631.034)"
              fill="#fff"
            />
            <path
              id="Path_36"
              dataName="Path 36"
              d="M599.1,52.819a2.385,2.385,0,1,1,2.382,2.519A2.271,2.271,0,0,1,599.1,52.819Zm4.076,0a1.749,1.749,0,1,0-1.74,1.924A1.713,1.713,0,0,0,603.176,52.819Z"
              transform="translate(510.281 629.408)"
              fill="#fff"
            />
            <path
              id="Path_37"
              dataName="Path 37"
              d="M611.6,50.492h.641V51.5a1.633,1.633,0,0,1,1.649-1.1c1.374,0,2.015.779,2.015,2.336V55.3h-.641V52.782c0-1.191-.458-1.786-1.511-1.786s-1.466.687-1.466,1.924V55.3h-.641l-.046-4.809Z"
              transform="translate(503.505 629.355)"
              fill="#fff"
            />
          </g>
          <text
            id="_Copyright_ExecHunt_India_Private_Limited."
            dataName="© Copyright ExecHunt (India) Private Limited."
            transform="translate(399.41 713)"
            fill="#fff"
            fontSize="12"
            fontFamily="Public Sans, sans-serif "
          >
            <tspan x="0" y="12">
              © Copyright ExecHunt (India) Private Limited.
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export default LoginFooter;
