import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ContactBlock from "./contactContainer/contactBlock";
import AddressBlock from "./contactContainer/addressBlock";
import ActionBlock from "./contactContainer/actionBlock";
import DocumentContainer from "./documentContainer";
import Footer from "./footer";
import { useSelector } from "react-redux";

const DetailBlock = ({
  record,
  clientId,
  deleteClick,
  hasDeleteAccess,
  isUserSuperAdmin,
}) => {
  const navigate = useNavigate();
  const [isAuthUser, setIsAuthUser] = useState(false);
  const loggedUserId = useSelector((state) => state?.auth?.data?._id);

  useEffect(() => {
    if (loggedUserId === record?.uploadedBy?._id) {
      setIsAuthUser(true);
    }
  }, []);

  return (
    <div className={styles.blockContainer}>
      <div className={styles.heading}>Billing Details</div>
      <Grid container columnSpacing={2} marginTop="20px">
        <ContactBlock record={record} />
        <AddressBlock record={record} />
        <ActionBlock
          hasDeleteAccess={hasDeleteAccess || isUserSuperAdmin || isAuthUser}
          onEditClick={() =>
            navigate(
              `/clients/billing/${clientId}?source=overview&id=${record?._id}`
            )
          }
          onDeleteClick={(e) => deleteClick(e, record?._id)}
        />
      </Grid>
      <DocumentContainer
        buttonLabel="GST Details"
        cardNumber={record?.gstNo}
        dataObj={record?.gst}
        heading="GSTIN"
        deleteClick={deleteClick}
        deleteType={2}
        hasDeleteAccess={hasDeleteAccess || isUserSuperAdmin || isAuthUser}
      />
      <DocumentContainer
        buttonLabel="PAN Details"
        cardNumber={record?.panCard}
        dataObj={record?.pan}
        heading="PAN"
        deleteClick={deleteClick}
        deleteType={1}
        hasDeleteAccess={hasDeleteAccess || isUserSuperAdmin || isAuthUser}
      />

      <Footer record={record} />
    </div>
  );
};

export default DetailBlock;
