import { dayOption } from "../../../../../constants/contants";
import Select from "react-select";

const filterStyles = {
  control: (provided, state) => ({
    display: "flex",
    borderRadius: "5px",
    color: "#2f2f2f",
    fontSize: "10px",
    height: "32px",
    backgroundColor: "#f4f4f4",
    minWidth: "100%",
    fontWeight: "500",
    padding: "0px 0px 0 10px",
    cursor: "pointer",
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),

  placeholder: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      fontSize: "16px",
      color: "#2f2f2f",
      fontWeight: 500,
      paddingLeft: 0,
    };
  },

  valueContainer: (defaultStyles) => {
    return {
      ...defaultStyles,
      paddingLeft: "25px",
      whiteSpace: "nowrap",
    };
  },
  singleValue: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: "13px",
    };
  },
  menu: (provided, state) => ({
    ...provided,
    color: "#2f2f2f",
    fontSize: "12px",
    fontWeight: "500",
    width: "100%",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color:"#2f2f2f",
   
  }),
};


const DaysSelect = ({ ...rest }) => {
  return (
    <Select
      options={dayOption}
      name="offlimitdays"
      isSearchable={false}
      styles={filterStyles}
      {...rest}
    />
  );
};
export default DaysSelect;
