import React, { useState, useEffect, useMemo } from "react";
import { listApi } from "api/commonApis";
import { APIS } from "api/endpoints";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash.debounce";
import { order } from "utils/helper";
import TalentFilter from "./talentFilter";
import ClientFilter from "./clientFilter";
import AssignmentFilter from "./assignmentFilter";
import AddCandidateFilter from "./addAssignmentFilter";

const CommonFilter = ({
  onChange,
  appliedFilters,
  onReset,
  isApplied,
  type,
}) => {
  const [filterData, setFilterData] = useState({});
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [country, setCountry] = useState([]);

  /*To call The fetch Filter when ever component mount */

  useEffect(() => {
    fetchFilters();
  }, [type]);

  const fetchFilters = (event) => {
    listApi(APIS.LIST_FILTER, {
      type: type === 4 ? 1 : type,
    }).then((result) => {
      const data = cloneDeep(result?.data);
      setState(data?.state.filter((i) => i !== null && i !== ""));
      setCity(data?.city.filter((i) => i !== null && i !== ""));
      setCountry(data?.country.filter((i) => i !== null && i !== ""));
      let customeLocation = [
        ...data?.city.filter((i) => i !== null && i !== ""),
        ...data?.state.filter((i) => i !== null && i !== ""),
        ...data?.country.filter((i) => i !== null && i !== ""),
      ];

      // make unique array values

      customeLocation = customeLocation.filter(function (item, i, ar) {
        return ar.indexOf(item) === i;
      });

      /////// Data Creation For Talent Filter///////

      if (type === 1) {
        data.locations = customeLocation
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);
        data.sectors = data?.sectors
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);
        data.educations = data?.educations
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);
        data.designation = data?.designation
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);
        data.functionalAreas = data?.functionalAreas
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);

        let years = [];

        for (
          let i = data?.experience?.min;
          i < data?.experience?.max;
          i = i + data?.experience?.steps + 1
        ) {
          years.push({
            label: `${i} to ${i + data?.experience?.steps}`,
            value: [i, i + data?.experience?.steps],
          });
        }
        data.experience = years;
      }

      /////// Data Creation For Assignment Filter///////

      if (type === 2) {
        data.company = data?.company
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value.name,
            value: value._id,
          }))
          .sort(order);
        data.locations = customeLocation
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);
        data.director = data?.director
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: `${value.name} ${value.lastName || ""}`,
            value: value._id,
          }))
          .sort(order);
        data.consultant = data?.consultant
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: `${value.name} ${value.lastName || ""}`,
            value: value._id,
          }))
          .sort(order);
      }

      /////// Data Creation For Client Filter///////

      if (type === 3) {
        data.locations = customeLocation
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);

        data.sector = data?.sector
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);

        data.director = data?.director
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: `${value.name} ${value.lastName || ""}`,
            value: value._id,
          }))
          .sort(order);
        data.manager = data?.manager
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: `${value.name} ${value.lastName || ""}`,
            value: value._id,
          }))
          .sort(order);
      }

      /////// Data Creation For Assignment==>Add Candidate Filter///////

      if (type === 4) {
        data.locations = customeLocation
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);

        data.sectors = data?.sectors
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);
        data.skillRoles = data?.skillRoles
          ?.filter((i) => i !== null && i !== "")
          .map((value) => ({
            label: value,
            value,
          }))
          .sort(order);

        let years = [];
        for (
          let i = data?.experience?.min;
          i < data?.experience?.max;
          i += data?.experience?.steps + 1
        ) {
          years.push({
            label: `${i} - ${i + data?.experience?.steps}`,
            value: [i, i + data?.experience?.steps],
          });
        }
        data.experience = years;
      }

      setFilterData(data);
    });
  };

  ////Handler For Sider/////////

  const handleChange = (event, newValue) => {
    onChange((prev) => ({
      ...prev,
      [event.target.name]: newValue,
    }));
  };

  /////Hanlder For Select  Options//////////

  const debouncedResults = useMemo(() => {
    return debounce(fetchFilters, 300);
  }, []);

  /////Hanlder For Filter Value Change//////

  const onFilterChange = (key, option, isMulti) => {
    let newValue = option?.value;

    if (isMulti && appliedFilters?.[key]?.includes(option?.value)) {
      newValue = appliedFilters?.[key]?.filter((i) => i !== option?.value);
    } else if (isMulti && !appliedFilters?.[key]?.includes(option?.value)) {
      newValue = [...appliedFilters?.[key], option?.value];
    }

    ///////Hanlder When Other FIlter Value CHnages///////////

    onChange((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  return (
    <>
      {/* talent Filter */}

      {type === 1 && (
        <TalentFilter
          onChange={onChange}
          appliedFilters={appliedFilters}
          onReset={onReset}
          isApplied={isApplied}
          filterData={filterData}
          onFilterChange={onFilterChange}
          debouncedResults={debouncedResults}
          handleChange={handleChange}
          state={state}
          city={city}
          country={country}
        />
      )}

      {/* Assignment Filter */}

      {type === 2 && (
        <AssignmentFilter
          appliedFilters={appliedFilters}
          onReset={onReset}
          isApplied={isApplied}
          filterData={filterData}
          onFilterChange={onFilterChange}
          debouncedResults={debouncedResults}
          state={state}
          city={city}
          country={country}
        />
      )}

      {/* Client Filter */}

      {type === 3 && (
        <ClientFilter
          onChange={onChange}
          appliedFilters={appliedFilters}
          onReset={onReset}
          isApplied={isApplied}
          filterData={filterData}
          onFilterChange={onFilterChange}
          debouncedResults={debouncedResults}
          handleChange={handleChange}
          state={state}
          city={city}
          country={country}
        />
      )}

      {/* Add Candidate Filter */}

      {type === 4 && (
        <AddCandidateFilter
          onChange={onChange}
          appliedFilters={appliedFilters}
          onReset={onReset}
          isApplied={isApplied}
          filterData={filterData}
          onFilterChange={onFilterChange}
          debouncedResults={debouncedResults}
          handleChange={handleChange}
          state={state}
          city={city}
          country={country}
        />
      )}
    </>
  );
};

export default CommonFilter;
