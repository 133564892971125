import React, { useRef, useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../constants/assets";
import useKeyPress from "../../hooks/useKeyPress";
import CloseIcon from "@mui/icons-material/Close";
import cloneDeep from "lodash/cloneDeep";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

const SearchBar = ({
  appliedFilters,
  fulllength,
  onSubmit,
  onChange,
  onFilterClick,
  showFilters = true,
  fullWidth = false,
  placeholder = "",
  showBorder,
  multiSearch,
  internalSearch,
  onSearchFocus,
  setInputValue,
  inputValue,
  landingPlaceholder,
  SearchClick,
  resetValue,
  setResetValue,
}) => {
  const inputRef = useRef(null);
  const [onFocusValue, setOnFocusValue] = useState(false);
  const [filterFocus, setFilterFocus] = useState(false);
  const [filterObject, setFilterObject] = useState();

  const focusHandler = (isFocus) => {
    setOnFocusValue(isFocus);
    onSearchFocus?.(isFocus);
  };

  /*Handler To get value that any one of the filter have data or not  */
  const filterValueHandler = () => {
    let hasValue = false;
    const cloneFilterData = cloneDeep(filterObject);
    if (cloneFilterData.activeAssignments) {
      return (hasValue = true);
    }
    if (cloneFilterData.relocate) {
      return (hasValue = true);
    }
    delete cloneFilterData.activeAssignments;
    delete cloneFilterData.relocate;
    Object.keys(cloneFilterData).map((items) => {
      if (cloneFilterData[items].length > 0) {
        hasValue = true;
      }
    });
    return hasValue;
  };

  /* UseEffect Call whenever the appliedFilter Value Will Change */

  useEffect(() => {
    setFilterObject(appliedFilters);
  }, [appliedFilters]);

  const onKeyPress = (event) => {
    if (event?.key === "k" && (event?.ctrlKey || event?.metaKey)) {
      inputRef?.current?.focus();
    }
  };

  useKeyPress(["Control", "k"], onKeyPress);

  const resetHandler = (event) => {
    if (event.target.value !== "") {
      setResetValue(true);
      focusHandler(true);
    } else {
      setResetValue(false);
      focusHandler(false);
    }
    onChange?.(event);
    setInputValue(event?.target?.value);
  };

  const onClickResetHanlder = () => {
    let e = {
      target: {
        value: "",
      },
    };
    focusHandler(false);
    setResetValue(false);
    onChange?.(e);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div
          className={`${
            multiSearch ? styles.multiSearch : styles.searchfilter
          } ${fullWidth ? styles.fullWidth : ""} ${
            showBorder ? styles.border : ""
          }
          ${fulllength && styles.fullLengthBar}
          `}
        >
          <form
            className={`d-flex pe-2 ${styles.searchContainer} ${
              onFocusValue
                ? multiSearch
                  ? `${styles.searchPageBar}`
                  : `${styles.focusSearchBar}`
                : ""
            }
            `}
            onSubmit={onSubmit}
          >
            <div className="form-group w-100 position-relative">
              <img
                src={IMAGES.SEARCH_ICON}
                alt="Linkdin"
                className={styles.searchIcon}
              />

              <input
                ref={inputRef}
                type="search"
                value={!resetValue ? "" : inputValue}
                onFocus={() => focusHandler(true)}
                onBlur={() => {
                  if (inputValue === "" || inputValue === undefined) {
                    focusHandler(false);
                  } else {
                    focusHandler(true);
                  }
                }}
                placeholder={
                  landingPlaceholder
                    ? landingPlaceholder
                    : `Type command+k or ctrl+k to quick search ${placeholder}`
                }
                onChange={(e) => resetHandler(e)}
                className={`${styles.searchinput} ${
                  fullWidth ? styles.removedivider : ""
                } `}
              />
              {!internalSearch && <div className={styles.verticleLine}></div>}
            </div>
            {resetValue && (
              <CloseIcon
                fontSize="14px"
                className={`${styles.crossIcon} ${styles.searchPageStyle} ${
                  internalSearch && styles.internalSearchStyle
                } ${multiSearch && styles.searchPageStyle}`}
                onClick={() => onClickResetHanlder()}
              />
            )}

            <div
              className={`d-flex align-items-center justify-content-between ${styles.filters}
              } `}
            >
              {showFilters && (
                <div
                  className={` 
                     ${filterFocus ? styles.filterFocus : styles.filterRow}
                    `}
                  onClick={() => {
                    onFilterClick();
                    if (filterValueHandler()) {
                      setFilterFocus(true);
                    } else {
                      setFilterFocus(!filterFocus);
                    }
                  }}
                >
                  <FilterListOffOutlinedIcon
                    style={{
                      fontSize: "14px",
                      color: "#011cf5",
                      marginTop: "3px",
                      marginRight: "-7px",
                    }}
                  />

                  <p>filters</p>
                </div>
              )}

              <button
                className={`${styles.searchbtn} ${
                  onFocusValue && styles.focusbtn
                }`}
                type="submit"
                onClick={() => {
                  if (inputValue) {
                    SearchClick();
                    focusHandler(true);
                  }
                }}
                disabled={!onFocusValue}
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
