import React, { useState } from "react";
import styles from "./styles.module.scss";
import { LOGIN, SUCCESS } from "../../../redux/actions/actionTypes";
import { Formik } from "formik";
import FormInput from "../../../components/forms/formInput";
import { listApi, addEditApi } from "../../../api/commonApis";
import { APIS } from "../../../api/endpoints";
import { useEffect } from "react";
import { ROUTES } from "../../../router/routes";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ToastMessage from "../../../components/layout/toast";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import ImageUploader from "../../../components/imageUploader";
import { IMAGE_BASE_URL } from "../../../constants/contants";
import { userProfileSchema } from "../../../constants/formSchema";
import { ristrictAccess, validationError } from "../../../utils/helper";
import PrimaryButton from "../../../components/buttons/primaryButton";
import LinkButton from "components/buttons/linkButton";
import { useSelector } from "react-redux";
import { ROLES } from "../../../constants/contants";
import { TOAST_MESSAGE } from "constants/toastMessage";

const ProfileDetail = ({ RefreshHanlder }) => {
  const userRole = useSelector((state) => state?.auth?.data?.talentLabRole);
  const commonId = useSelector((state) => state?.auth?.data?.commonId);
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tab = params?.get?.("tab");
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState({});
  const [refresh, setRefresh] = useState(false);

  if (!tab) {
    navigate(`${ROUTES.USER_PROFILE}`);
  }

  const BackNavigationHandler = (userRole) => {
    if (userRole === ROLES.TALENT) {
      navigate(`${ROUTES.TALENT}/overview/${commonId}?tab=overview`);
    } else if (userRole === ROLES.ORGANIZATIONS) {
      navigate(`${ROUTES.CLIENTS}/overview/${commonId}?tab=overview`);
    }
  };

  ///////// profile list Api UseEffect///////////

  useEffect(() => {
    setLoading(true);
    listApi(APIS.ADMIN_PROFILE)
      .then((result) => {
        const data = cloneDeep(result?.data);
        data.talentLabRole = {
          label: data.talentLabRole,
          value: data.talentLabRole,
        };

        if (data && data?.imageUrl?.original) {
          data.imageUrl = {
            preview: IMAGE_BASE_URL + data?.imageUrl?.original,
            raw: "",
          };
        } else {
          data.imageUrl = {
            preview: "",
            raw: "",
          };
        }

        setAdminData(data);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  /////////////////// Update Profile OnSubmit Handler///////////////
  const onSubmit = (payload) => {
    const data = new FormData();
    data.append("name", payload?.name);
    if (payload?.middleName) {
      data.append("middleName", payload?.middleName);
    } else {
      data.append("middleName", "");
    }

    data.append("lastName", payload?.lastName);
    data.append("email", payload?.email);
    data.append("roleName", payload?.roleName);
    data.append("phoneNumber", payload.phoneNumber);
    data.append("talentLabRole", payload?.talentLabRole?.value);
    if (payload?.imageUrl && payload?.imageUrl?.raw !== "") {
      data.append("image", payload?.imageUrl?.raw);
    }
    addEditApi(APIS.ADMIN_UPDATE_PROFILE, data).then((response) => {
      dispatch({ type: LOGIN[SUCCESS], data: response?.data });
      setRefresh(!refresh);
      RefreshHanlder();
      toast.success(
        <ToastMessage
          title={"Success"}
          message={TOAST_MESSAGE.UPADTE_PROFILE}
        />
      );
    });
  };

  return (
    <div className={`tab-content ${styles.tabcontent}`} id="v-pills-tabContent">
      <Formik
        onSubmit={onSubmit}
        initialValues={adminData}
        validationSchema={userProfileSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          setValues,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.editprofile}>
              <div className={styles.edithead}>
                <div className={styles.topheading}>
                  <h3 className={styles.h3heading}>Edit Basic Profile</h3>

                  <div className="d-flex">
                    {ristrictAccess() && (
                      <LinkButton
                        onClick={() => BackNavigationHandler(userRole)}
                      >
                        Back
                      </LinkButton>
                    )}

                    <PrimaryButton
                      onClick={() => validationError(errors)}
                      label={loading ? "saving..." : "Save"}
                      disabled={loading || !dirty}
                    />
                  </div>
                </div>
              </div>
              <hr className={styles.cardDivider} />

              <div className={styles.middleCard}>
                <ImageUploader
                  setFieldValue={setFieldValue}
                  title="Admin Image"
                  handleImageChange={(e) => {
                    if (e.target.files.length) {
                      setFieldValue("imageUrl", {
                        preview: URL.createObjectURL(e.target.files[0]),
                        raw: e.target.files[0],
                      });
                    }
                  }}
                  image={values?.imageUrl}
                />{" "}
                <FormInput
                  label="First Name"
                  {...getFieldProps("name")}
                  error={touched?.name && errors?.name}
                />
                <FormInput
                  label="Middle Name"
                  {...getFieldProps("middleName")}
                  error={touched?.middleName && errors?.middleName}
                />
                <FormInput
                  label="Last Name"
                  {...getFieldProps("lastName")}
                  error={touched?.lastName && errors?.lastName}
                />
                <FormInput
                  label="Email Address"
                  {...getFieldProps("email")}
                  error={touched?.email && errors?.email}
                />
                <FormInput
                  label="Organizational Role/Designation"
                  {...getFieldProps("roleName")}
                  error={touched?.roleName && errors?.roleName}
                />
                <FormInput
                  maxLength={20}
                  label="Mobile Number"
                  {...getFieldProps("phoneNumber")}
                  error={touched?.phoneNumber && errors?.phoneNumber}
                />
                {/* <FormInput
                  label="TalentLab Role"
                  type="async-select"
                  value={values?.talentLabRole}
                  defaultOptions={talentLabRoleOptions}
                  onChange={(data) => setFieldValue("talentLabRole", data)}
                  error={touched?.talentLabRole && errors?.talentLabRole}
                /> */}
              </div>
              <hr className={styles.bottomDivider} />

              <div className={styles.bottomFotter}>
                <div className="d-flex">
                  <PrimaryButton
                    onClick={() => validationError(errors)}
                    label={loading ? "saving..." : "Save"}
                    disabled={loading || !dirty}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileDetail;
