import React from "react";
import styles from "./styles.module.scss";

const Checkbox = ({
  label,
  id,
  smallSize,
  disabled,
  candidateDossier,
  ...rest
}) => {
  return (
    <div
      className={candidateDossier ? styles.dossierContainer : styles.container}
    >
      <div className={styles.round}>
        <input id={id} type="checkbox" disabled={disabled} {...rest} />
        <label
          className={`${smallSize ? styles.smallLabel : styles.bigLabel} ${
            disabled ? styles.disabled : ""
          }`}
          for={id}
        ></label>
      </div>
      <label
        for={id}
        className={`${candidateDossier ? styles.dossierStyles : styles.label}`}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
