import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { IMAGES } from "../../constants/assets";

import styles from "./styles.module.scss";
import { ristrictAccess, isSuperAdmin } from "../../utils/helper";
import { getState } from "redux/store";

const IconComponent = ({
  talentHeader,
  copyProfileUrl,
  onEditClickHandler,
  mailTOEmaillink,
  isArchive,
  duplicateAssignment,
  duplicateAssignmentHandler,
  hasWriteAccess,
  handleOpenConfirmation,
  billingPage,
  isAuthUser,
}) => {
  const [self_signed_in_user, set_self_signed_in_user] = useState(false);

  useEffect(() => {
    const user = getState()?.auth?.data?.selfSignUp;
    set_self_signed_in_user(user);
  }, []);

  return (
    <Fragment>
      <div className={`d-flex `}>
        {!!billingPage &&
          (ristrictAccess() ||
            (hasWriteAccess && isAuthUser) ||
            isSuperAdmin()) && (
            <Tooltip title="Add">
              <span
                className={styles.iconContainer}
                onClick={onEditClickHandler}
              >
                <img
                  src={IMAGES.ROUND_PLUS}
                  alt="add"
                  className={styles.editIcon}
                />
              </span>
            </Tooltip>
          )}

        {(ristrictAccess() || hasWriteAccess) && !billingPage && (
          <Tooltip title={isArchive ? "Not Allowed" : "Edit"}>
            <span
              className={styles.iconContainer + " tl-talent-edit-icon"}
              onClick={isArchive ? "" : onEditClickHandler}
            >
              <img
                src={IMAGES.EDIT_ICON}
                alt="edit"
                className={styles.editIcon}
              />
            </span>
          </Tooltip>
        )}

        {talentHeader &&
          (ristrictAccess() || hasWriteAccess) &&
          !self_signed_in_user && (
            <Tooltip title={"Add to assignment"}>
              <span
                className={styles.iconContainer}
                onClick={handleOpenConfirmation}
              >
                <img
                  src={IMAGES.SIMPLE_USER_ICON}
                  alt="edit"
                  className={styles.editIcon}
                />
              </span>
            </Tooltip>
          )}

        {!ristrictAccess() && (
          <>
            <Tooltip title="Copy URL">
              <span className={styles.iconContainer} onClick={copyProfileUrl}>
                <img
                  src={IMAGES.SAVE_ICON}
                  alt="save"
                  className={styles.copyIcon}
                />
              </span>
            </Tooltip>
            {hasWriteAccess && (
              <Tooltip title="Share via email">
                <a href={mailTOEmaillink} className={styles.openMailLink}>
                  <span className={styles.iconContainer}>
                    <img
                      src={IMAGES.EMAIL_ICON}
                      alt="email"
                      className={styles.mailIcon}
                    />
                  </span>
                </a>
              </Tooltip>
            )}
          </>
        )}

        {hasWriteAccess && duplicateAssignment && (
          <Tooltip title="Duplicate Assignment" placement="bottom-end">
            <span
              className={styles.iconContainer}
              onClick={duplicateAssignmentHandler}
            >
              <img
                src={IMAGES.DUPLICATE_ICON}
                alt="duplicate"
                className={styles.mailIcon}
              />
            </span>
          </Tooltip>
        )}
      </div>
    </Fragment>
  );
};
export default IconComponent;
