import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { addEditApi, listApi } from "../../../../api/commonApis";
import { useParams, useNavigate } from "react-router-dom";
import { APIS } from "../../../../api/endpoints";
import { ROUTES } from "../../../../router/routes";
import styles from "./styles.module.scss";
import FormInput from "../../../../components/forms/formInput";
import Checkbox from "../../../../components/forms/checkbox";
import { talentCandidateDossierSchema } from "../../../../constants/formSchema";
import LinkButton from "../../../../components/buttons/linkButton";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import { DELETE_MODAL_CONFIRMATION } from "../../../../constants/contants";
import ConfirmationModal from "../../../../components/modal/confirmationModal";
import ToastMessage from "../../../../components/layout/toast";
import {
  validationError,
  hasPermission,
  order,
  isSuperAdmin,
} from "../../../../utils/helper";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../../constants/accessControl";
import DossierTable from "components/docTable/dossierTable";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import PdfIcon from "../../../../components/icons/pdfIcon";
import { TOAST_MESSAGE } from "constants/toastMessage";
import moment from "moment";
import { DOSSIER_TABLE_HEADING } from "../../../../constants/contants";

////TO SET PRE DEFINE CURRENT DATE TO dateProfileSent FIELD IN FORM//////

var newDate = new Date();
var updateDate = moment(newDate).format("YYYY-MM-DD");

const initialValues = {
  shortRole: "",
  sector: "",
  dateProfileSent: updateDate,
  assignment: "",
  currentLocation: "",
  executiveSummary: true,
  competency: [],
  education2: [],
  certifications: [],
  employment: [],
};

const CandidateDossier = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [dossierList, setDossierList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [documentId, setDocumentId] = useState();
  const [refresh, setRefresh] = useState(false);

  const handleOpen = (id) => {
    setDocumentId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [compentencyList, setCompentencyList] = useState();

  ///// GETTING PERMISSIN FOR SAME COMPONENT/////

  useEffect(() => {
    setHasWriteAccess(
      hasPermission(
        MODULES.TALENT,
        SUB_MODULES.CANDIDATE_DOSSIER,
        PERMISSIONS.WRITE
      )
    );
    setHasDeleteAccess(isSuperAdmin());
  }, [refresh, open]);

  ////////Handler to Delete Candidate Dosier////////////
  const handleDeleteDocument = () => {
    addEditApi(APIS.DELETE_DOCUMENT_DATA, { type: 11, id: documentId }).then(
      (result) => {
        setRefresh(!refresh);
        handleClose();
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.DELETE_DOSIER}
          />
        );
      }
    );
  };

  useEffect(() => {
    listApi(APIS.TALENT_DETAILS_TYPE_WISE, {
      talentId: id,
      type: 6,
    }).then((result) => {
      setDossierList(result?.data);
    });
  }, [refresh, open]);

  useEffect(() => {
    listApi(APIS.LIST_TALENT_OVERVIEW, { talentId: id }).then((result) => {
      setData(result?.data);

      ////TO MAKE THE EDUCATION CHECKBOX PRE SELECTED///////
      let educationIds = result?.data[0]?.education?.map((edu, index, arr) => {
        return edu?._id;
      });
      setFieldValue("education2", educationIds);

      /////TO MAKE CERTIFICATION  CHECKBOX PRE SELECTED/////////
      let certificationsIds = result?.data[0]?.certificates?.map(
        (cert, index, arr) => {
          return cert?._id;
        }
      );
      setFieldValue("certifications", certificationsIds);

      /////TO MAKE EMPLOYMENT CHECKBOX PRE SELECTED/////////
      let employmentIds = result?.data[0]?.work?.map((wrk, index, arr) => {
        return wrk?._id;
      });
      setFieldValue("employment", employmentIds);
    });
  }, []);

  const loadClientOption = (inputValue, resolve) => {
    listApi(APIS.LIST_CLIENT, { search: inputValue }).then((result) => {
      const filter = result?.data?.data
        ?.map((item) => ({
          label: item?.name,
          value: item?._id,
        }))
        .sort(order);
      resolve(filter);
    });
  };

  const loadclient = (inputValue, resolve) =>
    loadClientOption(inputValue, resolve);
  const loadClient = debounce(loadclient, 300);

  const loadAssignmentOption = (inputValue, resolve) => {
    listApi(APIS.LIST_ASSIGNMENT, { search: inputValue }).then((result) => {
      const filter = result?.data?.data
        ?.map((item) => ({
          label: `${item?.title} / ${item?.companyId?.name || ""}`,
          value: item?._id,
          clientId: item?.companyId?._id,
        }))
        .sort(order);
      resolve(filter);
    });
  };
  const loadassignment = (inputValue, resolve) =>
    loadAssignmentOption(inputValue, resolve);
  const loadAssignment = debounce(loadassignment, 300);

  const onSubmit = (payload, { resetForm }) => {
    const data = {
      ...payload,
      talentId: id,
      clientId: payload?.assignment?.clientId,
      assignment: payload?.assignment?.value,
    };
    delete data.sector;
    delete data.currentLocation;

    if (payload?.sector) {
      data.sector = payload?.sector;
    }
    if (payload?.currentLocation) {
      data.currentLocation = payload?.currentLocation;
    }

    addEditApi(APIS.ADD_EDIT_TALENT_DOSSIER, data).then((res) => {
      toast.success(
        <ToastMessage
          title={"Success"}
          isMultiLine
          message={TOAST_MESSAGE.DOSIER_PROGRESS}
        />
      );
      setRefresh(!refresh);
      resetForm();
    });
  };

  const {
    values,
    errors,
    touched,
    dirty,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: talentCandidateDossierSchema,
    onSubmit: onSubmit,
  });

  /////////  PUSH AND POP THE CHECKBOX ID TO ITS RELEVENT ARRAY IN INTIAL VALUES/////////

  const onCertificatesChange = (e, id) => {
    if (values?.certifications?.includes(id)) {
      setFieldValue(
        "certifications",
        values?.certifications?.filter((i) => i !== id)
      );
    } else {
      setFieldValue("certifications", [...values?.certifications, id]);
    }
  };

  /////////  PUSH AND POP THE CHECKBOX ID TO ITS RELEVENT ARRAY IN INTIAL VALUES/////////

  const onEducation2Change = (e, id) => {
    if (values?.education2?.includes(id)) {
      setFieldValue(
        "education2",
        values?.education2?.filter((i) => i !== id)
      );
    } else {
      setFieldValue("education2", [...values?.education2, id]);
    }
  };

  /////////  PUSH AND POP THE CHECKBOX ID TO ITS RELEVENT ARRAY IN INTIAL VALUES/////////

  const onEmploymentChange = (e, id) => {
    if (values?.employment?.includes(id)) {
      setFieldValue(
        "employment",
        values?.employment?.filter((i) => i !== id)
      );
    } else {
      setFieldValue("employment", [...values?.employment, id]);
    }
  };

  /////////  PUSH AND POP THE CHECKBOX ID TO ITS RELEVENT ARRAY IN INTIAL VALUES/////////

  const onCompentencyChange = (e, id) => {
    if (values?.competency?.includes(id)) {
      setFieldValue(
        "competency",
        values?.competency?.filter((i) => i !== id)
      );
    } else {
      setFieldValue("competency", [...values?.competency, id]);
    }
  };

  /////GET COMPENTENCY MAPPING LIST FROM API //////////

  useEffect(() => {
    if (values?.assignment?.value) {
      let payload = {
        talentId: id,
        type: 5,
        assignmentId: values?.assignment?.value,
      };

      listApi(APIS.TALENT_DETAILS_TYPE_WISE, payload).then((result) => {
        setCompentencyList(result?.data);

        /////TO MAKE COMPENTANCY CHECKBOX PRE SELECTED/////////

        let compentancyIds = result?.data?.map((compentancy, index, arr) => {
          return compentancy?._id;
        });
        setFieldValue("competency", compentancyIds);
      });
    }
  }, [values?.assignment]);

  return (
    <div className={`tab-content ${styles.tabcontent}`}>
      <div className="fade show active">
        <form onSubmit={handleSubmit}>
          <div>
            <div className={styles.edithead}>
              <div className={styles.topheading}>
                <h2 className={styles.headingText}>Candidate Dossier</h2>
                <div className="d-flex">
                  <LinkButton
                    onClick={() =>
                      navigate(
                        `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=overview`
                      )
                    }
                  >
                    Back to overview
                  </LinkButton>
                </div>
              </div>
            </div>
            {values?.assignment === "" && (
              <div className={styles.middleCard}>
                <FormInput
                  label="Please choose an assignment to start"
                  type="async-select"
                  defaultOptions
                  isClearable
                  value={values?.assignment}
                  onChange={(e) => setFieldValue("assignment", e)}
                  loadOptions={loadAssignment}
                  error={touched?.assignment && errors?.assignment}
                />
                {/* <div className={styles.noassignment}>
                  Please choose an assignment to start
                </div> */}
              </div>
            )}
            {values?.assignment && (
              <div className={styles.formname}>
                <div className={styles.middleCard}>
                  <FormInput
                    label="Assignment"
                    type="async-select"
                    defaultOptions
                    isClearable
                    value={values?.assignment}
                    onChange={(e) => setFieldValue("assignment", e)}
                    loadOptions={loadAssignment}
                    error={touched?.assignment && errors?.assignment}
                  />

                  <FormInput
                    label="Role"
                    {...getFieldProps("shortRole")}
                    error={touched?.shortRole && errors?.shortRole}
                  />

                  <FormInput
                    label="Sector"
                    {...getFieldProps("sector")}
                    error={touched?.sector && errors?.sector}
                  />
                  <h3>Role Summary</h3>
                </div>
                <hr className={styles.cardDivider} />
                <div className={styles.middleCard}>
                  <FormInput
                    label="Date Profile Sent"
                    type="date"
                    value={values?.dateProfileSent}
                    onChange={(e) =>
                      setFieldValue("dateProfileSent", e.target.value)
                    }
                    error={touched?.dateProfileSent && errors?.dateProfileSent}
                  />

                  <FormInput
                    id="currentLocation"
                    label="Current Location"
                    {...getFieldProps("currentLocation")}
                    error={touched?.currentLocation && errors?.currentLocation}
                  />

                  <h3>Candidate Details </h3>
                </div>
                <hr className={styles.cardDivider} />
                <div className={styles.middleCard}>
                  <p>
                    Please select the elements below that should be used to
                    generate the Candidate Dossier PDF
                  </p>
                  <Checkbox
                    id="executiveSummary"
                    label="Executive Summary"
                    checked={values?.executiveSummary}
                    candidateDossier
                    {...getFieldProps("executiveSummary")}
                  />

                  {compentencyList?.length > 0 && (
                    <>
                      <hr className={styles.cardDivider} />
                      <div className={styles.listLabel}>
                        Competency Mapping{" "}
                      </div>
                      {compentencyList?.map((item) => (
                        <Checkbox
                          candidateDossier
                          id={item?._id}
                          label={`${item.name}\u00A0\u00A0-\u00A0\u00A0${item.score}`}
                          checked={values?.competency?.includes(item?._id)}
                          onChange={(e) => onCompentencyChange(e, item?._id)}
                        />
                      ))}
                    </>
                  )}

                  <hr className={styles.cardDivider} />

                  {data[0]?.work?.length > 0 && (
                    <>
                      <div className={styles.listLabel}>
                        Employment Summary{" "}
                      </div>
                      {data?.map((items) =>
                        items.work.map((data) => (
                          <Checkbox
                            candidateDossier
                            id={data?._id}
                            label={`${data.companyName}\u00A0\u00A0-\u00A0\u00A0${data.designation}`}
                            checked={values?.employment?.includes(data?._id)}
                            onChange={(e) => onEmploymentChange(e, data?._id)}
                          />
                        ))
                      )}
                    </>
                  )}

                  <hr className={styles.cardDivider} />

                  {data[0]?.certificates?.length > 0 && (
                    <>
                      <div className={styles.listLabel}>
                        Certifications & Publications{" "}
                      </div>
                      {data?.map((items) =>
                        items?.certificates.map((data) => (
                          <Checkbox
                            candidateDossier
                            id={data?.title}
                            label={`${data?.title}\u00A0\u00A0-\u00A0\u00A0${data?.instituteName}`}
                            checked={values?.certifications?.includes(
                              data?._id
                            )}
                            onChange={(e) => onCertificatesChange(e, data?._id)}
                          />
                        ))
                      )}
                      <hr className={styles.cardDivider} />
                    </>
                  )}

                  {data[0]?.education?.length > 0 && (
                    <>
                      <div className={styles.listLabel}>Education</div>

                      {data?.map((items) =>
                        items.education.map((data) => (
                          <Checkbox
                            candidateDossier
                            id={`${data?.degree}${data?.educationType}`}
                            label={`${data.degree}\u00A0\u00A0-\u00A0\u00A0${data?.universityName}`}
                            checked={values?.education2?.includes(data?._id)}
                            onChange={(e) => onEducation2Change(e, data?._id)}
                          />
                        ))
                      )}
                      <hr className={styles.cardDivider} />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          {values?.assignment && (
            <div className={styles.bottomFotter}>
              {hasWriteAccess && (
                <>
                  <PrimaryButton
                    onClick={() => validationError(errors)}
                    type="submit"
                    disabled={!dirty}
                    rightIcon={PdfIcon}
                    label=" Generate Dossier"
                    rightImageClassName={styles.pdfIcon}
                  />
                  <p className={styles.helperText}>
                    PDF generation may take a few minutes, once ready, it will
                    be emailed automatically.
                  </p>
                </>
              )}
            </div>
          )}
        </form>

        <div className={styles.bottomCard}>
          <hr className={styles.cardDivider} />
          <div className={styles.edithead}>
            <div className={styles.headingContainer}>
              <h3 className={styles.tableHeading}>Dossier Archive</h3>
            </div>
          </div>
          <DossierTable
            tableHeading={DOSSIER_TABLE_HEADING}
            tableData={dossierList}
            hasDeleteAccess={hasDeleteAccess}
            deleteClick={handleOpen}
          />
        </div>
      </div>
      <ConfirmationModal
        title={DELETE_MODAL_CONFIRMATION}
        open={open}
        closeModal={handleClose}
        onChangeHanlder={handleDeleteDocument}
        data="this document"
        description=""
      />
    </div>
  );
};

export default CandidateDossier;
