import React from "react";
import styles from "./styles.module.scss";

const LocationBlock = ({ data }) => {
  return (
    <>
      <h4 className={styles.clientCity}>{data?.city ? data?.city : ""}</h4>
      <p className={styles.clientState}>{`${
        data?.state ? `${data?.state},` : ""
      } ${data?.country ? data?.country : ""}`}</p>
    </>
  );
};

export default LocationBlock;
