import { Backdrop, Menu } from "@mui/material";
import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleLogout } from "../../../../redux/actions/auth";
import styles from "./styles.module.scss";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import NotificationRow from "./notificationRow";
import { IMAGES } from "../../../../constants/assets";
import { APIS } from "../../../../api";
import { addEditApi } from "../../../../api/commonApis";
import { listApi } from "../../../../api/commonApis";
import { refreshNotification } from "../../../../redux/actions/auth";
import { getState } from "redux/store";

const STYLED_TAB = () => {
  const user = getState()?.auth?.data;

  return user?.talentLabRole === undefined
    ? []
    : user.talentLabRole === "Talent" ||
      user.talentLabRole === "Organizations" ||
      user.selfSignUp
    ? [
        {
          title: "Mentions",
          type: 1,
          image: IMAGES.MENTION_ICON,
          key: "mentionUnread",
        },
      ]
    : [
        {
          title: "All",
          type: " ",
          image: "",
          key: "allUnread",
        },
        {
          title: "Mentions",
          type: 1,
          image: IMAGES.MENTION_ICON,
          key: "mentionUnread",
        },
        {
          title: "Reminders/Follow Ups",
          type: 2,
          image: IMAGES.CALENDER_ICON,
          key: "reminderUnread",
        },
      ];
};

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: 12,
    marginRight: theme.spacing(4),
    padding: "0",
    minHeight: "30px",
    minWidth: "30px",
    color: "#2f2f2f",
    "&.Mui-selected": {
      color: "#000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const NotificationMenu = ({ open, anchorEl, handleClose, innerApiRefresh }) => {
  const [value, setValue] = React.useState(0);
  const [notificationTabType, setNotificationTabType] = useState(" ");
  const [refresh, setRefresh] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);

  /*NOTIFICATION API*/
  useEffect(() => {
    setLoading(true);
    let payload = {};
    if (notificationTabType !== " ") {
      payload.type = parseInt(notificationTabType);
    }

    listApi(APIS.LIST_NOTIFICATIONS, { ...payload })
      .then((result) => {
        setNotificationData(result?.data);
        // setTimeout(() => {
        //   marksAsReadHandler();
        // }, 2000);
      })
      .finally(() => setLoading(false));
  }, [notificationTabType, refresh, innerApiRefresh]);

  /* SET notificationTabType TO EMPTY WHHEN NOTIFICATION OPEN AND CLOSE */
  useEffect(() => {
    setNotificationTabType(" ");
    setValue(0);
  }, [open]);

  const handleChange = (event, newValue) => {
    event?.stopPropagation();
    setValue(newValue);
  };

  const marksAsReadHandler = () => {
    addEditApi(APIS.NOTIFICATION_ACTIONS, { action: 2 }).then((response) => {
      setRefresh((s) => !s);
      dispatch(refreshNotification());
    });
  };

  const dispatch = useDispatch();
  const logout = () => {
    dispatch(handleLogout());
  };

  return (
    <Backdrop
      sx={{
        backgroundColor: "#ffffff94",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={(e) => e?.stopPropagation()}
        PaperProps={{
          elevation: 0,
          sx: {
            background: "#f9f9f9",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            width: "31%",
            height: notificationData?.data?.length !== 0 ? "70vh" : "22vh",
            border: "4px",
            top: "51px!important",
            // left: "922px!important",

            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 10,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "#f9f9f9",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        // transformOrigin={{
        //   horizontal: "right",
        //   vertical: "top",
        // }}
        // anchorOrigin={{
        //   horizontal: "right",
        //   vertical: "bottom",
        // }}
      >
        <div className={styles.header}>
          <h3 className={styles.title}>Notifications</h3>
          <span className={styles.markAll} onClick={marksAsReadHandler}>
            Mark all as read
          </span>
        </div>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ bgcolor: "#f9f9f9" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={styles.toggleRow}
              aria-label="styled tabs example"
              sx={{
                "& .MuiTabs-indicator": { backgroundColor: "#011cf5" },

                // "& .MuiTabs-root": {
                //   width: "102%",
                // },
              }}
            >
              {STYLED_TAB().map((item) => {
                return (
                  <StyledTab
                    key={item.key}
                    onClick={() => setNotificationTabType(item?.type)}
                    label={
                      <div className={styles.tabHeadingButton}>
                        {item?.image && (
                          <img
                            src={item?.image}
                            className={styles.topTabIcon}
                          />
                        )}
                        {item?.title}
                        {notificationData?.[item?.key] > 0 && (
                          <div className={styles.count}>
                            {notificationData?.[item?.key]}
                          </div>
                        )}
                      </div>
                    }
                  />
                );
              })}
            </Tabs>
          </Box>
          <div className={styles.itemDiv}>
            {STYLED_TAB().map((item, index) => {
              return (
                <TabPanel
                  key={item.key}
                  style={{
                    height:
                      notificationData?.data?.length === 0 ? "15vh" : "55vh",
                    overflowY: "auto",
                    borderRadius: "4px",
                  }}
                  value={value}
                  index={index}
                >
                  {notificationData?.data?.map((data) => {
                    return (
                      <NotificationRow
                        key={data._id}
                        data-id={data}
                        data={data}
                        unread={!data?.isRead}
                        setRefresh={setRefresh}
                      />
                    );
                  })}

                  <div className={styles.noMore}>No more notifications</div>
                </TabPanel>
              );
            })}
          </div>
        </Box>
      </Menu>
    </Backdrop>
  );
};
export default NotificationMenu;
