import React from "react";
import styles from "./styles.module.scss";
import SocialHandler from "../socialHandler";
import { IMAGES } from "constants/assets";

const SociaHandlerRow = ({ data }) => {
  return (
    <div className={`d-flex mt-2 pt-1 ${styles.detailsicons}`}>
      {data?.linkedinUrl && (
        <SocialHandler
          tipTittle="LinkedIn Profile"
          linkedin
          onClick={(e) => {
            e?.stopPropagation();
            window.open(`${data?.linkedinUrl}`, "_blank");
          }}
        />
      )}
      {data?.twitterUrl && (
        <SocialHandler
          tipTittle="Twitter URL"
          icon={IMAGES.SOCIAL_TWITTER}
          onClick={(e) => {
            e?.stopPropagation();
            window.open(`${data?.twitterUrl}`, "_blank");
          }}
        />
      )}

      {data?.email && (
        <SocialHandler
          tipTittle="Send email"
          icon={IMAGES.MAIL_ICON}
          onClick={(e) => {
            e?.stopPropagation();
            window.location.assign(`mailto:${data?.email}`);
          }}
        />
      )}
    </div>
  );
};

export default SociaHandlerRow;
