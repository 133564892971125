import React from "react";
import styles from "./styles.module.scss";
import MailIcon from "components/icons/mail";

const ContactRightBox = ({ ristrictAccess, client }) => {
  return (
    <div
      className={
        ristrictAccess() ? styles.hideRightSummary : styles.rightsummary
      }
    >
      {
        <>
          <h3 className={styles.accountDirector}>Account Owner</h3>
          {client?.director?.name === "" ||
          client?.director?.name === undefined ? (
            <p
              style={{
                textTransform: "none",
                textTransform: "none",
                fontSize: "12px",
                marginBottom: "16px",
              }}
            >
              Not assigned yet
            </p>
          ) : (
            <div>
              <a
                href={`mailto:${client?.director?.email}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                  textDecoration: "none",
                  color: "black",
                  marginBottom: "12px",
                }}
              >
                <span>
                  {client?.director?.name === "" ||
                  client?.director?.name === undefined ? (
                    <span style={{ textTransform: "none" }}>
                      Not assigned yet.
                    </span>
                  ) : (
                    client?.director?.name + " " + client?.director?.lastName
                  )}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  style={{ marginLeft: "4px" }}
                  width={14}
                  height={14}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>
              </a>
            </div>
          )}

          {/* <p className={styles.directorName}>
            {client?.director?.name === "" ||
            client?.director?.name === undefined ? (
              <span style={{ textTransform: "none" }}>Not assigned yet.</span>
            ) : (
              client?.director?.name + " " + client?.director?.lastName
            )}

            {ristrictAccess() && client?.director?.email && (
              <MailIcon
                className={styles.mailIcon}
                onClick={(e) => {
                  e?.stopPropagation();
                  window.location.assign(`mailto:${client?.director?.email}`);
                }}
              />
            )}
          </p> */}
        </>
      }

      {
        <>
          <h3 className={styles.manager}>Account Director</h3>
          {client.manager.length <= 0 ? (
            <p style={{ textTransform: "none", fontSize: "12px" }}>
              Not assigned yet
            </p>
          ) : (
            client?.manager?.map((data, index) => (
              <div key={data._id} className="d-flex">
                <a
                  href={`mailto:${data?.email}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "black",
                    fontSize: "12px",
                  }}
                >
                  <span>{`${data?.name} ${data?.lastName}`}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{ marginLeft: "4px" }}
                    width={14}
                    height={14}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                </a>
                {/* <p className={styles.managerName} key={index}>
                {`${data?.name} ${data?.lastName}`}
                {ristrictAccess() && data?.email && (
                  <a style={{ display: "inline-block", marginLeft: "6px" }}>
                    <MailIcon
                      className={styles.secondMailIcon}
                      onClick={(e) => {
                        e?.stopPropagation();
                        window.location.assign(`mailto:${data?.email}`);
                      }}
                    />
                  </a>
                )}
              </p> */}
              </div>
            ))
          )}
        </>
      }
    </div>
  );
};

export default ContactRightBox;
