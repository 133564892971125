import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import "./index.css";
import { IMAGES } from "../../../../../constants/assets";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import { IMAGE_BASE_URL } from "../../../../../constants/contants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { hasPermission } from "../../../../../utils/helper";
import { useSelector } from "react-redux";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../../constants/accessControl";
import ChangePassowrd from "../../../../../components/icons/changePassword";
import CreateProfileButton from "../createProfile";
import { APIS } from "api";
import { toast } from "react-toastify";
import ToastMessage from "components/layout/toast";
import { addEditApi } from "api/commonApis";
import axios from "axios";

const ITEM_HEIGHT = 48;

const CustomTable = ({
  addEditHanlder,
  open,
  handleClose,
  handleClick,
  anchorEl,
  list,
  openConfirmHanlder,
  status,
  filterHandler,
  filter,
  passowrdModalHandler,
}) => {
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [hasEditAccess, setHasEditAccess] = useState(false);
  const isSuperAdmin = useSelector((state) => state?.auth?.data?.superAdmin);

  useEffect(() => {
    setHasEditAccess(
      hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.WRITE
      )
    );
    setHasDeleteAccess(
      hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.DELETE
      )
    );
  }, []);

  const [profiles, set_profiles] = useState([]);

  const [loading, set_loading] = useState(false);

  const [show_checked, set_show_checked] = useState(false);

  const bulk_select = (check) => {
    console.log(check);

    if (check) {
      set_profiles([...list]);
      set_show_checked(true);
    } else {
      set_profiles([]);
      set_show_checked(false);
    }
  };

  const add_remove_from_profile = (talent) => {
    console.log(profiles);

    const match = profiles.filter((profile) => profile?._id == talent?._id)[0];

    console.log(match);

    if (match?._id) {
      const filteredProfiles = profiles.filter(
        (profile) => profile?._id !== talent?._id
      );
      set_profiles([...filteredProfiles]);
    } else {
      set_profiles([...profiles, talent]);
    }
  };

  const base_url =
    process.env.NODE_ENV === "development"
      ? "http://Milkeshs-MacBook-Pro.local:8002"
      : "https://talentlabapi.peopleasset.in";

  const bulk_create_logins = async () => {
    try {
      set_loading(true);

      const profiles_without_logins = profiles.filter((p) => {
        return p.email == undefined || p?.email == "";
      });

      if (profiles_without_logins.length > 0) {
        throw new Error("Some profiles do not contain email address");
      }

      for (let talent of profiles) {
        addEditApi(APIS.ADD_EDIT_TALENT_PROFILE, {
          talentId: talent._id,
          has_taken_walkthrough: false,
          has_skiped_walkthrough: false,
          selfSignUp: true,
          admin_generated_login: true,
        }).then(async (response) => {
          console.log(response);

          if (response.statusCode === 200) {
            // using formdata as this part accepts form data due to multipart
            const formdata = new FormData();
            formdata.append("name", talent.firstName);
            formdata.append("middleName", talent.middleName);
            formdata.append("lastName", talent.lastName);
            formdata.append("email", talent.email);
            formdata.append("talentLabRole", "Talent");
            formdata.append("roles", JSON.stringify([]));
            formdata.append("selfSignUp", true);
            formdata.append("admin_generated_login", true);
            formdata.append("commonId", talent._id);

            const user_response = await axios.post(
              `${base_url}/user/create-talent-user`,
              formdata
            );

            if (user_response.data.statusCode === 200) {
              toast.success(
                <ToastMessage
                  title={"Logins created successfully"}
                  message={"The page will refresh automatically"}
                />,
                {
                  autoClose: 20000,
                }
              );
              //set_loading(false);
              //set_confirmation(false);
              // toast.success(
              //   <ToastMessage
              //     title={"Login created successfully"}
              //     // message={"The page will refresh automatically"}
              //   />,
              //   {
              //     autoClose: 20000,
              //   }
              // );
              // setTimeout(() => {
              //   window.location.href = `/profile?tab=tl-talents`;
              // }, 2000);
            }
          }
        });
      }
    } catch (error) {
      toast.error(
        <ToastMessage
          title={"Error"}
          message={"There was an error creating the profile"}
        />,
        {
          autoClose: 2000,
        }
      );
    } finally {
      set_loading(false);
      set_profiles([]);

      setTimeout(() => {
        window.location.href = `/profile?tab=tl-talents`;
      }, 2000);
    }
  };

  return (
    <div className="tw-p-4">
      {/* <p className="tw-text-[10px]">{JSON.stringify(profiles)}</p> */}

      {list.length > 0 && (
        <div>
          <div className="tw-mb-8 tw-flex tw-items-center tw-justify-end">
            <button
              onClick={() => {
                bulk_create_logins();
              }}
              disabled={profiles.length <= 0}
              className="lg:hover:tw-bg-[#011fc5] disabled:tw-opacity-25 lg:tw-bg-[#f4f4f4] tw-shadow-sm lg:tw-text-black flux lg:hover:tw-text-white tw-px-3 tw-py-2 tw-rounded-md tw-text-[12px]"
            >
              {loading ? (
                <span className="tw-flex tw-space-x-2 tw-items-center">
                  <svg
                    className="tw-animate-spin tw-h-3 tw-w-3 tw-text-currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Processing...</span>
                </span>
              ) : (
                <span>
                  Bulk create logins{" "}
                  {profiles.length > 0 && (
                    <span>for {profiles.length} users</span>
                  )}
                </span>
              )}
            </button>
          </div>

          <table class="flux tw-table-fixed tw-w-full tw-text-[12px] tw-font-normal">
            <thead className="tw-bg-transparent tw-text-[12px] tw-font-normal">
              <tr className="tw-pb-3 tw-border-b tw-border-[##dedede]">
                <th className="w-1/4 tw-text-[12px] tw-font-medium tw-flex tw-items-center tw-space-x-2 tw-pb-3">
                  <div className="tw-h-[13px]">
                    <input
                      onChange={(e) => {
                        bulk_select(e.target.checked);
                      }}
                      className="tw-accent-[#011cf5]"
                      type="checkbox"
                      name=""
                      id=""
                    />
                  </div>
                  <span>Name</span>
                </th>
                <th className="w-1/4 tw-text-[12px] tw-font-medium tw-pb-3">
                  Email
                </th>
                <th className="w-1/4 tw-text-[12px] tw-font-medium tw-pb-3">
                  Generate Login
                </th>
              </tr>
            </thead>
            <tbody className="tw-text-[#212529]">
              {list?.map((data) => (
                <tr
                  key={data._id}
                  className="tw-py-4 tw-border-b tw-border-[##dedede] "
                >
                  <td className="tw-py-4">
                    <div className=" tw-flex tw-items-center tw-space-x-2">
                      <input
                        onChange={() => {
                          add_remove_from_profile(data);
                        }}
                        className="tw-accent-[#011cf5]"
                        type="checkbox"
                        name=""
                        checked={profiles.some((p) => p._id === data._id)}
                        id=""
                      />
                      <span>{`${data?.firstName} ${data?.lastName}`}</span>
                    </div>
                  </td>
                  <td className="tw-py-4 ">{data?.email}</td>
                  <td className="tw-py-4">
                    {data?.email && data?.email !== "" ? (
                      <CreateProfileButton talent={data}></CreateProfileButton>
                    ) : (
                      <p style={{ fontSize: "10px", color: "#ccc", margin: 0 }}>
                        Email address is required to generate login
                      </p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {list.length <= 0 && <div>All users have their logins generated</div>}
    </div>
  );

  // return (
  //   <>
  //     <div
  //       className={`${styles.tableHeading} ${styles.tableHeadingFont} tw-flex tw-items-center`}
  //     >
  //       <div className="tw-w-8">
  //         <input type="checkbox" name="" id="" />
  //       </div>
  //       <div onClick={filterHandler} className={styles.tableHeadingDataName}>
  //         Name
  //         {filter === 2 ? (
  //           <ArrowDownwardIcon className={styles.arrowStyle} />
  //         ) : (
  //           <ArrowUpwardIcon className={styles.arrowStyle} />
  //         )}
  //       </div>
  //       <div className={styles.tableHeadingSpace}></div>
  //       <div className={styles.tableHeadingDataSlug}>Role</div>
  //       <div className={styles.tableHeadingSpace}></div>
  //       {/* <div className={styles.tableHeadingDataCreated}>Last Active</div> */}
  //       {/* <div className={styles.tableHeadingDataStatus}>Status</div> */}
  //       <div className={styles.tableHeadingDataActions}>Actions</div>
  //     </div>
  //     <hr className={styles.tableDivider} />
  //     <div className={styles.tableDataOuterContainer}>
  //       {list?.map((data) => (
  //         <>
  //           <div
  //             className={`${styles.tableDataContainer} ${styles.TableDataFontStyle}`}
  //           >
  //             <div className="tw-w-8">
  //               <input type="checkbox" name="" id="" />
  //             </div>

  //             <div className={`${styles.tableDataNameSize} `}>
  //               <div className={styles.commenttext}>
  //                 {data?.imageUrl?.original ? (
  //                   <img
  //                     alt="head"
  //                     src={IMAGE_BASE_URL + data?.imageUrl?.original}
  //                     className={styles.adminThumImage}
  //                   />
  //                 ) : (
  //                   <h5
  //                     className={styles.adminInitail}
  //                   >{`${data?.firstName?.[0]}${data?.lastName?.[0]}`}</h5>
  //                 )}
  //               </div>
  //               <div>
  //                 <div
  //                   className={styles.nameStyles}
  //                 >{`${data?.firstName} ${data?.lastName}`}</div>
  //                 <div className={styles.emailStyle}>{data?.email}</div>
  //               </div>
  //             </div>
  //             <div className={styles.tableDataSpace}></div>
  //             <div className={`${styles.tableDataSlugSize} `}>
  //               {data?.talentLabRole}
  //             </div>
  //             <div className={styles.tableDataSpace}></div>
  //             {/* <div className={`${styles.tableDataCreatedSize} `}>
  //               {moment(data?.lastSeen)?.fromNow?.()}
  //             </div> */}
  //             {/* <div className={`${styles.tableDataStatusSize} `}>
  //               <div
  //                 className={
  //                   data?.isBlocked ? styles.blackDot : styles.greenDot
  //                 }
  //               ></div>
  //               {data?.isBlocked ? "Blocked" : "Active"}
  //             </div> */}

  //             <div className={styles.tableDataActionsSize}>
  //               {(hasDeleteAccess || hasEditAccess) && (
  //                 <Tooltip title="Menu">
  //                   <IconButton
  //                     aria-label="menu"
  //                     onClick={(event) => {
  //                       handleClick(event, data);
  //                     }}
  //                   >
  //                     <MoreVertIcon
  //                       sx={{ transform: "rotate(90deg)" }}
  //                       fontSize="small"
  //                     />
  //                   </IconButton>
  //                 </Tooltip>
  //               )}
  //             </div>
  //           </div>
  //           <hr className={styles.cardDivider} />
  //         </>
  //       ))}
  //     </div>

  //     <Menu
  //       id="long-menu"
  //       MenuListProps={{
  //         "aria-labelledby": "long-button",
  //       }}
  //       sx={{
  //         "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
  //           "&:hover": {
  //             backgroundColor: "#e7e9fe",
  //           },
  //         },
  //       }}
  //       anchorOrigin={{
  //         vertical: "bottom",
  //         horizontal: "right",
  //       }}
  //       transformOrigin={{
  //         vertical: "top",
  //         horizontal: "right",
  //       }}
  //       anchorEl={anchorEl}
  //       open={open}
  //       onClose={handleClose}
  //       PaperProps={{
  //         style: {
  //           maxHeight: ITEM_HEIGHT * 4.5,
  //         },
  //       }}
  //     >
  //       {hasEditAccess && (
  //         <MenuItem
  //           className={styles.menuItemStyle}
  //           onClick={(handleClose, addEditHanlder)}
  //         >
  //           <img
  //             alt="edit"
  //             src={
  //               hasEditAccess
  //                 ? IMAGES.TABLE_MENU_EDIT
  //                 : IMAGES.TABLE_DISABLE_EDIT
  //             }
  //             className={styles.iconstyle}
  //           />
  //           Edit
  //         </MenuItem>
  //       )}

  //       {hasEditAccess && (
  //         <MenuItem
  //           className={styles.menuItemStyle}
  //           onClick={() => {
  //             handleClose();
  //             openConfirmHanlder("block");
  //           }}
  //         >
  //           <img
  //             alt="deleet"
  //             src={status ? IMAGES.TABLE_MENU_UNBLOCK : IMAGES.TABLE_MENU_BLOCK}
  //             className={styles.iconstyle}
  //           />
  //           {status ? "Unblock" : "Block"}
  //         </MenuItem>
  //       )}
  //       {hasDeleteAccess && (
  //         <MenuItem
  //           className={styles.menuItemStyle}
  //           onClick={() => {
  //             handleClose();
  //             openConfirmHanlder("delete");
  //           }}
  //         >
  //           <img
  //             alt="delete"
  //             src={IMAGES.TABLE_MENU_DELETE}
  //             className={styles.iconstyle}
  //           />
  //           Delete
  //         </MenuItem>
  //       )}

  //       {isSuperAdmin && (
  //         <MenuItem
  //           className={styles.menuItemStyle}
  //           onClick={() => {
  //             handleClose();
  //             passowrdModalHandler("changePassword");
  //           }}
  //         >
  //           <div style={{ marginRight: "8px" }}>
  //             <ChangePassowrd />
  //           </div>
  //           Reset Password
  //         </MenuItem>
  //       )}
  //     </Menu>
  //   </>
  // );
};
export default CustomTable;
