import React from "react";
import styles from "./styles.module.scss";
import { Grid, Tooltip } from "@mui/material";
import { IMAGES } from "constants/assets";

const ActionBlock = ({ onEditClick, onDeleteClick, hasDeleteAccess }) => {
  return (
    <Grid item xs={2} md={3} display="flex" justifyContent="end">
      <Tooltip title="Edit">
        <span className={styles.iconContainer} onClick={onEditClick}>
          <img src={IMAGES.EDIT_ICON} alt="edit" className={styles.editIcon} />
        </span>
      </Tooltip>
      {hasDeleteAccess && (
        <Tooltip title="Delete">
          <span className={styles.iconContainer} onClick={onDeleteClick}>
            <img
              src={IMAGES.RI_DELETE_BIN}
              alt="edit"
              className={styles.editIcon}
            />
          </span>
        </Tooltip>
      )}
    </Grid>
  );
};

export default ActionBlock;
