import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import styles from "./styles.module.scss";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Slider from "@mui/material/Slider";
import { formatAmount, getAmountPostFix } from "../../../utils/helper";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

function valuetext(value, label) {
  switch (label) {
    case 1:
      return `${value}`;
      break;
    case 2:
      return `₹ ${formatAmount(value)} ${getAmountPostFix(value)} `;
      break;
    case 3:
      return `${value}`;
      break;
    default:
      console.log("Switch Case Default Value");
  }
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    PaperProps={{
      style: {
        width: "100%",
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function FilterSliderSelect({
  placeholder,
  style,
  label,
  handleChange = () => {},
  name,
  startEndData,
  defaultValue,
  dataLabel,
}) {
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const emptyValue = [startEndData?.min, startEndData?.max];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = debounce(handleChange, 300);

  const isSelected = () => {
    if (defaultValue?.length) {
      return !isEqual(defaultValue, [+startEndData?.min, +startEndData?.max]);
    } else return false;
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        className={`${styles.hoverEffect} ${
          style === "assignmentStyles"
            ? `${styles.assignmentStyles} `
            : `${styles.selectDropDown} `
        }
        ${isSelected() ? styles.selectedDropDown : ""}
        ${anchorEl ? styles.dropDownActive : ""}`}
        onClick={handleClick}
        endIcon={
          <KeyboardArrowDownIcon
            className={`${anchorEl ? styles.activeIcon : ""} ${
              anchorEl ? styles.activeIconColor : ""
            }`}
          />
        }
      >
        <div
          style={{
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: "10px",
            paddingRight: "8px",
          }}
        >
          {placeholder}
        </div>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "30ch",
            paddingLeft: "20px",
            paddingRight: "17px",
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={styles.mainContainer}>
          <div>{label}</div>

          <div disableRipple>
            <Slider
              getAriaLabel={() => "compensation range"}
              className={styles.sliderCss}
              defaultValue={
                defaultValue?.length ? defaultValue : [0, 9999999999]
              }
              disableSwap
              name={name}
              min={parseInt(startEndData?.min)}
              step={parseInt(startEndData?.steps)}
              max={parseInt(startEndData?.max)}
              onChange={onChange}
              // valueLabelDisplay="on"
              // valueLabelFormat={currencyLabel ? valuetext : (value) => value}
              sx={{
                "& .MuiSlider-thumb": {
                  height: "18px",
                  width: "18px",

                  "&:focus, &:hover, &.Mui-active": {
                    boxShadow: "none",
                  },
                },
                "& .MuiSlider-rail": { height: "2px" },
                "& .MuiSlider-track": { height: "2px" },
                "& .MuiSlider-valueLabel": {
                  fontSize: 12,
                  fontWeight: "bold",
                  bottom: -48,
                  top: "unset",
                  backgroundColor: "unset",
                  color: "#000",
                  "&:before": {
                    display: "none",
                  },
                  "& *": {
                    background: "transparent",
                    color: "#000",
                  },
                },
              }}
            />
            <div className={styles.labelText}>
              <div>
                {defaultValue.length > 0
                  ? valuetext(defaultValue[0], dataLabel)
                  : valuetext(emptyValue[0], dataLabel)}
              </div>
              <div>
                {defaultValue.length > 0
                  ? valuetext(defaultValue[1], dataLabel)
                  : valuetext(emptyValue[1], dataLabel)}
              </div>
            </div>
          </div>
        </div>
      </StyledMenu>
    </div>
  );
}
