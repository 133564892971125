import React from "react";
import styles from "./styles.module.scss";
// import ReactPaginate from "react-paginate";
import PaginationComponent from "@mui/material/Pagination";
import { MenuItem, Select } from "@mui/material";

const limits = [5, 10, 25, 35];

const Pagination = ({
  totalCount,
  limitPerPage,
  currentPage,
  onPageChange,
  setLimitPerPage,
  activityLog,
  tablePagination,
}) => {
  const showing = limitPerPage * currentPage + 1;
  const recordTill =
    showing - 1 + limitPerPage > totalCount
      ? totalCount
      : showing - 1 + limitPerPage;
  const count = Math.ceil(totalCount / limitPerPage);

  return (
    <React.Fragment>
      <div className={styles.paginationRow}>
        <div className="d-flex">
          <PaginationComponent
            page={currentPage + 1}
            shape="rounded"
            count={count}
            size="small"
            showFirstButton
            showLastButton
            onChange={(e, page) => onPageChange(page - 1)}
          />
        </div>
        <div className={styles.limitSection}>
          <span className={styles.rowsPerPage}>Rows per page</span>

          <Select
            size="small"
            value={limitPerPage}
            onChange={(e) => {
              setLimitPerPage(e.target.value);
              onPageChange(0);
            }}
            variant="standard"
            className={styles.select}
            sx={{
              "& .MuiSelect-select": {
                padding: "5px 8px",
              },
            }}
          >
            {limits.map((li, index) => (
              <MenuItem value={li} className={styles.selectItems} key={index}>
                {li}
              </MenuItem>
            ))}
          </Select>
        </div>
        {!tablePagination && !activityLog && (
          <div
            className={styles.rowsPerPage}
          >{`Showing ${showing} to ${recordTill} of ${totalCount} entries`}</div>
        )}
      </div>

      {tablePagination && !activityLog && (
        <div
          className={styles.tableRowPerPage}
        >{`Showing ${showing} to ${recordTill} of ${totalCount} entries`}</div>
      )}
    </React.Fragment>
  );
};

export default Pagination;
