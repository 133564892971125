import { Formik, FieldArray } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { listApi, addEditApi } from "../../../../api/commonApis";
import { APIS } from "../../../../api/endpoints";
import FormInput from "../../../../components/forms/formInput";
import {
  organizationOverviewSchema,
  ristrictOrgAddEditSchema,
} from "../../../../constants/formSchema";
import ToggleButton from "../../../../components/forms/togglebutton";
import styles from "./styles.module.scss";
import debounce from "lodash/debounce";
import CountryStateCityPicker from "../../../../components/countryStateCityPicker";
import cloneDeep from "lodash/cloneDeep";
import { ROUTES } from "../../../../router/routes";
import ToastMessage from "../../../../components/layout/toast";
import { toast } from "react-toastify";
import LinkButton from "../../../../components/buttons/linkButton";
import ImageUploader from "../../../../components/imageUploader";
import { IMAGE_BASE_URL } from "../../../../constants/contants";
import {
  order,
  ristrictAccess,
  validationError,
} from "../../../../utils/helper";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import { TOAST_MESSAGE } from "constants/toastMessage";
import OutLineButton from "components/buttons/outLineButton";
import { IMAGES } from "constants/assets";
import { getState } from "redux/store";

const contactPersons = [
  {
    name: "",
    designation: "",
    email: "",
    phoneNumber: "",
    isPrimary: true,
  },
];

const initialValues = {
  isClientAlso: false,
  name: "",
  category: "",
  city: null,
  state: null,
  country: { label: "India", value: "India" },
  contactPersons,
  linkedinUrl: "",
  twitterUrl: "",
  summary: "",
  director: "",
  manager: [],
  imageUrl: { preview: "", raw: "" },
  legalEntityName: "",
};

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [clientData, setClientData] = useState({});
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const source = params.get("source");
  const scrollLink = params.get("linkto");
  const [selectedValue, setSelectedValue] = useState("");
  const [onHover, setOnHover] = useState(false);
  const [user, set_user] = useState();
  let myRef = useRef();

  /*Jump to person Contact details  */

  useEffect(() => {
    const profile = getState()?.auth?.data;

    console.log(profile);
    set_user(profile);

    if (scrollLink) {
      var element = document.getElementById("box");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
      myRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      listApi(APIS.LIST_CLIENT_OVERVIEW, { clientId: id })
        .then((result) => {
          const data = cloneDeep(result?.data?.[0]);

          ///// HANLDING IN CASE OF NUll//////////

          data.country = { label: data?.country, value: data?.country };

          if (data?.state === undefined) {
            data.state = null;
          } else {
            data.state = { label: data?.state, value: data?.state };
          }
          if (data?.city === undefined) {
            data.city = null;
          } else {
            data.city = { label: data?.city, value: data?.city };
          }

          if (data?.director === undefined) {
            data.director = "";
          } else {
            data.director = {
              label: ` ${data?.director?.name} ${data?.director?.lastName}`,
              value: data?.director?._id,
            };
          }

          data.manager = data.manager.map((i) => ({
            label: `${i?.name} ${i?.lastName}`,
            value: i?._id,
          }));

          if (data?.category === undefined) {
            data.category = "";
          } else {
            data.category = {
              label: data?.category,
              value: data?.category,
            };
          }

          if (data && data?.imageUrl?.original) {
            data.imageUrl = {
              preview: IMAGE_BASE_URL + data?.imageUrl?.original,
              raw: "",
            };
          } else {
            data.imageUrl = {
              preview: "",
              raw: "",
            };
          }

          setClientData(data);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  const onSubmit = (payload) => {
    const data = new FormData();

    if (id) {
      data.append("clientId", id);
    }
    data.append("isClientAlso", payload?.isClientAlso);
    data.append("name", payload?.name);

    if (payload?.category?.value) {
      data.append("category", payload?.category?.value);
    }

    data.append("country", payload?.country?.label || "");
    data.append("city", payload?.city?.label || "");
    data.append("state", payload?.state?.label || "");

    const contactPersons = payload?.contactPersons?.filter((x) => !!x?.name);

    data.append("contactPersons", JSON.stringify(contactPersons));
    data.append("linkedinUrl", payload?.linkedinUrl || "");
    data.append("twitterUrl", payload?.twitterUrl || "");
    data.append("summary", payload?.summary || "");

    data.append(
      "manager",
      JSON.stringify(payload?.manager?.map((data) => data?.value))
    );

    // if (payload?.legalEntityName) {
    data.append("legalEntityName", payload?.legalEntityName || "");
    // }

    if (payload?.imageUrl && payload?.imageUrl?.raw !== "") {
      data.append("image", payload?.imageUrl?.raw);
    }

    ///// temp check need to chnag/////

    if (payload?.director?.value === undefined) {
      data.append("director", "");
    } else {
      data.append("director", payload?.director?.value);
    }

    addEditApi(APIS.ADD_EDIT_CLIENT, data)
      .then((response) => {
        if (source) {
          navigate(-1);
        } else {
          navigate(
            `${ROUTES.CLIENTS}${ROUTES.OVERVIEW}/${response?.data?._id}?tab=overview`
          );
        }
        toast.success(
          <ToastMessage
            title={"Success"}
            message={
              id ? TOAST_MESSAGE.UPADTE_PROFILE : TOAST_MESSAGE.CREATE_PROFILE
            }
          />
        );
      })
      .finally(() => setLoading(false));
  };

  const loadOptions = (inputValue, resolve) => {
    listApi(APIS.LIST_GET_ADMIN, { search: inputValue }).then((result) => {
      const only_admins = result?.data.filter(
        (a) =>
          a.talentLabRole !== "Talent" &&
          a.talentLabRole !== "Organizations" &&
          (a.selfSignUp === false || a.selfSignUp === undefined)
      );
      console.log(only_admins, "only_admins");
      const filter = only_admins
        .filter((x) => {
          if (Array.isArray(selectedValue)) {
            const ids = selectedValue?.map((i) => i?.value);
            return !ids?.includes(x?._id);
          } else return x?._id !== selectedValue?.value;
        })
        ?.map((item) => ({
          label: `${item?.name} ${item.lastName}`,
          value: item?._id,
        }))
        .sort(order);
      resolve(filter);
    });
  };

  const categoryLoadOptions = (type, inputValue, resolve) => {
    listApi(APIS.ADMIN_DEFAULTS, { search: inputValue, type: 1 }).then(
      (result) => {
        const filter = result?.data
          ?.map((item) => ({
            label: item?.name,
            value: item?.name,
          }))
          .sort(order);
        resolve(filter);
      }
    );
  };

  const loadmanager = (inputValue, resolve) => loadOptions(inputValue, resolve);

  const loadcategory = (inputValue, resolve) =>
    categoryLoadOptions(1, inputValue, resolve);

  const loadManager = debounce(loadmanager, 300);
  const loadDirector = debounce(loadmanager, 300);
  const loadCategory = debounce(loadcategory, 300);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={id ? clientData : initialValues}
      validationSchema={
        ristrictAccess() ? ristrictOrgAddEditSchema : organizationOverviewSchema
      }
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        dirty,
        setFieldValue,
        handleChange,
        handleSubmit,
        getFieldProps,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.editprofile}>
            <div className={styles.edithead}>
              <div className={styles.topheading}>
                <h3 className={styles.heading}>Basic organization details</h3>
                {!user?.selfSignUp && (
                  <div className={"d-flex"}>
                    <ToggleButton
                      label="Is this organization a client?"
                      {...getFieldProps("isClientAlso")}
                    />
                  </div>
                )}
              </div>
              <hr className={styles.cardDivider} />
              <div className={styles.formname}>
                <div className={styles.middleCard}>
                  <ImageUploader
                    organizationImageUploader
                    setFieldValue={setFieldValue}
                    handleImageChange={(e) => {
                      if (e.target.files.length) {
                        setFieldValue("imageUrl", {
                          preview: URL.createObjectURL(e.target.files[0]),
                          raw: e.target.files[0],
                        });
                      }
                    }}
                    image={values?.imageUrl}
                  />

                  <div className={styles.spaceContainer}>
                    <FormInput
                      label="Organization Name"
                      {...getFieldProps("name")}
                      error={touched?.name && errors?.name}
                    />
                    <FormInput
                      label="Legal Entity Name"
                      {...getFieldProps("legalEntityName")}
                      error={
                        touched?.legalEntityName && errors?.legalEntityName
                      }
                    />
                  </div>
                  <FormInput
                    defaultOptions
                    label="Organization Category"
                    type="async-select"
                    value={values?.category}
                    loadOptions={loadCategory}
                    onChange={(data) => setFieldValue("category", data)}
                    error={touched?.category && errors?.category}
                  />

                  <CountryStateCityPicker
                    isEdit={!!id}
                    country={values?.country}
                    state={values?.state}
                    city={values?.city}
                    onCountyChange={(value) => {
                      setFieldValue("country", value);
                      setFieldValue("state", null);
                      setFieldValue("city", null);
                    }}
                    onStateChange={(value) => {
                      setFieldValue("state", value);
                      setFieldValue("city", null);
                    }}
                    onCityChange={(value) => {
                      setFieldValue("city", value);
                    }}
                    countryError={touched?.country && errors?.country}
                    stateError={touched?.state && errors?.state}
                    cityError={touched?.city && errors?.city}
                  />
                  <FormInput
                    label="LinkedIn URL"
                    placeholder="For example - https://www.linkedin.com/in/johndoe"
                    {...getFieldProps("linkedinUrl")}
                    error={touched?.linkedinUrl && errors?.linkedinUrl}
                  />
                  <FormInput
                    label="Twitter URL"
                    placeholder="For example - https://twitter.com/johndoe"
                    {...getFieldProps("twitterUrl")}
                    error={touched?.twitterUrl && errors?.twitterUrl}
                  />
                  <FormInput
                    type="textarea"
                    label="Organization Summary"
                    {...getFieldProps("summary")}
                    error={touched?.summary && errors?.summary}
                  />
                </div>
              </div>

              <FieldArray
                name="contactPersons"
                render={(arrayHelpers) => (
                  <div ref={myRef} id="box">
                    {values?.contactPersons?.map((person, index) => (
                      <>
                        <div className={styles.pointHeading}>
                          <h3>Point of Contact</h3>
                          <div className={"d-flex"}>
                            <ToggleButton
                              className={styles.checkBoxLabel}
                              label="Is this a primary contact?"
                              value={
                                values?.contactPersons.length <= 1 &&
                                values?.contactPersons[0].name === undefined &&
                                index === 0
                                  ? true
                                  : values?.contactPersons?.[index]?.isPrimary
                              }
                              onChange={(e) => {
                                if (e?.target?.value) {
                                  setFieldValue(
                                    `contactPersons`,
                                    values?.contactPersons?.map((x, i) => ({
                                      ...x,
                                      isPrimary: i === index ? true : false,
                                    }))
                                  );
                                } else {
                                  setFieldValue(
                                    `contactPersons.${index}.isPrimary`,
                                    false
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                        <hr className={styles.cardDivider} />
                        <div className={styles.formname}>
                          <div className={styles.middleCard}>
                            <FormInput
                              label="Contact Person Full Name"
                              {...getFieldProps(`contactPersons.${index}.name`)}
                              error={
                                touched?.contactPersons?.[index]?.name &&
                                errors?.contactPersons?.[index]?.name
                              }
                            />
                            <FormInput
                              label="Contact Person Designation/Role"
                              {...getFieldProps(
                                `contactPersons.${index}.designation`
                              )}
                              error={
                                touched?.contactPersons?.[index]?.designation &&
                                errors?.contactPersons?.[index]?.designation
                              }
                            />
                            <FormInput
                              label="Contact Person Email"
                              {...getFieldProps(
                                `contactPersons.${index}.email`
                              )}
                              error={
                                touched?.contactPersons?.[index]?.email &&
                                errors?.contactPersons?.[index]?.email
                              }
                            />
                            <FormInput
                              maxLength={20}
                              label="Contact Person Mobile Number"
                              {...getFieldProps(
                                `contactPersons.${index}.phoneNumber`
                              )}
                              error={
                                touched?.contactPersons?.[index]?.phoneNumber &&
                                errors?.contactPersons?.[index]?.phoneNumber
                              }
                            />
                            <div
                              className={`d-flex justify-content-end align-items-end ${styles.btnContainer} `}
                            >
                              {values?.contactPersons?.length !== 1 && (
                                <OutLineButton
                                  label="Remove"
                                  className={`me-1`}
                                  onClick={() => {
                                    if (
                                      values?.contactPersons?.[index]?.isPrimary
                                    ) {
                                      setFieldValue(
                                        `contactPersons.${
                                          index === 0 ? index + 1 : 0
                                        }.isPrimary`,
                                        true
                                      );
                                    }
                                    arrayHelpers.remove(index);
                                  }}
                                />
                              )}
                              {values?.contactPersons?.length === index + 1 && (
                                <OutLineButton
                                  icon={
                                    onHover
                                      ? IMAGES.ACTIVE_PLUS_BUTTON
                                      : IMAGES.NEW_ADDITION
                                  }
                                  onMouseEnter={(e) => {
                                    setOnHover(true);
                                  }}
                                  onMouseLeave={(e) => {
                                    setOnHover(false);
                                  }}
                                  label="Add Point of Contact"
                                  onClick={() => {
                                    setOnHover(false);
                                    arrayHelpers.push({
                                      ...contactPersons?.[0],
                                      isPrimary:
                                        values?.contactPersons?.length === 0,
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              />
              {/* </div> */}

              {!ristrictAccess() && (
                <>
                  <div className={styles.represntHeading}>
                    <h3>PeopleAsset Representative</h3>
                  </div>
                  <hr className={styles.cardDivider} />
                  <div className={styles.formname}>
                    <div className={styles.middleCard}>
                      <FormInput
                        defaultOptions
                        key={`${selectedValue}-director`}
                        label="Account Owner"
                        type="async-select"
                        value={values?.director}
                        isClearable
                        loadOptions={loadDirector}
                        onChange={(data) => {
                          setSelectedValue(data);
                          setFieldValue("director", data);
                          const ids = values?.manager?.filter(
                            (x) => x?.value !== data?.value
                          );
                          setFieldValue("manager", ids);
                        }}
                        error={touched?.director && errors?.director}
                      />

                      <FormInput
                        isMulti
                        defaultOptions
                        key={`${selectedValue?.label}-managers`}
                        label="Account Director"
                        type="async-select"
                        value={values?.manager}
                        loadOptions={loadManager}
                        onChange={(data) => {
                          const hasMangerSame = data?.map(
                            (item) => item?.value === values?.director?.value
                          );
                          if (hasMangerSame.includes(true)) {
                            setFieldValue("director", "");
                          }
                          setSelectedValue(data);
                          setFieldValue("manager", data || []);
                        }}
                        error={touched?.manager && errors?.manager}
                      />
                    </div>
                  </div>
                </>
              )}
              <hr className={styles.cardDivider} />
              <div className={styles.formname}>
                <div className={styles.middleCard}>
                  <div
                    className={`d-flex justify-content-end align-items-end  ${styles.btnContainer}`}
                  >
                    {id && (
                      <LinkButton
                        onClick={() =>
                          navigate(
                            `${ROUTES.CLIENTS}${ROUTES.OVERVIEW}/${id}?tab=overview`
                          )
                        }
                      >
                        Back
                      </LinkButton>
                    )}
                    <PrimaryButton
                      onClick={() => validationError(errors)}
                      label={
                        loading
                          ? "saving..."
                          : id
                          ? "Save and View"
                          : "Save and Create"
                      }
                      disabled={loading || !dirty}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Detail;
