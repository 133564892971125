import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { listApi } from "../../api/commonApis";
import { APIS } from "../../api/endpoints";
import DashBoardCount from "./dashBoardCount";
import Skeleton from "../../components/skeleton";
import "react-circular-progressbar/dist/styles.css";
import AssignmentTable from "./assignmentTable";
import ProgressChart from "./progressChart";
import CommentsSection from "../../components/commentSection";
import { sidePanelAccess, headerPermission } from "utils/helper";
import { MODULES } from "constants/accessControl";

const progressOption = [1, 2, 3];

const Overview = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assignmentsData, setAssignmentsData] = useState([]);
  const [recentMentionChat, setRecentMentionChat] = useState([]);
  const [count, setCount] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [isSidePanel, setIsSidePanel] = useState(false);

  useEffect(() => {
    ///////// DashBoard APi //////
    setLoading(true);
    listApi(APIS.LIST_DASHBOARD).then((result) => {
      setData(result?.data);
      setLoading(false);
    });

    /////// Assignment Api//////////

    listApi(APIS.LIST_ASSIGNMENT, { sendOwnAssignment: true }).then(
      (result) => {
        setAssignmentsData(result?.data?.data);
        setCount(result?.data?.count);
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    listApi(APIS.LIST_COMMENTS, { fromDashboard: true }).then((result) =>
      setRecentMentionChat(result?.data?.data)
    );
  }, [refresh]);

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  return (
    <section
      className={`${styles.hasStyles} ${
        isSidePanel ? styles.overview : styles.centerOverview
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12"></div>
        </div>
        {loading ? (
          <Skeleton height="550px" marginBottom="15px" marginTop="15px" />
        ) : (
          <>
            <div className="row mt-4 pt-2">
              <div className="col-md-12">
                <DashBoardCount data={data} loading={loading} />
              </div>
            </div>

            {/* <div className={`row mt-3 ${styles.progressRow}`}>
              {progressOption?.map((data) => (
                <ProgressChart />
              ))}
            </div> */}

            <div className={`row-md-12 ${styles.BottomRow}`}>
              <div className={`col-md-8  `}>
                {assignmentsData.length > 0 &&
                  headerPermission(MODULES.ASSIGNMENT) && (
                    <div className={`${styles.tableBox} ${styles.leftBox}`}>
                      <div className={styles.tableButtonCard}>
                        <span className={styles.assignmentCount}>{count}</span>{" "}
                        open assignments
                      </div>
                      <AssignmentTable assignmentsData={assignmentsData} />
                    </div>
                  )}
              </div>
              {recentMentionChat.length > 0 && (
                <div className={`col-md-4 `}>
                  <div className={`${styles.commentBox} ${styles.rightBox}`}>
                    <div className={styles.chatHeading}>Recent Mentions</div>

                    <CommentsSection
                      fromDashboard
                      mentionData={recentMentionChat}
                      setRefresh={setRefresh}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Overview;
