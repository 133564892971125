import React from "react";
import styles from "./styles.module.scss";
import PrimaryButton from "../../../../../components/buttons/primaryButton";
import PlusIcon from "../../../../../components/icons/plusIcon";

const CommonHeader = ({ heading, accessControl, onClick, buttonLabel }) => {
  return (
    <>
      <div className={styles.edithead}>
        <div className={styles.topheading}>
          <h3 className={styles.h3heading}>{heading}</h3>
          <div className="d-flex">
            <PrimaryButton
              visible={accessControl}
              leftImageClassName={styles.addTalentUser}
              leftIcon={PlusIcon}
              label={buttonLabel}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
      <hr className={styles.cardDivider} />
    </>
  );
};

export default CommonHeader;
