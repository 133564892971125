import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../../../constants/assets";
import DaysSelect from "../../daysSelect";
import moment from "moment";

const RevokeUpdateLimit = ({
  offLimitDays,
  setofflimit,
  revokeofflimitButton,
  updateperiodButton,
  talentData,
}) => {
  const [buttonDisable, setButtonDisable] = useState(true);
  const [daysleft, setDaysLeft] = useState(1);
  const [days, setDays] = useState({
    label: `${offLimitDays} Days`,
    value: offLimitDays,
  });

  useEffect(() => {
    const currentDate = moment();
    const offLimitDate = moment(talentData.offLimitTill);
    setDaysLeft(offLimitDate.diff(currentDate, "days"));
  }, []);

  return (
    <div className={styles.modal}>
      <div className={` ${styles.limit} ${styles.user}`}>
        <h2 className="text-start pe-0 ps-0">
          Choose a time period to mark as ‘Off Limit’
        </h2>

        <div
          className={`mt-3 ${styles.days} position-relative`}
          onClick={(e) => e?.stopPropagation()}
        >
          <DaysSelect
            defaultValue={days}
            onChange={(e) => {
              setDays(e.value);
              setofflimit(e.value);
              setButtonDisable(false);
            }}
          />

          <div className={styles.imageDivStyles}>
            <img
              src={IMAGES.ACCOUNT_IDIT}
              className={styles.timerImageStyle}
              alt=""
            />
          </div>
        </div>
        <p>{daysleft} days left</p>
        <h2 className="text-start pe-0 ps-0">
          Do you wish to Update off-limit time period or revoke off-limit
          status?
        </h2>
        <div className={styles.btncancalyes}>
          <button className={styles.no} onClick={revokeofflimitButton}>
            Revoke Off Limit
          </button>
          <button
            disabled={buttonDisable === true}
            className={buttonDisable ? `${styles.disable}` : `${styles.yes}`}
            onClick={updateperiodButton}
          >
            Update Period
          </button>
        </div>
      </div>
    </div>
  );
};
export default RevokeUpdateLimit;
