import React, { useEffect, useState } from "react";
import { listApi } from "../../api/commonApis";
import { APIS } from "../../api/endpoints";
import FormInput from "../forms/formInput";

const filterData = (data) => {
  const filtered = [];
  for (let key of data) {
    filtered.push({
      label: key?.name || key,
      value: key?.code || key?.iso || key,
    });
  }
  return filtered;
};

const CountryStateCityPicker = ({
  country,
  state,
  city,
  onCountyChange,
  onStateChange,
  onCityChange,
  countryError,
  stateError,
  cityError,
  isEdit,
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState();

  useEffect(() => {
    listData().then((allCountries) => {
      setCountries(allCountries);
    });
  }, []);

  // fetch the selected list
  useEffect(() => {
    if (
      country?.value &&
      countries?.length &&
      !cities?.length &&
      !cities?.length &&
      !selectedCountryCode
    ) {
      const selectedCountry = countries?.find(
        (x) => x?.label === country?.value
      );
      setSelectedCountryCode(selectedCountry?.value);

      if (selectedCountry?.value) {
        listData({ country: selectedCountry?.value }).then((allStates) => {
          setStates(allStates);

          const selectedState = allStates?.find(
            (x) => x?.label === state?.value
          );
          if (selectedState?.value) {
            listData({
              country: selectedCountry?.value,
              state: selectedState?.value,
            }).then((data) => setCities(data));
          }
        });
      }
    }
  }, [country, countries]);

  const countryHandler = (newCountry) => {
    onCountyChange?.(newCountry);
    setSelectedCountryCode(newCountry?.value);
    setCities([]);
    listData({ country: newCountry?.value }).then((data) => setStates(data));
  };

  const stateHandler = (newState) => {
    onStateChange?.(newState);
    listData({
      country: selectedCountryCode,
      state: newState?.value,
    }).then((data) => setCities(data));
  };

  const listData = (params) => {
    return listApi(APIS.LIST_COUNTRY_STATE_CITY, params).then((data) =>
      filterData(data?.data)
    );
  };

  return (
    <>
      <FormInput
        label="Country"
        type="select"
        value={country}
        options={countries}
        onChange={countryHandler}
        error={countryError}
        isClearable
      />

      <FormInput
        label="State"
        type="select"
        value={state}
        options={states}
        onChange={stateHandler}
        error={stateError}
        isClearable
      />

      <FormInput
        label="City"
        type="select"
        value={city}
        options={cities}
        onChange={onCityChange}
        error={cityError}
        isClearable
      />
    </>
  );
};

export default CountryStateCityPicker;
