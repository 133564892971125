import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../../constants/assets";
import { Tooltip } from "@mui/material";

const RoundEditIcon = ({ onClick, title, visible = true }) => {
  return (
    <Tooltip title={title ? title : "Edit"}>
      <div
        className={`d-flex ${styles.detailsicons}`}
        style={{ visibility: visible ? "visible" : "hidden" }}
        onClick={onClick}
      >
        <span>
          <img src={IMAGES.EDIT_ICON} alt="Edit Icon" />
        </span>
      </div>
    </Tooltip>
  );
};

export default RoundEditIcon;
