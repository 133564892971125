import React, { useEffect } from "react";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import { APIS } from "../../../../api";
import { addEditApi } from "../../../../api/commonApis";
import { useDispatch } from "react-redux";
import { googleAuthSuccess } from "../../../../redux/actions/auth";

const GoogleAuth = ({ hideButton }) => {
  const dispatch = useDispatch();
  // const clientId =
  //   "851289695658-7d51ankpjket9cniv82uprtnlut1f7hk.apps.googleusercontent.com"; // LIVE

  const clientId =
    "715219667597-vm0e3ec3mo2padsnme170nbl3o50k3eh.apps.googleusercontent.com"; // STAGE

  const onSuccess = (res) => {
    if (res?.accessToken) {
      let response = res;
      addEditApi(APIS.GOOGLE_DETAILS, {
        googleAccessToken: response?.accessToken,
        googleId: response?.googleId,
      }).then((response) => {
        dispatch(googleAuthSuccess({ googleId: response?.data?.googleId }));
      });
    }
  };

  const onFailure = (err) => {
    console.log("failed:", err);
  };

  /* Google authetication Hanlder */

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "https://www.googleapis.com/auth/calendar",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  return (
    <div style={{ display: hideButton ? "none" : "unset" }}>
      <GoogleLogin
        clientId={clientId}
        buttonText="Sign in with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
        accessType="offline"
        responseType="offline"
        theme="dark"
      />
    </div>
  );
};

export default GoogleAuth;
