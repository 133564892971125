import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";
import LoginFooter from "components/loginFooter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { loginSchema } from "constants/formSchema";
import { handleLogin } from "redux/actions/auth";
import { ROUTES } from "router/routes";

///INITIALVALUES

const defaultValues = {
  email: "",
  password: "",
};

const LoginExtPage = ({ isTalent }) => {
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const [buttonHover, setButtonHover] = useState(false);

  /// SUBMIT HANDLER

  const onSubmit = (payload) => {
    if (isTalent) {
      payload.type = 2;
    } else {
      payload.type = 3;
    }
    dispatch(handleLogin(payload));
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.middleBox}>
        <div>
          <img src={IMAGES.LOGO_SVG} />
        </div>
        <div>
          <div className={styles.headingContainer}>
            <h1 className={styles.headingtext}>
              {isTalent ? "Candidate Login" : "Client Portal"}
            </h1>
            <p className={styles.headingPara}>
              {isTalent
                ? " Your search for a great career ends here "
                : "Find the right talent to strengthen your organization"}
            </p>
          </div>

          <Formik
            onSubmit={onSubmit}
            initialValues={defaultValues}
            validationSchema={loginSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.formContainer}>
                  <div className={styles.inputContainer}>
                    <div className={styles.labelContainer}>
                      <span className={styles.errorMsg}>
                        &nbsp;
                        {(errors.email && touched.email && errors.email) || ""}
                      </span>
                    </div>
                    <input
                      name="email"
                      className={styles.inputStyles}
                      type="text"
                      placeholder="Email Address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.email}
                    />
                  </div>
                  <div className={styles.passwordContainer}>
                    <div className={styles.labelContainer}>
                      <span className={styles.errorMsg}>
                        &nbsp;
                        {(errors.password &&
                          touched.password &&
                          errors.password) ||
                          ""}
                      </span>
                    </div>
                    <input
                      type={passwordVisibility ? "text" : "password"}
                      name="password"
                      className={styles.passwordInput}
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.password}
                    />
                    <img
                      src={passwordVisibility ? IMAGES.EYE : IMAGES.EYESLASH}
                      className={styles.svgStyles}
                      onClick={() => setPasswordVisibility(!passwordVisibility)}
                    />
                  </div>
                  <div className={styles.labelContainer}>
                    <span className={styles.errorMsg}>&nbsp;</span>
                  </div>
                  <button
                    type="submit"
                    onMouseOver={() => setButtonHover(true)}
                    onMouseLeave={() => setButtonHover(false)}
                    className={styles.button}
                    disabled={loading}
                  >
                    <span>{loading ? "Loading..." : "Sign In"}</span>
                    <img
                      src={
                        buttonHover ? IMAGES?.BLACK_ARROW : IMAGES.RIGHT_ARROW
                      }
                      className={styles.arrowStyles}
                    />
                  </button>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className={styles.forgetContainer}
                  >
                    {isTalent && (
                      <span
                        className={styles.forgetText}
                        onClick={() =>
                          navigate(
                            isTalent
                              ? ROUTES.TALENT_SIGNUP
                              : ROUTES.CLIENT_SIGNUP
                          )
                        }
                      >
                        New to TalentLab? Join now
                      </span>
                    )}
                    <span
                      className={styles.forgetText}
                      onClick={() => navigate(ROUTES.FORGET_PASSWORD)}
                    >
                      Forgot Password?
                    </span>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>

        <LoginFooter />
      </div>
    </div>
  );
};

export default LoginExtPage;
