import React from "react";
import { formatAmount, getAmountPostFix } from "../../../utils/helper";
import { IMAGES } from "../../../constants/assets";
import styles from "./styles.module.scss";

const DashBoardCount = ({ data }) => {
  return (
    <div className={styles.bgimg}>
      <div className="row">
        <div className={`col-lg-4 mt-lg-1 col-xl-2  ${styles.boxStyle}`}>
          <div className={styles.box}>
            <div className={`d-flex align-items-start ${styles.boximg}`}>
              <img
                src={IMAGES.DOUBLE_USER_IMAGE}
                alt=""
                className={styles.userImage}
              />
              <p>
                Total <br />
                Candidates
              </p>
            </div>
            <h2>
              {data?.totalCount
                ? `${formatAmount(data?.totalCount)} ${getAmountPostFix(
                    data?.totalCount
                  )}`
                : "0"}
            </h2>
          </div>
        </div>
        <div className={`col-lg-4 mt-lg-1 col-xl-2  ${styles.boxStyle}`}>
          <div className={styles.box}>
            <div className={`d-flex align-items-start ${styles.boximg}`}>
              <img src={IMAGES.NEW_TALENT} alt="" />
              <p>
                New
                <br />
                Talent
              </p>
            </div>
            <h2>
              {data?.newCount
                ? `${formatAmount(data?.newCount)} ${getAmountPostFix(
                    data?.newCount
                  )}`
                : "0"}
            </h2>
          </div>
        </div>
        <div className={`col-lg-4 mt-lg-1 col-xl-2 ${styles.boxStyle}`}>
          <div className={`${styles.box} ${styles.converted}`}>
            <div className={`d-flex align-items-start ${styles.boximg}`}>
              <img src={IMAGES.ACTIVE_OFF_LIMIT} alt="" />
              <p>
                Off <br />
                Limits
              </p>
            </div>
            <h2>
              {data?.offLimitCount
                ? `${formatAmount(data?.offLimitCount)} ${getAmountPostFix(
                    data?.offLimitCount
                  )}`
                : "0"}
            </h2>
          </div>
        </div>
        <div className={`col-lg-4 mt-lg-1 col-xl-2  ${styles.boxStyle}`}>
          <div className={styles.box}>
            <div className={`d-flex align-items-start ${styles.boximg}`}>
              <img src={IMAGES.IN_PIPLINE} alt="" />
              <p>
                In
                <br /> Pipeline
              </p>
            </div>
            <h2>
              {data?.inPipelineCount
                ? `${formatAmount(data?.inPipelineCount)} ${getAmountPostFix(
                    data?.inPipelineCount
                  )}`
                : "0"}
            </h2>
          </div>
        </div>
        <div className={`col-lg-4 mt-lg-1 col-xl-2 mt-lx-0 ${styles.boxStyle}`}>
          <div className={`${styles.box} ${styles.converted}`}>
            <div className={`d-flex align-items-start ${styles.boximg}`}>
              <img src={IMAGES.ACTIVE_TOTAL_CONVERTED} alt="" />
              <p>
                Total <br />
                Converted
              </p>
            </div>
            <h2>
              {data?.convertedCount
                ? `${formatAmount(data?.convertedCount)} ${getAmountPostFix(
                    data?.convertedCount
                  )}`
                : "0"}
            </h2>
          </div>
        </div>
        <div className={`col-lg-4 mt-lg-1 col-xl-2 mt-lx-0 ${styles.boxStyle}`}>
          <div className={styles.box}>
            <div className={`d-flex align-items-start ${styles.boximg}`}>
              <img src={IMAGES.OFFER_DROP} alt="" />
              <p>
                Offer <br />
                Dropped
              </p>
            </div>
            <h2>
              {data?.droppedCount
                ? `${formatAmount(data?.droppedCount)} ${getAmountPostFix(
                    data?.droppedCount
                  )}`
                : "0"}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoardCount;
