import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Formik } from "formik";
import FormInput from "components/forms/formInput";
import { addEditApi } from "api/commonApis";
import { APIS } from "../../../../../api/endpoints";
import LinkButton from "components/buttons/linkButton";
import { dataSetAddEditSchema } from "constants/formSchema";
import { validationError } from "utils/helper";
import PrimaryButton from "components/buttons/primaryButton";
import { toast } from "react-toastify";
import ToastMessage from "components/layout/toast";
import { apiTypeHandler, DataSetType } from "constants/dataSetConstant";
import { TOAST_MESSAGE } from "constants/toastMessage";

const initialValues = {
  name: "",
  description: "",
};

const AddEdit = ({
  addEditSaveHanlder,
  selectedData,
  backButtonHandler,
  activeTab,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const saveHanlder = (payload) => {
    if (selectedData) {
      payload.id = payload?._id;
      delete payload["_id"];
      delete payload["isBlocked"];
      delete payload["isDeleted"];
      delete payload["updatedAt"];
      delete payload["createdAt"];
      delete payload["__v"];
      delete payload["notes"];
      delete payload["scores"];
      delete payload["score"];
      delete payload["updatedBy"];
      delete payload["addedBy"];
      delete payload["blockedBy"];
    }

    setLoading(true);
    addEditApi(APIS.ADD_EDIT_DATASET, {
      type: apiTypeHandler(activeTab),
      ...payload,
    })
      .then((response) => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={`${DataSetType(activeTab)} ${
              selectedData ? TOAST_MESSAGE.UPDATE_SUCESS : TOAST_MESSAGE.ADD
            }`}
          />
        );
        addEditSaveHanlder();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedData) {
      setData(selectedData);
    }
  }, []);

  return (
    <div className={`tab-content ${styles.tabcontent}`} id="v-pills-tabContent">
      <div className={styles.middleCard}>
        <Formik
          onSubmit={saveHanlder}
          initialValues={selectedData ? data : initialValues}
          validationSchema={dataSetAddEditSchema}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.topheading}>
                <h3 className={styles.h3heading}>{`${
                  selectedData ? "Edit" : "Add"
                } ${DataSetType(activeTab)} `}</h3>
                <div className="d-flex">
                  <LinkButton onClick={backButtonHandler}>Back</LinkButton>

                  <PrimaryButton
                    onClick={() => validationError(errors)}
                    label={"Save"}
                    disabled={loading || !dirty}
                  />
                </div>
              </div>
              <hr className={styles.topCardDivider} />
              <FormInput
                label="Name"
                {...getFieldProps("name")}
                error={touched?.name && errors?.name}
              />
              {activeTab === "datasets-compentency" && (
                <FormInput
                  type="textarea"
                  label="Description"
                  {...getFieldProps("description")}
                  error={touched?.description && errors?.description}
                />
              )}

              <hr
                className={`${styles.bottomDivider} ${
                  activeTab === "datasets-compentency" && styles.comPageStyle
                }`}
              />

              <div className={styles.topheading}>
                <h3 className={styles.h3heading}></h3>
                <div className="d-flex">
                  <PrimaryButton
                    onClick={() => validationError(errors)}
                    label={"Save"}
                    disabled={loading || !dirty}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddEdit;
