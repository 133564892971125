import React from "react";
import styles from "./styles.module.scss";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ROUTES } from "router/routes";

const ErrorPage = () => {
  return (
    <div className={styles.mainbox}>
      <div className={styles.err}>4</div>
      <i className={styles.far}>
        <HelpOutlineIcon fontSize="100%" />
      </i>
      <div className={styles.err2}>4</div>
      <div className={styles.msg}>
        Maybe this page moved? Got deleted? Is hiding out in quarantine? Never
        existed in the first place?
        <p className={styles.para}>
          Let's go <a className={styles.aTag} href={ROUTES.OVERVIEW}>overview</a> and try from there.
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
