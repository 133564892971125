import React from "react";

export function UserIcon({ color, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.5"
      height="13.5"
      viewBox="0 0 13.5 13.5"
      {...rest}
    >
      <path
        id="Path_188"
        data-name="Path 188"
        d="M14,6V8m0,0v2m0-2h2M14,8H12M10.5,5.25A2.25,2.25,0,1,1,8.25,3,2.25,2.25,0,0,1,10.5,5.25ZM4,13.823V13.75a4.25,4.25,0,1,1,8.5,0v.073a8.26,8.26,0,0,1-8.5,0Z"
        transform="translate(-3.25 -2.25)"
        fill="none"
        stroke={`${color}`}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  );
}
export default UserIcon;

// "#f4f4f4"
