import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./index.css";

const EmptyState = ({ entity = "Talent" }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div className="tw-flex tw-flex-col tw-space-y-4 tw-mt-4 tw-bg-gray-50 tw-p-4 tw-mb-8 tw-container">
      <div className="tw-flex vitems-center tw-justify-center tw-text-[#011cf5]/50">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5rem"
          height="5rem"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M18 13q.425 0 .713-.288T19 12q0-.425-.288-.712T18 11h-3q-.425 0-.712.288T14 12q0 .425.288.713T15 13zm0-3q.425 0 .713-.288T19 9q0-.425-.288-.712T18 8h-3q-.425 0-.712.288T14 9q0 .425.288.713T15 10zm-9 3q-.9 0-1.625.163t-1.275.512q-.525.325-.8.738t-.275.887q0 .3.225.5t.55.2h6.4q.325 0 .55-.213t.225-.537q0-.425-.275-.825t-.8-.75q-.55-.35-1.275-.513T9 13m0-1q.825 0 1.412-.587T11 10q0-.825-.587-1.412T9 8q-.825 0-1.412.588T7 10q0 .825.588 1.413T9 12m-5 8q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20z"
          ></path>
        </svg>
      </div>

      <div className="tw-text-center">
        <p className="tw-text-base tw-tracking-tight tw-text-black tw-mb-2">
          Your profile looks empty!
        </p>
        <p>
          <span>Start by clicking on the </span>
          <span
            onClick={() => {
              navigate(
                `${
                  entity === "Talent"
                    ? `/talent/profile/${id}?source=overview`
                    : `/clients/detail/${id}?source=overview`
                }`
              );
            }}
            className="tw-inline-flex tw-cursor-pointer tw-items-center tw-justify-center tw-bg-gray-200 tw-w-8 tw-h-8 tw-rounded-full hover:tw-bg-gray-300 hover:tw-text-gray-800 flux"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              className="iconify iconify--bytesize tw-inline"
              width="12"
              height="12"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                fill="none"
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m30 7l-5-5L5 22l-2 7l7-2Zm-9-1l5 5ZM5 22l5 5Z"
              />
            </svg>
          </span>
          <span> to populate your profile</span>
        </p>
      </div>
    </div>
  );
};

export default EmptyState;
