import React from "react";
import styles from "./styles.module.scss";
import { BASE_URL } from "../../../api";
import { IMAGES } from "../../../constants/assets";
import { formatBytes } from "../../../utils/helper";
import moment from "moment";
import ActionButtons from "components/buttons/actionButtons";

const DocumentTable = ({
  tableHeading,
  tableData,
  deleteClick,
  accessControl,
  forInvoice = false,
}) => {
  const handlerDownloadFile = (e, fileName) => {
    fetch(`${BASE_URL}/bucket/${fileName}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      });
    });
  };

  return (
    <div className={styles.tablehead}>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" className={styles.fileNameTh}>
              {tableHeading.th1}
            </th>
            <th scope="col" className={styles.uploadedByStyles}>
              {tableHeading.th2}
            </th>

            <th scope="col" className={styles.actionHead}>
              {tableHeading.th5}
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={index}>
              <td>
                <div className={styles.documentName}>
                  <img src={IMAGES.FILE_PDF} alt="" className="me-1" />
                  <span className={styles.fileNameStyle}>
                    {item?.fileName?.length > 30
                      ? item?.fileName?.substring(0, 30) +
                        "...." +
                        item?.fileName?.split(".").pop()
                      : item?.fileName || ""}

                    <div className={styles.bottomStyles}>
                      {item?.size ? formatBytes(item?.size) : ""}
                    </div>
                  </span>
                </div>
              </td>
              <td className={styles.td2}>
                <div>
                  {` ${item?.uploadedBy?.name ? item?.uploadedBy?.name : ""}
                ${
                  item?.uploadedBy?.lastName ? item?.uploadedBy?.lastName : ""
                }`}
                </div>

                <div className={styles.byStyles}>
                  {`${moment(item?.createdAt).format("DD MMM YYYY")}` ||
                    `${item?.generatedBy?.name} ${item?.generatedBy?.lastName}` ||
                    ""}
                </div>
              </td>

              <td className={styles.tableData}>
                <div
                  className={styles.tableLinkConatiner}
                  onClick={(e) => e.stopPropagation(e)}
                >
                  <ActionButtons
                    forInvoice={forInvoice}
                    data={item}
                    generatedById={item?.uploadedBy?._id}
                    viewAction={() => {
                      window.open(`/docs/${item?.fileUrl?.original}`, "_blank");
                    }}
                    DownloadAction={(e) =>
                      handlerDownloadFile(e, item?.fileUrl?.original)
                    }
                    deleteAction={() => deleteClick(item?._id)}
                    accessControl={accessControl}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DocumentTable;
