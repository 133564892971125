import React from "react";
import styles from "./styles.module.scss";
import { MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { assignmentTalentStatus } from "constants/contants";

const StatusSelect = ({
  greenBackDropDown,
  data,
  hasWriteAccess,
  assignment,
  onStatusChange,
}) => {
  return (
    <Select
      size="small"
      variant="standard"
      className={`${styles.topSelect} ${
        greenBackDropDown.includes(data?.status) && styles.greenSelect
      }`}
      value={data?.status}
      disabled={!hasWriteAccess || !!assignment?.isArchive}
      inputProps={{
        "aria-label": "Without label",
      }}
      onChange={(e) => {
        if (e?.target?.value === "") {
        } else {
          onStatusChange([data?.talentId?._id], "status", e);
        }
      }}
      IconComponent={ExpandMoreIcon}
      sx={{
        "&	.MuiSelect-select": {
          padding: "0px 0px 0px 7px",
          fontSize: "10px",
          fontWeight: "bold",
          ":focus": {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      {assignmentTalentStatus.map((item, index) => (
        <MenuItem
          key={index}
          value={item?.value}
          disabled={item?.label === "Select"}
          className={
            item?.label === "Select" ? styles.trasnStyles : styles.selectItem
          }
        >
          {item?.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StatusSelect;
