import React from "react";
import styles from "./styles.module.scss";
import { noDataLabels } from "constants/contants";
import RoundEditIcon from "pages/talent/components/actionIcons/roundEditIcon";
import RoundDeleteIcon from "pages/talent/components/actionIcons/roundDeleteIcon";
import { useNavigate } from "react-router-dom";
import { convert } from "html-to-text";

const WorkRow = ({
  data,
  hasWriteAccess,
  ristrictAccess,
  id,
  hasDeleteAccess,
  setDeleteId,
  setOpenModal,
  active,
  setActive,
}) => {
  const navigate = useNavigate();

  const convertHtmlTotext = (htmlText, truncate) => {
    const text = convert(htmlText, {
      wordwrap: 130,
    });
    const removeSpace = text.replace(/[\n\r\s\t]+/g, " ");

    if (truncate) {
      return removeSpace.substring(0, 300);
    }
    return removeSpace;
  };

  return (
    <>
      {data?.length === 0 ? (
        <div className={styles.noDataContainer}>
          <h4 className={styles.dataNotFound}>{noDataLabels.workList}</h4>
        </div>
      ) : (
        data?.map((data, index) => (
          <div key={data._id} className={styles.newdetailscontent}>
            <div className={styles.newdetailborder}>
              <div className={styles.flexContainer}>
                <div className={styles.roundIconContainer}>
                  {hasWriteAccess || ristrictAccess() ? (
                    <RoundEditIcon
                      onClick={() =>
                        navigate(
                          `/talent/work/${id}?workId=${data?._id}&source=overview`
                        )
                      }
                    />
                  ) : (
                    ""
                  )}

                  {hasDeleteAccess && (
                    <RoundDeleteIcon
                      onClick={() => {
                        setDeleteId(data?._id);
                        setOpenModal(true);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={styles.detailsheading}>
                <h2 className={styles.capitalText}>{data?.designation}</h2>
                <h3>{data?.companyName}</h3>
                <h4 className={styles.capitalText}>
                  {data?.joinDateMonth ? data?.joinDateMonth : ""}
                  {data?.joinDateYear ? ` ${data?.joinDateYear}` : ""}
                  {(data?.joinDateYear || data?.joinDateMonth) &&
                  (data?.currentlyWork ||
                    data?.endDateMonth ||
                    data?.endDateYear)
                    ? "-"
                    : ""}

                  {data?.currentlyWork
                    ? `  Present`
                    : `${
                        data?.endDateMonth ? `  ${data?.endDateMonth} ` : ""
                      } ${data?.endDateYear ? ` ${data?.endDateYear}` : ""}`}
                </h4>

                <div className={styles.checkdata}>
                  <div className={styles.workSynopsis}>
                    {active === data?._id
                      ? convertHtmlTotext(data?.workSynopsis)
                      : convertHtmlTotext(data?.workSynopsis, true)}

                    <span
                      className={styles.seeMore}
                      onClick={() => {
                        if (active === data?._id) {
                          setActive(null);
                        } else {
                          setActive(data?._id);
                        }
                      }}
                    >
                      {convertHtmlTotext(data?.workSynopsis).length > 370
                        ? active === data?._id
                          ? `... See Less`
                          : `... See More`
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default WorkRow;
