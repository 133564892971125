import React from "react";
import styles from "./styles.module.scss";
import moment from "moment";
import { formatAmount, getAmountPostFix } from "../../utils/helper";
const CurrencyBox = ({ data, current }) => {
  return (
    <div className={styles.boxx}>
      <h5>{current ? "Current CTC" : "Expected CTC "}</h5>
      <p className={styles.paragraphStyles}>
        {`Last updated `}
        {data?.compensationHistory?.[0]?.updatedAt
          ? moment(data?.compensationHistory?.[0]?.updatedAt).format(
              "DD MMM YYYY"
            )
          : ""}
      </p>
      {current
        ? !!data?.compensationHistory?.[0]?.current && (
            <h1>
              {`${data?.currency || "₹"} ${formatAmount(
                data?.compensationHistory?.[0]?.current
              )} `}
              <span className={styles.amountPostFix}>
                {getAmountPostFix(data?.compensationHistory?.[0]?.current)}
              </span>
            </h1>
          )
        : !!data?.compensationHistory?.[0]?.expected && (
            <h1>
              {`${data?.currency || "₹"} ${formatAmount(
                data?.compensationHistory?.[0]?.expected
              )} `}
              <span className={styles.amountPostFix}>
                {getAmountPostFix(data?.compensationHistory?.[0]?.expected)}
              </span>
            </h1>
          )}
    </div>
  );
};
export default CurrencyBox;
