import { SUB_MODULES } from "./accessControl";
import { DATA_SET_CASE } from "./contants";

export const DatasetPageHeading = (activeTab) => {
  switch (activeTab) {
    case DATA_SET_CASE.INDUSTRY_TYPE:
      return "Industry Type";
    case DATA_SET_CASE.SECTORS:
      return "Sectors";
    case DATA_SET_CASE.FUNCTIONAL_AREAS:
      return "Functional Areas";
    case DATA_SET_CASE.SKILLS_TAGS:
      return "Skills and Tags";
    case DATA_SET_CASE.COMPENTENCY:
      return "Competencies";
    case DATA_SET_CASE.EDUCATION_TYPE:
      return "Education Type";
    default:
      return console.log("No Active Tab");
  }
};

export const modalText = (activeTab) => {
  switch (activeTab) {
    case DATA_SET_CASE.INDUSTRY_TYPE:
      return "Industry Type";
    case DATA_SET_CASE.SECTORS:
      return "Sector";
    case DATA_SET_CASE.FUNCTIONAL_AREAS:
      return "Functional Area";
    case DATA_SET_CASE.SKILLS_TAGS:
      return "Skills/Tags";
    case DATA_SET_CASE.COMPENTENCY:
      return "Competency";
    case DATA_SET_CASE.EDUCATION_TYPE:
      return "Education Type";
    default:
      return console.log("No Active Tab");
  }
};

export const DatasetAddButtonLabel = (activeTab) => {
  switch (activeTab) {
    case DATA_SET_CASE.INDUSTRY_TYPE:
      return "Add Industry Type";
    case DATA_SET_CASE.SECTORS:
      return "Add Sectors";
    case DATA_SET_CASE.FUNCTIONAL_AREAS:
      return "Add Functional Areas";
    case DATA_SET_CASE.SKILLS_TAGS:
      return "Add Skills and Tags";
    case DATA_SET_CASE.COMPENTENCY:
      return "Add Competency";
    case DATA_SET_CASE.EDUCATION_TYPE:
      return "Add Education Type";
    default:
      return console.log("No Active Tab");
  }
};

export const subModuleHandler = (activeTab) => {
  switch (activeTab) {
    case DATA_SET_CASE.INDUSTRY_TYPE:
      return SUB_MODULES.INDUSTRY_TYPE;
    case DATA_SET_CASE.SECTORS:
      return SUB_MODULES.SECTORS;
    case DATA_SET_CASE.FUNCTIONAL_AREAS:
      return SUB_MODULES.FUNCTIONAL_AREAS;
    case DATA_SET_CASE.SKILLS_TAGS:
      return SUB_MODULES.SKILLS_TAGS;
    case DATA_SET_CASE.COMPENTENCY:
      return SUB_MODULES.COMPETENCIES;
    case DATA_SET_CASE.EDUCATION_TYPE:
      return SUB_MODULES.EDUCATION;
    default:
      return console.log("No Active Tab");
  }
};

export const deleteDocumnetType = (activeTab) => {
  switch (activeTab) {
    case DATA_SET_CASE.INDUSTRY_TYPE:
      return 6;
    case DATA_SET_CASE.SECTORS:
      return 9;
    case DATA_SET_CASE.FUNCTIONAL_AREAS:
      return 7;
    case DATA_SET_CASE.SKILLS_TAGS:
      return 8;
    case DATA_SET_CASE.COMPENTENCY:
      return 10;
    case DATA_SET_CASE.EDUCATION_TYPE:
      return 13;
    default:
      return console.log("No Active Tab");
  }
};

export const blockDocumnetType = (activeTab) => {
  switch (activeTab) {
    case DATA_SET_CASE.INDUSTRY_TYPE:
      return 1;
    case DATA_SET_CASE.SECTORS:
      return 5;
    case DATA_SET_CASE.FUNCTIONAL_AREAS:
      return 2;
    case DATA_SET_CASE.SKILLS_TAGS:
      return 4;
    case DATA_SET_CASE.COMPENTENCY:
      return 6;
    case DATA_SET_CASE.EDUCATION_TYPE:
      return 7;
    default:
      return console.log("No Active Tab");
  }
};

export const DataSetType = (activeTab) => {
  switch (activeTab) {
    case DATA_SET_CASE.INDUSTRY_TYPE:
      return "Industry Type";
    case DATA_SET_CASE.SECTORS:
      return "Sectors";
    case DATA_SET_CASE.FUNCTIONAL_AREAS:
      return "Functional Areas";
    case DATA_SET_CASE.SKILLS_TAGS:
      return "Skills/Tags";
    case DATA_SET_CASE.COMPENTENCY:
      return "Competency";
    case DATA_SET_CASE.EDUCATION_TYPE:
      return "Education Type";
    default:
      return console.log("No Active Tab");
  }
};

export const apiTypeHandler = (activeTab) => {
  switch (activeTab) {
    case DATA_SET_CASE.INDUSTRY_TYPE:
      return 1;
    case DATA_SET_CASE.SECTORS:
      return 4;
    case DATA_SET_CASE.FUNCTIONAL_AREAS:
      return 2;
    case DATA_SET_CASE.SKILLS_TAGS:
      return 3;
    case DATA_SET_CASE.COMPENTENCY:
      return 5;
    case DATA_SET_CASE.EDUCATION_TYPE:
      return 6;
    default:
      return console.log("No Active Tab");
  }
};
