export const TOAST_MESSAGE = {
  DELETE_COMMENT: "Comment deleted successfully",
  RESTRICT_SIDEBAR: "You are not allowed to perform this action",
  ADD_REMINDER: "Reminder added successfully",
  ASSIGNMENT_ARCHIVED: "Assignment archived",
  UPDATE_STATUS: "Status updated successfully",
  ADD: " Added successfully",
  DELETE_CANDIDATE: "Candidate deleted successfully",
  REMOVE_CANDIDATE: "Candidate removed successfully",
  COPY_LINK: "Link copied",
  CREATE_PROFILE: "Profile created successfully",
  UPADTE_PROFILE: "Profile updated successfully",
  DELETE_ORGANISATION: "Organization deleted duccessfully",
  SEND_LINK: "Link sent successfully!",
  DELETE_COMPETENCY: "Competency deleted successfully",
  BLOCK_COMPETENCY: "Competency blocked successfully",
  DELETE_FUNCTIONAL_AREA: "Functional area deleted successfully",
  BLOCKED_FUNCTIONAL_AREA: "Functional area blocked successfully",
  DELETE_INDUSTRY_TYPE: "Industry type deleted successfully",
  BLOCKED_INDUSTRY_TYPE: "Industry type blocked successfully",
  DELETE_SECTOR: "Sector deleted successfully",
  BLOCKED_SECTOR: "Sector blocked successfully",
  ADD_TALENT_USER: "TalentLab user added successfully",
  UPDATE_TALENT_USER: "TalentLab user updated successfully",
  DELETE_USER: "User deleted successfully",
  BLOCKED_USER: "User blocked successfully",
  UNBLOCKED_USER: "User unblocked successfully",
  NEW_PASSWORD: ["New password and confirm password ", "should be same"],
  DOSIER_PROGRESS: [
    "Dossier generation is in progress,",
    "dossier will be emailed to you shortly",
  ],
  SAVED_CERTIFICATE: "Certification saved successfully",
  SAVED_PUBLICATION: "Publication saved successfully",
  SAVED_COMPETENCY: "Competency saved successfully",
  DELETE_DOCUMENT: "Document deleted successfully",
  RECORD_DOCUMENT: "Record deleted successfully",
  SAVE_DOCUMENT: "Document saved successfully",
  SAVED_EDUCATION: "Education saved successfully",
  SAVED_WORK: "Work saved successfully",
  SET_OFF_LIMIT: "Off limit set successfully",
  REVOKE_OFF_LIMIT: "Off limit revoked successfully",
  UPDATE_OFF_LIMIT: "Off limit updated successfully",
  REQUEST: "Request submit successfully",
  DELETE_WORK: "Work deleted successfully",
  LOGOUT: "Logout successful!",
  ALL_FILL: "Please fill the required fields",
  SOMETHING_WENT_WRONG: "Something went wrong",
  UPDATE_PASSWORD:
    "Password updated successfully. Please wait while we reload the page",
  SET_PASSWORD:
    "Password set successfully. Please wait while we reload the page",
  DELETE: "deleted successfully",
  BLOCK: "blocked successfully",
  EDUCATION_DELETE: "Education type deleted successfully",
  EDUCATION_BLOCK: "Education type blocked successfully",
  SKILL_DELETE: "Skills/Tags deleted successfully",
  SKILL_BLOCK: "Skills/Tags blocked successfully",
  RESET: "Reset instructions sent successfully",
  DELETE_DOSIER: "Dossier deleted successfully",
  DELETE_REQUEST: [
    "Your request for deletion has been ",
    " sent successfully.",
  ],
  DELETE_CERT: "Certification deleted successfully",
  DELETE_PUBL: "Publication deleted successfully",
  DELETE_EDUCATTION: "Education deleted successfully",
  UPDATE_SUCESS: "Updated successfully",
  COMPENSATION_SAVED: "Compensation saved successfully",
  BILLING_SAVED: "Information saved successfully",
  BILLING_UPDATE: "Information updated successfully",
  ASSIGNMENT_ADD: "Assignment added successfully",
  ASSIGNMENT_UPDATE: "Assignment updated successfully",
  BLOCK: "blocked successfully",
  UNBLOCK: "unblocked successfully",
  UPLOAD_PROGRESS: "Upload is in progress",
};
