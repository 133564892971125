import React from "react";
import styles from "./styles.module.scss";

const SummaryBox = ({ title, value, denominator }) => {
  return (
    <div className={styles.boxx}>
      <h5 className={denominator === false && styles.totalCandidates}>
        {title}
      </h5>

      <h1>{value}</h1>
      {denominator !== false && <hr className={styles.lineDivider} />}

      {denominator !== false ? (
        denominator ? (
          <h3>
            {`${((value / denominator) * 100).toFixed(0)}`}
            <span className={styles.percentageStyle}>%</span>
          </h3>
        ) : (
          <h3>
            0<span className={styles.percentageStyle}>%</span>
          </h3>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default SummaryBox;
