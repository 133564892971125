import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import Ageing from "components/Ageing";
import { IMAGES } from "constants/assets";

const AssignmentsTable = ({ tableData, hasAssignmentAccess }) => {
  return (
    <>
      <h3 className={styles.summaryHead}>Ongoing Assignments</h3>
      <div className={styles.assignmentTable}>
        <table class="table">
          <thead>
            <tr>
              <th scope="col" className={styles.thPosition}>
                Name
              </th>
              <th scope="col" className={styles.thAgeing}>
                Ageing
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((data, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className={styles.tData}
                  >
                    <b style={{ textTransform: "capitalize" }}>{data?.title}</b>

                    {hasAssignmentAccess && (
                      <Link
                        to={`/assignments/overview/${data?._id}?tab=overview&activetab=open-assignments`}
                        target="_blank"
                      >
                        <img
                          alt=""
                          src={IMAGES.EXTERNAL_LINK}
                          className={styles.imageIcon}
                        />
                      </Link>
                    )}
                  </td>
                  <td>
                    <Ageing assignmentDate={data?.createdAt} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AssignmentsTable;
