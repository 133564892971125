import React from "react";
import styles from "./styles.module.scss";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";

const AddEditAssignment = () => {
  const { id } = useParams();

  return (
    <section className={styles.overview}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={styles.heading}>
              <h2>{id ? "Edit assignment" : "Add a new assignment"}</h2>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="d-flex align-items-start">
              <div className={`tab-content ${styles.tabcontent}`}>
                <div className="fade show active">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddEditAssignment;
