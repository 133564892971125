import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import moment from "moment";
import { IMAGE_BASE_URL } from "../../../../../constants/contants";
import { ROUTES } from "../../../../../router/routes";
import { addEditApi } from "../../../../../api/commonApis";
import { APIS } from "../../../../../api";
import { IMAGES } from "../../../../../constants/assets";

const NotificationRow = ({ unread, data, setRefresh }) => {
  const notificationAction = () => {
    if (data?.onModel === "Talents") {
      window.open(
        `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${data?.id?._id}?tab=overview`,
        "_blank"
      );
    }
    if (data?.onModel === "Organization") {
      window.open(
        `${ROUTES.CLIENTS}${ROUTES.OVERVIEW}/${data?.id?._id}?tab=overview`,
        "_blank"
      );
    }
    if (data?.onModel === "Assignments") {
      window.open(
        `${ROUTES.ASSIGNMENTS}${ROUTES.OVERVIEW}/${data?.id?._id}?tab=overview&activetab=open-assignments`,
        "_blank"
      );
    }
    addEditApi(APIS.NOTIFICATION_ACTIONS, { id: data?._id, action: 1 }).then(
      (response) => setRefresh((s) => !s)
    );
  };
  return (
    <>
      <div>
        <div
          onClick={notificationAction}
          className={`${styles.container} ${unread ? styles.unread : ""}`}
        >
          {data?.type === 1 ? (
            data?.by?.imageUrl?.original ? (
              <img
                src={IMAGE_BASE_URL + data?.by?.imageUrl?.original}
                className={styles.adminThumImage} ////// userImage Calender Image
              />
            ) : (
              <div className={styles.iconNameBackground}>
                {data?.by?.name[0]}
                {data?.by?.lastName[0]}
              </div>
            )
          ) : (
            <div className={styles.iconBackground}>
              <img
                src={IMAGES.CALENDER_ICON}
                className={styles.adminThumImageCalender} ////// Static Calender Image
              />
            </div>
          )}

          <div className={styles.content}>
            <div className={styles.text}>
              {data?.type === 1 && (
                <>
                  <span className={styles.mentionText}>
                    {data?.by?.name} {data?.by?.lastName}{" "}
                  </span>
                  mentioned you on{" "}
                  <span className={styles.mentionText}>
                    {data?.id?.firstName
                      ? `${data?.id?.firstName} ${data?.id?.lastName}`
                      : data?.id?.title
                      ? data?.id?.title
                      : data?.id?.name
                      ? data?.id?.name
                      : ""}
                  </span>
                  .
                </>
              )}
              {data?.type === 2 && (
                <>
                  {`You have an upcoming reminder for `}
                  <span className={styles.mentionText}>
                    {data?.id?.firstName
                      ? `${data?.id?.firstName} ${data?.id?.lastName}`
                      : data?.id?.title
                      ? data?.id?.title
                      : data?.id?.name
                      ? data?.id?.name
                      : ""}
                    {`.`}
                  </span>
                </>
              )}
            </div>
            <span className={styles.time}>
              {moment(data?.createdAt)?.fromNow?.()}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.rowDivider}>
        <hr className={styles.divider} />
        <Divider />
      </div>
    </>
  );
};

export default NotificationRow;
