import React from "react";
import Invoices from "../../addEdit/invoices";
import styles from "./styles.module.scss";
import Contracts from "pages/clients/addEdit/contract";

const ContractsOverview = () => {
  return (
    <div className={styles.talentdetails}>
      <Contracts />
    </div>
  );
};

export default ContractsOverview;
