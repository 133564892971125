import { getState } from "../redux/store";
import ToastMessage from "../components/layout/toast";
import { toast } from "react-toastify";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { MODULES, PERMISSIONS, SUB_MODULES } from "constants/accessControl";
import { subModuleHandler } from "constants/dataSetConstant";

const dataSetOptions = [
  { label: "Industry Types", id: "datasets-industrytype" },
  { label: "Sectors", id: "datasets-sectors" },
  { label: "Functional Areas", id: "datasets-functionalareas" },
  { label: "Skills/Tags", id: "datasets-skillstags" },
  { label: "Competencies", id: "datasets-compentency" },
  { label: "Education Types", id: "datasets-education" },
];

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getTalentStatus = (statuscode) => {
  switch (statuscode) {
    case 1:
      return "Not Assigned";
    case 2:
      return "Converted";
    case 3:
      return "In Pipeline";
    case 4:
      return "Off Limit";
    case 5:
      return "Joined";
    default:
      return "No Match";
  }
};
export const getAssignmentStatus = (statuscode) => {
  switch (statuscode) {
    case 1:
      return "Job Description";
    case 2:
      return "Discussion deck";
    case 3:
      return "Weekly cadence";
    case 4:
      return "Eng. Progress";
    case 5:
      return "Dossier Submission";
    case 6:
      return "Interviews";
    case 7:
      return "Offer stage";
    case 8:
      return "Handholding";
    case 9:
      return "Converted";
    case 10:
      return "Candidate joining";
    case 11:
      return "Post joining eng.";
    case 12:
      return "Completed";
    default:
      return "No Match";
  }
};

export const getAssignmentCandidateStatus = (statuscode) => {
  switch (statuscode) {
    case 1:
      return "Assigned";
    case 2:
      return "To be interviewed";
    case 3:
      return "Interviewed";
    case 4:
      return "Offer Made";
    case 5:
      return "Benched";
    case 6:
      return "Offer Accepted";
    case 7:
      return "Joined";
    case 8:
      return "Offer Dropped";
    case 9:
      return "Dropped After Conversion";
    case 10:
      return "Not Selected";
    default:
      return "-";
  }
};

export const getAmountPostFix = (value) => {
  if (value >= 10000000) {
    return "Cr";
  } else if (value >= 100000) {
    return "Lacs";
  } else return "";
};

export const formatAmount = (value) => {
  let val = Math.abs(value);

  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2);
    val = parseFloat(val);
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2);
    val = parseFloat(val);
  }
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const yearDropDownOptions = (updateYear) => {
  const dataSet = [];
  const startYear = updateYear ? updateYear : 1950;
  for (let i = startYear; i <= new Date().getFullYear(); i++) {
    dataSet.push({
      label: i.toString(),
      value: i.toString(),
    });
  }

  return dataSet;
};
export const EndYearDropDownOptions = (updateYear) => {
  const dataSet = [];
  const startYear = updateYear ? updateYear : 1950;
  for (let i = startYear; i <= new Date().getFullYear() + 5; i++) {
    dataSet.push({
      label: i.toString(),
      value: i.toString(),
    });
  }

  return dataSet;
};

export const copyToClipboard = (text) => {
  const dummy = document.createElement("input");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

export const convertCommentToHtml = (comment = "") => {
  let newComment = comment;

  // for hash tags
  newComment = newComment.replace(
    /#\w+/g,
    `<a class='mention' href='#'>$&</a>`
  );

  // for links
  newComment = newComment.replace(
    /(https?:\/\/[^\s]+)/g,
    `<a href='$&' class='link' target="_blank">$&</a>`
  );

  // for talent
  newComment = newComment
    .split("@@@__")
    .join(`<a class='mention' href='/talent/overview/`);

  // for teams
  newComment = newComment.split("ttt__").join(`<a class='mention' href='#' `);

  // for assignment
  newComment = newComment
    .split("aaa__")
    .join(`<a class='mention' href='/assignments/overview/`);

  // for organisations
  newComment = newComment
    .split("ooo__")
    .join(`<a class='mention' href='/clients/overview/`);

  // close anchor tags
  newComment = newComment.split("^^^__").join(`'>`);
  newComment = newComment.split("@@@^^^").join("</a>");

  return newComment?.trim();
};

export const filterAdminIds = (allMentions = []) => {
  return allMentions
    ?.filter((x) => x.includes("admin_id_"))
    .map((x) => x.replace("admin_id_", ""));
};

export const validationError = (errors) => {
  if (Object.keys(errors).length) {
    toast.error(
      <ToastMessage title={"Error"} message={TOAST_MESSAGE.ALL_FILL} />
    );
  }
};
export const validationErrorMapping = (errors) => {
  if (Object.keys(errors).length) {
    toast.error(
      <ToastMessage
        title={"Error"}
        message={
          errors?.compentancyMapping?.[0]?.score
            ? errors?.compentancyMapping?.[0]?.score
            : TOAST_MESSAGE.ALL_FILL
        }
      />
    );
  }
};

export const globalStandardNumber = (number) => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const order = (a, b) => {
  return a?.label.localeCompare(b?.label);
};

////////// Search Page Filter Page TYpe Hanlder///////
export const pageTypeHandler = (type) => {
  if (type === 1) {
    return 1;
  }
  if (type === 3) {
    return 2;
  }
  if (type === 2) {
    return 3;
  }
};

/////// PERMISSION HANDLER FOR ALL/////////

export const hasPermission = (module, subModule, permission) => {
  const user = getState()?.auth?.data;

  if (user?.superAdmin) {
    return true;
  }

  const selectedModule = user?.roles?.find((item) => item.module === module);

  if (selectedModule) {
    if (selectedModule?.permission?.[subModule]?.includes(permission)) {
      return true;
    }
  }

  return false;
};

/////////// PERMISSION HANDLER FOR TALNET AND ORGANIZATION

export const ristrictAccess = () => {
  const ristricRoutes = ["Organizations", "Talent"];
  const user = getState()?.auth?.data?.talentLabRole;

  if (ristricRoutes.includes(user)) {
    return true;
  }

  return false;
};

/////////// PERMISSION HANDLER FOR DATA SET //////////

export const dataSetHasPermission = () => {
  const user = getState()?.auth?.data;

  if (user?.superAdmin) {
    return true;
  }
  const hasDataSetPermission = dataSetOptions?.map((menu, index, arr) => {
    return hasPermission(
      MODULES.DATA_SETS,
      subModuleHandler(menu?.id),
      PERMISSIONS.READ
    );
  });

  if (hasDataSetPermission.includes(true)) {
    return true;
  } else {
    return false;
  }
};

///// SET CLICK ROUTE THAT WHICH WILL ACTIVE WEHN CLICK ON DATA SETS////

export const tabHandler = () => {
  const ActiveTab = dataSetOptions?.map((tab, index, arr) => {
    if (
      hasPermission(
        MODULES.DATA_SETS,
        subModuleHandler(tab?.id),
        PERMISSIONS.READ
      )
    ) {
      return tab?.id;
    }
  });
  const newArray = ActiveTab?.filter((tabs, index, arr) => {
    return tabs !== undefined;
  });

  if (newArray?.length > 0) {
    return newArray[0];
  } else {
    return "";
  }
};
////// PERMISSION FOR SIDE PANEL///////

export const sidePanelAccess = () => {
  const user = getState()?.auth?.data;
  let array = [];

  if (user?.superAdmin) {
    return true;
  } else {
    array?.push(
      hasPermission(
        MODULES.TALENT,
        SUB_MODULES.TALENT_OVERVIEW,
        PERMISSIONS.WRITE
      )
    );

    array?.push(
      hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.ORGANIZATION_OVERVIEW,
        PERMISSIONS.WRITE
      )
    );
    array?.push(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_OVERVIEW,
        PERMISSIONS.WRITE
      )
    );
  }

  if (array.includes(true)) {
    return true;
  }

  return false;
};
//////// IS SUPER USER///////
export const isSuperAdmin = () => {
  const user = getState()?.auth?.data;

  if (user?.superAdmin) {
    return true;
  }
  return false;
};

////// PERMISSION OF HEADER//////////

export const headerPermission = (module) => {
  const user = getState()?.auth?.data;

  if (user?.superAdmin) {
    return true;
  }

  const hasAccess = user?.roles?.map((menu, index, arr) => {
    if (menu?.module === module) {
      for (let key in menu?.permission) {
        if (menu?.permission[key].includes("read")) {
          return true;
        } else {
          return false;
        }
      }
    }
  });
  const AccessArray = hasAccess?.filter((tabs, index, arr) => {
    return tabs !== undefined;
  });

  if (AccessArray?.length > 0) {
    return AccessArray[0];
  } else {
    return "";
  }
};

export const hasSearchAccess = () => {
  if (
    headerPermission(MODULES.TALENT) &&
    headerPermission(MODULES.ORGANIZATIONS) &&
    headerPermission(MODULES.ASSIGNMENT)
  ) {
    return true;
  }
  return false;
};

//////// check access for super admin and finance manager for billing/////

export const hasSuperAdminAndManger = () => {
  const user = getState()?.auth?.data;

  if (user?.superAdmin || user?.talentLabRole === "Finance Manager") {
    return true;
  }
  return false;
};
