import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";
import LoginFooter from "components/loginFooter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { ResetPassowrdSchema } from "constants/formSchema";
import { useParams } from "react-router-dom";
import { TOAST_MESSAGE } from "constants/toastMessage";
import ToastMessage from "components/layout/toast";
import { addEditApi } from "api/commonApis";
import { APIS } from "api";
import { ROUTES } from "router/routes";
import { toast } from "react-toastify";
///INITIALVALUES

const defaultValues = {
  newPassowrd: "",
  confirmPassword: "",
};

const ResetPasswordExt = () => {
  const navigate = useNavigate();
  const [buttonHover, setButtonHover] = useState(false);
  const { id } = useParams();
  const { type } = useParams();
  const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);

  /////// HANDLER FOR SUBMIT///

  const onSubmit = (payload, { resetForm }) => {
    setLoading(true);
    if (payload.confirmPassword !== payload.newPassowrd) {
      toast.error(
        <ToastMessage
          title={"Error"}
          isMultiLine
          message={TOAST_MESSAGE.NEW_PASSWORD}
        />
      );
    } else {
      addEditApi(APIS.UPDATE_PASSWORD, {
        id: id,
        password: payload.confirmPassword,
      }).then((res) => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={
              type == 1
                ? TOAST_MESSAGE.SET_PASSWORD
                : TOAST_MESSAGE.UPDATE_PASSWORD
            }
          />
        );
        resetForm(defaultValues);
        setTimeout(() => {
          if (res?.data?.role === "Talent") {
            navigate(ROUTES.TALENT_LOGIN);
          } else if (res?.data?.role === "Organizations") {
            navigate(ROUTES.CLIENT_LOGIN);
          } else {
            navigate(ROUTES.LOGIN);
          }
        }, 3000);
      });
    }
    setLoading(false);
  };

  //// RETURN //////

  return (
    <div className={styles.mainContainer}>
      <div className={styles.middleBox}>
        <div>
          <img src={IMAGES.LOGO_SVG} />
        </div>
        <div>
          <div className={styles.headingContainer}>
            <h1 className={styles.headingtext}>
              {type == 1 ? "Set Password" : ""}
              {type == 2 ? "Reset Password" : ""}
            </h1>
            <p className={styles.headingPara}>&nbsp;</p>
          </div>

          <Formik
            onSubmit={onSubmit}
            initialValues={defaultValues}
            validationSchema={ResetPassowrdSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.formContainer}>
                  <div className={styles.passwordContainer}>
                    <div className={styles.labelContainer}>
                      <span className={styles.errorMsg}>
                        &nbsp;
                        {(errors.newPassowrd &&
                          touched.newPassowrd &&
                          errors.newPassowrd) ||
                          ""}
                      </span>
                    </div>
                    <input
                      name="newPassowrd"
                      className={styles.passwordInput}
                      type={newPasswordVisibility ? "text" : "password"}
                      password
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassowrd}
                      placeholder="New Password"
                    />
                    <img
                      alt="eye"
                      className={styles.svgStyles}
                      src={newPasswordVisibility ? IMAGES.EYE : IMAGES.EYESLASH}
                      onClick={() =>
                        setNewPasswordVisibility(!newPasswordVisibility)
                      }
                    />
                  </div>
                  <div className={styles.passwordContainer}>
                    <div className={styles.labelContainer}>
                      <span className={styles.errorMsg}>
                        &nbsp;
                        {(errors.confirmPassword &&
                          touched.confirmPassword &&
                          errors.confirmPassword) ||
                          ""}
                      </span>
                    </div>
                    <input
                      name="confirmPassword"
                      className={styles.passwordInput}
                      type={confirmPasswordVisibility ? "text" : "password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      placeholder="Confirm Password"
                    />
                    <img
                      alt="eye"
                      className={styles.svgStyles}
                      src={
                        confirmPasswordVisibility ? IMAGES.EYE : IMAGES.EYESLASH
                      }
                      onClick={() =>
                        setConfirmPasswordVisibility(!confirmPasswordVisibility)
                      }
                    />
                  </div>
                  <div className={styles.labelContainer}>
                    <span className={styles.errorMsg}>&nbsp;</span>
                  </div>
                  <button
                    type="submit"
                    onMouseOver={() => setButtonHover(true)}
                    onMouseLeave={() => setButtonHover(false)}
                    className={styles.button}
                    disabled={loading}
                  >
                    <span>
                      {type == 1
                        ? loading
                          ? "Loading..."
                          : "Set Password"
                        : ""}
                      {type == 2
                        ? loading
                          ? "Loading..."
                          : "Reset Password"
                        : ""}
                    </span>
                    <img
                      src={
                        buttonHover ? IMAGES?.BLACK_ARROW : IMAGES.RIGHT_ARROW
                      }
                      className={styles.arrowStyles}
                    />
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div style={{ marginTop: "31px" }}>
          <LoginFooter />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordExt;
