import React from "react";
import styles from "./styles.module.scss";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";

const FilterButton = ({ children, className, onClick, disabled, ...rest }) => {
  return (
    <div
      className={`${styles.btn} ${className} ${
        disabled ? styles.inactive : styles.hoverEffect
      } `}
      onClick={!disabled && onClick}
      {...rest}
    >
      <FilterListOffOutlinedIcon
        style={{
          fontSize: "14px",
          marginRight: "5px",
        }}
      />
      Reset Filters
    </div>
  );
};

export default FilterButton;
