import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";
import Skeleton from "../../../../components/skeleton";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../../constants/accessControl";
import { hasPermission, ristrictAccess } from "../../../../utils/helper";
import ClientHeader from "components/pageHeader/clientHeader";
import moment from "moment";
import ContactBox from "./contactBox";
import ContactRightBox from "./contactRightBox";
import SummaryBlock from "./summaryBlock";
import Table from "./table";
import { getState } from "redux/store";
import Walkthrough from "components/walkthrough";
import EmptyState from "components/emptyState";
import ExtendTable from "pages/assignmentExt/extendTable";
import { useSelector } from "react-redux";
import { APIS } from "api";
import { ROLES } from "constants/contants";
import { listApi } from "api/commonApis";

const Overview = ({ client }) => {
  const [params, setParams] = useSearchParams();
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasAssignmentAccess, setHasAssignmentAccess] = useState(false);
  const [hasContactAccess, setHasContactAccess] = useState(false);
  const [user, set_user] = useState({});

  const userRole = useSelector((state) => state?.auth?.data?.talentLabRole);
  const [tableData, setTableData] = useState([]);
  const [searchParams] = useSearchParams();
  const [isAssignment, setIsAssignment] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { id } = useParams();
  const activeId = searchParams.get("activeId");

  useEffect(() => {
    if (activeId) {
      setIsAssignment(true);
    }
  }, [activeId]);

  ///// GETTING PERMISSION FOR SAME COMPONENT//////

  useEffect(() => {
    const role = getState()?.auth?.data?.talentLabRole;

    set_user(getState()?.auth?.data);

    setHasAssignmentAccess(
      role === "Organizations"
        ? true
        : hasPermission(
            MODULES.ASSIGNMENT,
            SUB_MODULES.ASSIGNMENT_OVERVIEW,
            PERMISSIONS.READ
          )
    );
    setHasWriteAccess(
      role === "Organizations"
        ? true
        : hasPermission(
            MODULES.ORGANIZATIONS,
            SUB_MODULES.ORGANIZATION_OVERVIEW,
            PERMISSIONS.WRITE
          )
    );
    setHasContactAccess(
      role === "Organizations"
        ? true
        : hasPermission(
            MODULES.ORGANIZATIONS,
            SUB_MODULES.POINT_OF_CONTACTS,
            PERMISSIONS.READ
          )
    );
  }, []);

  useEffect(() => {
    // let apiKey;

    // if (userRole === ROLES.TALENT) {
    //   apiKey = "talentId";
    // } else if (userRole === ROLES.ORGANIZATIONS) {
    //   apiKey = "clientId";
    // }

    listApi(APIS.LIST_ASSIGNMENTS_ORG_TALNET, { ["clientId"]: id }).then(
      (result) => {
        setTableData(result?.data);
      }
    );
  }, []);

  const linkHandler = (id) => {
    setIsAssignment(true);
    setSelectedId(id);
  };

  //org
  const tours = [
    {
      title: "Edit your profile",
      node: "tl-talent-edit-icon",
      description:
        "You can click on the pencil icon to edit and add your organization details, invoices, billing details and more.",
      styles: "tw-top-[28%] tw-right-[21%]",
      arrow_styles: "tw-top-[20.6%] tw-right-[31.4%]",
    },
    {
      title: "Sidebar Navigation",
      node: "tl-side-menu",
      description:
        "This is a quick access menu to view and manage your organizations details and documents such as contracts, invoices and more. You can also use the Assignment section to view the details of ongoing and archived assignments.",
      styles: "tw-top-[12%] tw-left-[23%]",
      arrow_styles: "tw-top-[10%] tw-left-[18.7%] tw-rotate-[270deg]",
    },
    {
      title: "Conversations",
      node: "tl-comment-section",
      description:
        "You can start a conversation thread with us or participate in one you are tagged",
      styles: "tw-top-[12%] tw-right-[33%]",
      arrow_styles:
        "tw-top-[12%] tw-right-[28%] scale-x-[-1] tw-rotate-[90deg] transform",
    },
    // {
    //   title: "Deletion",
    //   node: "tl-talent-deletion",
    //   description:
    //     "At any point if you want to delete your profile, you can click on this button to request for deletion.",
    //   styles: "tw-top-[47%] tw-left-[23%]",
    //   arrow_styles: "tw-top-[45%] tw-left-[18.7%] tw-rotate-[270deg]",
    // },
    {
      title: "Notifications",
      node: "tl-bell-icon",
      description:
        "Any reminders and notifications sent to you will be pushed here.",
      styles: "tw-top-[14%] tw-right-[5%]",
      arrow_styles: "tw-top-[6.3%] tw-right-[8%]",
    },
    {
      title: "Profile & Help",
      node: "tl-talent-profile",
      description:
        "You can easily sign out, change password and seek help from here.",
      styles: "tw-top-[15%] tw-right-[3%]",
      arrow_styles: "tw-top-[7.2%] tw-right-[5%]",
    },
  ];

  ///// RETURN /////

  return (
    <div
      className={` ${styles.containerInner} ${
        ristrictAccess() ? styles.ristrictStyles : styles.talentdetails
      }
        
      `}
    >
      {client && client?.name ? (
        <>
          <ClientHeader
            hasWriteAccess={hasWriteAccess}
            client={client}
            id={id}
            currentLocation={window.location.href}
          />
          <div className={styles.details}>
            <div className={styles.detailscontent}>
              <div className={styles.pageContainer}>
                {client?.addedBy?.name !== undefined &&
                  client?.addedBy?.lastName !== undefined && (
                    <div className={styles.createByStyles}>
                      Organization added by
                      <b style={{ color: "#2f2f2f" }}>
                        {` ${client?.addedBy?.name} ${client?.addedBy?.lastName} `}
                      </b>
                      | {moment(client?.createdAt).format("DD MMM	YY")}
                    </div>
                  )}

                <div className={styles.contactContainer}>
                  {client?.contactPersons.filter((i) => i.isPrimary).length >
                    0 &&
                    hasContactAccess && (
                      <ContactBox
                        hasContactAccess={hasContactAccess}
                        client={client}
                        setParams={setParams}
                        ristrictAccess={
                          user.talentLabRole === "Organizations" &&
                          user.selfSignUp
                            ? () => false
                            : ristrictAccess
                        }
                      />
                    )}

                  <ContactRightBox
                    ristrictAccess={ristrictAccess}
                    client={client}
                  />
                </div>
                {(client?.summary !== undefined || !!client?.summary) && (
                  <hr className={styles.summaryDivider} />
                )}

                {!!client?.summary && <SummaryBlock client={client} />}
              </div>
            </div>
          </div>

          <div>
            {(client?.contactPerson === undefined ||
              client?.contactPerson === "") &&
              (client?.contactPersonEmail === undefined ||
                client?.contactPersonEmail === "") &&
              (client?.summary === undefined || client?.summary === "") &&
              user.selfSignUp && <EmptyState entity="Org"></EmptyState>}
            <Walkthrough destinations={tours} entity="Org"></Walkthrough>
          </div>

          {!!hasAssignmentAccess && client?.assignments?.length > 0 && (
            // <Table
            //   client={client}
            //   ristrictAccess={ristrictAccess}
            //   hasAssignmentAccess={hasAssignmentAccess}
            // ></Table>
            <div style={{ padding: "0px 42px 84px 42px" }}>
              <ExtendTable
                userRole={userRole}
                tableHeading="Ongoing Assignments"
                tableData={tableData?.ongoing}
                onClick={linkHandler}
              ></ExtendTable>
            </div>
          )}
        </>
      ) : (
        <Skeleton height="300px" marginBottom="30px" />
      )}
    </div>
  );
};

export default Overview;
