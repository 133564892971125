import React from "react";
import styles from "./styles.module.scss";
import FilterSelect from "../../filters/filterSelect";
import FilterSliderSelect from "components/filters/filterSliderSelect";

const AddCandidateFilter = ({
  onChange,
  appliedFilters,
  onReset,
  isApplied,
  filterData,
  onFilterChange,
  debouncedResults,
  handleChange,
  state,
  city,
  country,
}) => {
  return (
    <div className={`d-flex row ${styles.filter}`}>
      <div className={`d-flex gap-2 ${styles.scrollDiv}`}>
        <FilterSelect
          isMulti
          experienceFilter
          name="experience"
          placeholder="experience"
          style="assignmentStyles"
          onChange={onFilterChange}
          value={appliedFilters?.experience}
          options={filterData?.experience}
        />

        <FilterSelect
          isMulti
          name="locations"
          isSearchAble
          locationFilter
          state={state}
          city={city}
          country={country}
          value={appliedFilters?.locations}
          searchPlaceholder="Search for locations"
          options={filterData?.locations}
          onChange={onFilterChange}
          style="assignmentStyles"
          placeholder="Location"
        />
        <FilterSelect
          isMulti
          name="sectors"
          isSearchAble
          searchPlaceholder="Search for industries"
          value={appliedFilters?.sectors}
          options={filterData?.sectors}
          onChange={onFilterChange}
          style="assignmentStyles"
          placeholder="Sector"
        />
        {/* <FilterSliderSelect
        currencyLabel
        startEndData={filterData?.compensation}
        dataLabel={2}
        name="compensation"
        handleChange={handleChange}
        label="Compensation"
        style="assignmentStyles"
        placeholder="Compensation"
        defaultValue={appliedFilters?.compensation}
      /> */}
        <FilterSelect
          isMulti
          name="skillRoles"
          isSearchAble
          searchPlaceholder="Search for skill / Roles"
          value={appliedFilters?.skillRoles}
          options={filterData?.skillRoles}
          onChange={onFilterChange}
          style="assignmentStyles"
          placeholder="Skills/Tags"
        />
      </div>
    </div>
  );
};

export default AddCandidateFilter;
