import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../constants/assets";
import CloseIcon from "@mui/icons-material/Close";

const SearchAbleInput = ({
  searchQuery,
  onChangeText,
  searchPlaceholder,
  onResetHandler,
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <>
      <input
        className={styles.SearchAbleInput}
        type="text"
        value={searchQuery}
        onChange={onChangeText}
        placeholder={searchPlaceholder}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <img
        src={focus ? IMAGES.ENABLE_SEARCH_ICON : IMAGES.SEARCH_ICON}
        className={styles.searchIcon}
      />
      {searchQuery && (
        <CloseIcon
          fontSize="10px"
          className={styles.resetIcon}
          onClick={onResetHandler}
        />
      )}
    </>
  );
};

export default SearchAbleInput;
