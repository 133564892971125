import React from "react";
import styles from "./styles.module.scss";
import { IMAGE_BASE_URL } from "constants/contants";
import SocialHandler from "components/buttons/socialHandler";
import { IMAGES } from "constants/assets";
import ProfileAvatar from "components/Avatar/profileAvatar";

const TalentHeader = ({ headData }) => {
  return (
    <>
      <div className={styles.headContainer}>
        <div className={styles.middleContainer}>
          <div className={styles.detailsimg}>
            {headData?.imageUrl?.original ? (
              <img
                alt="headIMage"
                src={IMAGE_BASE_URL + headData?.imageUrl?.original}
                className={styles.talentUserImage}
              />
            ) : (
              <ProfileAvatar
                firstLabel={headData?.firstName?.charAt(0)}
                secondLabel={headData?.lastName?.charAt(0)}
              />
            )}
          </div>

          <div className={`${styles.detailscontent}`}>
            <div className={styles.detailsheading}>
              <h2>{`${headData?.firstName} ${
                headData?.middleName ? headData?.middleName : ""
              } ${headData?.lastName}`}</h2>
              {(headData?.recentWork?.designation ||
                headData?.recentWork?.companyName) && (
                <div className={styles.workStyle}>
                  <p className={styles.jobStyle}>
                    {headData?.recentWork?.designation
                      ? `${headData?.recentWork?.designation} | `
                      : " "}

                    <span>
                      &nbsp;
                      {headData?.recentWork?.companyName
                        ? `${headData?.recentWork?.companyName}`
                        : ""}
                    </span>
                  </p>
                </div>
              )}
              <p className="mt-2">
                {!!headData?.city && (
                  <div
                    className={styles.cityHeading}
                  >{`${headData?.city},`}</div>
                )}
                {`${headData?.state ? `${headData?.state},` : ""} ${
                  headData?.country ? headData?.country : ""
                }`}
              </p>

              <div className={`d-flex mt-2 pt-1 ${styles.detailsicons}`}>
                {headData?.linkedinUrl && (
                  <SocialHandler
                    tipTittle="LinkedIn Profile"
                    linkedin
                    onClick={(e) => {
                      e?.stopPropagation();
                      window.open(`${headData?.linkedinUrl}`, "_blank");
                    }}
                  />
                )}
                {headData?.twitterUrl && (
                  <SocialHandler
                    tipTittle="Twitter URL"
                    icon={IMAGES.SOCIAL_TWITTER}
                    onClick={(e) => {
                      e?.stopPropagation();
                      window.open(`${headData?.twitterUrl}`, "_blank");
                    }}
                  />
                )}
                {headData?.email && (
                  <SocialHandler
                    tipTittle="Send email"
                    icon={IMAGES.MAIL_ICON}
                    onClick={(e) => {
                      e?.stopPropagation();
                      window.location.assign(`mailto:${headData?.email}`);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentHeader;
