import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";

const BellIcon = ({ handleNotiClick, notiBadgeData, notiOpen }) => {
  return (
    <li
      className={`${styles.navitem} ${styles.bellStyles} tl-bell-icon`}
      onClick={handleNotiClick}
      aria-controls={notiOpen ? "account-menu" : undefined}
    >
      <img
        src={
          notiBadgeData?.allUnread > 0
            ? IMAGES.NOTI_BELL
            : IMAGES.NOTI_FULL_BELL
        }
        className={`${styles.bellIcon}`}
        alt="bell"
      />

      {notiBadgeData?.allUnread > 0 && (
        <div className={styles.bellNotifyIcon}>{notiBadgeData?.allUnread}</div>
      )}
    </li>
  );
};

export default BellIcon;
