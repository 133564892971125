import React from "react";
import styles from "./styles.module.scss";
import { ROUTES } from "router/routes";
import IconComponent from "components/iconComponent";
import { copyToClipboard } from "utils/helper";
import { toast } from "react-toastify";
import { TOAST_MESSAGE } from "constants/toastMessage";
import ToastMessage from "components/layout/toast";
import { useNavigate } from "react-router-dom";

const AssignmentHeader = ({
  hasWriteAccess,
  duplicateAssignmentHandler,
  id,
  assignment,
}) => {
  const navigate = useNavigate();

  const onEditClickHandler = () => {
    const route = ROUTES.EDIT_ASSIGNMENT.split("/")[0];
    return navigate(`${ROUTES.ASSIGNMENTS}/${route}/${id}`);
  };
  const copyProfileUrl = () => {
    copyToClipboard(window.location.href);
    toast.success(
      <ToastMessage title={"Success"} message={TOAST_MESSAGE.COPY_LINK} />
    );
  };
  return (
    <>
      <div className="row">
        <div className={`col-sm-7 ${styles.mainHeading}`}>
          {`${assignment?.title ? assignment?.title : ""}`}
        </div>

        <div className={`col-sm-5 ${styles.iconsDiv}`}>
          <IconComponent
            hasWriteAccess={hasWriteAccess}
            isArchive={assignment?.isArchive}
            copyProfileUrl={copyProfileUrl}
            onEditClickHandler={onEditClickHandler}
            mailTOEmaillink={`mailto:?subject=TalentLab - Assignment - ${assignment?.title}&body=Link: ${window.location.href}`}
            duplicateAssignment
            duplicateAssignmentHandler={duplicateAssignmentHandler}
          />
        </div>
      </div>
      <div class={`row ${styles.companyHeader}`}>
        <div className={`${styles.companyNameHeading}`}>
          {assignment?.companyId?.name ? assignment?.companyId?.name : ""}
        </div>
        <div className="d-flex">
          <h1 className={`${styles.stateHeading}`}>{`${
            assignment.city ? `${assignment.city},` : ""
          }`}</h1>
          <h1 className={`${styles.CSCHeading}`}>
            {`${assignment.state ? `${assignment.state},` : ""} ${
              assignment.country
            }`}
          </h1>
        </div>
      </div>
    </>
  );
};

export default AssignmentHeader;
