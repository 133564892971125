import React from "react";
import styles from "./styles.module.scss";
import { ristrictAccess } from "utils/helper";

const SideMenu = ({ items = [], isActive, onClick }) => {
  return (
    <div
      className={`flex-column mb-3 tl-side-menu ${
        ristrictAccess() ? styles.ristricSideMenu : styles.sideMenu
      }`}
    >
      {items.map(
        (menu, index) =>
          menu?.hasAccess && (
            <div
              className={`nav-link ${styles.navlink} 
             ${isActive?.(menu) ? styles.active : ""}
          `}
              key={index}
              onClick={() => onClick(menu)}
            >
              {menu.label}
            </div>
          )
      )}
    </div>
  );
};

export default SideMenu;
