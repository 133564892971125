import React from "react";
import styles from "./styles.module.scss";
import { ristrictAccess } from "utils/helper";

const LightButton = ({ label, onClick, className }) => {
  return (
    <p
      className={`${styles.inner} ${className} ${
        ristrictAccess() ? styles.ristrictButton : styles.deletetext
      }`}
      onClick={onClick}
    >
      {label}
    </p>
  );
};
export default LightButton;
