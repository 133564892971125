import CalendarIcon from "@mui/icons-material/CalendarMonthOutlined";
import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.scss";

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="custom-input" onClick={onClick} ref={ref}>
    <CalendarIcon fontSize="12" sx={{ color: "#c9c9c9", marginX: 1 }} />
    <span className={`input-value ${styles.inputStyles}`}> {value}</span>
  </div>
));

const DatePicker = ({ value, onChange }) => {
  return (
    <ReactDatePicker
      dateFormat="EEE dd MMM yyyy"
      selected={value}
      onChange={onChange}
      customInput={<ExampleCustomInput />}
      wrapperClassName="custom-datepicker-wrapper"
      calendarClassName="custom-datepicker-calendar"
    />
  );
};

export default DatePicker;
