import { Formik, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { addEditApi, listApi } from "../../../../api/commonApis";
import { APIS } from "../../../../api/endpoints";
import FormInput from "../../../../components/forms/formInput";
import { IMAGES } from "../../../../constants/assets";
import {
  talentProfileSchema,
  talentNewCaseProfileSchema,
} from "../../../../constants/formSchema";
import { ROUTES } from "../../../../router/routes";
import cloneDeep from "lodash/cloneDeep";
import styles from "./styles.module.scss";
import CountryStateCityPicker from "../../../../components/countryStateCityPicker";
import { toast } from "react-toastify";
import LinkButton from "../../../../components/buttons/linkButton";
import ToastMessage from "../../../../components/layout/toast";
import ImageUploader from "../../../../components/imageUploader";
import { IMAGE_BASE_URL, GENDER_OPTIONS } from "../../../../constants/contants";
import { ristrictAccess, validationError } from "../../../../utils/helper";
import ToggleButton from "../../../../components/forms/togglebutton";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { getState } from "redux/store";
import { LOGIN, SUCCESS } from "redux/actions/actionTypes";
import { useDispatch } from "react-redux";

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  city: null,
  state: null,
  country: { label: "India", value: "India" },
  email: "",
  phoneNumber: [""],
  linkedinUrl: "",
  twitterUrl: "",
  gender: 1,
  profession: "",
  executiveSummary: "",
  imageUrl: { preview: "", raw: "" },
  willingToRelocate: true,
  designation: "",
  role: "",
  companyName: "",
};

const Profile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const source = params.get("source");
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const [sideMenuRefresh, setSideMenuRefresh] = useState(false);

  const RefreshHanlder = () => {
    setSideMenuRefresh(!sideMenuRefresh);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      listApi(APIS.LIST_TALENT_OVERVIEW, { talentId: id })
        .then((result) => {
          const data = cloneDeep(result?.data?.[0]);
          data.country = { label: data?.country, value: data?.country };

          if (data?.state === undefined) {
            data.state = null;
          } else {
            data.state = { label: data?.state, value: data?.state };
          }
          if (data?.city === undefined) {
            data.city = null;
          } else {
            data.city = { label: data?.city, value: data?.city };
          }

          if (data && data?.imageUrl?.original) {
            data.imageUrl = {
              preview: IMAGE_BASE_URL + data?.imageUrl?.original,
              raw: "",
            };
          } else {
            data.imageUrl = {
              preview: "",
              raw: "",
            };
          }

          setProfileData(data);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  const updateMainProfile = (profiledata) => {
    console.log(profiledata, "profiledata");
    if (getState()?.auth?.data?.selfSignUp) {
      const data = new FormData();
      data.append("name", profiledata?.firstName);
      if (profiledata?.middleName) {
        data.append("middleName", profiledata?.middleName);
      } else {
        data.append("middleName", "");
      }

      data.append("lastName", profiledata?.lastName);
      data.append("email", profiledata?.email);

      data.append("phoneNumber", profiledata.phoneNumber || "");

      if (profiledata?.imageUrl && profiledata?.imageUrl?.raw !== "") {
        data.append("image", profiledata?.imageUrl?.raw);
      }
      addEditApi(APIS.ADMIN_UPDATE_PROFILE, data).then((response) => {
        dispatch({ type: LOGIN[SUCCESS], data: response?.data });
        setRefresh(!refresh);
        RefreshHanlder();
      });
    } else {
      return;
    }
  };

  const onSubmit = async (payload) => {
    setLoading(true);

    console.log({ payload });

    let data = {
      firstName: payload?.firstName,
      lastName: payload?.lastName,
      middleName: payload?.middleName || "",
      country: payload?.country?.label || "",
      city: payload?.city?.label || "",
      state: payload?.state?.label || "",
      gender: payload?.gender,
      profession: payload?.profession,
      executiveSummary: payload?.executiveSummary,
      talentId: id,
      willingToRelocate: payload?.willingToRelocate,
    };

    if (payload?.phoneNumber?.length > 0) {
      data.phoneNumber = payload?.phoneNumber;
    }

    if (payload?.email) {
      data.email = payload?.email;
    }

    if (!id) {
      if (payload?.designation) {
        data.designation = payload?.designation;
      }
      if (payload?.role) {
        data.role = payload?.role;
      }
      if (payload?.companyName) {
        data.companyName = payload?.companyName;
      }
    }

    if (payload?.imageUrl && payload?.imageUrl?.raw !== "") {
      const imageData = new FormData();
      imageData.append("image", payload?.imageUrl?.raw);

      let response = await addEditApi(APIS.UPLOAD_IMAGE, imageData);

      data.imageOriginal = response?.data?.original;
      data.imageThumbnail = response?.data?.thumbnail;
    }

    if (payload?.linkedinUrl) {
      data.linkedinUrl = payload?.linkedinUrl;
    }
    if (payload?.twitterUrl) {
      data.twitterUrl = payload?.twitterUrl;
    }

    updateMainProfile(payload);

    addEditApi(APIS.ADD_EDIT_TALENT_PROFILE, data)
      .then((response) => {
        if (source) {
          toast.success(
            <ToastMessage
              title={"Success"}
              message={TOAST_MESSAGE.UPADTE_PROFILE}
            />
          );
          navigate(-1);
        } else {
          toast.success(
            <ToastMessage
              title={"Success"}
              message={TOAST_MESSAGE.CREATE_PROFILE}
            />
          );
          // navigate(`/talent/work/${response?.data?._id}?source=overview`);
          navigate(
            `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${response?.data?._id}?tab=overview`
          );
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      className={`tab-content ${styles.innerContainer} ${
        ristrictAccess() ? styles.ristrictContainer : styles.tabcontent
      }`}
    >
      <div className="fade show active">
        <Formik
          onSubmit={onSubmit}
          initialValues={id ? profileData : initialValues}
          validationSchema={
            id ? talentProfileSchema : talentNewCaseProfileSchema
          }
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.editprofile}>
                <div className={styles.edithead}>
                  <div className={styles.topheading}>
                    <h3>{id ? "Edit Basic Profile" : "Basic Profile"}</h3>
                    <div className="d-flex">
                      {id && (
                        <LinkButton
                          onClick={() =>
                            navigate(
                              `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=overview`
                            )
                          }
                        >
                          Back to profile
                        </LinkButton>
                      )}

                      <PrimaryButton
                        type="submit"
                        className={styles.saveButton}
                        onClick={() => validationError(errors)}
                        label={
                          loading
                            ? "saving..."
                            : id
                            ? "Save and View"
                            : "Save and Create"
                        }
                        disabled={loading || !dirty}
                      />
                    </div>
                  </div>
                </div>
                <hr className={styles.cardDivider} />

                <div className={styles.formname}>
                  <div className={styles.middleCard}>
                    <ImageUploader
                      setFieldValue={setFieldValue}
                      handleImageChange={(e) => {
                        if (e.target.files.length) {
                          setFieldValue("imageUrl", {
                            preview: URL.createObjectURL(e.target.files[0]),
                            raw: e.target.files[0],
                          });
                        }
                      }}
                      image={values?.imageUrl}
                    />
                    <FormInput
                      label="First Name"
                      {...getFieldProps("firstName")}
                      error={touched?.firstName && errors?.firstName}
                    />

                    <FormInput
                      label="Middle Name"
                      {...getFieldProps("middleName")}
                      error={touched?.middleName && errors?.middleName}
                    />
                    <FormInput
                      label="Last Name"
                      {...getFieldProps("lastName")}
                      error={touched?.lastName && errors?.lastName}
                    />

                    <FormInput
                      errorHandle="gender"
                      label="Gender"
                      type="select"
                      value={GENDER_OPTIONS.find(
                        (obj) => obj.value === values?.gender
                      )}
                      options={GENDER_OPTIONS}
                      onChange={(e) => setFieldValue("gender", e.value)}
                      error={touched?.gender && errors?.gender}
                    />
                  </div>

                  {!id && (
                    <>
                      <div className={styles.middleCard}>
                        <h3>Current Work Details</h3>
                      </div>
                      <hr className={styles.cardDivider} />
                      <div className={styles.middleCard}>
                        <FormInput
                          label="Title/Designation"
                          {...getFieldProps("designation")}
                          error={touched?.designation && errors?.designation}
                        />
                        <FormInput
                          label="Role"
                          {...getFieldProps("role")}
                          error={touched?.role && errors?.role}
                        />

                        <FormInput
                          label="Organization Name"
                          {...getFieldProps("companyName")}
                          error={touched?.companyName && errors?.companyName}
                        />
                      </div>
                    </>
                  )}

                  <div className={styles.middleCard}>
                    <h3>Location Details</h3>
                  </div>
                  <hr className={styles.cardDivider} />
                  <div className={styles.middleCard}>
                    <CountryStateCityPicker
                      isEdit={!!id}
                      country={values?.country}
                      state={values?.state}
                      city={values?.city}
                      onCountyChange={(value) => {
                        setFieldValue("country", value);
                        setFieldValue("state", null);
                        setFieldValue("city", null);
                      }}
                      onStateChange={(value) => {
                        setFieldValue("state", value);
                        setFieldValue("city", null);
                      }}
                      onCityChange={(value) => {
                        setFieldValue("city", value);
                      }}
                      countryError={touched?.country && errors?.country}
                      stateError={touched?.state && errors?.state}
                      cityError={touched?.city && errors?.city}
                    />
                    <div className={styles.toggleContainer}>
                      <ToggleButton
                        label="Willing to relocate?"
                        value={values?.willingToRelocate}
                        onChange={() =>
                          setFieldValue(
                            "willingToRelocate",
                            !values?.willingToRelocate
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.middleCard}>
                    <h3>Contact Details</h3>
                  </div>
                  <hr className={styles.cardDivider} />
                  <div className={styles.middleCard}>
                    <FormInput
                      label="Email"
                      {...getFieldProps("email")}
                      error={touched?.email && errors?.email}
                    />

                    <FieldArray
                      name="phoneNumber"
                      render={(arrayHelpers) => (
                        <div>
                          {values?.phoneNumber?.map((phone, index) => (
                            <div key={index} className={styles.mobileRow}>
                              <div className={styles.mobileInput}>
                                <FormInput
                                  maxLength={20}
                                  label="Mobile Number"
                                  {...getFieldProps(`phoneNumber.${index}`)}
                                  error={
                                    touched?.phoneNumber?.[index] &&
                                    errors?.phoneNumber?.[index]
                                  }
                                />
                              </div>
                              <div className={styles.mobilebuttons}>
                                {index !== 0 && (
                                  <img
                                    alt=""
                                    className={`${styles.documentList}`}
                                    src={IMAGES.MOBILE_DELETE_ICON}
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                )}
                                <img
                                  alt=""
                                  className={` ${styles.documentList}`}
                                  src={IMAGES.MOBILE_ADD_ICON}
                                  onClick={() =>
                                    arrayHelpers.insert(index + 1, "")
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                  <div className={styles.middleCard}>
                    <h3 className={styles.socialProfileH3}>Social Profiles</h3>
                  </div>
                  <hr className={styles.cardDivider} />
                  <div className={styles.middleCard}>
                    <FormInput
                      label="LinkedIn URL"
                      placeholder="For example - https://www.linkedin.com/in/johndoe"
                      {...getFieldProps("linkedinUrl")}
                      error={touched?.linkedinUrl && errors?.linkedinUrl}
                    />
                    <FormInput
                      label="Twitter URL"
                      placeholder="For example - https://twitter.com/johndoe"
                      {...getFieldProps("twitterUrl")}
                      error={touched?.twitterUrl && errors?.twitterUrl}
                    />
                    <FormInput
                      type="editor"
                      label="Executive Summary"
                      value={values?.executiveSummary}
                      onEditorChange={(value) =>
                        setFieldValue("executiveSummary", value || "")
                      }
                      error={
                        touched?.executiveSummary && errors?.executiveSummary
                      }
                    />
                  </div>
                  <div className={styles.bottomFotter}>
                    <div className="d-flex">
                      {id && (
                        <LinkButton
                          onClick={() =>
                            navigate(
                              `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=overview`
                            )
                          }
                        >
                          Back to profile
                        </LinkButton>
                      )}

                      <PrimaryButton
                        onClick={() => validationError(errors)}
                        label={
                          loading
                            ? "saving..."
                            : id
                            ? "Save and View"
                            : "Save and Create"
                        }
                        disabled={loading || !dirty}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Profile;
