import React from "react";
import styles from "./styles.module.scss";
import { Grid } from "@mui/material";

const ContactBlock = ({ record }) => {
  return (
    <Grid item xs={5} md={4}>
      {(record?.billingContact?.name ||
        record?.billingContact?.designation ||
        record?.billingContact?.email ||
        record?.billingContact?.phoneNumber) && (
        <>
          <div className={styles.nameStyle}>
            {record?.billingContact?.name ? record?.billingContact?.name : ""}
          </div>
          <div className={styles.designationStyle}>
            {record?.billingContact?.designation
              ? record?.billingContact?.designation
              : ""}
          </div>
          <a
            className={styles.emailStyle}
            href={`mailto:${record?.billingContact?.email}`}
          >
            {record?.billingContact?.email ? record?.billingContact?.email : ""}
          </a>
          <a
            className={styles.phNumberStyle}
            href={`tel:${record?.billingContact?.phoneNumber}`}
          >
            {record?.billingContact?.phoneNumber
              ? record?.billingContact?.phoneNumber
              : ""}
          </a>
        </>
      )}
    </Grid>
  );
};

export default ContactBlock;
