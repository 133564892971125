import { Grid } from "@mui/material";
import React from "react";
import styles from "./styles.module.scss";

const AddressBlock = ({ record }) => {
  return (
    <Grid item xs={5} className={styles.addressStyle}>
      {(record?.billingAddress?.addressLine1 ||
        record?.billingAddress?.addressLine2 ||
        record?.billingAddress?.city ||
        record?.billingAddress?.pinCode ||
        record?.billingAddress?.state ||
        record?.billingAddress?.country) && (
        <>
          <div>
            {record?.billingAddress?.addressLine1
              ? record?.billingAddress?.addressLine1
              : ""}
          </div>
          <div>
            {`${
              record?.billingAddress?.addressLine2
                ? `${record?.billingAddress?.addressLine2},`
                : ""
            } ${
              record?.billingAddress?.city ? record?.billingAddress?.city : ""
            }
        ${
          record?.billingAddress?.city && record?.billingAddress?.pinCode
            ? "-"
            : ""
        } 
         ${
           record?.billingAddress?.pinCode
             ? record?.billingAddress?.pinCode
             : ""
         } `}
          </div>
          <div>
            {(record?.billingAddress?.state ||
              record?.billingAddress?.country) &&
              `${
                record?.billingAddress?.state
                  ? `${record?.billingAddress?.state},`
                  : ""
              }  ${record?.billingAddress?.country}`}
          </div>
        </>
      )}
    </Grid>
  );
};

export default AddressBlock;
