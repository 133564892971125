import React from "react";
import styles from "./styles.module.scss";
import { MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchAbleInput from "components/filters/seacrhAbleInput";

const AddTalentSection = ({
  title,
  assignmentId,
  setAssignmentId,
  singlOuterList,
  search,
  setSearch,
  list,
  assigedAssignment,
}) => {
  return (
    <>
      <div className={styles.titleCss}>{title}</div>

      <Select
        variant="standard"
        className={styles.transferSelect}
        value={assignmentId}
        displayEmpty
        onChange={(e) => {
          setAssignmentId(e?.target?.value);
        }}
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={ExpandMoreIcon}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        MenuProps={{
          style: {
            maxHeight: "34vh",
            top: "18px",
            left: "-8px",
            minWidth: "435px!important",
          },
        }}
        sx={{
          "& .MuiSelect-iconStandard": {
            marginRight: "15px",
            height: "20px",
            width: "20px",
          },
          "&	.MuiSelect-select": {
            padding: "0px 0px 0px 12px",
            fontSize: "10px",
            fontWeight: "bold",
            display: "flex",

            width: "450px",

            ":focus": {
              backgroundColor: "transparent",
            },
            " & .MuiSelect-select-MuiInputBase-input-MuiInput-input": {
              display: "flex",
              marginTop: "7px;",
            },

            " & .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root": {
              top: "10px!important",
            },
          },
        }}
      >
        <MenuItem
          className={styles.searchbale}
          sx={singlOuterList}
          disableRipple
          onKeyDown={(event) => event.stopPropagation()}
        >
          <SearchAbleInput
            searchPlaceholder="Search assignment"
            searchQuery={search}
            onChangeText={(e) => setSearch(e.target.value)}
            onResetHandler={() => setSearch("")}
          />
        </MenuItem>

        {list?.map((data, index) => {
          return (
            <MenuItem
              key={index}
              value={data?._id}
              className={
                assigedAssignment.includes(data?._id) && styles.disableOptions
              }
              disabled={assigedAssignment.includes(data?._id) ? true : false}
            >
              <div className={styles.commenttext}>
                <h5
                  className={styles.adminInitail}
                >{`${data?.title?.[0]}${data?.title?.[1]}`}</h5>
              </div>
              <div>
                <div className={styles.nameStyles}>{`${data?.title} / ${
                  data?.companyId?.name ? data?.companyId?.name : "NA"
                }`}</div>
                {assigedAssignment.includes(data?._id) && (
                  <div className={styles.emailStyle}>(Already Added)</div>
                )}
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default AddTalentSection;
