import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import styles from "./styles.module.scss";

const LinkButton = ({ children, className, onClick, ...rest }) => {
  return (
    <div className={`${styles.btn} ${className}`} onClick={onClick} {...rest}>
      <KeyboardBackspaceIcon className={styles.arrow} />
      {children}
    </div>
  );
};

export default LinkButton;
