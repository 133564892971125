import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Skeleton from "components/skeleton";
import { APIS } from "api";
import { listApi } from "api/commonApis";
import { emptyTableText } from "constants/contants";
import SummaryBox from "./summaryBox";
import SummaryTable from "./summaryTable";
import ScrollIcon from "components/icons/scrollIcon";
import MailIcon from "components/icons/mail";
import { ristrictAccess } from "utils/helper";
import { getAssignmentStatus } from "utils/helper";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import LinkButton from "components/buttons/linkButton";

const ASSIGNMENT_SUMMARY_BOX = [
  { title: "Total Candidates", value: "totalCandidates" },
  { title: "Dossier Submitted", value: "dossierSubmitted" },
  { title: "Interviewed", value: "interviewd" },
  { title: "Benched", value: "benched" },
  { title: "Potential Hires", value: "potentialHires" },
];

const AssignmentPage = ({ refresh, id }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [assignment, setAssignment] = useState([]);
  const [onScroll, setOnScroll] = useState(false);
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    listApi(APIS.LIST_ASSIGNMENT_OVERVIEW, { assignmentId: id, ext: true })
      .then((result) => {
        setAssignment(result?.data);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  return (
    <>
      <div
        className={
          ristrictAccess() ? styles.ristrictStyles : styles.tabMainContent
        }
      >
        {loading ? (
          <Skeleton height="300px" />
        ) : (
          <>
            <div className="row">
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  marginBottom: "0.5rem",
                }}
              >
                <LinkButton
                  onClick={() => {
                    window.location.href = `/clients/overview/${params.id}?tab=assignments-ext`;
                  }}
                >
                  Back
                </LinkButton>
              </div>

              <div className={`col-sm-7 ${styles.mainHeading}`}>
                {`${assignment?.title}`}
              </div>

              <div className={`col-sm-5 ${styles.iconsDiv}`}>
                <div className={styles.statusHeading}> Assignment Status</div>
                <div className={styles.statusButton}>
                  {getAssignmentStatus(assignment?.status)}
                </div>
              </div>
            </div>
            <p className={styles.createByStyles} style={{ color: "#a3a3a3" }}>
              Assignment created by
              <b style={{ color: "#2f2f2f", fontSize: "12px" }}>
                {` ${assignment?.addedBy?.name} ${assignment?.addedBy?.lastName} `}
              </b>
              | {moment(assignment?.createdAt).format("DD MMM	YY")}
            </p>
            <div class={`row ${styles.companyHeader}`}>
              {/* <div className={`${styles.companyNameHeading}`}>
                {`${assignment?.companyId?.name}`}
              </div> */}
              {assignment.city && assignment.state && assignment.country && (
                <div className="d-flex">
                  <h1
                    className={`${styles.stateHeading}`}
                  >{`${assignment.city},`}</h1>
                  <h1 className={`${styles.CSCHeading}`}>
                    {`${assignment.state}, ${assignment.country}`}
                  </h1>
                </div>
              )}
            </div>
            <div className={styles.contactContainer}>
              <div className={styles.rightsummary}>
                <h3 className="pt-2 mb-0">Assignment Director</h3>
                {assignment?.director?.name === undefined ||
                assignment?.director?.name === "" ||
                assignment?.director?.lastName === undefined ||
                assignment?.director?.lastName === "" ? (
                  <p
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      color: "#a3a3a3",
                    }}
                  >
                    Not assigned yet
                  </p>
                ) : (
                  <p>
                    {`${assignment?.director?.name} ${assignment?.director?.lastName}`}
                    <MailIcon className={styles.mailIcon} />
                  </p>
                )}

                <h3 className="pt-0 mb-0 mt-3">Consultant</h3>
                {assignment?.consultant?.length <= 0 ? (
                  <p
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      color: "#a3a3a3",
                    }}
                  >
                    Not assigned yet
                  </p>
                ) : (
                  assignment?.consultant?.map((data, index) => (
                    <p key={index}>
                      {`${data.name} ${data.lastName}`}
                      <MailIcon className={styles.mailIcon} />
                    </p>
                  ))
                )}
              </div>
            </div>
            <hr className={styles.divider} />

            <div>
              <div className="d-flex justify-content-between ">
                <h3 className={`${styles.candidateContainer}`}>
                  Candidates Summary
                </h3>
                {onScroll && <ScrollIcon />}
              </div>
              <div
                onScroll={() => setOnScroll(true)}
                className={styles.boxContainer}
              >
                {ASSIGNMENT_SUMMARY_BOX.map((item, index) => {
                  return (
                    <SummaryBox
                      title={item?.title}
                      value={assignment?.[item.value]}
                      denominator={
                        index === 0
                          ? false
                          : assignment?.totalCandidates &&
                            assignment?.totalCandidates
                      }
                    />
                  );
                })}
              </div>
            </div>
            <hr className={styles.divider} />

            <h3 className={`${styles.candidateContainer}`}>
              Candidates Assigned
            </h3>

            {assignment?.candidates?.length === 0 ? (
              <div style={{}}>
                <p
                  style={{
                    textTransform: "none",
                    marginTop: "4px",
                    fontSize: "12px",
                    color: "#a3a3a3",
                  }}
                >
                  No candidates assigned yet
                </p>
              </div>
            ) : (
              <SummaryTable data={assignment} id={id} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AssignmentPage;
