import React from "react";
import styles from "./styles.module.scss";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const ButtonContainer = ({
  closeModal,
  deleteTalentLabUser,
  block,
  enable,
  transferId,
  onChangeHanlder,
  archive,
  status,
  addTalent,
  remove,
}) => {
  return (
    <Stack spacing={2} direction="row" className={`mt-4 ${styles.stack}`}>
      <Button
        variant="outlined"
        onClick={closeModal}
        className={styles.cancelButton}
      >
        Cancel
      </Button>
      {deleteTalentLabUser && !block ? (
        <Button
          variant="outlined"
          onClick={enable && transferId && onChangeHanlder}
          className={
            enable && transferId
              ? `${styles.enableConfirmationButton} ${styles.transferDelete}`
              : `${styles.disableConfirmatinButton} ${styles.transferDelete}`
          }
        >
          Transfer Records and Delete
        </Button>
      ) : addTalent ? (
        <Button
          variant="outlined"
          disabled={!enable}
          onClick={onChangeHanlder}
          className={
            enable || block
              ? styles.enableConfirmationButton
              : styles.disableConfirmatinButton
          }
        >
          Add
        </Button>
      ) : (
        <Button
          variant="outlined"
          disabled={block ? false : !enable}
          onClick={onChangeHanlder}
          className={
            enable || block
              ? styles.enableConfirmationButton
              : styles.disableConfirmatinButton
          }
        >
          {archive
            ? "Archive"
            : block
            ? status
              ? "Unblock"
              : "Block"
            : remove
            ? "Remove"
            : "Delete"}
        </Button>
      )}
    </Stack>
  );
};

export default ButtonContainer;
