import React from "react";
import styles from "./styles.module.scss";

const ContactBlock = ({ data }) => {
  return (
    <div className={styles.clientdetail}>
      <h4 className={styles.head}>Contact Person</h4>
      <p className={styles.personName}>{data?.contactPerson}</p>
      <p className={styles.personDesignation}>
        {data?.contactPersonDesignation}
      </p>
      <a href="#" className={styles.personEmail}>
        {data?.contactPersonEmail}
      </a>
      <a href="#" className={styles.personPhone}>
        {data?.contactPersonPhoneNumber}
      </a>
    </div>
  );
};

export default ContactBlock;
