import React from "react";
import styles from "./styles.module.scss";

const Checkbox = ({ tableStyle, id, ...rest }) => {
  return (
    <div className={tableStyle ? styles.tableCheckBox : styles.container}>
      <div className={styles.round}>
        <input id={id} type="checkbox" {...rest} />
        <label for={id}></label>
      </div>
    </div>
  );
};

export default Checkbox;
