import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import AssignedCandidate from "./assignedCandidate";
import AddCandidate from "./addCandidate";
import Overview from "./overview";
import { APIS } from "../../../api/endpoints";
import ActivityLog from "./activityLog";
import SideMenu from "../../../components/layout/sideMenu";
import Settings from "./settings";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CommentsSection from "../../../components/commentSection";
import {
  COMMENT_TYPE,
  ARCHIVE_MODAL_CONFIRMATION,
} from "../../../constants/contants";
import ConfirmationModal from "../../../components/modal/confirmationModal";
import { addEditApi, listApi } from "../../../api/commonApis";
import { toast } from "react-toastify";
import ToastMessage from "../../../components/layout/toast";
import { ROUTES } from "../../../router/routes";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../constants/accessControl";
import { hasPermission, sidePanelAccess } from "../../../utils/helper";
import LightButton from "../../../components/buttons/lightButton";
import { TOAST_MESSAGE } from "constants/toastMessage";

const AssignmentsOverview = () => {
  const { id } = useParams();
  const [params, setParams] = useSearchParams();
  const [assignment, setAssignment] = useState();
  const tab = params.get("tab");
  const activetab = params.get("activetab");
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [hasAsgArhAccess, setHasAsgArhAccess] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasCommentWriteAccess, setHasCommentWriteAccess] = useState(false);
  const [hasCommentDeleteAccess, setHasCommentDeleteAccess] = useState(false);
  const [isSidePanel, setIsSidePanel] = useState(false);

  ////// GETTING ACTIVE TAB/////

  useEffect(() => {
    if (!tab) {
      setParams({ tab: "overview" });
    }
  }, []);

  /////// GETTTING ALL API DATA//////

  useEffect(() => {
    listApi(APIS.LIST_ASSIGNMENT_OVERVIEW, { assignmentId: id }).then(
      (result) => {
        setAssignment(result?.data);
      }
    );
  }, []);

  ////// PERMISSIN///////

  useEffect(() => {
    setHasWriteAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_OVERVIEW,
        PERMISSIONS.WRITE
      )
    );
    setHasAsgArhAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_OVERVIEW,
        PERMISSIONS.ARCHIVE
      )
    );
    setHasCommentWriteAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_HISTORY,
        PERMISSIONS.WRITE
      )
    );
    setHasCommentDeleteAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_HISTORY,
        PERMISSIONS.DELETE
      )
    );
  }, []);

  //////////MENU ///////

  const MENU = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Assigned Candidates",
      id: "assignedcandidate",
      hasAccess: hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNED_CANDIDATE,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Add Candidates",
      id: "addcandidates",
      hasAccess: hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ADD_CANDIDATE,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Assignment History",
      id: "history",
      hasAccess: hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_HISTORY,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Activity Log",
      id: "activitylog",
      hasAccess: hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ACTIVITY_LOG,
        PERMISSIONS.READ
      ),
    },
  ];

  //////// HANDLER FOR RENDER TAB ACCORDNG TO CLICK//////

  const renderTab = () => {
    switch (tab) {
      case "assignedcandidate":
        return <AssignedCandidate />;
      case "addcandidates":
        return <AddCandidate />;
      case "history":
        return (
          <CommentsSection
            module="Assignment"
            subModule="assignmentHistory"
            renderAsTab
            id={id}
            title="Assignment History"
            type={COMMENT_TYPE.ASSIGNMENT}
            allowComment={!assignment?.isArchive}
            // hasCommentWriteAccess={hasCommentWriteAccess}
            // hasCommentDeleteAccess={hasCommentDeleteAccess}
          />
        );
      case "activitylog":
        return <ActivityLog />;

      default:
        return <Overview refresh={refresh} />;
    }
  };
  /////////// HANDLER FOR ARCHIVE ASSIGNEMN////////

  const handleArchiveAssignemnt = () => {
    addEditApi(APIS.CHANGE_ARCHIVE_ASSIGNMENT, { assignmentId: id }).then(
      () => {
        handleClose();
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.ASSIGNMENT_ARCHIVED}
          />
        );
        navigate(ROUTES.ASSIGNMENTS);
      }
    );
  };

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  //////// RETURN /////////

  return (
    <>
      <section
        className={`${styles.hasStyles} ${
          isSidePanel ? styles.overview : styles.centerOverview
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="d-flex align-items-start">
                <div className="mb-3 ">
                  <SideMenu
                    items={MENU}
                    isActive={(menu) => menu.id === tab}
                    onClick={(item) => {
                      if (item.href) {
                        navigate(
                          `${item.href}/${id}?source=overview&activetab=${activetab}`
                        );
                      } else {
                        setParams({ tab: item.id, activetab: activetab });
                      }
                    }}
                  />

                  {hasAsgArhAccess && assignment && !assignment?.isArchive && (
                    <LightButton
                      onClick={handleOpen}
                      label="Archive Assignment"
                    />
                  )}
                </div>

                {renderTab()}
                <Settings
                  hasWriteAccess={hasWriteAccess}
                  // hasCommentWriteAccess={hasCommentWriteAccess}
                  // hasCommentDeleteAccess={hasCommentDeleteAccess}
                  id={id}
                  tab={tab}
                  isArchive={assignment?.isArchive}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmationModal
        archive
        title={ARCHIVE_MODAL_CONFIRMATION}
        open={open}
        closeModal={handleClose}
        onChangeHanlder={handleArchiveAssignemnt}
        data={assignment?.title}
      />
    </>
  );
};

export default AssignmentsOverview;
