import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import moment from "moment";

const RequestOffLimit = ({ closeButton, requestoflimit, talentData }) => {
  const [daysLeft, setDaysLeft] = useState(1);

  const offLimitName = `${talentData?.offlimitPerson?.name} ${talentData?.offlimitPerson?.lastName}`;

  useEffect(() => {
    const currentDate = moment();
    const offLimitDate = moment(talentData.offLimitTill);
    setDaysLeft(offLimitDate.diff(currentDate, "days"));
  }, []);

  return (
    <div className={styles.modal}>
      <div className={` ${styles.limituser} ${styles.user}`}>
        <div className={`mt-3 text-start ${styles.notes}`}>
          <p>
            This candidate has been marked as ‘Off Limit’ for{" "}
            <strong>
              {" "}
              {talentData?.offLimitDays
                ? `${talentData?.offLimitDays} Days`
                : `1 Day`}{" "}
            </strong>{" "}
            ({daysLeft} days left) by <strong> {offLimitName} </strong>
          </p>
          <p>
            If you wish for this candidate to be released from ‘Off Limit’,
            click on the button below to request ‘Off Limit’ removal. This will
            send an email to <strong>{offLimitName} </strong>
            requesting for removal.
          </p>
        </div>

        <div className={styles.btncancalyes}>
          <button className={styles.no} onClick={closeButton}>
            No, Cancel
          </button>
          <button
            className={`${styles.blue}  ${styles.yes}`}
            onClick={requestoflimit}
          >
            Request Removal
          </button>
        </div>
      </div>
    </div>
  );
};
export default RequestOffLimit;
