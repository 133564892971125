import React from "react";
import styles from "./styles.module.scss";
import { IMAGE_BASE_URL } from "constants/contants";
import ProfileAvatar from "components/Avatar/profileAvatar";
import SociaHandlerRow from "components/buttons/socialHandlerRow";

const AssignmentHeader = ({ headData }) => {
  return (
    <>
      <div className={`d-flex text-capitalize ${styles.details}`}>
        {headData?.imageUrl?.original ? (
          <img
            alt=""
            src={IMAGE_BASE_URL + headData?.imageUrl?.original}
            className={styles.clientLogo}
          />
        ) : (
          <ProfileAvatar
            firstLabel={headData?.name[0]}
            secondLabel={headData?.name[1]}
          />
        )}

        <div
          className={`d-flex w-100 justify-content-between  ${styles.detailscontent}`}
        >
          <div className="w-100">
            <div className={styles.detailsheading}>
              <h2 className={styles.clientName}>{headData?.name} </h2>
              <p className={styles.clientCategoty}>{headData?.category}</p>

              {headData?.country &&
                headData?.country !== "" &&
                headData?.state &&
                headData?.state !== "" &&
                headData?.city &&
                headData?.city !== "" && (
                  <p className={styles.cilentCity}>
                    {headData?.city ? (
                      <div
                        className={styles.cityHeading}
                      >{`${headData?.city},`}</div>
                    ) : (
                      ""
                    )}
                    {`${headData?.state ? headData?.state : ""}, ${
                      headData?.country ? headData?.country : ""
                    }`}
                  </p>
                )}
            </div>
            <SociaHandlerRow data={headData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentHeader;
