import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../constants/assets";
import { listApi } from "../../api/commonApis";
import { APIS } from "../../api/endpoints";
import { useEffect } from "react";
import ProfileSideMenu from "../../components/layout/profileSideMenu";
import TalentLab from "./talentLabUser";
import ProfileDetail from "./details";
import Assignment from "./assignment";
import ChangePassowd from "./changePassword";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IMAGE_BASE_URL } from "../../constants/contants";
import {
  ristrictAccess,
  dataSetHasPermission,
  hasPermission,
  tabHandler,
} from "utils/helper";
import { DATA_SET_CASE, TAB_ROUTES } from "../../constants/contants";
import DataSet from "./dataSet";
import { MODULES, PERMISSIONS, SUB_MODULES } from "constants/accessControl";
import UnsignedTalentUsers from "./unsigned-talent-users";
import Shortcuts from "./shortcuts";

const RISTRICTED_MENU = [{ label: "Profile", id: "profiledetails" }];

const Profile = () => {
  ///////// MAIN MENU OPTIONS/////////

  const MENU = [
    { label: "Profile", id: "profiledetails", hasAccess: true },
    { label: "Calendar", id: "calendar", hasAccess: true },
    { label: "Change Password", id: "changepassword", hasAccess: true },
    {
      label: "Assignments",
      id: "assignments",
      hasAccess: true,
    },
    // {
    //   label: "TalentLab Users",
    //   id: "talentlabusers",
    //   hasAccess: hasPermission(
    //     MODULES.DATA_SETS,
    //     SUB_MODULES.USER_MANAGMENT,
    //     PERMISSIONS.READ
    //   ),
    // },
    {
      label: "Users",
      id: "tl-admins",
      hasAccess: true,
      // children: [
      //   {
      //     label: "TalentLab Admins",
      //     id: "tl-admins",
      //     hasAccess: true,
      //   },
      //   {
      //     label: "Unsigned Talents Users",
      //     id: "tl-talents",
      //     hasAccess: true,
      //   },
      //   {
      //     label: "Signed Up Talent Users",
      //     id: "tl-talent-users",
      //     hasAccess: true,
      //   },
      //   {
      //     label: "Organizations",
      //     id: "tl-orgs",
      //     hasAccess: true,
      //   },
      // ],
    },
  ];
  ///// DATA SET OPTIONS//////////

  const dataSetOptions = [
    {
      label: "Industry Types",
      id: "datasets-industrytype",
      hasAccess: hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.INDUSTRY_TYPE,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Sectors",
      id: "datasets-sectors",
      hasAccess: hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.SECTORS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Functional Areas",
      id: "datasets-functionalareas",
      hasAccess: hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.FUNCTIONAL_AREAS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Skills/Tags",
      id: "datasets-skillstags",
      hasAccess: hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.SKILLS_TAGS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Competencies",
      id: "datasets-compentency",
      hasAccess: hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.COMPETENCIES,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Education Types",
      id: "datasets-education",
      hasAccess: hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.EDUCATION_TYPE,
        PERMISSIONS.READ
      ),
    },
  ];

  const user_set_options = [
    {
      label: "TalentLab Admins",
      id: "tl-admins",
      hasAccess: true,
    },
    {
      label: "Talents",
      id: "tl-talents",
      hasAccess: true,
    },
    {
      label: "Talent Users",
      id: "tl-talent-users",
      hasAccess: true,
    },
    {
      label: "Organizations",
      id: "tl-orgs",
      hasAccess: true,
    },
  ];

  const [params, setParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const tab = params?.get?.("tab");
  const [sideMenuRefresh, setSideMenuRefresh] = useState(false);
  const [adminData, setAdminData] = useState({});
  const [enableOptions, setEnableOptions] = useState(false);
  const [activePage, setActivePage] = useState(dataSetOptions[0].id);
  const profileData = useSelector((state) => state?.auth?.data);

  const RefreshHanlder = () => {
    setSideMenuRefresh(!sideMenuRefresh);
  };

  const renderTab = () => {
    switch (tab) {
      case "tl-admins":
        return <TalentLab />;
      case "tl-talent-users":
        return <TalentLab role="talents" />;
      case "tl-talents":
        return <UnsignedTalentUsers role="talents" />;
      case "tl-shortcuts":
        return <Shortcuts> </Shortcuts>;
      case "assignments":
        return <Assignment />;
      case DATA_SET_CASE.INDUSTRY_TYPE:
        return <DataSet activeTab={DATA_SET_CASE.INDUSTRY_TYPE} />;
      case DATA_SET_CASE.SECTORS:
        return <DataSet activeTab={DATA_SET_CASE.SECTORS} />;
      case DATA_SET_CASE.FUNCTIONAL_AREAS:
        return <DataSet activeTab={DATA_SET_CASE.FUNCTIONAL_AREAS} />;
      case DATA_SET_CASE.SKILLS_TAGS:
        return <DataSet activeTab={DATA_SET_CASE.SKILLS_TAGS} />;
      case DATA_SET_CASE.COMPENTENCY:
        return <DataSet activeTab={DATA_SET_CASE.COMPENTENCY} />;
      case DATA_SET_CASE.EDUCATION_TYPE:
        return <DataSet activeTab={DATA_SET_CASE.EDUCATION_TYPE} />;

      case "changepassword":
        return <ChangePassowd />;

      default:
        return (
          <ProfileDetail
            adminData={adminData}
            RefreshHanlder={RefreshHanlder}
          />
        );
    }
  };
  ///////// profile list Api UseEffect///////////

  useEffect(() => {
    listApi(APIS.ADMIN_PROFILE).then((result) => {
      setAdminData(result?.data);
    });
  }, [sideMenuRefresh]);

  /////when jump from the profile as dataset is already  open (to make it close)//////////

  useEffect(() => {
    if (TAB_ROUTES.includes(tab)) {
      setEnableOptions(true);
    }
    if (tab === "profiledetails" && enableOptions === true) {
      setEnableOptions(false);
    }
    if (!Object.values(DATA_SET_CASE).includes(tab)) {
      setEnableOptions(false);
    }
  }, [tab === MENU[0].id, tab]);

  /////////keep the sub Menu Bar open even when page refersh Note:if the menu bar is already open////////

  useEffect(() => {
    dataSetOptions.map((data) => {
      if (data.id === tab) {
        setEnableOptions(true);
        setActivePage(tab); /////for make the button active color blue
      }
    });
  }, []);

  const handlerDataSetOptions = () => {
    setParams({ tab: tabHandler() }); //when click ondataset button set industry type as its first page//
    setActivePage(tabHandler()); /////for butto set active
    setEnableOptions(!enableOptions); ////to open the sub menu bar
  };

  return (
    <section className={styles.overview}>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className={`col-md-10 ${styles.imageRowHeading}`}>
            <div className={styles.heading}>
              Welcome <span style={{ textTransform: "none" }}> back</span>,{" "}
              <span className={styles.adminName}>{profileData?.name}.</span>
            </div>
          </div>
          <div className={`col-md-10 mt-4 ${styles.middleContainer}`}>
            <div className="d-flex">
              <div
                className={`nav flex-column nav-pills me-3 ${styles.bgprofileleft}`}
                id="v-pills-tab"
              >
                <div className={`d-flex ${styles.userimg}`}>
                  {adminData?.imageUrl?.original ? (
                    <img
                      src={IMAGE_BASE_URL + adminData?.imageUrl?.original}
                      alt="logo"
                      className="me-2"
                    />
                  ) : (
                    <h5
                      className={
                        adminData?.name
                          ? styles.userIntial
                          : styles.loadingIntial
                      }
                    >
                      {`${adminData?.name ? adminData?.name?.[0] : ""} ${
                        adminData?.lastName ? adminData?.lastName?.[0] : " "
                      }`}
                    </h5>
                  )}
                </div>

                <hr className={styles.cardDivider} />
                <ProfileSideMenu></ProfileSideMenu>
                {/* <ProfileSideMenu
                  items={ristrictAccess() ? RISTRICTED_MENU : MENU}
                  isActive={(menu) => menu.id === tab}
                  onClick={(item) => {
                    if (item?.id === "calendar") {
                      window.open(
                        "https://calendar.google.com/calendar/",
                        "_blank"
                      );
                    }
                    if (item.href) {
                      navigate(`${item.href}/${id}?tab=profiledetails`);
                      setEnableOptions(false);
                    } else {
                      setParams({ tab: item.id });
                      setEnableOptions(false);
                    }
                  }}
                /> */}
                {/* 
                {!ristrictAccess() && dataSetHasPermission() && (
                  <div
                    className={`${styles.dataSetContainer}

                  ${activePage === tab && styles.activeButton}
                  `}
                  >
                    <button
                      className={`${styles.dataSetButton} ${
                        activePage === tab && styles.activeButtonStyle
                      }`}
                      onClick={handlerDataSetOptions}
                    >
                      Users
                      <img
                        alt="data set"
                        src={
                          activePage === tab
                            ? IMAGES.ACTIVE_DROPDOWN
                            : IMAGES.INACTIVE_DROPDOWN
                        }
                        className={`${styles.dropDownImage} ${
                          enableOptions && styles.activeRotation
                        }`}
                      />
                    </button>
                  </div>
                )}

                <div>
                  {enableOptions && (
                    <ProfileSideMenu
                      dataSetsOptions
                      items={user_set_options}
                      isActive={(user_set_options) =>
                        user_set_options.id === tab
                      }
                      onClick={(item) => {
                        if (item.href) {
                          navigate(`${item.href}/${id}?tab=profiledetails`);
                        } else {
                          setParams({ tab: item.id });
                          setActivePage(item.id);
                        }
                      }}
                    />
                  )}
                </div>

                {!ristrictAccess() && dataSetHasPermission() && (
                  <div
                    className={`${styles.dataSetContainer}

                  ${activePage === tab && styles.activeButton}
                  `}
                  >
                    <button
                      className={`${styles.dataSetButton} ${
                        activePage === tab && styles.activeButtonStyle
                      }`}
                      onClick={handlerDataSetOptions}
                    >
                      Data Sets
                      <img
                        alt="data set"
                        src={
                          activePage === tab
                            ? IMAGES.ACTIVE_DROPDOWN
                            : IMAGES.INACTIVE_DROPDOWN
                        }
                        className={`${styles.dropDownImage} ${
                          enableOptions && styles.activeRotation
                        }`}
                      />
                    </button>
                  </div>
                )} */}

                {/* <div>
                  {enableOptions && (
                    <ProfileSideMenu
                      dataSetsOptions
                      items={dataSetOptions}
                      isActive={(dataSetOptions) => dataSetOptions.id === tab}
                      onClick={(item) => {
                        if (item.href) {
                          navigate(`${item.href}/${id}?tab=profiledetails`);
                        } else {
                          setParams({ tab: item.id });
                          setActivePage(item.id);
                        }
                      }}
                    />
                  )}
                </div> */}
              </div>
              {renderTab()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
