import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";
import { hasPermission, ristrictAccess } from "../../../../utils/helper";
import Skeleton from "../../../../components/skeleton";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../constants/accessControl";
import TalentHeader from "components/pageHeader/talentHeader";
import ConfirmationModal from "components/modal/confirmationModal";
import { useEffect } from "react";
import { listApi, addEditApi } from "api/commonApis";
import { APIS } from "api/endpoints";
import ToastMessage from "components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { toast } from "react-toastify";
import SummaryBlock from "./summaryBlock";
import CompSummaryBlock from "./compSummaryBlock";
import WorkBlock from "./workBlock";
import EducationBlock from "./educationBlock";
import { getState } from "redux/store";
import EmptyState from "components/emptyState";
import Walkthrough from "components/walkthrough";
import { Tornado } from "@mui/icons-material";

const Overview = ({ talent }) => {
  const [params, setParams] = useSearchParams();
  const { id } = useParams();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [assignmentList, setAssignmentList] = useState();
  const [assignmentSearch, setAssignmentSearch] = useState();
  const [assignmentId, setAssignmentId] = useState("");
  const [addedAssignment, setAddedAssignment] = useState([]);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasWorkReadAccess, setHasWorkReadAccess] = useState(false);
  const [hasEducationReadAccess, setHasEducationReadAccess] = useState(false);
  const [hasCompensationAccess, setHasCompensationAccess] = useState(false);
  const [user, set_user] = useState({});

  //////// GETTING PERMISSIN FOR SAME COMPONENT//////

  useEffect(() => {
    const role = getState()?.auth?.data?.talentLabRole;

    set_user(getState()?.auth?.data);
    // check if the user is of Organizations role then give write permission or then check the user roles

    setHasCompensationAccess(
      role === "Talent"
        ? true
        : hasPermission(
            MODULES.TALENT,
            SUB_MODULES.COMPENSATION,
            PERMISSIONS.READ
          )
    );

    setHasEducationReadAccess(
      role === "Talent"
        ? true
        : hasPermission(MODULES.TALENT, SUB_MODULES.EDUCATION, PERMISSIONS.READ)
    );
    setHasWorkReadAccess(
      role === "Talent"
        ? true
        : hasPermission(MODULES.TALENT, SUB_MODULES.WORK, PERMISSIONS.READ)
    );
    setHasWriteAccess(
      role === "Talent"
        ? true
        : hasPermission(
            MODULES.TALENT,
            SUB_MODULES.TALENT_OVERVIEW,
            PERMISSIONS.WRITE
          )
    );
  }, []);

  ///// TO CLOSE THE MODAL////////

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setAssignmentId("");
  };

  ///// TO OPEN THE MODAL////////

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  useEffect(() => {
    listApi(APIS.LIST_ASSIGNMENT, { search: assignmentSearch }).then(
      (result) => {
        setAssignmentList(result?.data?.data);
      }
    );
  }, [assignmentSearch]);

  ///////HANDLER FOR ADDING TO TALENT TO ASSIGNMENT///////////

  const addTalentHandler = () => {
    addEditApi(APIS.ADD_ASSIGNMENT_CANDIDATES, {
      assignmentId: assignmentId,
      talentId: [talent?._id],
      action: 1,
    }).then(() => {
      toast.success(
        <ToastMessage title={"Success"} message={TOAST_MESSAGE.ADD} />
      );
      handleCloseConfirmation();
    });
  };
  ///////////// LOAD ASSIGNEMNT TO MODAL SELECT///////////
  useEffect(() => {
    listApi(APIS.LIST_ASSIGNMENTS_ORG_TALNET, {
      search: "",
      talentId: talent?._id,
    }).then((result) => {
      if (result?.data?.ongoing?.length !== 0) {
        let assignemntArray = result?.data?.ongoing?.map(
          (assignment, index, arr) => {
            return assignment?._id;
          }
        );

        setAddedAssignment(assignemntArray);
      }
    });
  }, [openConfirmation]);

  // walkthrough

  //talent
  const tours = [
    {
      title: "Edit your profile",
      node: "tl-talent-edit-icon",
      description:
        "You can click on the pencil icon to edit and add your profile, work, education details and more.",
      styles: "tw-top-[28%] tw-right-[21%]",
      arrow_styles: "tw-top-[20.6%] tw-right-[31.4%]",
    },
    {
      title: "Sidebar Navigation",
      node: "tl-talent-sidebar",
      description:
        "This quick access menu allows you to view and update your profile details such as work, education, compensation and more. You can also use the documents section to add your documents/ resumes.",
      styles: "tw-top-[12%] tw-left-[23%]",
      arrow_styles: "tw-top-[10%] tw-left-[18.7%] tw-rotate-[270deg]",
    },
    // {
    //   title: "Deletion",
    //   node: "tl-talent-deletion",
    //   description:
    //     "At any point if you want to delete your profile, you can click on this button to request for deletion.",
    //   styles: "tw-top-[49.5%] tw-left-[23%]",
    //   arrow_styles: "tw-top-[47%] tw-left-[18.7%] tw-rotate-[270deg]",
    // },
    {
      title: "Conversations",
      node: "tl-comment-section",
      description:
        "You can start a conversation thread with us or participate in one you are tagged",
      styles: "tw-top-[12%] tw-right-[33%]",
      arrow_styles:
        "tw-top-[12%] tw-right-[28%] scale-x-[-1] tw-rotate-[90deg] transform",
    },
    {
      title: "Notifications",
      node: "tl-bell-icon",
      description:
        "Any reminders and notifications sent to you will be pushed here.",
      styles: "tw-top-[14%] tw-right-[5%]",
      arrow_styles: "tw-top-[6.3%] tw-right-[8%]",
    },
    {
      title: "Profile & Help",
      node: "tl-talent-profile",
      description:
        "You can easily sign out, change password, request for deletion and seek help from here.",
      styles: "tw-top-[15%] tw-right-[3%]",
      arrow_styles: "tw-top-[7.2%] tw-right-[5%]",
    },
  ];

  /////// RETURN ////////

  return (
    <>
      <div
        className={`${styles.innerContainer} ${
          ristrictAccess() ? styles.ristricContainer : styles.talentdetails
        }`}
      >
        {!talent ? (
          <Skeleton height="300px" />
        ) : (
          <>
            <TalentHeader
              handleOpenConfirmation={handleOpenConfirmation}
              talent={talent}
              hasWriteAccess={hasWriteAccess}
              currentLocation={window.location.href}
              id={id}
            />

            <div className={styles.middleCard}>
              {talent?.executiveSummary && <SummaryBlock talent={talent} />}

              {talent?.compensationHistory?.length !== 0 &&
                hasCompensationAccess && <CompSummaryBlock talent={talent} />}

              {talent?.work?.length !== 0 && hasWorkReadAccess && (
                <WorkBlock
                  talent={talent}
                  hasWorkReadAccess={hasWorkReadAccess}
                  setParams={setParams}
                />
              )}
              {talent?.education?.length !== 0 && hasEducationReadAccess && (
                <EducationBlock
                  talent={talent}
                  hasEducationReadAccess={hasEducationReadAccess}
                  setParams={setParams}
                />
              )}
            </div>

            {talent?.work?.length === 0 &&
              talent?.education?.length === 0 &&
              talent?.compensationHistory?.length === 0 &&
              talent?.executiveSummary === "" &&
              user.selfSignUp && <EmptyState></EmptyState>}

            <Walkthrough destinations={tours}></Walkthrough>
          </>
        )}
      </div>

      <ConfirmationModal
        assigedAssignment={addedAssignment}
        list={assignmentList}
        assignmentSearch={assignmentSearch}
        setAssignmentSearch={setAssignmentSearch}
        title={`Choose an assignment to assign ${talent?.firstName} ${
          talent?.middleName ? talent?.middleName : ""
        } ${talent?.lastName}`}
        open={openConfirmation}
        closeModal={handleCloseConfirmation}
        addTalent
        onChangeHanlder={addTalentHandler}
        data={`${talent?.firstName} ${
          talent?.middleName ? talent?.middleName : ""
        } ${talent?.lastName}`}
        setAssignmentId={setAssignmentId}
        assignmentId={assignmentId}
      />
    </>
  );
};

export default Overview;
