import React, { useEffect, useState } from "react";
import "./index.css";
import { getState } from "redux/store";

const Shortcuts = ({ role = "admins" }) => {
  const [activeOverview, setActiveOverview] = useState(true);
  const [selectedData, setSelectedData] = useState("");

  const RowSelected = (rowData) => {
    setSelectedData(rowData);
  };

  const addEditHanlder = (event, add) => {
    if (add) {
      setSelectedData(""); //to make the selected Data empty when clicked on the add talent Lab
    }
    setActiveOverview(false);
  };

  /* Handler When CLick on the save Button inside the Add Edit Page*/
  const addEditSaveHanlder = () => {
    setActiveOverview(true);
  };

  /*Hanlder when Back from the Edit page to table page*/
  const backButtonHandler = () => {
    setActiveOverview(true);
  };

  const [_user, set_user] = useState();
  const [shortcut_mac, set_shortcut_mac] = useState();
  const [shortcut_windows, set_shortcut_windows] = useState();

  useEffect(() => {
    const user = getState()?.auth?.data;
    set_user(user);

    //for admin
    if (
      user?.talentLabRole != "Talent" &&
      user?.talentLabRole != "Organizations"
    ) {
      set_shortcut_mac("/shortcuts/TalentLab-Mac.zip");
      set_shortcut_windows("/shortcuts/TalentLab-Windows.zip");
    }

    //for talent

    if (user?.talentLabRole == "Talent") {
      set_shortcut_mac("/shortcuts/TalentLab-Mac-Candidate-Login.zip");
      set_shortcut_windows("/shortcuts/TalentLab-Windows-Candidate-Login.zip");
    }

    //for org
    if (user?.talentLabRole == "Organizations") {
      set_shortcut_mac("/shortcuts/TalentLab-Mac-Client-Portal.zip");
      set_shortcut_windows("/shortcuts/TalentLab-Windows-Client-Portal.zip");
    }
  }, []);

  return (
    <div className="tw-w-full tw-h-[480px] tw-rounded-md tw-bg-white">
      <p className="tw-text-sm tw-font-medium tw-text-[#2f2f2f] tw-py-5 tw-border-b tw-border-[#dedede] tw-px-4">
        Download TalentLab Application Shortcut
      </p>
      <div className="tw-px-4 tw-py-8">
        <div className="tw-grid tw-grid-cols-2 tw-mx-auto tw-gap-16 tw-w-11/12 ">
          <div className="tw-border tw-border-[#dedede] tw-shadow-md tw-rounded-md tw-flex tw-py-10 tw-items-center tw-justify-center tw-flex-col tw-bg-[#fafafa]">
            <p className="mb-4 tw-text-lg tw-text-black">For Apple macOS</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="tw-w-2/12"
              viewBox="0 0 256 315"
            >
              <path d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262"></path>
            </svg>
            <a
              download
              href={shortcut_mac}
              target="_blank"
              rel="noreferrer"
              className="tw-flex tw-items-center tw-space-x-1 tw-text-xs tw-text-[#011fc5] hover:tw-underline tw-mt-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-w-4"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 15.575q-.2 0-.375-.062T11.3 15.3l-3.6-3.6q-.3-.3-.288-.7t.288-.7q.3-.3.713-.312t.712.287L11 12.15V5q0-.425.288-.712T12 4t.713.288T13 5v7.15l1.875-1.875q.3-.3.713-.288t.712.313q.275.3.288.7t-.288.7l-3.6 3.6q-.15.15-.325.213t-.375.062M6 20q-.825 0-1.412-.587T4 18v-2q0-.425.288-.712T5 15t.713.288T6 16v2h12v-2q0-.425.288-.712T19 15t.713.288T20 16v2q0 .825-.587 1.413T18 20z"
                ></path>
              </svg>
              <span className="">Download</span>
            </a>
            <a
              href="/demos/mac-shortcut-installation.mp4"
              target="_blank"
              rel="noreferrer"
              className="tw-flex tw-items-center tw-space-x-1 tw-text-xs tw-text-[#011fc5] hover:tw-underline tw-mt-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-w-4"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m10.65 15.75l4.875-3.125q.35-.225.35-.625t-.35-.625L10.65 8.25q-.375-.25-.763-.038t-.387.663v6.25q0 .45.388.663t.762-.038M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                ></path>
              </svg>
              <span className="">Watch shortcut installation demo</span>
            </a>
          </div>
          <div className="tw-border tw-border-[#dedede] tw-shadow-md tw-rounded-md tw-flex tw-py-10 tw-items-center tw-justify-center tw-flex-col tw-bg-[#fafafa]">
            <p className="mb-4 tw-text-lg tw-text-black">
              For Microsoft Windows
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="tw-w-2/12"
              viewBox="0 0 128 128"
            >
              <path
                fill="#00ADEF"
                d="m126 1.637l-67 9.834v49.831l67-.534zM1.647 66.709l.003 42.404l50.791 6.983l-.04-49.057zm56.82.68l.094 49.465l67.376 9.509l.016-58.863zM1.61 19.297l.047 42.383l50.791-.289l-.023-49.016z"
              ></path>
            </svg>
            <a
              download
              href={shortcut_windows}
              target="_blank"
              rel="noreferrer"
              className="tw-flex tw-items-center tw-space-x-1 tw-text-xs tw-text-[#011fc5] hover:tw-underline tw-mt-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-w-4"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 15.575q-.2 0-.375-.062T11.3 15.3l-3.6-3.6q-.3-.3-.288-.7t.288-.7q.3-.3.713-.312t.712.287L11 12.15V5q0-.425.288-.712T12 4t.713.288T13 5v7.15l1.875-1.875q.3-.3.713-.288t.712.313q.275.3.288.7t-.288.7l-3.6 3.6q-.15.15-.325.213t-.375.062M6 20q-.825 0-1.412-.587T4 18v-2q0-.425.288-.712T5 15t.713.288T6 16v2h12v-2q0-.425.288-.712T19 15t.713.288T20 16v2q0 .825-.587 1.413T18 20z"
                ></path>
              </svg>
              <span className="">Download</span>
            </a>
            <a
              href="/demos/windows-shortcut-installation.mp4"
              target="_blank"
              rel="noreferrer"
              className="tw-flex tw-items-center tw-space-x-1 tw-text-xs tw-text-[#011fc5] hover:tw-underline tw-mt-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-w-4"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m10.65 15.75l4.875-3.125q.35-.225.35-.625t-.35-.625L10.65 8.25q-.375-.25-.763-.038t-.387.663v6.25q0 .45.388.663t.762-.038M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                ></path>
              </svg>
              <span className="">Watch shortcut installation demo</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shortcuts;
