import React from "react";
import styles from "./styles.module.scss";
import { IMAGE_BASE_URL } from "../../constants/contants";

const SuggestionDropDown = ({ dropdownData, onClick }) => {
  return (
    <div className={styles.searchContainer}>
      {dropdownData.map((data, index) => {
        return (
          <>
            <div className={styles.searchRow} onClick={() => onClick(data)}>
              <div className={styles.commenttext}>
                {data?.imageUrl?.original ? (
                  <img
                    alt="profile"
                    src={
                      IMAGE_BASE_URL + data?.imageUrl?.original ||
                      data?.companyId?.imageUrl?.original
                    }
                    className={styles.adminThumImage}
                  />
                ) : data?.companyId?.imageUrl?.original ? (
                  <img
                    alt="another profile"
                    src={IMAGE_BASE_URL + data?.companyId?.imageUrl?.original}
                    className={styles.adminThumImage}
                  />
                ) : (
                  <h5 className={styles.adminInitail}>
                    {/*Check for talent */}
                    {`${data?.firstName?.[0] ? data?.firstName?.[0] : ""}${
                      data?.lastName?.[0] ? data?.lastName?.[0] : ""
                    }`}
                    {/*Check for client */}
                    {`${data?.name?.[0] ? data?.name?.[0] : ""}${
                      data?.name?.[1] ? data?.name?.[1] : ""
                    }`}
                    {/*Check for assignment */}
                    {`${data?.title?.[0] ? data?.title?.[0] : ""}${
                      data?.title?.[1] ? data?.title?.[1] : ""
                    }`}
                  </h5>
                )}
              </div>
              <div className={styles.selectValue}>
                <div className={styles.nameStyles}>
                  {/* data from Different module so check will there */}
                  {/*Check for talent */}
                  {`${data?.firstName ? data?.firstName : ""} ${
                    data?.lastName ? data?.lastName : ""
                  }`}
                  {/*Check for client */}
                  {data?.name ? data?.name : ""}
                  {/*Check for assignment */}
                  {data?.title ? data?.title : ""}
                </div>
                <div className={styles.emailStyle}>
                  {/*Check for talent */}
                  {data?.email ? data?.email : ""}
                  {/*Check for client */}
                  {data?.category ? data?.category : ""}
                  {/*Check for assignment */}
                  {data?.companyId?.name ? data?.companyId?.name : ""}
                  {/* If there is not any data will there */}
                  {!data?.email &&
                    !data?.category &&
                    !data?.companyId?.name && <>NA</>}
                </div>
              </div>
            </div>

            {dropdownData?.length - 1 !== index && (
              <hr className={styles.searchDivider} />
            )}
          </>
        );
      })}
    </div>
  );
};

export default SuggestionDropDown;
