import {
  LOGIN,
  SUCCESS,
  LOGOUT,
  REQUEST,
  FAIL,
  GOOGLE_AUTH,
  REFRESH_NOTIFICATIONS,
} from "./actionTypes";
import { toast } from "react-toastify";
import { login, logout } from "../../api/authApis";
import ToastMessage from "../../components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { useNavigate } from "react-router-dom";

export const handleLogin = (payload) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN[REQUEST],
    });
    login(payload)
      .then(({ data }) => {
        dispatch({
          type: LOGIN[SUCCESS],
          data,
        });
      })
      .catch(() => {
        dispatch({
          type: LOGIN[FAIL],
        });
      });
  };
};

export const handleLogout = (autoLogout) => {
  return (dispatch) => {
    if (autoLogout) {
      dispatch({
        type: LOGOUT[SUCCESS],
      });
    } else {
      logout().then(() => {
        toast.success(
          <ToastMessage title={"Success"} message={TOAST_MESSAGE.LOGOUT} />
        );
        dispatch({
          type: LOGOUT[SUCCESS],
        });
      });
    }
  };
};

export const googleAuthSuccess = (payload) => ({
  type: GOOGLE_AUTH[SUCCESS],
  payload,
});

export const refreshNotification = () => ({
  type: REFRESH_NOTIFICATIONS[SUCCESS],
});
