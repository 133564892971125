import React from "react";
import styles from "./styles.module.scss";

const OutLineButton = ({
  active,
  onClick,
  label,
  icon,
  className = "",
  visible = true,
  ...rest
}) => {
  return (
    <button
      type="button"
      className={`${styles.newAddButton} ${className} ${
        active && styles.activeBtn
      }`}
      style={{ visibility: visible ? "visible" : "hidden" }}
      onClick={onClick}
      {...rest}
    >
      {icon && <img src={icon} alt="icon" />}
      {label}
    </button>
  );
};

export default OutLineButton;
