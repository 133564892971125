import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import FormInput from "../../../../components/forms/formInput";
import { IMAGES } from "../../../../constants/assets";
import { talentCertificationsSchema } from "../../../../constants/formSchema";
import { listApi, addEditApi } from "../../../../api/commonApis";
import styles from "./styles.module.scss";
import {
  monthOption,
  saveAddNewButtonLabels,
  saveViewButtonLabels,
} from "../../../../constants/contants";
import { APIS } from "../../../../api/endpoints";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../router/routes";
import { toast } from "react-toastify";
import {
  validationError,
  EndYearDropDownOptions,
} from "../../../../utils/helper";
import LinkButton from "../../../../components/buttons/linkButton";
import ToastMessage from "../../../../components/layout/toast";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import OutLineButton from "../../../../components/buttons/outLineButton";
import { TOAST_MESSAGE } from "constants/toastMessage";

const initialValues = {
  title: "",
  endDateMonth: "",
  endDateYear: "",
  instituteName: "",
  synopsis: "",
  url: "",
  type: 1,
};

const CertsAndPublications = () => {
  const [searchParams] = useSearchParams();
  const certId = searchParams.get("certId");
  const [certPublData, setCertPublData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const yearOption = EndYearDropDownOptions();
  let action;

  useEffect(() => {
    if (certId) {
      listApi(APIS.TALENT_DETAILS_TYPE_WISE, {
        talentId: id,
        type: 4,
        id: certId,
      }).then((result) => {
        setCertPublData(result?.data[0]);
      });
    }
  }, [certId]);

  const onSubmit = (payload, { resetForm }) => {
    let data = {
      title: payload?.title,
      instituteName: payload?.instituteName,
      talentId: id,
    };
    if (payload?.synopsis) {
      data.synopsis = payload?.synopsis;
    }
    if (payload?.endDateMonth) {
      data.endDateMonth = payload?.endDateMonth;
    }
    if (payload?.endDateYear) {
      data.endDateYear = payload?.endDateYear;
    }
    if (payload?.url) {
      data.url = payload?.url;
    }
    if (payload?.type === 1) {
      data.type = 1;
    } else if (payload?.type === 2) {
      data.type = 2;
    } else {
    }

    if (certId) {
      data.certificateId = certId;
    }
    addEditApi(APIS.ADD_EDIT_TALENT_CERTIFICATION, data).then((response) => {
      if (action === "save") {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={
              payload?.type == 1
                ? TOAST_MESSAGE.SAVED_CERTIFICATE
                : TOAST_MESSAGE.SAVED_PUBLICATION
            }
          />
        );
        navigate(`${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=certs`);
      } else {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={
              payload?.type == 1
                ? TOAST_MESSAGE.SAVED_CERTIFICATE
                : TOAST_MESSAGE.SAVED_PUBLICATION
            }
          />
        );
        resetForm();
      }
    });
  };

  return (
    <div className={`tab-content ${styles.tabcontent}`}>
      <div className="fade show active">
        <Formik
          onSubmit={(value, { resetForm }) => {
            if (action === "add") {
              onSubmit(value, "add");
              resetForm();
            } else {
              onSubmit(value, "save");
            }
          }}
          enableReinitialize
          initialValues={certPublData || initialValues}
          validationSchema={talentCertificationsSchema}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleSubmit,
            getFieldProps,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.editprofile}>
                <div className={styles.edithead}>
                  <div className={styles.topheading}>
                    <h3>Add Certification(s) & Publication(s) details</h3>
                  </div>
                  <div className={styles.certHeading}>
                    <div className="d-grid  d-md-flex">
                      <OutLineButton
                        active={values?.type === 1}
                        className={"me-1"}
                        icon={IMAGES.IC_TWO}
                        label="Add Certification"
                        onClick={() => setFieldValue("type", 1)}
                      />
                      <OutLineButton
                        active={values?.type === 2}
                        icon={IMAGES.IC_TWO}
                        label="Add Publication"
                        onClick={() => setFieldValue("type", 2)}
                      />
                    </div>

                    <div className="d-flex">
                      <LinkButton
                        onClick={() =>
                          navigate(
                            `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=certs`
                          )
                        }
                      >
                        Back
                      </LinkButton>

                      <PrimaryButton
                        label={
                          values?.type === 1
                            ? saveViewButtonLabels?.saveAndViewCert
                            : saveViewButtonLabels.saveAndViewPublication
                        }
                        className={
                          values?.type === 2 && styles.publicationStyles
                        }
                        onClick={() => {
                          action = "save";
                          validationError(errors);
                        }}
                        // disabled={!dirty}
                      />
                    </div>
                  </div>
                </div>
                <hr className={styles.cardDivider} />
                <div className={styles.formname}>
                  <div className={styles.middleCard}>
                    <FormInput
                      label={
                        values?.type === 1
                          ? "Certificate Title"
                          : "Publication Title"
                      }
                      {...getFieldProps("title")}
                      error={touched?.title && errors?.title}
                    />
                    <div className={styles.dateContainer}>
                      <div className={styles.dateSection}>
                        <div className={styles.smallDatePicker}>
                          <FormInput
                            bottomError
                            label={
                              values?.type === 1
                                ? "Certification Date"
                                : "Publication Date"
                            }
                            type="select"
                            value={
                              values?.endDateMonth
                                ? monthOption.find(
                                    (obj) => obj.value === values?.endDateMonth
                                  )
                                : ""
                            }
                            options={monthOption}
                            onChange={(e) =>
                              setFieldValue("endDateMonth", e.value)
                            }
                            error={
                              touched?.endDateMonth && errors?.endDateMonth
                            }
                          />
                        </div>
                        <div className={styles.smallDatePicker}>
                          <FormInput
                            bottomError
                            label="&nbsp;"
                            type="select"
                            value={
                              values?.endDateYear
                                ? yearOption.find(
                                    (obj) => obj.value === values?.endDateYear
                                  )
                                : ""
                            }
                            options={yearOption}
                            onChange={(e) =>
                              setFieldValue("endDateYear", e.value)
                            }
                            error={touched?.endDateYear && errors?.endDateYear}
                          />
                        </div>
                      </div>
                    </div>
                    <FormInput
                      label={
                        values?.type === 1
                          ? "Certification URL"
                          : "Publication URL"
                      }
                      placeholder="For example - https://www.certificationUrl.com/in/johndoe"
                      {...getFieldProps("url")}
                      error={touched?.url && errors?.url}
                    />

                    <FormInput
                      label="Institute & Organization Name"
                      {...getFieldProps("instituteName")}
                      error={touched?.instituteName && errors?.instituteName}
                    />
                    <FormInput
                      type="textarea"
                      id="synopsis"
                      name="synopsis"
                      label={
                        values?.type === 1
                          ? "Certification Synopsis"
                          : "Publication Synopsis"
                      }
                      {...getFieldProps("synopsis")}
                      error={touched?.synopsis && errors?.synopsis}
                    />
                  </div>
                  <hr className={styles.bottomCardDivider} />

                  <div className={styles.bottomFotter}>
                    {certId ? (
                      <div></div>
                    ) : (
                      <OutLineButton
                        className={values?.type === 2 && styles.outLineButton}
                        type="submit"
                        icon={IMAGES.IC_TWO}
                        label={
                          values?.type === 1
                            ? saveAddNewButtonLabels?.certsSave
                            : saveAddNewButtonLabels?.publicationsSave
                        }
                        onClick={() => {
                          action = "add";
                          onsubmit();
                        }}
                      />
                    )}
                    <div className="d-flex">
                      <LinkButton
                        onClick={() =>
                          navigate(
                            `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=certs`
                          )
                        }
                      >
                        Back
                      </LinkButton>
                      <PrimaryButton
                        label={
                          values?.type === 1
                            ? saveViewButtonLabels?.saveAndViewCert
                            : saveViewButtonLabels.saveAndViewPublication
                        }
                        className={
                          values?.type === 2 && styles.publicationStyles
                        }
                        // disabled={!dirty}
                        onClick={() => {
                          validationError(errors);
                          action = "save";
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CertsAndPublications;
