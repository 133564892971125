import React, { SVGProps } from "react";

export function MailIcon(props) {
  return (
    <svg width="15px" height="15px" viewBox="0 0 24 24" {...props}>
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <rect width="16" height="13" x="6" y="4" rx="2"></rect>
        <path d="m22 7l-7.1 3.78c-.57.3-1.23.3-1.8 0L6 7M2 8v11c0 1.1.9 2 2 2h14"></path>
      </g>
    </svg>
  );
}
export default MailIcon;
