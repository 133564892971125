import React, { useState } from "react";
import { ROUTES } from "../../../router/routes";
import { getAssignmentStatus } from "../../../utils/helper";
import styles from "./styles.module.scss";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IMAGES } from "../../../constants/assets";
const ITEM_HEIGHT = 48;

const AssignmentRow = ({
  data: talent,
  activetab,
  cloneOptionClickHanlder,
  hasAssWriteAccess,
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const [SelectedId, setSelectedId] = useState();

  /* Open Menu Button Handle Click */
  const handleClick = (event, assignmentId) => {
    setSelectedId(assignmentId);
    setAnchorEl(event.currentTarget);
  };
  /* Close Menu Button Handle Click */
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className={`d-flex position-relative w-100 mb-3 ${styles.rowSetting}`}
      >
        <div
          className={`w-100 d-flex align-items-center justify-content-between text-capitalize
                ${styles.usersimple} `}
          onClick={() =>
            window.open(
              `${ROUTES.ASSIGNMENTS}${ROUTES.OVERVIEW}/${talent?._id}?tab=overview&activetab=${activetab}`,
              "_blank"
            )
          }
        >
          <div className={` align-items-center ${styles.userName}`}>
            <h2>{`${talent?.title}`}</h2>
            <p>{talent?.companyId?.name || "-"}</p>
          </div>
          <div className={styles.userstatus}>
            <div
              className={`${styles.statusButton}
          ${talent?.status === 12 ? `${styles.statusConvertedButton}` : ``}`}
            >
              {/* Offer Stage */}
              {getAssignmentStatus(talent?.status)}
            </div>
          </div>

          <div className={styles.usercity}>
            <h3>Location</h3>
            <p>{`${talent?.city ? `${talent?.city},` : ""} ${
              talent?.state ? `${talent?.state},` : ""
            } ${talent?.country}`}</p>
          </div>
          <div className={styles.totalcandidates}>
            <h3>Total Candidates</h3>
            <p>{talent?.totalCandidates}</p>
          </div>
          <div className={styles.usercompanyold}>
            <h3>Assignment Director</h3>
            {talent?.director?.name || talent?.director?.lastName ? (
              <p>{`${talent?.director?.name ? talent?.director?.name : ""} ${
                talent?.director?.lastName ? talent?.director?.lastName : ""
              }`}</p>
            ) : (
              <p>-</p>
            )}
          </div>
          <div className={styles.userAction}>
            <h3></h3>
            <div className={styles.tableDataActionsSize}>
              {hasAssWriteAccess && (
                <Tooltip title="Menu">
                  <IconButton
                    sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    className={styles.iconHover}
                    aria-label="menu"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(event, talent?._id);
                    }}
                  >
                    <MoreVertIcon
                      sx={{
                        transform: "rotate(90deg)",
                      }}
                      fontSize="large"
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>

      <Menu
        id={`row-menu-${talent?._id}`}
        MenuListProps={{
          "aria-labelledby": `row-menu-${talent?._id}`,
        }}
        sx={{
          "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
            "&:hover": {
              backgroundColor: "#e7e9fe",
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "12ch",
          },
        }}
      >
        <MenuItem
          className={styles.menuItemStyle}
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
            cloneOptionClickHanlder(SelectedId);
          }}
        >
          <img
            alt="icon"
            src={IMAGES.DUPLICATE_ICON}
            className={styles.iconstyle}
          />
          Duplicate
        </MenuItem>
      </Menu>
    </>
  );
};

export default AssignmentRow;
