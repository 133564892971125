import React, { useCallback, useEffect, useState } from "react";
import Resume from "./resume";
import CompensationDoc from "./compensationdoc";
import Offerdoc from "./offerdoc";
import ResignationDoc from "./resignationdoc";

const Documents = ({ fromOverview = false }) => {
  return (
    <div>
      <Resume fromOverview={fromOverview}></Resume>
      <CompensationDoc fromOverview={fromOverview}></CompensationDoc>
      <Offerdoc fromOverview={fromOverview}></Offerdoc>
      <ResignationDoc fromOverview={fromOverview}></ResignationDoc>
    </div>
  );
};

export default Documents;
