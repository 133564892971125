import React from "react";
import { Formik } from "formik";
import { addEditApi } from "../../../../api/commonApis";
import { APIS } from "../../../../api/endpoints";
import moment from "moment";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ToastMessage from "../../../layout/toast";
import { toast } from "react-toastify";
import {
  timeSelect,
  durationSelect,
  durationOptions,
} from "../../../../constants/contants";
import { reminderSchema } from "../../../../constants/formSchema";
import FormInput from "../../../forms/formInput";
import { Select, MenuItem } from "@mui/material";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../constants/assets";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { refreshNotification } from "../../../../redux/actions/auth";
import GoogleAuth from "../googleAuth";
import TimePicker from "./timePicker";
import DatePicker from "./datePicker";
import { TOAST_MESSAGE } from "constants/toastMessage";

let TIMES = [];
let startTime = moment().startOf("day");
let endTime = moment().set("hour", 23).set("minute", 45);

while (startTime <= endTime) {
  TIMES.push({
    label: moment(startTime).format("hh:mma"),
    value: moment(startTime),
  });
  startTime.add("m", 15);
}
const timeNow = moment();
const defaultTime = TIMES?.find((x) => x?.value >= timeNow);

const initialValues = {
  title: "",
  description: "",
  date: new Date(),
  time: defaultTime,
  reminderTime: 10,
  reoccuring: 4,
  duration: 60,
};

const ReminderForm = ({ closeModal, activeTabType }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    let data = {
      title: payload?.title,
      reminderTime: payload?.reminderTime,
      reoccuring: payload?.reoccuring,
      id: id,
      type: activeTabType,
      duration: payload?.duration,
    };

    if (payload?.description !== "") {
      data.description = payload?.description;
    }
    if (payload?.date !== "" && payload?.time !== "") {
      const date = moment(payload?.date).format("YYYY-MM-DD");
      const time = moment(payload?.time?.value).format("HH:mm:ss");
      const dateTime = moment(date + " " + time, "DD/MM/YYYY HH:mm:ss");
      data.dateTime = dateTime?._i;
    }

    addEditApi(APIS.ADD_EDIT_FOLLOWUPS, data).then(() => {
      toast.success(
        <ToastMessage title={"Success"} message={TOAST_MESSAGE.ADD_REMINDER} />
      );
      closeModal();
      dispatch(refreshNotification());
    });
  };

  return (
    <div>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={reminderSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <h2 className={styles.popoverHead}>
              Set date and time for a{" "}
              <span className={styles.subHEad}>follow up.</span>
            </h2>
            <div className="d-flex mb-2">
              <DatePicker
                value={values?.date}
                onChange={(date) => setFieldValue("date", date)}
              />
              <TimePicker
                options={TIMES}
                value={values?.time}
                onChange={(time) => setFieldValue("time", time)}
              />
            </div>

            <FormInput
              placeholder="Title"
              type="reminderInput"
              {...getFieldProps("title")}
              error={touched?.title && errors?.title}
            />

            <textarea
              className={styles.joiningDateFor}
              placeholder="Description"
              {...getFieldProps("description")}
            />
            <div className={styles.topSelect}>
              <div className={styles.durationIcon}>
                <div className={styles.bellIconContainer}>
                  <svg width="17" height="17" viewBox="0 0 256 256">
                    <path
                      fill="currentColor"
                      d="M198 75.6V40a14 14 0 0 0-14-14H72a14 14 0 0 0-14 14v36a14 14 0 0 0 5.6 11.2L118 128l-54.4 40.8A14 14 0 0 0 58 180v36a14 14 0 0 0 14 14h112a14 14 0 0 0 14-14v-35.6a14 14 0 0 0-5.6-11.2L138 128l54.4-41.2a14 14 0 0 0 5.6-11.2Zm-12 104.8V216a2 2 0 0 1-2 2H72a2 2 0 0 1-2-2v-36a2.1 2.1 0 0 1 .8-1.6l57.2-42.9l57.2 43.3a1.8 1.8 0 0 1 .8 1.6Zm0-104.8a1.8 1.8 0 0 1-.8 1.6L128 120.5L70.8 77.6A2.1 2.1 0 0 1 70 76V40a2 2 0 0 1 2-2h112a2 2 0 0 1 2 2Z"
                    />
                  </svg>
                </div>
                <Select
                  size="small"
                  displayEmpty
                  variant="standard"
                  className={styles.select}
                  value={values?.duration}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(data) =>
                    setFieldValue("duration", data?.target?.value)
                  }
                  IconComponent={ExpandMoreIcon}
                  sx={{
                    "&	.MuiSelect-select": {
                      padding: "3px 0px 3px 25px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      ":focus": {
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                >
                  {durationOptions.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?.value}
                      className={styles.selectItem}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className={styles.timeSelect}>
              <img
                src={IMAGES.PHBELLRINGING}
                alt="bell"
                className={styles.bellIcon}
              />

              <Select
                size="small"
                displayEmpty
                variant="standard"
                className={styles.selectDown}
                value={values?.reminderTime}
                inputProps={{ "aria-label": "Without label" }}
                onChange={(data) =>
                  setFieldValue("reminderTime", data?.target?.value)
                }
                IconComponent={ExpandMoreIcon}
                sx={{
                  "&	.MuiSelect-select": {
                    padding: "3px 0px 3px 25px",
                    fontSize: "12px",
                    fontWeight: "normal",
                    ":focus": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
              >
                {timeSelect.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item?.value}
                    className={styles.selectItem}
                  >
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={styles.durationSelect}>
              <img
                src={IMAGES.ICOMOONFREEL2}
                alt="Linkdin"
                className={styles.calenderIcon}
              />
              <Select
                size="small"
                displayEmpty
                variant="standard"
                className={styles.selectDown}
                value={values?.reoccuring}
                inputProps={{ "aria-label": "Without label" }}
                onChange={(data) =>
                  setFieldValue("reoccuring", data?.target?.value)
                }
                IconComponent={ExpandMoreIcon}
                sx={{
                  "&	.MuiSelect-select": {
                    padding: "3px 0px 3px 25px",
                    fontSize: "12",
                    fontWeight: "normal",
                    ":focus": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
              >
                {durationSelect.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item?.value}
                    className={styles.selectItem}
                  >
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="d-flex justify-content-end align-items-end mt-4 mb-2">
              <PrimaryButton label="Save" />
            </div>
          </form>
        )}
      </Formik>
      <GoogleAuth hideButton />
    </div>
  );
};

export default ReminderForm;
