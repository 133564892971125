import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import IconComponent from "components/iconComponent";
import { IMAGE_BASE_URL } from "constants/contants";
import { copyToClipboard } from "utils/helper";
import { toast } from "react-toastify";
import ToastMessage from "components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { useNavigate } from "react-router-dom";
import ProfileAvatar from "components/Avatar/profileAvatar";
import SociaHandlerRow from "components/buttons/socialHandlerRow";
import { useSelector } from "react-redux";

const ClientHeader = ({
  client,
  hasWriteAccess,
  currentLocation,
  id,
  billingPage,
  billingList,
}) => {
  const navigate = useNavigate();
  const [isAuthUser, setIsAuthUser] = useState(false);
  const loggedUserId = useSelector((state) => state?.auth?.data?._id);

  useEffect(() => {
    let valueArray = [];
    if (billingList?.length > 0) {
      valueArray = billingList?.map((record, index, arr) => {
        if (record?.uploadedBy?._id === loggedUserId) {
          return true;
        }
        return false;
      });

      if (valueArray.includes(true)) {
        setIsAuthUser(false);
      } else {
        setIsAuthUser(true);
      }
    } else {
      setIsAuthUser(true);
    }
  }, [billingList]);

  /*Copy pofile Url */
  const copyProfileUrl = () => {
    copyToClipboard(currentLocation);
    toast.success(
      <ToastMessage title={"Success"} message={TOAST_MESSAGE.COPY_LINK} />
    );
  };

  /*Edit Handler */
  const onEditClickHandler = () => {
    return navigate(
      `${
        !billingPage
          ? `/clients/detail/${id}?source=overview`
          : `/clients/billing/${id}?source=overview`
      }`
    );
  };

  return (
    <div className={`d-flex text-capitalize ${styles.details}`}>
      {client?.imageUrl?.original ? (
        <img
          alt=""
          src={IMAGE_BASE_URL + client?.imageUrl?.original}
          className={styles.clientLogo}
        />
      ) : (
        <ProfileAvatar
          firstLabel={client?.name?.charAt(0)}
          secondLabel={client?.name?.charAt(1)}
        />
      )}

      <div className={styles.detailscontent}>
        <div className="w-100">
          <div className={styles.detailsheading}>
            <h2 className={styles.clientName}>{`${client?.name} `}</h2>
            <p className={styles.clientCategoty}>
              {client?.category && client?.category}
            </p>
            {client?.country &&
              client?.country !== "" &&
              client?.state &&
              client?.state !== "" &&
              client?.city &&
              client?.city !== "" && (
                <p className={styles.cilentCity}>
                  {client?.city + ", " + client?.state + ", " + client?.country}
                </p>
              )}

            {/* <p className={styles.cilentCity}>
              {client?.city  ? (
                <div className={styles.cityHeading}>{`${client?.city},`}</div>
              ) : (
                ""
              )}
              {`${client?.state ? `${client?.state},` : ""} ${
                client?.country ? client?.country : ""
              }`}
            </p> */}
          </div>

          <SociaHandlerRow data={client} />
        </div>

        <IconComponent
          isAuthUser={isAuthUser}
          billingPage={billingPage}
          hasWriteAccess={hasWriteAccess}
          copyProfileUrl={copyProfileUrl}
          onEditClickHandler={onEditClickHandler}
          mailTOEmaillink={`mailto:?subject=&body=Link: ${currentLocation}`}
        />
      </div>
    </div>
  );
};

export default ClientHeader;
