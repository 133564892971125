import React from "react";
import styles from "./styles.module.scss";
import FilterSelect from "../../filters/filterSelect";
import FilterButton from "components/buttons/filterButton";

const AssignmentFilter = ({
  appliedFilters,
  onReset,
  isApplied,
  filterData,
  onFilterChange,
  debouncedResults,
  state,
  city,
  country,
}) => {
  return (
    <section style={{ transition: "all 0.3s ease-in-out" }}>
      <div className="row mt-4">
        <div className="col-md-12 d-flex justify-content-between">
          <div>
            <div className={`d-flex  gap-2 ${styles.selectboxes}`}>
              <div className={styles.select}>
                <FilterSelect
                  isMulti
                  name="company"
                  value={appliedFilters?.company}
                  onChange={onFilterChange}
                  placeholder="Client Name"
                  searchPlaceholder="Search for client name"
                  isSearchAble
                  options={filterData?.company}
                  onChangeText={debouncedResults}
                />
              </div>
              <div className={styles.select}>
                <FilterSelect
                  isMulti
                  name="locations"
                  isSearchAble
                  locationFilter
                  state={state}
                  city={city}
                  country={country}
                  searchPlaceholder="Search for locations"
                  value={appliedFilters?.locations}
                  onChange={onFilterChange}
                  placeholder="Location"
                  options={filterData?.locations}
                />
              </div>
              <div className={styles.select}>
                <FilterSelect
                  isMulti
                  name="director"
                  value={appliedFilters?.director}
                  onChange={onFilterChange}
                  placeholder="Assignment Director"
                  isSearchAble
                  searchPlaceholder="Search for assignment director"
                  options={filterData?.director}
                  onChangeText={debouncedResults}
                />
              </div>
              <div className={styles.select}>
                <FilterSelect
                  isMulti
                  name="consultant"
                  value={appliedFilters?.consultant}
                  onChange={onFilterChange}
                  placeholder="Consultant"
                  searchPlaceholder="Search for consultant"
                  isSearchAble
                  options={filterData?.consultant}
                  onChangeText={debouncedResults}
                />
              </div>
            </div>
          </div>
          <div>
            <div className={styles.resetButtonContainer}>
              <FilterButton
                filterReset
                onClick={onReset}
                disabled={!isApplied}
              />
            </div>
            {/* <PrimaryButton
          label="Reset Filters"
          onClick={onReset}
          disabled={!isApplied}
        /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AssignmentFilter;
