import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";

const Header = ({
  block,
  archive,
  data,
  description,
  blockUser,
  deleteTalentLabUser,
  reuqestDelete,
  status,
  addTalent,
  remove,
}) => {
  return (
    <div className={styles.redAlert}>
      <div className={styles.alertHeading}>
        <img alt="" src={IMAGES.ALERT_CAUTION} />
        {block ? "Caution" : " Alert"}
      </div>
      {archive ? (
        <div className={styles.alertDescription}>
          You are about to archive <b>{data}</b> assignment.{description}
        </div>
      ) : block ? (
        <div className={styles.alertDescription}>
          You are about to{status ? ` unblock` : ` block`} <b>{data?.trim()}</b>
          .
          {blockUser && (
            <>
              This will {status ? "unblock" : "block"} <b>{data}</b> from
              accessing the TalentLab application
            </>
          )}
        </div>
      ) : deleteTalentLabUser ? (
        <div className={styles.alertDescription}>
          You are about to delete a <b>{data?.trim()}</b>. This will delete all
          records associated with <b>{data}</b> and transfer it to selected user
          below. Please note that this action is
          <b> non-reversible</b>.
        </div>
      ) : reuqestDelete ? (
        <div className={styles.alertDescription}>
          You are requesting to delete your profile. Once your request is
          confirmed by the administrator, your account will be automatically
          deleted and all associated data will be deleted and can not be
          recovered. Please note that this action is non-reversible.
        </div>
      ) : addTalent ? (
        <div className={styles.alertDescription}>
          You are about to add <b>{data?.trim()}</b> to an assignment.
        </div>
      ) : (
        <div className={styles.alertDescription}>
          You are about to {remove ? "remove" : "delete"} <b>{data}</b>.{" "}
          {description} Please note that this action is <b>non-reversible</b>.
        </div>
      )}
    </div>
  );
};

export default Header;
