import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../constants/assets";
import { useNavigate, useParams } from "react-router-dom";
import { DELETE_MODAL_CONFIRMATION } from "../../../../constants/contants";
import { hasPermission } from "../../../../utils/helper";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../constants/accessControl";
import ConfirmationModal from "../../../../components/modal/confirmationModal";
import { APIS } from "../../../../api/endpoints";
import { addEditApi } from "../../../../api/commonApis";
import ToastMessage from "../../../../components/layout/toast";
import { toast } from "react-toastify";
import OutLineButton from "../../../../components/buttons/outLineButton";
import { ristrictAccess } from "../../../../utils/helper";
import { TOAST_MESSAGE } from "constants/toastMessage";
import EducationRow from "./educationRow";
import { getState } from "redux/store";

const Education = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [onHover, setOnHover] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  /////// GETTIN ACCESS FOR THE SAME COMPONENET///
  useEffect(() => {
    const user = getState()?.auth?.data?.talentLabRole;
    setHasWriteAccess(
      user === "Talent"
        ? true
        : hasPermission(
            MODULES.TALENT,
            SUB_MODULES.EDUCATION,
            PERMISSIONS.WRITE
          )
    );
    setHasDeleteAccess(
      user === "Talent"
        ? true
        : hasPermission(
            MODULES.TALENT,
            SUB_MODULES.EDUCATION,
            PERMISSIONS.DELETE
          )
    );
  }, []);

  ///////HANDLER USE TO CLOSE THE MODAL////////

  const closeModal = () => {
    setDeleteId();
    setOpenModal(false);
  };

  ///////////// DELETE HANDLER/////////

  const onChangeHanlder = () => {
    addEditApi(APIS.DELETE_DOCUMENT_DATA, { type: 15, id: deleteId }).then(
      (result) => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.DELETE_EDUCATTION}
          />
        );
        props?.setRefresh((pre) => !pre);
        closeModal();
      }
    );
  };

  /////// RETURN //////

  return (
    <>
      <div
        className={
          ristrictAccess() ? styles.ristrictContainer : styles.talentdetails
        }
      >
        <div>
          <div className={styles.topheading}>
            <div className={styles.overviewHeading}>
              <h2> Education History</h2>

              <OutLineButton
                visible={hasWriteAccess || ristrictAccess()}
                label="Add New"
                icon={onHover ? IMAGES.ACTIVE_PLUS_BUTTON : IMAGES.NEW_ADDITION}
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
                onClick={() =>
                  navigate(`/talent/education/${id}?source=overview`)
                }
              />
            </div>
          </div>
          <EducationRow
            data={props?.data}
            hasWriteAccess={hasWriteAccess}
            ristrictAccess={ristrictAccess}
            id={id}
            hasDeleteAccess={hasDeleteAccess}
            setDeleteId={setDeleteId}
            setOpenModal={setOpenModal}
          />
        </div>
      </div>
      <ConfirmationModal
        open={openModal}
        closeModal={closeModal}
        title={DELETE_MODAL_CONFIRMATION}
        data={`education`}
        onChangeHanlder={onChangeHanlder}
      />
    </>
  );
};

export default Education;
