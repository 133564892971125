import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";
import { Tooltip } from "@mui/material";
import { getState } from "redux/store";
import EmailInvoices from "components/emailInvoices";

const ActionButtons = ({
  viewAction,
  DownloadAction,
  deleteAction,
  accessControl,
  generatedById,
  forInvoice = false,
  data,
}) => {
  const cuurentUserId = getState()?.auth?.data?._id;
  const [isActive, setIsActive] = useState(false);

  ///// TO CHECK WHEATHER THE DOCUMENT UPLODED BY CURRENY LOGGED IN USED////

  useEffect(() => {
    if (generatedById === cuurentUserId) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [accessControl, generatedById]);

  return (
    <>
      {forInvoice && (
        <Tooltip title="Send mail with invoice">
          {/* <img
          src={IMAGES.EXTERNAL_LINK}
          alt="view Document"
          onClick={viewAction}
          className={styles.pointer}
        /> */}

          <EmailInvoices invoice_data={data}></EmailInvoices>
        </Tooltip>
      )}

      <Tooltip title="View">
        <img
          src={IMAGES.EXTERNAL_LINK}
          alt="view Document"
          onClick={viewAction}
          className={styles.pointer}
        />
      </Tooltip>
      <Tooltip title="Download">
        <img
          alt="download Document"
          src={IMAGES.DOWNLOAD_ICON}
          onClick={DownloadAction}
          className={styles.pointer}
        />
      </Tooltip>

      <Tooltip title={isActive || accessControl ? "Delete" : "Access Denied"}>
        <img
          alt="delete Document"
          src={
            isActive || accessControl
              ? IMAGES.RI_ACTIVE_DELETE_BIN
              : IMAGES.RI_DISABLE_DELETE_BIN
          }
          className={
            isActive || accessControl ? styles.pointer : styles.withOuterPointer
          }
          onClick={(isActive || accessControl) && deleteAction}
        />
      </Tooltip>
    </>
  );
};

export default ActionButtons;
