import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Formik } from "formik";
import { userChangePassword } from "../../../constants/formSchema";
import PrimaryButton from "../../../components/buttons/primaryButton";
import FormInput from "../../../components/forms/formInput";
import { IMAGES } from "../../../constants/assets";
import { addEditApi } from "../../../api/commonApis";
import { APIS } from "../../../api/endpoints";
import { toast } from "react-toastify";
import ToastMessage from "../../../components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";

const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const ChangePassowd = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  /////////////////// Update PASSWORD OnSubmit Handler///////////////
  const onSubmit = (payload, { resetForm, setSubmitting }) => {
    const data = {
      oldPassword: payload?.currentPassword,
      newPassword: payload?.confirmNewPassword,
    };

    addEditApi(APIS.CHANGE_PASSWORD, data)
      .then(() => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.UPDATE_PASSWORD}
          />
        );
        resetForm();
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className={`tab-content ${styles.tabcontent}`} id="v-pills-tabContent">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={userChangePassword}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          setValues,
          dirty,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.topheading}>
              <h3 className={styles.h3heading}>Change Password</h3>

              <div className="d-flex">
                <PrimaryButton
                  type="submit"
                  label={isSubmitting ? "updating..." : "Update"}
                  disabled={isSubmitting || !dirty}
                />
              </div>
            </div>
            <hr className={styles.cardDivider} />
            <div className={styles.middleCard}>
              <div style={{ position: "relative" }}>
                <FormInput
                  label="Current Password"
                  type={showCurrentPassword ? "text" : "password"}
                  {...getFieldProps("currentPassword")}
                  error={touched?.currentPassword && errors?.currentPassword}
                />
                <img
                  alt="eye"
                  className={styles.eyeViewHide}
                  src={showCurrentPassword ? IMAGES.EYESLASH : IMAGES.EYE}
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                />
              </div>
              <div style={{ position: "relative" }}>
                <FormInput
                  label="New Password"
                  type={showNewPassword ? "text" : "password"}
                  {...getFieldProps("newPassword")}
                  error={touched?.newPassword && errors?.newPassword}
                />
                <img
                  alt="hide eye"
                  className={styles.eyeViewHide}
                  src={showNewPassword ? IMAGES.EYESLASH : IMAGES.EYE}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              </div>
              <div style={{ position: "relative" }}>
                <FormInput
                  label="Confirm New Password"
                  type={showConfirmNewPassword ? "text" : "password"}
                  {...getFieldProps("confirmNewPassword")}
                  error={
                    touched?.confirmNewPassword && errors?.confirmNewPassword
                  }
                />
                <img
                  alt="show eye"
                  className={styles.eyeViewHide}
                  src={showConfirmNewPassword ? IMAGES.EYESLASH : IMAGES.EYE}
                  onClick={() =>
                    setShowConfirmNewPassword(!showConfirmNewPassword)
                  }
                />
              </div>
            </div>
            <hr className={styles.bottomDivider} />
          </form>
        )}
      </Formik>
    </div>
  );
};
export default ChangePassowd;
