import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../../../constants/assets";
import { dayOption } from "../../../../../../constants/contants";
import DaysSelect from "../../daysSelect";

const OffLimitSelect = ({ closeButton, setofflimit, yesButtonClick }) => {
  return (
    <div className={styles.modal}>
      <div className={` ${styles.limit} ${styles.user}`}>
        <h2 className="text-start pe-0 ps-0">
          Choose a time period for ‘Off Limit’
        </h2>
        <div className={`mt-3 ${styles.days} position-relative`}>
          <DaysSelect
            defaultValue={dayOption[0]}
            onChange={(e) => {
              setofflimit(e.value);
            }}
          />

          <div className={styles.imageDivStyles}>
            <img
              src={IMAGES.ACCOUNT_IDIT}
              alt=""
              className={styles.timerImageStyle}
            />
          </div>
        </div>
        <div className={`mt-3 text-start ${styles.notes}`}>
          <p>Please Note</p>
          <p>
            1. ‘Off Limit’ a candidate, blocks them from being added to any
            assignment.
          </p>
          <p>
            2. Once marked as ‘Off Limit’, only you and Super Admins can remove
            the ‘Off Limit’ status on the candidate.
          </p>
          <p>
            3. You will receive an intimation if any team member would like to
            revoke ‘Off Limit’ status.
          </p>
          <p>
            4. You can revisit this panel if you wish to extend the off limit
            period.
          </p>
        </div>
        <h2 className="text-start pe-0 ps-0">
          Are you sure you wish to Off-Limit this candidate?
        </h2>
        <div className={styles.btncancalyes}>
          <button className={styles.no} onClick={closeButton}>
            No, Cancel
          </button>
          <button className={styles.yes} onClick={yesButtonClick}>
            Yes, Off-Limit
          </button>
        </div>
      </div>
    </div>
  );
};
export default OffLimitSelect;
