import React from "react";
import styles from "./styles.module.scss";
import CurrencyBox from "components/currencyBox";

const CompSummaryBlock = ({ talent }) => {
  return (
    <>
      {(talent?.compensationHistory?.[0]?.current ||
        talent?.compensationHistory?.[0]?.expected) && (
        <>
          <div className={styles.textBlock}>
            <h3>Compensation Summary</h3>
            <div className={styles.currencyBoxContainer}>
              {!!talent?.compensationHistory?.[0]?.current && (
                <CurrencyBox data={talent} current />
              )}
              {!!talent?.compensationHistory?.[0]?.expected && (
                <CurrencyBox data={talent} />
              )}
            </div>
          </div>
          <hr className={styles.horizontalLine} />
        </>
      )}
    </>
  );
};

export default CompSummaryBlock;
