import React from "react";
import styles from "./styles.module.scss";
import CommonFilter from "components/commonFilter";

const SearchFilters = ({
  onChange,
  appliedFilters,
  activeTab,
  isApplied,
  onReset,
  type,
}) => {
  const renderSearchBar = () => {
    if (activeTab === 1) {
      return (
        <CommonFilter
          {...{ appliedFilters, onChange, isApplied, onReset, type }}
        />
      );
    }
    if (activeTab === 2) {
      return (
        <CommonFilter
          {...{ appliedFilters, onChange, isApplied, onReset, type }}
        />
      );
    } else {
      return (
        <CommonFilter
          {...{ appliedFilters, onChange, isApplied, onReset, type }}
        />
      );
    }
  };

  return (
    <section className={styles.overview}>
      <div className={`row  ${styles.container}`}>
        <div className="col-md-12">
          <div className={`gap-2 ${styles.selectboxes}`}>
            {renderSearchBar()}
          </div>
        </div>
      </div>
    </section>
  );
};
export default SearchFilters;
