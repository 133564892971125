import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import styles from "./styles.module.scss";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Checkbox from "../../table/checkbox";
import SearchAbleInput from "../seacrhAbleInput";

export const searchByFields = (data, searchValue = "") => {
  return (
    searchValue === null ||
    searchValue === "" ||
    (data &&
      data?.some((i) => i?.toLowerCase().includes(searchValue?.toLowerCase())))
  );
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    PaperProps={{
      style: {
        width: "100%",
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,

    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      "&:hover": {
        backgroundColor: "#E7E9FE",
      },
    },
  },
}));

const singlOuterList = {
  color: "black",
  fontSize: "12px",
  fontWeight: "normal",
  paddingLeft: "17px",
  paddingRight: "17px",
  paddingBottom: "14px",
};
const MultiOuterList = {
  color: "black",
  fontSize: "12px",
  fontWeight: "normal",
  paddingLeft: "17px",
  paddingRight: "17px",
};

export default function CustomSelect({
  placeholder,
  options = [],
  value,
  isMulti,
  state,
  city,
  onChange,
  name,
  country,
  style,
  onChangeText,
  isSearchAble,
  searchPlaceholder,
  locationFilter,
  experienceFilter,
  competencyFilter,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (value?.length > 0 && locationFilter) {
      setSearch("");
    }
  }, [open]);

  const isSelected = isMulti ? !!value?.length : !!value;

  const handlerCityState = (value) => {
    if (state?.includes(value)) {
      return "state";
    } else if (city?.includes(value)) {
      return "city";
    } else if (country?.includes(value)) {
      return "country";
    } else {
      return "";
    }
  };

  const onResetHandler = () => {
    setSearch("");
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        className={` ${styles.hoverEffect}
        ${
          style === "assignmentStyles"
            ? styles.assignmentStyles
            : styles.selectDropDown
        } ${anchorEl ? styles.dropDownActive : ""}


        ${
          style === "assignmentStyles"
            ? isSelected
              ? styles.selectedAssignmentStyles
              : ""
            : isSelected
            ? styles.selectedDropDown
            : ""
        }
          `}
        onClick={handleClick}
        endIcon={
          <KeyboardArrowDownIcon
            className={`${anchorEl ? styles.activeIcon : ""} ${
              anchorEl ? (isSelected ? "" : styles.activeIconColor) : ""
            }`}
            sx={{ "& .MuiButton-endIcon": { marginLeft: "0px" } }}
          />
        }
        sx={{
          "& .MuiButton-endIcon": {
            marginLeft: "0px",
          },
        }}
      >
        <div
          style={{
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: "10px",
            whiteSpace: "nowrap",
            paddingRight: "8px",
          }}
        >
          {placeholder}
        </div>
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            paddingTop: "7px",
            minHeight: "2ch",
            maxHeight: ITEM_HEIGHT * 5,
            minWidth: "26ch",
            paddingBottom: "6px",
            // boxShadow: "0px 10px 18px  #d3d3d3",
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {isSearchAble ? (
          <MenuItem
            className={styles.searchbale}
            sx={singlOuterList}
            disableRipple
            onKeyDown={(event) => event.stopPropagation()}
          >
            <SearchAbleInput
              searchPlaceholder={searchPlaceholder}
              searchQuery={search}
              onChangeText={(e) => setSearch(e.target.value)}
              onResetHandler={onResetHandler}
            />
          </MenuItem>
        ) : (
          ""
        )}

        {options?.map((option, index) => {
          if (searchByFields([option?.label || option?.title], search)) {
            return (
              <MenuItem
                sx={MultiOuterList}
                disableRipple
                key={`option-${index}`}
                onClick={(e) => onChange(name, option, isMulti)}
              >
                <div>
                  <Checkbox
                    // id={option?.value}
                    className={styles.checkBox}
                    checked={
                      isMulti
                        ? value?.includes(option?.value)
                        : option?.value || option?.title === value
                    }
                  />
                </div>
                <div className={styles.menuDataCss}>
                  <div
                    className={`${styles.menuValue} ${
                      locationFilter ? styles.valueOverflow : ""
                    }`}
                  >
                    {option?.label || option?.title}
                  </div>
                  {!!locationFilter && (
                    <div className={styles.cityOrState}>
                      {handlerCityState(option?.label)}
                    </div>
                  )}
                  {!!experienceFilter && (
                    <div className={styles.cityOrState}>years</div>
                  )}
                  {!!competencyFilter && (
                    <div className={styles.cityOrState}>score</div>
                  )}
                </div>
              </MenuItem>
            );
          } else return null;
        })}
      </StyledMenu>
    </div>
  );
}
