import React from "react";
import styles from "./styles.module.scss";

const ContactRightBlock = ({ assignment }) => {
  return (
    <div className={styles.rightsummary}>
      {(assignment?.director?.name || assignment?.director?.lastName) && (
        <>
          <h3 className="pt-0 mb-0">Assignment Director</h3>
          <p className="mb-2">{`${
            !!assignment?.director?.name && assignment?.director?.name
          } ${
            !!assignment?.director?.lastName && assignment?.director?.lastName
          }`}</p>
        </>
      )}

      {!!assignment?.consultant?.length > 0 && (
        <>
          <h3 className="pt-0 mb-0 ">Consultants</h3>
          {assignment?.consultant?.map((data, index) => (
            <p key={index}>{`${data.name} ${data.lastName}`}</p>
          ))}
        </>
      )}
    </div>
  );
};

export default ContactRightBlock;
