import React from "react";
import styles from "./styles.module.scss";
import RoundDeleteIcon from "pages/talent/components/actionIcons/roundDeleteIcon";
import RoundEditIcon from "pages/talent/components/actionIcons/roundEditIcon";
import { noDataLabels } from "constants/contants";
import { IMAGES } from "constants/assets";
import { useNavigate } from "react-router-dom";

const DataRow = ({
  data,
  id,
  hasWriteAccess,
  ristrictAccess,
  hasDeleteAccess,
  setDeleteData,
  setOpenModal,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {data?.length === 0 ? (
        <div className={styles.noDataFoundContainer}>
          <h4 className={styles.dataNotFound}>
            {noDataLabels.certificationList}
          </h4>
        </div>
      ) : (
        data?.map((data) => (
          <div className={styles.newdetailscontent}>
            <div>
              <div className={styles.flexContainer}>
                <div className={styles.flexInnerContainer}>
                  <div
                    className={styles.certification_details_icons}
                    onClick={() =>
                      navigate(
                        `/talent/certs-publications/${id}?certId=${data?._id}&source=overview`
                      )
                    }
                  >
                    {hasWriteAccess && (
                      <>
                        <span>
                          <img
                            src={
                              data?.type === 1
                                ? IMAGES.CERTIFICATE
                                : IMAGES.PUBLICATION
                            }
                            alt=""
                            className={styles.socialIcon}
                          />
                        </span>
                        <h5 style={{ cursor: "pointer" }}>
                          {data?.type === 1 ? "Certification" : "Publication"}
                        </h5>
                      </>
                    )}
                  </div>
                  <div className={`ms-2 ${styles.certification_details_icons}`}>
                    <div className={styles.roundIconContainer}>
                      {hasWriteAccess || ristrictAccess() ? (
                        <RoundEditIcon
                          onClick={() =>
                            navigate(
                              `/talent/certs-publications/${id}?certId=${data?._id}&source=overview`
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                      {hasDeleteAccess && (
                        <RoundDeleteIcon
                          certsAndPublication
                          onClick={() => {
                            setDeleteData(data);
                            setOpenModal(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.detailsheading}>
                <h2 className={styles.capitalText}>{data?.title}</h2>
                <h3>{data?.instituteName}</h3>
                <h4 className={styles.capitalText}>
                  {data?.endDateYear ? data?.endDateYear : ""}
                </h4>
                {data?.type === 2 && (
                  <p>
                    <b>
                      <a href={data?.url} target="_blank" rel="noreferrer">
                        View Publication
                      </a>
                    </b>
                  </p>
                )}
              </div>
              <hr className={styles.dashedLine} />
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default DataRow;
