import React from "react";
import styles from "./styles.module.scss";
import Select, { components } from "react-select";
import { IMAGES } from "../../../constants/assets";
import { Radio } from "@mui/material";

const SortFilter = ({ name, placeholder, value, fontSize, ...rest }) => {
  const filterStyles = {
    control: (provided, state) => ({
      display: "flex",
      borderRadius: "5px",
      color: "#2f2f2f",
      fontSize: "11px",
      height: "23px",
      backgroundColor: "transparent",
      width: "fit-content",
      fontWeight: "500",
      cursor: "pointer",
    }),

    indicatorSeparator: (styles) => ({ display: "none" }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#2f2f2f",
      padding: 0,
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "10px",
        color: "#2f2f2f",
        fontSize: "12px",
        fontWeight: 500,
      };
    },

    valueContainer: (defaultStyles) => {
      return {
        ...defaultStyles,
        whiteSpace: "nowrap",
        padding: "0px",
      };
    },
    menu: (provided, state) => ({
      ...provided,
      color: "#2f2f2f",
      fontSize: "12px",
      fontWeight: "500",
      width: "220px !important",
      padding: 0,
      right: "1px",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: fontSize,
      fontWeight: 500,
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "transparent",
        color: "black",

        ":hover": {
          ...styles[":hover"],
          backgroundColor: "#e7e9fe",
        },
      };
    },
  };
  const CaretDownIcon = () => {
    return (
      <img
        src={IMAGES.SORT_FILLTER}
        alt="Sort"
        style={{ height: "16px", width: "16px" }}
      />
    );
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props} className="d-flex ">
          <Radio
            size="small"
            className={styles.radio}
            checked={props.isSelected}
            sx={{
              "&.Mui-checked": {
                color: "#011cf5",
              },
            }}
          />
          <label>{props.label} </label>
        </components.Option>
      </div>
    );
  };

  return (
    <div className={styles.select}>
      <div className={styles.formgroup}>
        <Select
          styles={filterStyles}
          name={name}
          placeholder={placeholder}
          components={{ DropdownIndicator, Option }}
          isSearchable={false}
          value={value}
          {...rest}
        />
      </div>
    </div>
  );
};

export default SortFilter;
