import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import FormInput from "../../../../components/forms/formInput";
import { clinetBillingSchema } from "../../../../constants/formSchema";
import styles from "./styles.module.scss";
import { APIS } from "../../../../api/endpoints";
import { listApi, addEditApi } from "../../../../api/commonApis";
import Dropzone from "../../../../components/forms/dropzone";
import CountryStateCityPicker from "../../../../components/countryStateCityPicker";
import LinkButton from "../../../../components/buttons/linkButton";
import { ROUTES } from "../../../../router/routes";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { validationError } from "../../../../utils/helper";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { toast } from "react-toastify";
import ToastMessage from "../../../../components/layout/toast";
import { AcceptedFormat } from "constants/contants";

const initialValues = {
  billingContact: {
    name: "",
    designation: "",
    email: "",
    phoneNumber: "",
  },
  billingAddress: {
    addressLine1: "",
    addressLine2: "",
    pinCode: "",
    city: null,
    state: null,
    country: { label: "India", value: "India" },
  },
  gstNo: "",
  gstCertificate: [],
  panCard: "",
  panCardCertificate: [],
};

const Billing = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [params] = useSearchParams();
  const [searchParams] = useSearchParams();
  const contactId = searchParams.get("id");
  const [loading, setLoading] = useState(false);
  const [gstFiles, setGstFiles] = useState([]);
  const [panFiles, setPanFiles] = useState([]);
  const [clientData, setClientData] = useState({});
  const [refresh, setRefresh] = useState(false);

  /*Edit Case */

  useEffect(() => {
    if (contactId !== null) {
      let payload = {
        clientId: id,
      };
      if (contactId !== null) {
        payload.billingId = contactId;
      }
      setLoading(true);
      listApi(APIS.LIST_CLIENT_BILLING, {
        ...payload,
      })
        .then((result) => {
          const data = cloneDeep(result?.data?.data);
          let EditCaseData = {
            billingContact: data?.billingContact,
            billingAddress: {
              addressLine1: data?.billingAddress?.addressLine1,
              addressLine2: data?.billingAddress?.addressLine2,
              pinCode: data?.billingAddress?.pinCode,
            },
            gstCertificate: [],
            panCardCertificate: [],
          };
          /// gst
          if (data?.gstNo === undefined) {
            EditCaseData.gstNo = "";
          } else {
            EditCaseData.gstNo = data?.gstNo;
          }
          // pancard
          if (data?.panCard === undefined) {
            EditCaseData.panCard = "";
          } else {
            EditCaseData.panCard = data?.panCard;
          }
          ///// if there is no country////////
          if (data?.billingAddress?.country === undefined) {
            EditCaseData.billingAddress.country = {
              label: "India",
              value: "India",
            };
          } else {
            EditCaseData.billingAddress.country = {
              label: data?.billingAddress?.country,
              value: data?.billingAddress?.country,
            };
          }
          //// if there is state in edit case
          if (data?.billingAddress?.state === undefined) {
            EditCaseData.billingAddress.state = null;
          } else {
            EditCaseData.billingAddress.state = {
              label: data?.billingAddress?.state,
              value: data?.billingAddress?.state,
            };
          }
          ///////// if there is city in edit case
          if (data?.billingAddress?.city === undefined) {
            EditCaseData.billingAddress.city = null;
          } else {
            EditCaseData.billingAddress.city = {
              label: data?.billingAddress?.city,
              value: data?.billingAddress?.city,
            };
          }
          setClientData(EditCaseData);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  /*Submit Hanlder*/

  const onSubmit = (payload) => {
    const data = new FormData();
    payload.billingAddress.country =
      payload?.billingAddress?.country?.label || "";
    payload.billingAddress.state = payload?.billingAddress?.state?.label || "";
    payload.billingAddress.city = payload?.billingAddress?.city?.label || "";

    if (payload?.billingContact) {
      data.append("billingContact", JSON.stringify(payload?.billingContact));
    }
    data.append("billingAddress", JSON.stringify(payload?.billingAddress));
    data.append("panCard", payload?.panCard);
    data.append("gstNo", payload?.gstNo);
    if (payload?.gstCertificate?.length) {
      data.append("gstCertificate", payload?.gstCertificate[0]);
    } else {
      delete payload.gstCertificate;
    }
    if (payload?.panCardCertificate?.length) {
      data.append("panCardCertificate", payload?.panCardCertificate[0]);
    } else {
      delete payload.panCardCertificate;
    }
    data.append("clientId", id);

    /*Billing Id edit case */

    if (contactId !== null) {
      data.append("billingId", contactId);
    }

    if (gstFiles?.length !== 0 || panFiles.length !== 0) {
      toast.success(
        <ToastMessage
          title={"Success"}
          message={TOAST_MESSAGE.UPLOAD_PROGRESS}
        />
      );
    }

    addEditApi(APIS.ADD_EDIT_BILLING, data)
      .then((response) => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={
              contactId !== null
                ? TOAST_MESSAGE.BILLING_UPDATE
                : TOAST_MESSAGE.BILLING_SAVED
            }
          />
        );

        navigate(`${ROUTES.CLIENTS}/overview/${id}?tab=billing`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={contactId !== null ? clientData : cloneDeep(initialValues)}
      enableReinitialize
      validationSchema={clinetBillingSchema}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        handleChange,
        handleSubmit,
        getFieldProps,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.editprofile}>
            <div className={styles.edithead}>
              <div className={styles.topheading}>
                <div>
                  <h1>{clientData?.name}</h1>
                  <h5>{clientData?.category}</h5>
                </div>
                <div>
                  <h3>Billing Point of Contact</h3>
                </div>
              </div>
              <hr className={styles.cardDivider} />
              <div className={styles.formname}>
                <div className={styles.middleCard}>
                  <FormInput
                    label="Full Name"
                    {...getFieldProps("billingContact.name")}
                    error={
                      touched?.billingContact?.name &&
                      errors?.billingContact?.name
                    }
                  />
                  <FormInput
                    label="Designation/Role"
                    {...getFieldProps("billingContact.designation")}
                    error={
                      touched?.billingContact?.designation &&
                      errors?.billingContact?.designation
                    }
                  />
                  <FormInput
                    label="Email"
                    {...getFieldProps("billingContact.email")}
                    error={
                      touched?.billingContact?.email &&
                      errors?.billingContact?.email
                    }
                  />
                  <FormInput
                    maxLength={20}
                    label="Mobile Number"
                    {...getFieldProps("billingContact.phoneNumber")}
                    error={
                      touched?.billingContact?.phoneNumber &&
                      errors?.billingContact?.phoneNumber
                    }
                  />
                  <hr className={styles.divider} />
                  <FormInput
                    maxLength={15}
                    label="GSTIN"
                    {...getFieldProps("gstNo")}
                    error={touched?.gstNo && errors?.gstNo}
                  />
                  <div className={styles.dropzone}>
                    UPLOAD GST CERTIFICATE
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        setFieldValue("gstCertificate", acceptedFiles);
                        setGstFiles(acceptedFiles);
                      }}
                      multiple={false}
                      files={gstFiles}
                      handleText={"pdf"}
                      zoneSize={"small"}
                      accept={AcceptedFormat}
                    />
                  </div>
                  <hr className={styles.divider} />
                  <FormInput
                    maxLength={10}
                    label="PAN #"
                    {...getFieldProps("panCard")}
                    error={touched?.panCard && errors?.panCard}
                  />
                  <div className={styles.dropzone}>
                    UPLOAD PAN CARD
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        setFieldValue("panCardCertificate", acceptedFiles);
                        setPanFiles(acceptedFiles);
                      }}
                      multiple={false}
                      files={panFiles}
                      handleText={"pdf"}
                      zoneSize={"small"}
                      accept={AcceptedFormat}
                    />
                  </div>
                </div>
                <div className={styles.billingHead}>
                  <h3>Billing Address</h3>
                </div>
                <hr className={styles.cardDivider} />
                <div className={styles.formname}>
                  <div className={styles.middleCard}>
                    <FormInput
                      label="Address Line 1"
                      {...getFieldProps("billingAddress.addressLine1")}
                      error={
                        touched?.billingAddress?.addressLine1 &&
                        errors?.billingAddress?.addressLine1
                      }
                    />

                    <FormInput
                      label="Address Line 2"
                      {...getFieldProps("billingAddress.addressLine2")}
                      error={
                        touched?.billingAddress?.addressLine2 &&
                        errors?.billingAddress?.addressLine2
                      }
                    />
                    <FormInput
                      label="Pin Code"
                      {...getFieldProps("billingAddress.pinCode")}
                      error={
                        touched?.billingContact?.phoneNumber &&
                        errors?.billingContact?.phoneNumber
                      }
                    />

                    <CountryStateCityPicker
                      // isEdit={!!id}
                      country={values?.billingAddress?.country}
                      state={values?.billingAddress?.state}
                      city={values?.billingAddress?.city}
                      onCountyChange={(value) => {
                        setFieldValue("billingAddress.country", value);
                        setFieldValue("billingAddress.state", null);
                        setFieldValue("billingAddress.city", null);
                      }}
                      onStateChange={(value) => {
                        setFieldValue("billingAddress.state", value);
                        setFieldValue("billingAddress.city", null);
                      }}
                      onCityChange={(value) =>
                        setFieldValue("billingAddress.city", value)
                      }
                      countryError={
                        touched?.billingAddress?.country &&
                        errors?.billingAddress?.country
                      }
                      stateError={
                        touched?.billingAddress?.state &&
                        errors?.billingAddress?.state
                      }
                      cityError={
                        touched?.billingAddress?.city &&
                        errors?.billingAddress?.city
                      }
                    />
                  </div>
                </div>
                <hr className={styles.cardDivider} />
                <div className={styles.formname}>
                  <div className={styles.middleCard}>
                    <div className="d-flex justify-content-end align-items-end mt-4 mb-2">
                      <div></div>
                      {id && (
                        <LinkButton
                          onClick={() =>
                            navigate(
                              `${ROUTES.CLIENTS}${ROUTES.OVERVIEW}/${id}?tab=billing`
                            )
                          }
                        >
                          Back
                        </LinkButton>
                      )}

                      <PrimaryButton
                        type="submit"
                        label={loading ? "saving..." : id ? "Save" : "Save"}
                        disabled={loading || !dirty}
                        onClick={() => validationError(errors)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Billing;
