import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../../constants/assets";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import { IMAGE_BASE_URL } from "../../../../../constants/contants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { hasPermission } from "../../../../../utils/helper";
import { useSelector } from "react-redux";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../../constants/accessControl";
import ChangePassowrd from "../../../../../components/icons/changePassword";

const ITEM_HEIGHT = 48;

const CustomTable = ({
  addEditHanlder,
  open,
  handleClose,
  handleClick,
  anchorEl,
  list,
  openConfirmHanlder,
  status,
  filterHandler,
  filter,
  passowrdModalHandler,
}) => {
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [hasEditAccess, setHasEditAccess] = useState(false);
  const isSuperAdmin = useSelector((state) => state?.auth?.data?.superAdmin);

  useEffect(() => {
    setHasEditAccess(
      hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.WRITE
      )
    );
    setHasDeleteAccess(
      hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.DELETE
      )
    );
  }, []);

  return (
    <>
      <div className={`${styles.tableHeading} ${styles.tableHeadingFont}`}>
        <div onClick={filterHandler} className={styles.tableHeadingDataName}>
          Name
          {filter === 2 ? (
            <ArrowDownwardIcon className={styles.arrowStyle} />
          ) : (
            <ArrowUpwardIcon className={styles.arrowStyle} />
          )}
        </div>
        <div className={styles.tableHeadingSpace}></div>
        <div className={styles.tableHeadingDataSlug}>Role</div>
        <div className={styles.tableHeadingSpace}></div>
        {/* <div className={styles.tableHeadingDataCreated}>Last Active</div> */}
        <div className={styles.tableHeadingDataStatus}>Status</div>
        <div className={styles.tableHeadingDataActions}>Actions</div>
      </div>
      <hr className={styles.tableDivider} />
      <div className={styles.tableDataOuterContainer}>
        {list?.map((data) => (
          <>
            <div
              className={`${styles.tableDataContainer} ${styles.TableDataFontStyle}`}
            >
              <div className={`${styles.tableDataNameSize} `}>
                <div className={styles.commenttext}>
                  {data?.imageUrl?.original ? (
                    <img
                      alt="head"
                      src={IMAGE_BASE_URL + data?.imageUrl?.original}
                      className={styles.adminThumImage}
                    />
                  ) : (
                    <h5
                      className={styles.adminInitail}
                    >{`${data?.name?.[0]}${data?.lastName?.[0]}`}</h5>
                  )}
                </div>
                <div>
                  <div
                    className={styles.nameStyles}
                  >{`${data?.name} ${data?.lastName}`}</div>
                  <div className={styles.emailStyle}>{data?.email}</div>
                </div>
              </div>
              <div className={styles.tableDataSpace}></div>
              <div className={`${styles.tableDataSlugSize} `}>
                {data?.talentLabRole}
              </div>
              <div className={styles.tableDataSpace}></div>
              {/* <div className={`${styles.tableDataCreatedSize} `}>
                {moment(data?.lastSeen)?.fromNow?.()}
              </div> */}
              <div className={`${styles.tableDataStatusSize} `}>
                <div
                  className={
                    data?.isBlocked ? styles.blackDot : styles.greenDot
                  }
                ></div>
                {data?.isBlocked ? "Blocked" : "Active"}
              </div>

              <div className={styles.tableDataActionsSize}>
                {(hasDeleteAccess || hasEditAccess) && (
                  <Tooltip title="Menu">
                    <IconButton
                      aria-label="menu"
                      onClick={(event) => {
                        handleClick(event, data);
                      }}
                    >
                      <MoreVertIcon
                        sx={{ transform: "rotate(90deg)" }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
            <hr className={styles.cardDivider} />
          </>
        ))}
      </div>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        sx={{
          "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
            "&:hover": {
              backgroundColor: "#e7e9fe",
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {hasEditAccess && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={(handleClose, addEditHanlder)}
          >
            <img
              alt="edit"
              src={
                hasEditAccess
                  ? IMAGES.TABLE_MENU_EDIT
                  : IMAGES.TABLE_DISABLE_EDIT
              }
              className={styles.iconstyle}
            />
            Edit
          </MenuItem>
        )}

        {hasEditAccess && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={() => {
              handleClose();
              openConfirmHanlder("block");
            }}
          >
            <img
              alt="deleet"
              src={status ? IMAGES.TABLE_MENU_UNBLOCK : IMAGES.TABLE_MENU_BLOCK}
              className={styles.iconstyle}
            />
            {status ? "Unblock" : "Block"}
          </MenuItem>
        )}
        {hasDeleteAccess && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={() => {
              handleClose();
              openConfirmHanlder("delete");
            }}
          >
            <img
              alt="delete"
              src={IMAGES.TABLE_MENU_DELETE}
              className={styles.iconstyle}
            />
            Delete
          </MenuItem>
        )}

        {isSuperAdmin && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={() => {
              handleClose();
              passowrdModalHandler("changePassword");
            }}
          >
            <div style={{ marginRight: "8px" }}>
              <ChangePassowrd />
            </div>
            Reset Password
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
export default CustomTable;
