import React, { useState, useEffect } from "react";
import Skeleton from "../../../../components/skeleton";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMessage from "../../../../components/layout/toast";
import { DELETE_MODAL_CONFIRMATION } from "../../../../constants/contants";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../../constants/accessControl";
import { isSuperAdmin, ristrictAccess } from "../../../../utils/helper";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { hasPermission } from "../../../../utils/helper";
import ConfirmationModal from "components/modal/confirmationModal";
import { APIS } from "api/endpoints";
import { addEditApi, listApi } from "api/commonApis";
import ClientHeader from "components/pageHeader/clientHeader";
import DetailBlock from "./detailBlock";

const BillingDetails = ({ client, setRefresh, refresh }) => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [type, setType] = useState("");
  const [deleteDocumentId, setDeleteDocumentId] = useState();
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [billingList, setBillingList] = useState([]);
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  /////// GETTING PERMISSION FOR SAME COMPONENT/////////

  useEffect(() => {
    setHasWriteAccess(
      hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.BILLING,
        PERMISSIONS.WRITE
      )
    );
    setHasDeleteAccess(
      hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.BILLING,
        PERMISSIONS.DELETE
      )
    );
    setIsUserSuperAdmin(isSuperAdmin());
  }, []);

  /*MODAL SETUP*/

  const deleteHandleOpen = (e, id, type) => {
    setOpen(true);
    setType(type);
    setDeleteDocumentId(id);
  };
  const deleteHandleClose = () => {
    setOpen(false);
    setType("");
    setDeleteDocumentId("");
  };

  /////// HANDLER FOR DELETING  DOCUMENTS///////

  const handleDeleteDocument = () => {
    let payload = {
      clientId: client?._id,
    };
    if (type) {
      payload.type = type;
      payload.documentId = deleteDocumentId;
    } else {
      payload.billingId = deleteDocumentId;
    }

    addEditApi(APIS.DELETE_CLIENT_DATA, { ...payload }).then((result) => {
      setRefresh(!refresh);
      deleteHandleClose();
      toast.success(
        <ToastMessage
          title={"Success"}
          message={
            type ? TOAST_MESSAGE.DELETE_DOCUMENT : TOAST_MESSAGE.RECORD_DOCUMENT
          }
        />
      );
    });
  };

  /*Api Handling for GST and Pan */

  useEffect(() => {
    listApi(APIS.LIST_CLIENT_BILLING, {
      clientId: id,
    }).then((result) => {
      setBillingList(result?.data?.data);
    });
  }, [refresh]);

  /*Return */

  return (
    <>
      <div
        className={` ${styles.containerInner}
        ${ristrictAccess() ? styles.ristricContainer : styles.talentdetails}
     `}
      >
        {!client?.name ? (
          <Skeleton height="300px" marginBottom="30px" />
        ) : (
          <>
            <ClientHeader
              billingList={billingList}
              client={client}
              hasWriteAccess={hasWriteAccess}
              id={id}
              currentLocation={window.location.href}
              billingPage={true}
            />
            {!!billingList?.length > 0 ? (
              billingList?.map((record, index, arr) => {
                return (
                  <DetailBlock
                    record={record}
                    clientId={id}
                    deleteClick={deleteHandleOpen}
                    hasDeleteAccess={hasDeleteAccess}
                    isUserSuperAdmin={isUserSuperAdmin}
                  />
                );
              })
            ) : (
              <div className={styles.noDataFound}>
                No billing details exist. Click on the + icon to add billing
                details.
              </div>
            )}
          </>
        )}
      </div>

      <ConfirmationModal
        title={DELETE_MODAL_CONFIRMATION}
        open={open}
        closeModal={deleteHandleClose}
        onChangeHanlder={handleDeleteDocument}
        data={type ? "this document" : "this record"}
        description=""
      />
    </>
  );
};

export default BillingDetails;
