import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../../constants/assets";

const SearchBar = ({ onChange, value }) => {
  return (
    <div className={styles.middleCard}>
      <div className={styles.searchInputContainer}>
        <input
          type="text"
          className={styles.searchInputDataSets}
          placeholder="Search"
          onChange={onChange}
          value={value}
        />
        <img
          alt="search"
          src={IMAGES.SEARCH_ICON}
          className={styles.searchIcon}
        />
      </div>
    </div>
  );
};

export default SearchBar;
