import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "router/routes";
import { IMAGES } from "constants/assets";
import { headerPermission, hasSearchAccess } from "utils/helper";
import { MODULES } from "constants/accessControl";

const HeaderLink = ({
  isMenuActive,
  setOpenModal,
  activetab,
  isAssigActive,
  onAssigmentNavLinkClick,
  DropDownMenu,
  openModal,
}) => {
  const [hasAssingmentAccess, setHasAssingmentAccess] = useState(false);

  useEffect(() => {
    setHasAssingmentAccess(headerPermission(MODULES.ASSIGNMENT));
  }, []);

  //// HEADER MENU//////

  const TOP_NAVBAR_MENU = [
    {
      title: "Overview",
      to: ROUTES.OVERVIEW,
      icon: IMAGES.OVERVIEW,
      hasAccess: true,
    },

    {
      title: "Search",
      to: ROUTES.SEARCH,
      icon: IMAGES.SEARCH,
      hasAccess: hasSearchAccess(),
    },
    {
      title: "Talent",
      to: ROUTES.TALENT,
      icon: IMAGES.USER,
      hasAccess: headerPermission(MODULES.TALENT),
    },
    {
      title: "Organizations",
      to: ROUTES.CLIENTS,
      icon: IMAGES.CLIENTS,
      hasAccess: headerPermission(MODULES.ORGANIZATIONS),
    },
  ];

  ////// RETURN ///////

  return (
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <div className=" me-auto ms-auto">
        <ul className={`navbar-nav ${styles.navbarnav}`}>
          {TOP_NAVBAR_MENU.map(
            (menu, index) =>
              menu?.hasAccess && (
                <li class="nav-item" key={index}>
                  <Link
                    className={`nav-link ${
                      isMenuActive(menu?.to) ? `active ${styles.active}` : ""
                    } ${styles.navlink} `}
                    aria-current="page"
                    to={menu.to}
                  >
                    <img
                      src={menu.icon}
                      className={styles.navBarIcon}
                      alt="icon"
                    />
                    {menu.title}
                  </Link>
                </li>
              )
          )}

          {hasAssingmentAccess && (
            <div
              className={styles.linkContainer}
              onMouseEnter={() => setOpenModal(true)}
              onMouseLeave={() => setOpenModal(false)}
            >
              <li
                className={`ms-2 ${styles.navlink} ${
                  styles.assignmentNavLink
                } ${activetab ? styles.active : ""} ${
                  isAssigActive ? styles.active : ""
                }`}
                onClick={onAssigmentNavLinkClick}
              >
                <img
                  src={IMAGES.ASSIGNMENTS}
                  className={styles.navBarIcon}
                  alt="Assignment Icon"
                />
                Assignments
                <DropDownMenu openModal={openModal} />
              </li>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default HeaderLink;
