import React, { useState, useRef } from "react";
import ReminderModal from "../../../../../components/modal/reminderModal";
import AssignmentStatusBox from "./assignmentStatusBox";
import CalenderBox from "./calenderBox";
import styles from "./styles.module.scss";

const StatusToggle = ({ isArchive, activeTabType }) => {
  const [reminderAnchorEl, setReminderAnchorEl] = useState(null);
  const [openRminderBackDrop, setOpenReminderBackDrop] = useState(false);
  const [reminderActive, setReminderActive] = useState(false);
  const openReminder = Boolean(reminderAnchorEl);

  const [aria, setAria] = useState("");
  const divRef = useRef();

  const openReminderModal = (event) => {
    setReminderActive(!reminderActive);
    setAria("reminder-popover");
    if (!reminderActive) {
      setReminderAnchorEl(divRef.current);
      setOpenReminderBackDrop(!openReminder);
    } else {
      setReminderAnchorEl(null);
      setOpenReminderBackDrop(false);
    }
  };
  /**
   * Reminder close modal
   */
  const closeReminderModal = () => {
    setReminderAnchorEl(null);
    setOpenReminderBackDrop(false);
    setReminderActive(!reminderActive);
  };

  return (
    <>
      <div className={styles.upperDiv} ref={divRef}>
        <div className={styles.leftContainer}>
          <AssignmentStatusBox isArchive={isArchive} />
        </div>
        <div className={styles.rightContainer}>
          <CalenderBox active={reminderActive} openModal={openReminderModal} />
        </div>
      </div>

      <ReminderModal
        activeTabType={activeTabType}
        open={openReminder}
        openBackDrop={openRminderBackDrop}
        anchorEl={reminderAnchorEl}
        closeModal={closeReminderModal}
        idd={aria}
      />
    </>
  );
};

export default StatusToggle;
