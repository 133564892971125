import React, { useEffect, useState } from "react";
import styles from "../sidebar/styles.module.scss";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../../router/routes";
import {
  hasPermission,
  ristrictAccess,
  sidePanelAccess,
} from "../../../utils/helper";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../constants/accessControl";
import ToastMessage from "../../../components/layout/toast";
import { toast } from "react-toastify";
import { TOAST_MESSAGE } from "constants/toastMessage";

const Sidebar = () => {
  const { pathname } = useLocation();
  const [showPanel, setShowPanel] = useState(false);

  /////// MENU OPTIONS/////////
  const MENU = [
    {
      href: `${ROUTES.TALENT}/${ROUTES.PROFILE}`,
      label: (
        <span>
          Add
          <br /> Talent
        </span>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          className="iconify iconify--ic"
          width="32"
          height="32"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M12 2a9 9 0 0 0-9 9c0 4.17 2.84 7.67 6.69 8.69L12 22l2.31-2.31C18.16 18.67 21 15.17 21 11a9 9 0 0 0-9-9zm0 2c1.66 0 3 1.34 3 3s-1.34 3-3 3s-3-1.34-3-3s1.34-3 3-3zm0 14.3a7.2 7.2 0 0 1-6-3.22c.03-1.99 4-3.08 6-3.08c1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 0 1-6 3.22z"
          ></path>
        </svg>
      ),
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.TALENT_OVERVIEW,
        PERMISSIONS.WRITE
      ),
    },
    {
      href: `${ROUTES.CLIENTS}/${ROUTES.CLIENT_DETAIL}`,
      label: (
        <span>
          Add <br /> Organization
        </span>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          className="iconify iconify--ic"
          width="32"
          height="32"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M12 7V3H2v18h20V7H12zm-2 12H4v-2h6v2zm0-4H4v-2h6v2zm0-4H4V9h6v2zm0-4H4V5h6v2zm10 12h-8V9h8v10zm-2-8h-4v2h4v-2zm0 4h-4v2h4v-2z"
          ></path>
        </svg>
      ),
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.ORGANIZATION_OVERVIEW,
        PERMISSIONS.WRITE
      ),
    },

    {
      href: `${ROUTES.ASSIGNMENTS}/${ROUTES.ADD_ASSIGNMENT}`,
      label: (
        <span>
          Add <br /> Assignment
        </span>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          className="iconify iconify--ic"
          width="32"
          height="32"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M21 3h-6.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H3v18h18V3zm-9 0c.55 0 1 .45 1 1s-.45 1-1 1s-1-.45-1-1s.45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"
          ></path>
        </svg>
      ),
      hasAccess: hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_OVERVIEW,
        PERMISSIONS.WRITE
      ),
    },
  ];

  ////// USE EFFECT USE TO CHECK WHERE THERE IS NEED TO SHOW THW PANEL OR NOT///

  useEffect(() => {
    setShowPanel(sidePanelAccess());
  }, []);

  return (
    <section
      className={
        ristrictAccess()
          ? styles.hideSideBar
          : !showPanel
          ? styles.hideSideBar
          : styles.sidebar
      }
    >
      <ul className={styles.quickAccessBar}>
        {MENU.map(
          (menu, index) =>
            menu?.hasAccess && (
              <>
                <li
                  className={`${`q-${index + 1}`} ${
                    pathname === menu.href && styles.active
                  }`}
                >
                  <Link to={menu?.hasAccess ? menu.href : "#"}>
                    {menu.icon}
                    {menu.label}
                  </Link>

                  <span className={styles.plusIcon}>+</span>
                </li>
              </>
            )
        )}
      </ul>
    </section>
  );
};

export default Sidebar;

// className={styles.plusIcon}
