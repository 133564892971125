import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  Outlet,
  useLocation,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ROUTES } from "../../../router/routes";
import SideMenu from "../../../components/layout/sideMenu";
import { ristrictAccess } from "utils/helper";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../constants/accessControl";
import { hasPermission, sidePanelAccess } from "utils/helper";

const AddEditClient = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [params] = useSearchParams();
  const source = params.get("source");
  const navigate = useNavigate();
  const [isSidePanel, setIsSidePanel] = useState(false);

  //////////SIDE MENU FOR EDIT CASE IN CLINET/////

  const TAB_MENU = [
    {
      label: "Overview",
      href: ROUTES.CLIENT_DETAIL,
      tabName: "overview",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.ORGANIZATION_OVERVIEW,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Assignments",
      href: "assignments",
      tabName: "assignments",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.ASSIGNMENTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Point of Contacts",
      tabName: "contacts",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.POINT_OF_CONTACTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Contracts",
      tabName: "contracts",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.CONTRACTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Invoices",
      tabName: "invoices",
      hasAccess: true,
    },
    {
      label: "Billing",
      href: "billing",
      tabName: "billing",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.BILLING,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Client History",
      tabName: "history",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.CLIENT_HISTORY,
        PERMISSIONS.READ
      ),
    },
  ];

  //////////SIDE MENU FOR EDIT CASE IN CLINET IN RISTRICT CASE/////

  const RISTRCIT_MENU = [
    {
      label: "Overview",
      href: ROUTES.CLIENT_DETAIL,
      tabName: "overview",
      hasAccess: true,
    },
    {
      label: "Billing",
      href: "billing",
      tabName: "billing",
      hasAccess: true,
    },
    {
      label: "Contracts",
      href: "contracts",
      tabName: "contracts",
      hasAccess: true,
    },
    {
      label: "Invoices",
      href: "invoices",
      tabName: "invoices",
      hasAccess: true,
    },
  ];

  const getLink = (menu) => {
    if (source && menu.tabName) {
      return `/clients/overview/${id}?tab=${menu.tabName}`;
    } else if (source) {
      return `${menu.href}${id ? `/${id}` : ""}?source=overview`;
    } else {
      return `${menu.href}${id ? `/${id}` : ""}`;
    }
  };

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  ////// RETURN ////////

  return (
    <section
      className={
        ristrictAccess() || !isSidePanel
          ? styles.ristrictContainer
          : styles.overview
      }
    >
      <div className="container">
        <div className="row">
          {!id && (
            <div className="col-md-12 mb-4">
              <div className={styles.heading}>
                <h2>Add a new organization</h2>
              </div>
            </div>
          )}{" "}
          <div className="col-md-12">
            <div className="d-flex align-items-start">
              {id && (
                <SideMenu
                  items={ristrictAccess() ? RISTRCIT_MENU : TAB_MENU}
                  onClick={(menu) => navigate(getLink(menu))}
                  isActive={(menu) => pathname?.includes(menu.href)}
                />
              )}

              <div
                className={`tab-content 
                ${styles.innerContainer} ${
                  ristrictAccess()
                    ? styles.ristrictInnerContainer
                    : styles.tabcontent
                }`}
              >
                <div className="fade show active">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddEditClient;
