import React, { useState } from "react";
import styles from "./styles.module.scss";
import Popover from "@mui/material/Popover";
import { Backdrop } from "@mui/material";
import GoogleAuth from "./googleAuth";
import ReminderForm from "./reminderForm";
import { useSelector } from "react-redux";

const ReminderModal = ({
  openBackDrop,
  closeModal,
  open,
  idd,
  anchorEl,
  activeTabType,
}) => {
  const loggedInData = useSelector((state) => state?.auth?.data);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);

  const setShowCalendar = () => {
    setOpenCalendar(true);
  };
  return (
    <Backdrop
      open={openBackDrop}
      onClick={() => {
        if (openCalendar === true) {
          setOpenCalendar(false);
        }
      }}
    >
      <Popover
        id={idd}
        open={open}
        anchorEl={anchorEl}
        sx={{
          "&	.MuiPopover-paper": {
            width: "22.5%",
            "@media ((min-width: 1366px) and (max-width: 1366px))": {
              width: "20%!important",
              left: "1002px!important",
            },
            "@media ((min-width: 1400px) and (max-width: 1400px))": {
              width: "22.2%!important",
              left: "1023px!important",
            },

            marginTop: "12px",
            left: 5,
            maxWidth: "345px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={closeModal}
      >
        <div className={styles.popoverContainer}>
          <div className={styles.googleAuthContainer}>
            {!loggedInData?.googleId && (
              <h5 className={styles.authHeading}>
                Please share the access to Google Calender for Reminder.
              </h5>
            )}
            {loggedInData?.googleId ? (
              <ReminderForm
                activeTabType={activeTabType}
                closeModal={closeModal}
                setShowCalendar={setShowCalendar}
                openCalendar={openCalendar}
                setOpenTimePicker={setOpenTimePicker}
                openTimePicker={openTimePicker}
              />
            ) : (
              <div className={styles.googleAuth}>
                <GoogleAuth />
              </div>
            )}
          </div>
        </div>
      </Popover>
    </Backdrop>
  );
};
export default ReminderModal;
