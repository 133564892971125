import React, { useEffect, useState } from "react";
import { addEditApi } from "../../../../../../api/commonApis";
import { APIS } from "../../../../../../api/endpoints";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Popover from "@mui/material/Popover";
import { Backdrop } from "@mui/material";
import OffLimitSelect from "./offLimitSelection";
import RevokeUpdateLimit from "./revokeAndUpdate";
import RequestOffLimit from "./requestOffLimit";
import ToastMessage from "../../../../../../components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";

const OffLimitModal = ({
  talent,
  closeModal,
  openBackDrop,
  idd,
  open,
  anchorEl,
}) => {
  const { id } = useParams();
  const [auth, setAuth] = useState(false);
  const [offlimit, setOfflimit] = useState(talent?.offLimitDays || 10);
  const data = useSelector((state) => state.auth.data);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  useEffect(() => {
    if (!talent?.offLimitBy) {
      setAuth("setlimit");
    } else if (talent?.offLimitBy) {
      if (talent?.offLimitBy === data?._id) {
        setAuth("updatelimit");
      } else {
        setAuth("requestlimit");
      }
    }
  }, [talent]);

  const yesOfflimitButtonHandler = () => {
    let data = {
      offLimitDays: offlimit,
      talentId: id,
    };

    addEditApi(APIS.ADD_EDIT_TALENT_PROFILE, data).then((response) => {
      closeModal();
      toast.success(
        <ToastMessage title={"Success"} message={TOAST_MESSAGE.SET_OFF_LIMIT} />
      );
    });
  };

  const revokeOffLimitButton = () => {
    let data = {
      revokeOffLimit: true,
      talentId: id,
    };

    addEditApi(APIS.ADD_EDIT_TALENT_PROFILE, data).then((response) => {
      closeModal();
      toast.success(
        <ToastMessage
          title={"Success"}
          message={TOAST_MESSAGE.REVOKE_OFF_LIMIT}
        />
      );
    });
  };

  const updatePeriodButton = () => {
    let data = {
      offLimitDays: offlimit,
      talentId: id,
    };
    addEditApi(APIS.ADD_EDIT_TALENT_PROFILE, data).then((response) => {
      closeModal();
      toast.success(
        <ToastMessage
          title={"Success"}
          message={TOAST_MESSAGE.UPDATE_OFF_LIMIT}
        />
      );
    });
  };

  const requestOfLimit = () => {
    let data = {
      requestRemovalOffLimit: true,
      talentId: id,
    };
    addEditApi(APIS.ADD_EDIT_TALENT_PROFILE, data).then((response) => {
      closeModal();
      toast.success(
        <ToastMessage title={"Success"} message={TOAST_MESSAGE.REQUEST} />
      );
    });
  };
  return (
    <>
      <Backdrop open={openBackDrop}>
        <Popover
          id={idd}
          open={open}
          anchorEl={anchorEl}
          sx={{
            "&	.MuiPopover-paper": {
              width: "22.5%",
              marginTop: "12px",
              left: 5,
              maxWidth: "345px",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={closeModal}
        >
          {auth === "setlimit" && (
            <OffLimitSelect
              setofflimit={setOfflimit}
              closeButton={closeModal}
              yesButtonClick={() => yesOfflimitButtonHandler()}
            />
          )}
          {auth === "updatelimit" && (
            <RevokeUpdateLimit
              talentData={talent}
              offLimitDays={talent?.offLimitDays}
              setofflimit={setOfflimit}
              revokeofflimitButton={() => revokeOffLimitButton()}
              updateperiodButton={() => updatePeriodButton()}
            />
          )}
          {auth === "requestlimit" && (
            <RequestOffLimit
              talentData={talent}
              closeButton={closeModal}
              requestoflimit={() => requestOfLimit()}
            />
          )}
        </Popover>
      </Backdrop>
    </>
  );
};
export default OffLimitModal;
