import React from "react";
import styles from "./styles.module.scss";
import IconComponent from "components/iconComponent";
import { IMAGE_BASE_URL } from "constants/contants";
import { copyToClipboard } from "utils/helper";
import { toast } from "react-toastify";
import ToastMessage from "components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { useNavigate } from "react-router-dom";
import ProfileAvatar from "components/Avatar/profileAvatar";
import SociaHandlerRow from "components/buttons/socialHandlerRow";
import { getState } from "redux/store";

const TalentHeader = ({
  hasWriteAccess,
  currentLocation,
  talent,
  id,
  handleOpenConfirmation,
}) => {
  const navigate = useNavigate();
  ///COPY URL HANDLER/////

  const user = getState()?.auth?.data;

  const copyProfileUrl = () => {
    copyToClipboard(currentLocation);
    toast.success(
      <ToastMessage title={"Success"} message={TOAST_MESSAGE.COPY_LINK} />
    );
  };

  ///////// ON ADD EDIT HANLDER////////

  const onEditClickHandler = () => {
    navigate(`/talent/profile/${id}?source=overview`);
  };

  return (
    <div className={styles.headContainer}>
      <div className={styles.middleContainer}>
        {talent?.imageUrl?.original ? (
          <img
            alt="Head"
            src={IMAGE_BASE_URL + talent?.imageUrl?.original}
            className={styles.talentUserImage}
          />
        ) : (
          <ProfileAvatar
            firstLabel={talent?.firstName?.charAt(0)}
            secondLabel={talent?.lastName?.charAt(0)}
          />
        )}

        <div className={`${styles.detailscontent}`}>
          <div className={styles.detailsheading}>
            <h2>{`${talent?.firstName} ${
              talent?.middleName ? talent?.middleName : ""
            } ${talent?.lastName}`}</h2>
            {(talent?.recentWork?.designation ||
              talent?.recentWork?.companyName) && (
              <div className={styles.workStyle}>
                <p className={styles.jobStyle}>
                  {talent?.recentWork?.designation
                    ? `${talent?.recentWork?.designation} | `
                    : " "}

                  <span>
                    &nbsp;
                    {talent?.recentWork?.companyName
                      ? `${talent?.recentWork?.companyName}`
                      : ""}
                  </span>
                </p>
              </div>
            )}

            <p className="mt-2">
              {talent?.city ? (
                <div className={styles.cityHeading}>{`${talent?.city},`}</div>
              ) : (
                ""
              )}
              {`${talent?.state ? `${talent?.state},` : ""} ${
                talent?.country ? talent?.country : ""
              }`}
            </p>
            <SociaHandlerRow data={talent} />
          </div>
        </div>
      </div>

      <div className={styles.rightConatainer}>
        <IconComponent
          handleOpenConfirmation={handleOpenConfirmation}
          talentHeader
          hasWriteAccess={hasWriteAccess}
          copyProfileUrl={copyProfileUrl}
          onEditClickHandler={onEditClickHandler}
          mailTOEmaillink={`mailto:?subject=TalentLab - Talent - ${talent?.firstName} ${talent?.lastName}&body=Link: ${currentLocation}`}
        />
      </div>
    </div>
  );
};

export default TalentHeader;
