import React from "react";
import styles from "./styles.module.scss";
import { ROUTES } from "../../../router/routes";
import ContactBlock from "./contactBlock";
import LocationBlock from "./locationBlock";
import AssignmentBlock from "./assignmentBlock";
import IntroBlock from "./introBlock";

const ClinetRow = ({ data }) => {
  return (
    <div
      className={styles.clientRow}
      onClick={() =>
        window.open(
          `${ROUTES.CLIENTS}${ROUTES.OVERVIEW}/${data?._id}?tab=overview`,
          "_blank"
        )
      }
    >
      <IntroBlock data={data} />
      <div className={styles.details}>
        <LocationBlock data={data} />
        {(data?.contactPerson ||
          data?.contactPersonDesignation ||
          data?.contactPersonEmail ||
          data?.contactPersonPhoneNumber) && <ContactBlock data={data} />}
      </div>
      <AssignmentBlock data={data} />
      <div className={styles.management}>
        {(data?.director?.name || data?.director?.lastName) && (
          <div>
            <h4 className={styles.director}>Account Owner</h4>
            <p className={styles.directorName}>
              {data?.director?.name} {data?.director?.lastName}
            </p>
          </div>
        )}
        {!!data?.manager?.length > 0 && (
          <div className={styles.manager}>
            <h4 className={styles.manager}>Account Director</h4>
            {data?.manager?.map((data) => (
              <p className={styles.managerName}>
                {data.name} {data.lastName}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default ClinetRow;
