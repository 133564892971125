import React from "react";
import styles from "./styles.module.scss";

const SummaryBlock = ({ client }) => {
  return (
    <>
      <div className={styles.summary}>
        <h3 className={styles.summaryHead}>Organization Summary</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: client?.summary,
          }}
          className={styles.summaryDetail}
        ></div>
      </div>
      <hr className={styles.assignmentDivider} />
    </>
  );
};

export default SummaryBlock;
