import React from "react";
import styles from "./styles.module.scss";
import FilterSelect from "../../filters/filterSelect";
import FilterButton from "components/buttons/filterButton";
import { compentancyOptions } from "constants/contants";
import FilterSliderSelect from "components/filters/filterSliderSelect";
import Checkbox from "components/forms/checkbox";

const TalentFilter = ({
  onChange,
  appliedFilters,
  onReset,
  isApplied,
  filterData,
  onFilterChange,
  debouncedResults,
  handleChange,
  state,
  city,
  country,
}) => {
  return (
    <section>
      <div className="row mt-4">
        <div className="col-md-12 ">
          <div className={`d-flex  gap-2 ${styles.selectboxes}`}>
            <div className={styles.select}>
              <FilterSelect
                isMulti
                experienceFilter
                name="experience"
                placeholder="experience"
                onChange={onFilterChange}
                value={appliedFilters?.experience}
                options={filterData?.experience}
              />
            </div>

            <div className={styles.select}>
              <FilterSelect
                isMulti
                name="locations"
                locationFilter
                state={state}
                city={city}
                country={country}
                isSearchAble
                searchPlaceholder="Search for locations"
                placeholder="Location"
                options={filterData?.locations}
                value={appliedFilters?.locations}
                onChange={onFilterChange}
              />
            </div>

            <div className={styles.select}>
              <FilterSelect
                isMulti
                isSearchAble
                name="sectors"
                searchPlaceholder="Search for industries"
                placeholder="Industry Type"
                value={appliedFilters?.sectors}
                options={filterData?.sectors}
                onChange={onFilterChange}
                onChangeText={debouncedResults}
              />
            </div>

            <div className={styles.select}>
              <FilterSelect
                isMulti
                isSearchAble
                name="functionalAreas"
                placeholder="Functional Areas"
                searchPlaceholder="Search for functional areas"
                value={appliedFilters?.functionalAreas}
                options={filterData?.functionalAreas}
                onChange={onFilterChange}
                onChangeText={debouncedResults}
              />
            </div>

            <div className={styles.select}>
              <FilterSelect
                isMulti
                isSearchAble
                name="educations"
                placeholder="Education Qual."
                searchPlaceholder="Search for education qual."
                value={appliedFilters?.educations}
                options={filterData?.educations}
                onChange={onFilterChange}
                onChangeText={debouncedResults}
              />
            </div>

            {/* <div className={styles.select}>
          <FilterSliderSelect
            dataLabel={2}
            startEndData={filterData?.compensation}
            name="compensation"
            handleChange={handleChange}
            label="Compensation"
            placeholder="Compensation"
            defaultValue={appliedFilters?.compensation}
          />
        </div> */}

            <div className={styles.select}>
              <FilterSelect
                isMulti
                competencyFilter
                name="competency"
                placeholder="Competency"
                onChange={onFilterChange}
                value={appliedFilters?.competency}
                options={compentancyOptions}
                onChangeText={debouncedResults}
              />
            </div>
            <div className={styles.select}>
              <FilterSelect
                isMulti
                isSearchAble
                name="designation"
                onChange={onFilterChange}
                searchPlaceholder="Search for designation"
                placeholder="Designation"
                value={appliedFilters?.designation}
                options={filterData?.designation}
                onChangeText={debouncedResults}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mt-3 col-md-12 d-flex justify-content-between ${styles.topBorder}`}
      >
        <div className={`d-flex align-items-center ${styles.selectboxesinput}`}>
          <div
            className={`pe-4 me-4 d-flex ${styles.selectinput} ${styles.selectborder} `}
          >
            <label className="me-3">Willing to relocate</label>
            <label class={styles.switch}>
              <input
                type="checkbox"
                onChange={(e) =>
                  onChange((prev) => ({
                    ...prev,
                    relocate: e?.target?.checked,
                  }))
                }
                checked={appliedFilters?.relocate}
              />
              <span
                className={`slider round ${styles.checkHoverEffect} ${styles.slider} ${styles.round}`}
              ></span>
            </label>
          </div>
          <div
            className={`pe-4 me-2  ${styles.selectinput} ${styles.marginStyle} `}
          >
            <Checkbox
              id="1"
              label={"Male"}
              checked={appliedFilters?.gender?.includes(1)}
              onClick={(e) => onFilterChange("gender", { value: 1 }, true)}
            />
          </div>
          <div
            className={`pe-4 me-2 ${styles.selectinput} ${styles.marginStyle} `}
          >
            <Checkbox
              id="2"
              label={"Female"}
              checked={appliedFilters?.gender?.includes(2)}
              onClick={(e) => onFilterChange("gender", { value: 2 }, true)}
            />
          </div>
        </div>
        <div className={styles.resetButtonContainer}>
          <FilterButton filterReset onClick={onReset} disabled={!isApplied} />
        </div>
      </div>
    </section>
  );
};

export default TalentFilter;
