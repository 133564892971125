import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { listApi, addEditApi } from "../../../api/commonApis";
import ToastMessage from "../../../components/layout/toast";
import { toast } from "react-toastify";
import { APIS } from "../../../api/endpoints";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Work from "./work";
import Education from "./education";
import Compensation from "./compensation";
import Documents from "./documents";
import CertsAndPublication from "./certsAndPublications";
import Overview from "./overview";
import { getTalentStatus } from "../../../utils/helper";
import SideMenu from "../../../components/layout/sideMenu";
import CommentsSection from "../../../components/commentSection";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../constants/accessControl";
import {
  hasPermission,
  sidePanelAccess,
  ristrictAccess,
} from "../../../utils/helper";
import {
  COMMENT_TYPE,
  DELETE_MODAL_CONFIRMATION,
} from "../../../constants/contants";
import Settings from "./settings";
import ConfirmationModal from "../../../components/modal/confirmationModal";
import LightButton from "../../../components/buttons/lightButton";
import AssingnmentsExt from "pages/assignmentExt";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { getState } from "redux/store";
import ChangePassowrd from "components/icons/changePassword";
import ChangePassword from "./changepassword";
import CreateProfile from "components/createProfile";
import Shortcuts from "pages/profile/shortcuts";

const checkStatus = [2, 5];

const TalentOverview = () => {
  ////// MENU FOR SIDE MENU BAR FOR ALL

  const MENU = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Work",
      id: "work",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.WORK,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Education",
      id: "education",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.EDUCATION,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Compensation",
      id: "compensation",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.COMPENSATION,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Documents",
      id: "documents",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.DOCUMENTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Certifications & Publications",
      id: "certs",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.CERT_PUBLICATIONS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Competency Mapping",
      id: "compentancy",
      href: "/talent/compentancy-mapping",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.COMPENTENCY_MAPPING,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Candidate Dossier",
      id: "dossier",
      href: "/talent/candidate-dossier",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.CANDIDATE_DOSSIER,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Candidate History",
      id: "history",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.CANDIDATE_HISTORY,
        PERMISSIONS.READ
      ),
    },
  ];

  ////////////// SIDE MENU BAR FOR TALENT USER

  const RISTRICT_MENU = [
    { label: "Overview", id: "overview", hasAccess: true },
    { label: "Work", id: "work", hasAccess: true },
    {
      label: "Education",
      id: "education",
      hasAccess: true,
    },
    {
      label: "Compensation",
      id: "compensation",
      hasAccess: true,
    },
    { label: "Documents", id: "documents", hasAccess: true },
    {
      label: "Certifications & Publications",
      id: "certs",
      hasAccess: true,
    },
    { label: "Assignments", id: "assignments-ext", hasAccess: true },
    // { label: "Change Password", id: "change-password", hasAccess: true },
  ];

  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const { id } = useParams();
  const tab = params?.get?.("tab");
  const [talent, setTalent] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasCommentWriteAccess, setHasCommentWriteAccess] = useState(false);
  const [hasCommentDeleteAccess, setHasCommentDeleteAccess] = useState(false);
  const [comment_access, set_comment_access] = useState(false);
  const [isSidePanel, setIsSidePanel] = useState(false);

  ///// CHECK PERMISSION OR ACCESS//////

  useEffect(() => {
    const user = getState()?.auth?.data;
    set_comment_access(!user.selfSignUp);

    setHasWriteAccess(
      hasPermission(
        MODULES.TALENT,
        SUB_MODULES.TALENT_OVERVIEW,
        PERMISSIONS.WRITE
      )
    );
    setHasDeleteAccess(
      hasPermission(
        MODULES.TALENT,
        SUB_MODULES.TALENT_OVERVIEW,
        PERMISSIONS.DELETE
      )
    );
    setHasCommentWriteAccess(
      hasPermission(
        MODULES.TALENT,
        SUB_MODULES.CANDIDATE_HISTORY,
        PERMISSIONS.WRITE
      )
    );
    setHasCommentWriteAccess(
      hasPermission(
        MODULES.TALENT,
        SUB_MODULES.CANDIDATE_HISTORY,
        PERMISSIONS.WRITE
      )
    );
    setHasCommentDeleteAccess(
      hasPermission(
        MODULES.TALENT,
        SUB_MODULES.CANDIDATE_HISTORY,
        PERMISSIONS.DELETE
      )
    );
  }, []);

  if (!tab) {
    navigate(`/talent/overview/${id}?tab=overview`, { replace: true });
  }

  ///////// LIST TALNET API FOR USER DATA /////

  useEffect(() => {
    setLoading(true);
    listApi(APIS.LIST_TALENT_OVERVIEW, { talentId: id })
      .then((result) => {
        setTalent(result?.data[0]);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  /////// DELETE CANDIDATE AHNDLER///////

  const handlerDeleteCandidate = () => {
    if (ristrictAccess()) {
      addEditApi(APIS.REQUEST_DELETION, { type: 1 }).then((result) => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.DELETE_REQUEST}
          />
        );
        handleClose();
      });
      handleClose();
    } else {
      addEditApi(APIS.DELETE_DOCUMENT_DATA, { type: 4, id: id }).then(
        (result) => {
          handleClose();
          toast.success(
            <ToastMessage
              title={"Success"}
              message={TOAST_MESSAGE.DELETE_CANDIDATE}
            />
          );
          navigate(`/talent`);
        }
      );
    }
  };

  //////////// RANDER FUNCTION THAT WILL RENDER WITH THERE PAGES////////

  const renderTab = () => {
    switch (tab) {
      case "work":
        return <Work data={talent?.work} setRefresh={setRefresh} />;
      case "education":
        return <Education data={talent?.education} setRefresh={setRefresh} />;
      case "compensation":
        return (
          <Compensation
            currency={talent?.currency}
            asking={talent?.askingCompensation}
            current={talent?.currentCompensation}
            compensationHistory={talent?.compensationHistory}
          />
        );
      case "documents":
        return <Documents />;
      case "certs":
        return (
          <CertsAndPublication
            data={talent?.certificates}
            setRefresh={setRefresh}
          />
        );
      case "history":
        return (
          <CommentsSection
            // hasCommentWriteAccess={hasCommentWriteAccess}
            // hasCommentDeleteAccess={hasCommentDeleteAccess}
            id={id}
            renderAsTab
            title="Candidate History"
            type={COMMENT_TYPE.TALENT}
          />
        );

      case "assignments-ext":
        return <AssingnmentsExt headData={talent} />;
      case "change-password":
        return <ChangePassword></ChangePassword>;
      case "tl-shortcuts":
        return <Shortcuts></Shortcuts>;
      default:
        return <Overview talent={talent} />;
    }
  };

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  const user = getState()?.auth?.data;

  //////// RETURN //////

  return (
    <section
      className={` ${styles.hasStyles}  ${
        ristrictAccess() || !isSidePanel
          ? styles.centerOverview
          : styles.overview
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="mb-3 col-md-12">
            <div className="d-flex align-items-start">
              <div className="mb-3 ">
                {/* SIDE MENU BAR  */}

                <div className="tl-talent-sidebar">
                  <SideMenu
                    items={ristrictAccess() ? RISTRICT_MENU : MENU}
                    isActive={(menu) => menu.id === tab}
                    onClick={(item) => {
                      if (item.href) {
                        navigate(`${item.href}/${id}?source=overview`);
                      } else {
                        setParams({ tab: item.id });
                      }
                    }}
                  />
                </div>

                {/* BOTTOM FUCNITONAL OPTIONS */}

                {!ristrictAccess() && (
                  <div className="mt-3 mb-3">
                    <div className={` me-3 ${styles.bgprofileleft}`}>
                      <div className="status">
                        <span
                          className={
                            checkStatus.includes(talent?.status)
                              ? `${styles.greenContainer}`
                              : `${styles.simple}`
                          }
                        >
                          {getTalentStatus(talent?.status)}
                        </span>
                      </div>
                      {talent?.assignmentId && (
                        <>
                          <h4>Client</h4>

                          <p className={styles.sideClientText}>
                            {talent?.assignmentId?.companyId?.name} -{" "}
                            {talent?.assignmentId?.title}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {talent?.functionalAreas?.length !== 0 && (
                  <div className="mt-3 mb-3">
                    <div className={` me-3 ${styles.bgprofileleft}`}>
                      <div className={styles.btntag}>
                        <h4>Functional Areas</h4>
                        {talent?.functionalAreas?.map((data) => (
                          <span className={styles.tagbtn}>{data}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {talent?.skillRoles?.length !== 0 && (
                  <div className="mb-3 ">
                    <div className={` me-3 ${styles.bgprofileleft}`}>
                      <div className={styles.btntag}>
                        <h4>Skills/Roles</h4>
                        {talent?.skillRoles?.map((data) => (
                          <span className={styles.tagbtn}>{data}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {/* {(ristrictAccess() || hasWriteAccess) &&
                  (user?.talentLabRole == "Super Admin" ||
                    user?.talentLabRole == "Admin") &&
                  (talent?.selfSignUp == false ||
                    talent?.selfSignUp == undefined ||
                    talent?.admin_generated_login !== undefined ||
                    talent?.admin_generated_login) && (
                    <CreateProfile talent={talent}></CreateProfile>
                  )} */}

                {hasDeleteAccess && !ristrictAccess() && (
                  <LightButton onClick={handleOpen} label="Delete Candidate" />
                )}

                {/* {ristrictAccess() && (
                  <div className="">
                    <LightButton
                      className="bg-white tl-talent-deletion"
                      onClick={handleOpen}
                      label="Request Profile Deletion"
                    />
                  </div>
                )} */}
              </div>
              {renderTab()}

              <Settings
                hasWriteAccess={hasWriteAccess}
                hasCommentAccess={true}
                hasCommentWriteAccess={hasCommentWriteAccess}
                // hasCommentDeleteAccess={hasCommentDeleteAccess}
                talentData={talent}
                refreshdata={setRefresh}
                tab={tab}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={DELETE_MODAL_CONFIRMATION}
        open={open}
        reuqestDelete={ristrictAccess() ? true : false}
        closeModal={handleClose}
        onChangeHanlder={handlerDeleteCandidate}
        data={`${talent?.firstName} ${talent?.middleName} ${talent?.lastName}`}
      />
    </section>
  );
};

export default TalentOverview;
