import React from "react";
import styles from "./styles.module.scss";
import moment from "moment";

const Ageing = ({ assignmentDate }) => {
  const diff = moment().diff(assignmentDate, "days");
  let styleToUse = styles["yellow-dot"];

  if (diff >= 0 && diff <= 45) styleToUse = styles["green-dot"];
  if (diff >= 91) styleToUse = styles["red-dot"];

  return <div className={styleToUse}></div>;
};

export default Ageing;
