import React from "react";
import styles from "./styles.module.scss";

const ContactBox = ({
  client,
  setParams,
  ristrictAccess,
  hasContactAccess,
}) => {
  return (
    <div
      className={ristrictAccess() ? styles.hideContainer : styles.leftcontact}
    >
      <h3 className={styles.contactPerson}>Primary Contact Person</h3>{" "}
      <p className={styles.personName}>{`${client?.contactPerson}`}</p>
      <p className={styles.hrDirector}>
        {client?.contactPersonDesignation || ""}
      </p>
      <a className={styles.email} href={`mailto:${client?.contactPersonEmail}`}>
        {`${client?.contactPersonEmail}`}
      </a>
      <a
        className={styles.phone}
        href={`tel:${client?.contactPersonPhoneNumber}`}
      >
        {client?.contactPersonPhoneNumber}
      </a>
      {hasContactAccess && (
        <p
          className={styles.viewAllContacts}
          onClick={() => setParams({ tab: "contacts" })}
        >
          View all contacts
        </p>
      )}
    </div>
  );
};

export default ContactBox;
