import React from "react";
import Invoices from "../../addEdit/invoices";
import styles from "./styles.module.scss";

const InvoicesOverview = () => {
  return (
    <div className={styles.talentdetails}>
      <Invoices />
    </div>
  );
};

export default InvoicesOverview;
