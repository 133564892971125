import React from "react";
import styles from "./styles.module.scss";

const FollowUp = ({ notiBadgeData }) => {
  return (
    <li className={`${styles.navitem} ${styles.followUpText}`}>
      <span>
        {notiBadgeData?.todayFollowups
          ? `${notiBadgeData?.todayFollowups} `
          : `No `}
      </span>
      follow-ups today
    </li>
  );
};

export default FollowUp;
