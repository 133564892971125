import React from "react";
import styles from "./styles.module.scss";
import Skeleton from "components/skeleton";
import { emptyTableText } from "constants/contants";
import { ristrictAccess } from "utils/helper";
import ExtendTable from "pages/assignmentExt/extendTable";
import AssignmentHeader from "../assignmentHeader";
import TalentHeader from "../talentHeader";
import { useSelector } from "react-redux";
import { ROLES } from "constants/contants";
import { useState } from "react";
import { getState } from "redux/store";

const Overview = ({ headData, tableData, onClick }) => {
  const userRole = useSelector((state) => state?.auth?.data?.talentLabRole);

  return (
    <div
      className={` ${styles.containerInner} ${
        ristrictAccess() ? styles.ristrictAcces : styles.talentdetails
      }`}
    >
      {!headData ? (
        <Skeleton height="300px" marginBottom="30px" />
      ) : (
        <>
          {userRole === ROLES.TALENT ? (
            <TalentHeader headData={headData} />
          ) : (
            <AssignmentHeader headData={headData} />
          )}

          {ristrictAccess() && (
            <div className={styles.tableContainer}>
              <div className={`mb-3 pb-1 ${styles.summary}`}>
                {tableData?.ongoing?.length === 0 ? (
                  <div style={{ textAlign: "center" }}>
                    <h4 className={styles.dataNotFound}>
                      {userRole === "Talent"
                        ? "You have been not been mapped to any assignment yet."
                        : "No assignments started yet."}
                    </h4>
                  </div>
                ) : (
                  <ExtendTable
                    userRole={userRole}
                    tableHeading="Ongoing Assignments"
                    tableData={tableData?.ongoing}
                    onClick={onClick}
                  />
                )}
              </div>
            </div>
          )}
          {ristrictAccess() && (
            <div className={styles.tableContainer}>
              <div className={`mb-3 pb-1 ${styles.summary}`}>
                {tableData?.archive?.length === 0 ? (
                  ""
                ) : (
                  <ExtendTable
                    userRole={userRole}
                    tableHeading="Archived Assignments"
                    archive
                    tableData={tableData?.archive}
                    onClick={onClick}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Overview;
