import React, { useState } from "react";
import styles from "./styles.module.scss";
import ReminderModal from "../../../../../components/modal/reminderModal";
import CalenderComponent from "../../../../../components/overviewRightHeaderBar/calenderCoponent";
import { ristrictAccess } from "utils/helper";

const StatusToggle = ({ refresh, activeTabType }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const [active, setActive] = useState(false);

  const divRef = React.useRef();

  const openPopoverModal = (event) => {
    setActive(!active);
    if (!active) {
      setAnchorEl(divRef.current);
      setOpenBackDrop(!open);
    } else {
      setAnchorEl(null);
      setOpenBackDrop(false);
    }
  };

  const closePopoverModal = () => {
    setAnchorEl(null);
    setOpenBackDrop(false);
    setActive(!active);
    refresh((prev) => !prev);
  };

  const open = Boolean(anchorEl);
  const idd = open ? "simple-popover" : undefined;
  return (
    <>
      <div
        className={
          ristrictAccess() ? styles.hideContainer : styles.offlimitToggle
        }
        style={{ position: active ? "relative" : "unset" }}
        ref={divRef}
      >
        <CalenderComponent
          idd={idd}
          openPopoverModal={openPopoverModal}
          text="Add Reminder"
          reminderActive={active}
        />

        <div></div>
      </div>
      <ReminderModal
        activeTabType={activeTabType}
        open={open}
        openBackDrop={openBackDrop}
        anchorEl={anchorEl}
        closeModal={closePopoverModal}
        idd={idd}
      />
    </>
  );
};

export default StatusToggle;
