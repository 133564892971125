import React from "react";
import styles from "./styles.module.scss";

const ContactBlock = ({ assignment }) => {
  console.log("assignment==>", assignment);
  return (
    <div className={styles.leftcontact}>
      <h3 className=" mb-0">Contact Person</h3>{" "}
      <p>{assignment?.contactPerson ? assignment?.contactPerson : ""}</p>
      <p>
        {assignment?.contactPersonDesignation
          ? assignment?.contactPersonDesignation
          : ""}
      </p>
      {!!assignment?.contactPersonEmail && (
        <a
          className={styles.emaildiv}
          href={`mailto:${assignment?.contactPersonEmail}`}
        >
          {`${assignment?.contactPersonEmail}`}
        </a>
      )}
      {assignment?.contactPersonPhoneNumber?.map((data, key) => (
        <a className={styles.phonediv} href={`tel:${data}`} key={key}>
          {data}
        </a>
      ))}
    </div>
  );
};

export default ContactBlock;
