import React from "react";
import styles from "./styles.module.scss";
import { ROUTES } from "../../../../router/routes";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useSearchParams } from "react-router-dom";

const MENU = [
  {
    title: "Open Assignments",
    to: ROUTES.ASSIGNMENTS,
    id: "open-assignments",
  },
  {
    title: "Archived Assignments",
    to: ROUTES.ASSIGNMENTS,
    id: "archived-assignments",
  },
];

const DropDownMenu = ({ openModal }) => {
  const [params, setParams] = useSearchParams();
  const activetab = params?.get?.("activetab");
  const navigate = useNavigate();

  return (
    <ul
      className={styles.dropdownMenunew}
      style={{
        visibility: openModal ? "visible" : "hidden",
      }}
    >
      {MENU.map((menu, index) => (
        <MenuItem
          className={` ${styles.menuLink}
            ${activetab === menu.id ? styles.active : ""}
            `}
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/assignments?activetab=${menu.id}`);
          }}
          sx={{
            "& .MuiMenuItem-gutters": {
              fontSize: "14px",
            },
            "&:hover": {
              backgroundColor: "#E7E9FE",
            },
          }}
        >
          {menu.title}
        </MenuItem>
      ))}
    </ul>
  );
};

export default DropDownMenu;
