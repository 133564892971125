import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../constants/assets";
import { IMAGE_BASE_URL } from "../../constants/contants";

const ImageUploader = ({
  handleImageChange,
  image,
  title,
  organizationImageUploader,
  setFieldValue,
}) => {
  function hideImg() {
    document.getElementById("HideImg").style.display = "none";
  }

  // drag state
  const [dragActive, setDragActive] = React.useState(false);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFieldValue("imageUrl", {
        preview: URL.createObjectURL(e.dataTransfer.files[0]),
        raw: e.dataTransfer.files[0],
      });
    }
  };

  return (
    <>
      <div className={styles.label}>{title}</div>
      <label htmlFor="upload-button" className={styles.labelStyles}>
        <div className={styles.imageUploaderContainer} onDragEnter={handleDrag}>
          <img
            src={
              image?.preview === IMAGE_BASE_URL || image?.preview === ""
                ? organizationImageUploader
                  ? IMAGES.ORGANIZATION_PLACEHOLDER
                  : IMAGES.USER_PLACEHOLDER_IMAGE
                : image?.preview
            }
            id="HideImg"
            className={styles.userImage}
          />
          <div className={styles.iconContainer}>
            <img
              src={IMAGES.EDIT_ICON}
              alt="edit Icon"
              className={styles.editIcon}
            />
          </div>
          <br />
        </div>
      </label>
      {dragActive && (
        <div
          className={styles.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        onChange={handleImageChange}
        accept="image/jpeg, image/jpg, image/png"
      />

      <div className={styles.label}>
        Please upload image (JPG or PNG) of upto 400x400 pixels dimensions.
      </div>
      <hr className={styles.cardDivider} />
    </>
  );
};

export default ImageUploader;
