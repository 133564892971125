import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../constants/assets";
import { hasPermission } from "../../../../utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../constants/accessControl";
import { DELETE_MODAL_CONFIRMATION } from "../../../../constants/contants";
import ConfirmationModal from "../../../../components/modal/confirmationModal";
import { APIS } from "../../../../api/endpoints";
import { addEditApi } from "../../../../api/commonApis";
import ToastMessage from "../../../../components/layout/toast";
import { toast } from "react-toastify";
import OutLineButton from "../../../../components/buttons/outLineButton";
import { ristrictAccess } from "../../../../utils/helper";
import { TOAST_MESSAGE } from "constants/toastMessage";
import DataRow from "./dataRow";
import { getState } from "redux/store";

const CertsAndPublication = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [onHover, setOnHover] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteIdData, setDeleteData] = useState();

  /// GETTING PERMISSION FOR CURENT PAGE/////

  useEffect(() => {
    const user = getState()?.auth?.data?.talentLabRole;
    setHasWriteAccess(
      user === "Talent"
        ? true
        : hasPermission(
            MODULES.TALENT,
            SUB_MODULES.EDUCATION,
            PERMISSIONS.WRITE
          )
    );
    setHasDeleteAccess(
      user === "Talent"
        ? true
        : hasPermission(
            MODULES.TALENT,
            SUB_MODULES.EDUCATION,
            PERMISSIONS.DELETE
          )
    );
  }, []);

  //// HANLDER FOR CLOSING MODAL/////

  const closeModal = () => {
    setDeleteData();
    setOpenModal(false);
  };

  //// HANLDER FOR DELETE DATA/////

  const onChangeHanlder = () => {
    addEditApi(APIS.DELETE_DOCUMENT_DATA, {
      type: 16,
      id: deleteIdData?._id,
    }).then((result) => {
      toast.success(
        <ToastMessage
          title={"Success"}
          message={
            deleteIdData?.type === 1
              ? TOAST_MESSAGE.DELETE_CERT
              : TOAST_MESSAGE.DELETE_PUBL
          }
        />
      );
      props?.setRefresh((pre) => !pre);
      closeModal();
    });
  };

  ////// RETURN///////

  return (
    <>
      <div
        className={`${styles.innerContainer} ${
          ristrictAccess() ? styles.ristrictContainer : styles.talentdetails
        }`}
      >
        <div>
          <div className={styles.topheading}>
            <div className={styles.overviewHeading}>
              <h2> Certifications and Publications</h2>

              <OutLineButton
                visible={hasWriteAccess || ristrictAccess()}
                label="Add New"
                icon={onHover ? IMAGES.ACTIVE_PLUS_BUTTON : IMAGES.NEW_ADDITION}
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
                onClick={() =>
                  navigate(`/talent/certs-publications/${id}?source=overview`)
                }
              />
            </div>
          </div>

          <DataRow
            data={props?.data}
            id={id}
            hasWriteAccess={hasWriteAccess}
            ristrictAccess={ristrictAccess}
            hasDeleteAccess={hasDeleteAccess}
            setDeleteData={setDeleteData}
            setOpenModal={setOpenModal}
          />
        </div>
      </div>
      <ConfirmationModal
        open={openModal}
        closeModal={closeModal}
        title={DELETE_MODAL_CONFIRMATION}
        data={deleteIdData?.type === 1 ? "certification" : "publication"}
        onChangeHanlder={onChangeHanlder}
      />
    </>
  );
};

export default CertsAndPublication;
