import React from "react";
import styles from "./styles.module.scss";
import { MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { assignmentTalentStatus } from "constants/contants";
import DefaultSorting from "../../../../../components/filters/defaultsorting";
import { sortingOptions } from "constants/contants";

const StatusRow = ({
  hasWriteAccess,
  assignment,
  statusChecked,
  onStatusChange,
  setBulkStatus,
  candidates,
  sorting,
  DefaultSortingHandler,
  bulkStatus,
}) => {
  return (
    <div className={styles.tableFilters}>
      <div className={`d-flex align-items-center ${styles.filterdiv}`}>
        <label className={styles.changeStatusLabel}>Change status</label>

        <Select
          size="small"
          variant="standard"
          displayEmpty
          disabled={
            !hasWriteAccess || assignment?.isArchive
              ? true
              : statusChecked.length === 0
              ? true
              : false
          }
          className={`${styles.changeSelect} ${
            assignment?.isArchive
              ? ""
              : statusChecked.length === 0
              ? ""
              : styles.selectActive
          }`}
          value={bulkStatus}
          inputProps={{ "aria-label": "Without label" }}
          onChange={(e) => (
            onStatusChange(statusChecked, "status", e),
            setBulkStatus(e.target.value)
          )}
          IconComponent={ExpandMoreIcon}
          sx={{
            "&	.MuiSelect-select": {
              padding: "0px 0px 0px 12px",
              fontSize: "10px",
              fontWeight: "bold",
              ":focus": {
                backgroundColor: "transparent",
              },
            },
          }}
        >
          {assignmentTalentStatus?.map((item, index) => (
            <MenuItem
              key={index}
              value={item?.value}
              disabled={item?.label === "Select"}
              className={
                item?.label === "Select"
                  ? styles.trasnStyles
                  : styles.selectItem
              }
            >
              {item?.label}
            </MenuItem>
          ))}
        </Select>

        <span className={styles.totalCount}>
          Showing
          <span className={styles.bold}>
            {` ${candidates?.length}` || 0}
          </span>{" "}
          Candidates
        </span>
      </div>
      <div className={styles.sortingContainer}>
        <DefaultSorting
          type="select"
          value={sortingOptions.filter((i) => i.value === sorting)}
          onChange={DefaultSortingHandler}
          options={sortingOptions}
          placeholder={"Default Sorting"}
          name={"sorting"}
        />
      </div>
    </div>
  );
};

export default StatusRow;
