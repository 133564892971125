import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Pagination from "components/table/pagination";
import CustomTable from "../components/customTable";
import ConfirmationModal from "components/modal/confirmationModal";
import { DELETE_MODAL_CONFIRMATION } from "constants/contants";
import { APIS } from "../../../../api/endpoints";
import { listApi, addEditApi } from "api/commonApis";
import debounce from "lodash/debounce";
import ToastMessage from "components/layout/toast";
import { toast } from "react-toastify";
import { hasPermission } from "utils/helper";
import { PERMISSIONS, MODULES } from "constants/accessControl";
import CommonHeader from "../components/commonHeader";
import SearchBar from "../components/searchBar";
import {
  subModuleHandler,
  DatasetAddButtonLabel,
  apiTypeHandler,
  deleteDocumnetType,
  modalText,
  blockDocumnetType,
  DatasetPageHeading,
} from "constants/dataSetConstant";
import { TOAST_MESSAGE } from "constants/toastMessage";

const Overview = ({ activeTab, addEditHanlder, RowSelected }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [dataList, setDataList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [selectedType, setSelectedType] = useState();
  const [refresh, setRefresh] = useState(false);
  const [check, setCheck] = useState("");
  const open = Boolean(anchorEl);
  const [filter, setFilter] = useState(" ");

  useEffect(() => {
    setSearch("");
    setFilter(" ");
  }, [activeTab]);

  /*Filter Handler */

  const filterHandler = () => {
    if (filter === " ") {
      setFilter(2);
    }
    if (filter === 2) {
      setFilter(1);
    }
    if (filter === 1) {
      setFilter(2);
    }
  };

  // /////////// LISTING FOR TABLE (API)/////////////

  useEffect(() => {
    let payload = {};
    setLoading(true);

    if (filter !== " ") {
      payload.sort = filter;
    }
    listApi(APIS.LIST_DATASET, {
      search,
      type: apiTypeHandler(activeTab),
      skip: limitPerPage * page,
      limit: limitPerPage,
      ...payload,
    })
      .then((result) => {
        setDataList(result?.data?.data);
        setCount(result?.data?.count);
      })
      .finally(() => setLoading(false));
  }, [page, search, limitPerPage, refresh, filter, activeTab]);

  ///To get Data of the selected Row when Clicked on the ThreeDot Button/////

  const handleClick = (event, data) => {
    setSelectedType(data);
    RowSelected(data);
    setAnchorEl(event.currentTarget);
  };

  //////////to open and Close the Confirmation for Delete Industry Type//////////
  const openConfirmHanlder = (check) => {
    if (check === "delete") {
      setCheck(1);
    }
    if (check === "block") {
      setCheck(2);
    }

    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  ////////// DELETE AND BLOCK HANDLER (API)///////
  const confirmationHandler = () => {
    if (check === 1) {
      addEditApi(APIS.DELETE_DOCUMENT_DATA, {
        id: selectedType?._id,
        type: deleteDocumnetType(activeTab),
      }).then((result) => {
        setRefresh(!refresh);
        handleCloseConfirmation();
        toast.success(
          <ToastMessage
            title={"Success"}
            message={`${modalText(activeTab)} ${TOAST_MESSAGE.DELETE}`}
          />
        );
      });
    }
    if (check === 2) {
      addEditApi(APIS.BLOCK_DATA, {
        id: selectedType?._id,
        type: blockDocumnetType(activeTab),
        status: !selectedType?.isBlocked,
      }).then((result) => {
        setRefresh(!refresh);
        handleCloseConfirmation();
        toast.success(
          <ToastMessage
            title={"Success"}
            message={`${modalText(activeTab)} ${
              selectedType?.isBlocked
                ? TOAST_MESSAGE.UNBLOCK
                : TOAST_MESSAGE.BLOCK
            }`}
          />
        );
      });
    }
  };

  // //////////// for  table////////////

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onPageChange = (pageNo) => {
    setPage(pageNo);
  };

  // //////////// for Searching data without button CLick ///////////////
  const SearchedData = (event) => {
    setSearch(event?.target?.value);
  };
  const searchHandler = debounce(SearchedData, 300);

  return (
    <div className={`tab-content ${styles.tabcontent}`} id="v-pills-tabContent">
      <CommonHeader
        heading={DatasetPageHeading(activeTab)}
        accessControl={hasPermission(
          MODULES.DATA_SETS,
          subModuleHandler(activeTab),
          PERMISSIONS.WRITE
        )}
        onClick={(event) => addEditHanlder(event, true)}
        buttonLabel={DatasetAddButtonLabel(activeTab)}
      />
      <SearchBar key={`${activeTab}`} onChange={searchHandler} />

      <CustomTable
        activeTab={activeTab}
        hasWriteAccess={hasPermission(
          MODULES.DATA_SETS,
          subModuleHandler(activeTab),
          PERMISSIONS.WRITE
        )}
        hasDeleteAccess={hasPermission(
          MODULES.DATA_SETS,
          subModuleHandler(activeTab),
          PERMISSIONS.DELETE
        )}
        addEditHanlder={addEditHanlder}
        open={open}
        handleClose={handleClose}
        handleClick={handleClick}
        anchorEl={anchorEl}
        list={dataList}
        loading={loading}
        openConfirmHanlder={openConfirmHanlder}
        status={selectedType?.isBlocked}
        filterHandler={filterHandler}
        filter={filter}
      />

      <div className={styles.paginationContiner}>
        {dataList?.length !== 0 && (
          <Pagination
            totalCount={count}
            currentPage={page}
            limitPerPage={limitPerPage}
            onPageChange={onPageChange}
            setLimitPerPage={setLimitPerPage}
          />
        )}

        <ConfirmationModal
          title={DELETE_MODAL_CONFIRMATION}
          open={openConfirmation}
          closeModal={handleCloseConfirmation}
          onChangeHanlder={confirmationHandler}
          data={`${selectedType?.name} (${modalText(activeTab)})`}
          status={selectedType?.isBlocked}
          block={check === 2 ? true : false}
        />
      </div>
    </div>
  );
};

export default Overview;
