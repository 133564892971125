import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { addEditApi, listApi } from "../../../../api/commonApis";
import { APIS } from "../../../../api/endpoints";
import SearchBar from "../../../../components/searchBar";
import Skeleton from "../../../../components/skeleton";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import { assignmentTalentStatus } from "../../../../constants/contants";
import Pagination from "../../../../components/table/pagination";
import ToastMessage from "../../../../components/layout/toast";
import ConfirmationModal from "../../../../components/modal/confirmationModal";
import {
  REMOVE_MODAL_CONFIRMATION,
  STATUS_CHANGE,
} from "../../../../constants/contants";
import BasicConfirmationModal from "../../../../components/modal/basicConfirmationModal";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../../constants/accessControl";
import { hasPermission } from "../../../../utils/helper";
import ScrollIcon from "../../../../components/icons/scrollIcon";
import { TOAST_MESSAGE } from "constants/toastMessage";
import FilterRow from "./filterRow";
import StatusRow from "./statusRow";
import CandidateTable from "./candidateTable";

const greenBackDropDown = [4, 6, 7];
const confirmationValue = [4, 7, 8, 9];

const AssignedCandidate = () => {
  const [candidates, setCandidates] = useState([]);
  const [assignment, setAssignment] = useState();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [statusChecked, setStatusChecked] = useState([]);
  const [success, setSuccess] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [filterPotential, setFilterPotential] = useState(false);
  const [filterRef, setFilterRef] = useState(false);
  const [bulkStatus, setBulkStatus] = useState("");
  const [sorting, setSorting] = useState(1);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedTalent, setSelectedTalent] = useState();
  const [selectedTalentId, setSelectedTalentId] = useState();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [payloadData, setPayloadData] = useState();
  const { id } = useParams();
  const [onScroll, setOnScroll] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [searchClicked, setSearchClicked] = useState(false);
  const [refreshApi, SetRefreshApi] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  ////// GET PERMISSION///

  useEffect(() => {
    setHasWriteAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNED_CANDIDATE,
        PERMISSIONS.WRITE
      )
    );
    setHasDeleteAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNED_CANDIDATE,
        PERMISSIONS.DELETE
      )
    );
  }, []);

  /* DELETE CANDIDATE HANDLER */

  const handlerDeleteCandidate = () => {
    addEditApi(APIS.ADD_ASSIGNMENT_CANDIDATES, {
      assignmentId: id,
      talentId: selectedTalentId,
      action: 2,
    }).then(() => {
      handleClose();
      toast.success(
        <ToastMessage
          title={"Success"}
          message={TOAST_MESSAGE.REMOVE_CANDIDATE}
        />
      );
      setSuccess((prev) => !prev);
    });
  };

  /////// WHEN CLICK ON DELETE IMAEGE//////

  const deleteClickHanlder = (data) => {
    const talentName = `${data?.talentId?.firstName} ${data?.talentId?.lastName}`;
    setSelectedTalent(talentName);
    setSelectedTalentId([data?.talentId?._id]);
    handleOpen();
  };

  useEffect(() => {
    setLoading(false);

    let data = {};

    if (filterPotential) {
      data.potentialHire = true;
    }
    if (filterRef) {
      data.refCheck = true;
    }
    if (filterStatus) {
      data.status = filterStatus;
    }
    if (sorting) {
      data.sort = sorting;
    }

    listApi(APIS.LIST_ASSIGNED_CANDIDATES, {
      assignmentId: id,
      search,
      skip: limitPerPage * page,
      limit: limitPerPage,
      ...data,
    })
      .then((result) => {
        setAssignment(result?.data?.assignmentData);
        setCandidates(result?.data?.data);
        setCount(result?.data?.count);
      })
      .finally(() => setLoading(false));
  }, [
    refreshApi,
    searchClicked,
    success,
    page,
    filterStatus,
    filterPotential,
    filterRef,
    sorting,
    limitPerPage,
  ]);

  const onPageChange = (pageNo) => {
    setPage(pageNo);
  };

  const searchHandler = (e) => {
    e?.preventDefault();
    setSearch(e.target[0].value);
  };

  const allStatusCheckHandler = (event) => {
    var updatedList = [];
    if (event.target.checked) {
      candidates?.map(
        (data) => (updatedList = [...updatedList, data?.talentId?._id])
      );
    } else {
      updatedList.splice();
    }
    setStatusChecked(updatedList);
  };

  const statusCheckBoxHandler = (event) => {
    var updatedList = [...statusChecked];
    if (event.target.checked) {
      updatedList = [...statusChecked, event.target.id];
    } else {
      updatedList.splice(statusChecked.indexOf(event.target.id), 1);
    }
    setStatusChecked(updatedList);
  };

  const onStatusChange = (ids, property, event, isSwitch = false) => {
    let payload = {
      assginmentId: id,
      talentId: ids,
      [property]: isSwitch ? event?.target?.checked : event?.target?.value,
    };
    if (event?.target?.value) {
      payload.statusName = assignmentTalentStatus.find(
        (o) => o.value === event?.target?.value
      )?.label;
    }

    if (confirmationValue.includes(event?.target?.value)) {
      setOpenConfirmation(true);
      setPayloadData(payload);
    } else {
      addEditApi(APIS.CHANGE_ASSIGNMENT_CANDIDATE_STATUS, payload).then(() => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.UPDATE_STATUS}
          />
        );
        setSuccess((prev) => !prev);
        setStatusChecked([]);
      });
    }
  };

  const DefaultSortingHandler = (e) => {
    setSorting(e.value);
  };

  const filterLevelStatus = cloneDeep(assignmentTalentStatus);
  filterLevelStatus[0].label = "All Candidates";

  /////// Hanlder for MOdal ///
  const cloneConfirmHandler = () => {
    addEditApi(APIS.CHANGE_ASSIGNMENT_CANDIDATE_STATUS, payloadData).then(
      () => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.UPDATE_STATUS}
          />
        );
        setSuccess((prev) => !prev);
        setStatusChecked([]);
        handleCloseConfirmation();
      }
    );
  };

  /////////// to Close the basic Confirmation Modal//////

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const SearchClick = () => {
    setSearchClicked(!searchClicked);
  };

  const searchOnChange = (e) => {
    if (e.target.value === "") {
      setSearch("");
      SetRefreshApi(!refreshApi);
    } else {
      setSearch(e?.target?.value);
    }
  };
  return (
    <>
      <div className={`tab-content ${styles.tabMainContent}`}>
        {loading ? (
          <Skeleton height="300px" />
        ) : (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className={styles.searchfilter}>
                  <h2 className={styles.title}>
                    {assignment?.title ? `${assignment?.title}` : ""}
                    {assignment?.title && assignment?.companyId?.name
                      ? ` - `
                      : ""}
                    <span className={styles.fw400}>
                      {assignment?.companyId?.name}
                    </span>
                  </h2>
                  <hr className={styles.divider} />

                  <SearchBar
                    internalSearch
                    showBorder={true}
                    showFilters={false}
                    fullWidth={true}
                    placeholder="a candidate"
                    onSubmit={searchHandler}
                    onChange={searchOnChange}
                    setInputValue={setInputValue}
                    inputValue={inputValue}
                    SearchClick={SearchClick}
                    resetValue={resetValue}
                    setResetValue={setResetValue}
                  />
                </div>
              </div>
            </div>

            <FilterRow
              filterLevelStatus={filterLevelStatus}
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
              setFilterPotential={setFilterPotential}
              filterPotential={filterPotential}
              setFilterRef={setFilterRef}
              filterRef={filterRef}
            />

            <hr className={styles.divider} />
            <StatusRow
              hasWriteAccess={hasWriteAccess}
              assignment={assignment}
              statusChecked={statusChecked}
              onStatusChange={onStatusChange}
              setBulkStatus={setBulkStatus}
              candidates={candidates}
              sorting={sorting}
              DefaultSortingHandler={DefaultSortingHandler}
              bulkStatus={bulkStatus}
            />

            <div class="d-flex align-items-center justify-content-end">
              {onScroll && <ScrollIcon />}
            </div>

            <div className="row">
              <div
                onScroll={() => setOnScroll(true)}
                className={`col-md-12 ${styles.tableContainer}`}
              >
                <div className={styles.tablehead}>
                  <CandidateTable
                    statusChecked={statusChecked}
                    candidates={candidates}
                    allStatusCheckHandler={allStatusCheckHandler}
                    greenBackDropDown={greenBackDropDown}
                    hasWriteAccess={hasWriteAccess}
                    assignment={assignment}
                    onStatusChange={onStatusChange}
                    hasDeleteAccess={hasDeleteAccess}
                    deleteClickHanlder={deleteClickHanlder}
                    statusCheckBoxHandler={statusCheckBoxHandler}
                  />

                  {candidates?.length === 0 && (
                    <div className={styles.nodataStyle}>
                      No candidates have been assigned yet.
                    </div>
                  )}

                  {candidates?.length !== 0 && (
                    <Pagination
                      tablePagination
                      totalCount={count}
                      currentPage={page}
                      limitPerPage={limitPerPage}
                      onPageChange={onPageChange}
                      setLimitPerPage={setLimitPerPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ConfirmationModal
        title={REMOVE_MODAL_CONFIRMATION}
        open={open}
        closeModal={handleClose}
        onChangeHanlder={handlerDeleteCandidate}
        data={selectedTalent}
        remove
      />
      <BasicConfirmationModal
        openConfirmation={openConfirmation}
        closeModal={handleCloseConfirmation}
        cloneConfirmHandler={cloneConfirmHandler}
        data={STATUS_CHANGE}
        buttonLabel="Yes"
      />
    </>
  );
};
export default AssignedCandidate;
