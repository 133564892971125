import React from "react";
import styles from "./styles.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const DropButton = ({ label, onClick, open }) => {
  return (
    <button className={styles.dropButton} onClick={onClick}>
      {label}

      {open ? (
        <KeyboardArrowUpIcon style={{ fontSize: "21px" }} />
      ) : (
        <KeyboardArrowDownIcon style={{ fontSize: "21px" }} />
      )}
    </button>
  );
};

export default DropButton;
