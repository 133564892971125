import React from "react";
import styles from "./styles.module.scss";

const ProfileAvatar = ({ firstLabel, secondLabel }) => {
  return (
    <h6 className={styles.avtarContainer}>
      {firstLabel}
      {secondLabel}
    </h6>
  );
};

export default ProfileAvatar;
