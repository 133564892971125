import React from "react";
import Select, { components } from "react-select";
import TimeIcon from "@mui/icons-material/AccessTime";

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <TimeIcon
        fontSize="12"
        sx={{ marginLeft: 1, color: "hsl(0deg 0% 67%)" }}
      />
      {children}
    </components.Control>
  );
};

const styles = {
  control: (base, state) => ({
    ...base,
    cursor: "pointer",
    borderRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: "none",
    ":focus-visible": {
      borderColor: "#011fc5",
    },

    ":hover": {
      ...base[":hover"],
      borderColor: "#011fc5",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: 12,
    fontWeight: 700,
    outline: "none",
  }),
  option: (base, { isSelected }) => {
    return {
      ...base,
      backgroundColor: isSelected ? "#011cf5" : "transparent",
      color: isSelected ? "white" : "black",
      fontSize: 12,
      fontWeight: 700,
      ":hover": {
        ...base[":hover"],
        backgroundColor: isSelected ? "" : "#e7e9fe",
      },
    };
  },
};

const TimePicker = ({ options, value, onChange }) => {
  return (
    <div>
      <Select
        isSearchable={false}
        options={options}
        styles={styles}
        value={value}
        components={{ Control, IndicatorSeparator: () => null }}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
};

export default TimePicker;
