import React, { useState } from "react";
import { IMAGES } from "../../../../../../constants/assets";
import styles from "./styles.module.scss";

const CalenderBox = ({ openModal, active }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className={styles.calender}
      onClick={openModal}
      style={{ zIndex: active ? 9999 : 0 }}
    >
      <img
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        src={
          active
            ? IMAGES.ACTIVE_CALENDER
            : hover
            ? IMAGES.ACTIVE_CALENDER
            : IMAGES.CALENDER_ICON
        }
        alt="Linkdin"
        aria-disabled
        className={styles.socialIcon}
      />
      <h2
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        className={`${styles.text} ${active && styles.activetext} ${
          hover && styles.hoverEffect
        }`}
      >
        Add Reminder
      </h2>
    </div>
  );
};

export default CalenderBox;
