import React from "react";
import styles from "./styles.module.scss";
import moment from "moment";

const Footer = ({ record }) => {
  return (
    <div className={styles.footerContainer}>
      {record?.uploadedBy?.name ? (
        <div className={styles.createByStyles}>
          Created by
          <b
            style={{ color: "#2f2f2f" }}
          >{` ${record?.uploadedBy?.name} `}</b>|{" "}
          {moment(record?.createdAt).format("DD MMM	YY")}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Footer;
