import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../../constants/assets";
import { Tooltip } from "@mui/material";

const RoundDeleteIcon = ({ onClick, setDeleteId, setOpenModal }) => {
  return (
    <Tooltip title="Delete">
      <div className={`d-flex ${styles.detailsicons}`} onClick={onClick}>
        <span>
          <img src={IMAGES.RI_ACTIVE_DELETE_BIN} alt="Delete Icon" />
        </span>
      </div>
    </Tooltip>
  );
};
export default RoundDeleteIcon;
