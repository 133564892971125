import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { IMAGES } from "../../../../constants/assets";
import { talentCompentancySchema } from "../../../../constants/formSchema";
import { listApi, addEditApi } from "../../../../api/commonApis";
import styles from "./styles.module.scss";
import { APIS } from "../../../../api/endpoints";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LinkButton from "../../../../components/buttons/linkButton";
import ToastMessage from "../../../../components/layout/toast";
import debounce from "lodash/debounce";
import DataTable from "./dataTable";
import SelectedAssignment from "./selectAssignment";
import { validationErrorMapping, order } from "../../../../utils/helper";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../../constants/accessControl";
import { hasPermission } from "../../../../utils/helper";
import OutLineButton from "../../../../components/buttons/outLineButton";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import ScrollIcon from "../../../../components/icons/scrollIcon";
import { useMemo } from "react";
import Header from "./header";
import { TOAST_MESSAGE } from "constants/toastMessage";

const initialValues = {
  name: "",
  description: "",
  score: "",
  notes: "",
};

const CompentancyMapping = () => {
  const { id } = useParams();
  const arrayHelpersRef = useRef(null);
  const [compentancyData, setCompentancyData] = useState();
  const [loading, setLoading] = useState(false);
  const [showDatatable, setShowDataTable] = useState(false);
  const [assignmentSelected, setAssignmentSelected] = useState();
  const [listCompetencyDefaults, setListCompetencyDefaults] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const inputRefs = useRef([]);
  const [onHover, setOnHover] = useState(false);
  const [onHoverDown, setOnHoverDown] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasdeleteAccess, setHasDeleteAccess] = useState(false);
  const [onScroll, setOnScroll] = useState(false);
  const [noAssignment, setNoAssignment] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);

  useEffect(() => {
    setHasWriteAccess(
      hasPermission(
        MODULES.TALENT,
        SUB_MODULES.COMPENTENCY_MAPPING,
        PERMISSIONS.WRITE
      )
    );
    setHasDeleteAccess(
      hasPermission(
        MODULES.TALENT,
        SUB_MODULES.COMPENTENCY_MAPPING,
        PERMISSIONS.DELETE
      )
    );
  }, []);

  /*APi for Table DropDown CompetencyList/ */
  useEffect(() => {
    setListCompetencyDefaults([]);
    listApi(APIS.LIST_COMPENTENCY).then((result) => {
      setListCompetencyDefaults(result?.data);
    });
  }, [refresh]);

  /*When user Select Value from Table Dropdown */

  const onSelectChange = (obj) => {
    setAssignmentSelected(obj);
  };

  useEffect(() => {
    if (compentancyData?.length) {
      inputRefs.current = inputRefs.current.slice(0, compentancyData?.length);
    }
  }, [compentancyData, refresh]);

  /*Final Submission Save Button  */

  const onSubmit = (payload) => {
    setLoading(true);
    addEditApi(APIS.ADD_EDIT_TALENT_COMPENTENCY, {
      talentId: id,
      data: payload?.compentancyMapping,
      assignmentId: assignmentSelected?.value,
    })
      .then(() => {
        TalentDetailTypeWIse();
        setRefresh(!refresh);
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.SAVED_COMPETENCY}
          />
        );
      })
      .finally(() => setLoading(false));
  };

  /*Use useMeno to hit api before rendring  */
  useMemo(() => {
    listApi(APIS.LIST_ASSIGNMENTS_ORG_TALNET, {
      search: "",
      talentId: id,
    }).then((result) => {
      if (result?.data?.ongoing?.length === 0) {
        setNoAssignment(true);
      }
      setApiLoading(false);
    });
  }, []);

  /* loadash for table DropDown   */

  const loadOptions = (inputValue, resolve) => {
    listApi(APIS.LIST_ASSIGNMENTS_ORG_TALNET, {
      search: inputValue,
      talentId: id,
    }).then((result) => {
      const filter = result?.data?.ongoing
        ?.map((item) => ({
          label: `${item?.title} / ${item?.companyId?.name || ""}`,
          value: item?._id,
          clientId: item?.companyId?._id,
        }))
        .sort(order);
      resolve(filter);
    });
  };

  const AssignmentOptions = (inputValue, resolve) =>
    loadOptions(inputValue, resolve);

  const loadAssignment = debounce(AssignmentOptions, 300);

  /////Api for table Data //////////
  const TalentDetailTypeWIse = () => {
    setLoading(true);
    let payload = {
      talentId: id,
      type: 5,
      assignmentId: assignmentSelected?.value,
    };

    listApi(APIS.TALENT_DETAILS_TYPE_WISE, payload)
      .then((result) => {
        setCompentancyData(result?.data);
      })
      .finally(() => setLoading(false));
  };

  /////When user CLick On Next Button////////
  const onNextClick = (e) => {
    e.preventDefault(e);
    if (assignmentSelected) {
      setShowDataTable(true);
    }
    TalentDetailTypeWIse();
  };

  const getAvgScore = () => {
    let sum = 0;
    if (compentancyData?.length) {
      for (let i = 0; i < compentancyData?.length; i++) {
        sum = sum + compentancyData?.[i]?.score;
      }
      return (sum / compentancyData?.length)?.toFixed(1);
    } else return 0;
  };

  return (
    <>
      <div
        className={`tab-content ${styles.tabcontent} ${styles.fullTabContent}`}
      >
        {apiLoading ? (
          ""
        ) : (
          <div className="fade show active">
            <Formik
              onSubmit={onSubmit}
              initialValues={{
                compentancyMapping:
                  compentancyData?.length > 0
                    ? compentancyData
                    : [initialValues],
              }}
              enableReinitialize
              validationSchema={talentCompentancySchema}
              validateOnChange={true}
            >
              {({
                values,
                errors,
                touched,
                dirty,
                handleSubmit,
                getFieldProps,
                setFieldValue,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Header
                    showDatatable={showDatatable}
                    hasWriteAccess={hasWriteAccess}
                    setFieldValue={setFieldValue}
                    setAssignmentSelected={setAssignmentSelected}
                    setShowDataTable={setShowDataTable}
                    assignmentSelected={assignmentSelected}
                    resetForm={resetForm}
                    compentancyData={compentancyData}
                    errors={errors}
                    loading={loading}
                    dirty={dirty}
                  />

                  <div className={styles.middleCard}>
                    {showDatatable ? (
                      <div>
                        <div className={styles.totalavg}>
                          <div className={styles.totalcom}>
                            <h4>Total Competencies</h4>
                            <h2>
                              <img
                                src={IMAGES.TIE_ICON}
                                alt=""
                                className="me-1"
                              />
                              {compentancyData?.length || 0}
                            </h2>
                          </div>

                          <div
                            className={`${styles.totalcom} ${styles.scoreCon}`}
                          >
                            <h4>Avg. Score</h4>
                            <h2>
                              <img
                                src={IMAGES.CHART_ICON}
                                alt=""
                                className="me-1"
                              />{" "}
                              {getAvgScore()}
                            </h2>
                          </div>

                          <div className={styles.headRightContainer}>
                            {showDatatable && hasWriteAccess && (
                              <>
                                <div className="d-flex">
                                  <a
                                    href="#"
                                    className={`${styles.resetdata} me-4`}
                                    onClick={() => {
                                      setRefresh(!refresh);
                                      resetForm({
                                        compentancyMapping: compentancyData,
                                      });
                                    }}
                                  >
                                    Reset Data
                                  </a>
                                </div>
                                {hasWriteAccess && (
                                  <OutLineButton
                                    icon={
                                      onHover
                                        ? IMAGES.ACTIVE_COMP
                                        : IMAGES.TIE_ICON
                                    }
                                    label="Add a new competency"
                                    onClick={() => {
                                      arrayHelpersRef?.current?.unshift(
                                        initialValues
                                      );
                                      inputRefs?.current[0]?.focus();
                                    }}
                                    onMouseEnter={() => setOnHover(true)}
                                    onMouseLeave={() => setOnHover(false)}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <DataTable
                          hasdeleteAccess={hasdeleteAccess}
                          hasWriteAccess={hasWriteAccess}
                          compentancyData={compentancyData}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          arrayHelpersRef={arrayHelpersRef}
                          values={values}
                          listCompetencyDefaults={listCompetencyDefaults}
                          setOnScroll={setOnScroll}
                        />
                        <div className={styles.tableFooter}>
                          <div className="d-flex align-items-center">
                            {onScroll && <ScrollIcon />}
                          </div>

                          {showDatatable && hasWriteAccess && (
                            <div className="d-flex">
                              <a
                                href="#"
                                className={`${styles.resetdata} me-4`}
                                onClick={() => {
                                  setRefresh(!refresh);
                                  resetForm({
                                    compentancyMapping: compentancyData,
                                  });
                                }}
                              >
                                Reset Data
                              </a>

                              {hasWriteAccess && (
                                <OutLineButton
                                  icon={
                                    onHoverDown
                                      ? IMAGES.ACTIVE_COMP
                                      : IMAGES.TIE_ICON
                                  }
                                  label="Add a new competency"
                                  onClick={() => {
                                    arrayHelpersRef.current.unshift(
                                      initialValues
                                    );
                                    inputRefs.current[0].focus();
                                  }}
                                  onMouseEnter={() => setOnHoverDown(true)}
                                  onMouseLeave={() => setOnHoverDown(false)}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <SelectedAssignment
                        noAssignment={noAssignment}
                        values={values}
                        loadAssignment={loadAssignment}
                        assignmentSelected={assignmentSelected}
                        setShowDataTable={setShowDataTable}
                        onSelectChange={onSelectChange}
                        onNextClick={onNextClick}
                      />
                    )}
                  </div>

                  {showDatatable && (
                    <div className={styles.certHeading}>
                      <div className="d-flex">
                        <LinkButton
                          onClick={() => {
                            resetForm({
                              compentancyMapping: compentancyData,
                            });
                            setFieldValue("name", "");
                            setAssignmentSelected("");
                            setShowDataTable(false);
                          }}
                        >
                          Choose another assignment
                        </LinkButton>
                        {hasWriteAccess && (
                          <PrimaryButton
                            type="submit"
                            onClick={() => validationErrorMapping(errors)}
                            label={loading ? "Saving..." : "Save mapping"}
                            disabled={loading || !dirty}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default CompentancyMapping;
