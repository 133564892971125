import React from "react";
import styles from "./styles.module.scss";
import { IMAGE_BASE_URL } from "constants/contants";

const SearchSuggestion = ({ suggestionData, onClick, rowData }) => {
  return (
    <div className={styles.searchContainer}>
      {suggestionData?.map((data, index) => {
        return (
          <>
            <div onClick={() => onClick(data)} className={styles.searchRow}>
              <div className={styles.commenttext}>
                {data?.imageUrl?.original ||
                data?.companyId?.imageUrl?.original ? (
                  <img
                    alt="head"
                    src={
                      IMAGE_BASE_URL +
                      (data?.imageUrl?.original ||
                        data?.companyId?.imageUrl?.original)
                    }
                    className={styles.adminThumImage}
                  />
                ) : (
                  <h5 className={styles.adminInitail}>
                    {data?.firstName
                      ? `${data?.firstName?.[0] || ""}${
                          data?.lastName?.[0] || ""
                        }`
                      : data?.title
                      ? `${data?.title?.[0] || ""}${data?.title?.[1] || ""}`
                      : data?.name
                      ? `${data?.name?.[0] || ""}${data?.name?.[1] || ""}`
                      : ""}
                  </h5>
                )}
              </div>
              <div className={styles.selectValue}>
                <div className={styles.nameStyles}>
                  {data?.firstName
                    ? `${data?.firstName || ""} ${data?.lastName || ""}`
                    : data?.title
                    ? `${data?.title || ""}`
                    : `${data?.name || ""}`}
                </div>
                <div className={styles.emailStyle}>
                  {data?.companyId?.name
                    ? `${data?.companyId?.name || "NA"}`
                    : data?.email
                    ? `${data?.email || "NA"}`
                    : data?.category
                    ? `${data?.category}`
                    : "NA"}
                </div>
              </div>
            </div>
            {rowData?.length - 1 !== index && (
              <hr className={styles.searchDivider} />
            )}
          </>
        );
      })}
    </div>
  );
};
export default SearchSuggestion;
