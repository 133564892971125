import React from "react";
import styles from "./styles.module.scss";
import FormInput from "../../../../../components/forms/formInput";
import PrimaryButton from "../../../../../components/buttons/primaryButton";

const SelectedAssignment = ({
  noAssignment,
  values,
  loadAssignment,
  assignmentSelected,
  onSelectChange,
  onNextClick,
}) => {
  return (
    <div className={styles.middleContainer}>
      {noAssignment ? (
        <h3 className={styles.noassignment}>
          Please add the candidate to an assignment to get started
        </h3>
      ) : (
        <>
          <h3 className={styles.headingText}>Please choose the assignment</h3>
          <FormInput
            cacheOptions
            defaultOptions
            type="async-select"
            value={values?.selectedAssignment}
            loadOptions={loadAssignment}
            onChange={onSelectChange}
          />
          <div className={styles.buttonContainer}>
            <PrimaryButton
              label="Next"
              disabled={!assignmentSelected ? true : false}
              onClick={onNextClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SelectedAssignment;
