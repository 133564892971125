import React from "react";
import { FieldArray } from "formik";
import styles from "./styles.module.scss";
import { MenuItem, Select } from "@mui/material";

const DataTable = ({
  compentancyData,
  errors,
  touched,
  setFieldValue,
  arrayHelpersRef,
  values,
  listCompetencyDefaults,
  setOnScroll,
  hasdeleteAccess,
  hasWriteAccess,
}) => {
  return (
    <>
      <div onScroll={() => setOnScroll(true)} className={styles.tableContainer}>
        <table className={`table ${styles.tableStyles}`}>
          <thead>
            <tr className={styles.tableHeader}>
              <th scope="col" className={styles.textFontSize}>
                Competency
              </th>
              <th scope="col" className={styles.textFontSize}>
                Definition
              </th>
              <th scope="col" className={styles.textFontSize}>
                Score (Scale of 1-4)
              </th>
              <th scope="col" className={styles.textFontSize}>
                Notes on Candidate Assessment
              </th>
              <th scope="col" className={styles.textFontSize}></th>
            </tr>
          </thead>

          <tbody>
            <FieldArray
              name="compentancyMapping"
              render={(arrayHelpers) => {
                arrayHelpersRef.current = arrayHelpers;
                return values?.compentancyMapping?.map((comp, index) => (
                  <tr>
                    {/* <div className={`${styles.tableData}`}> */}
                    <td className={styles.tableHeading1Styles}>
                      <span className={styles.errorText}>
                        {touched?.compentancyMapping?.[index]?.name &&
                          errors?.compentancyMapping?.[index]?.name}
                      </span>

                      <Select
                        disabled={!hasWriteAccess}
                        size="small"
                        value={comp?.name}
                        disableUnderline
                        onChange={(e) => {
                          const item = listCompetencyDefaults?.find(
                            (id) => id?.name === e.target.value
                          );

                          setFieldValue(
                            `compentancyMapping.${index}.description`,
                            item?.description
                          );
                          setFieldValue(
                            `compentancyMapping.${index}.name`,
                            item?.name
                          );
                        }}
                        variant="standard"
                        className={styles.dropDownSelect}
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "5px 8px",
                          },
                        }}
                      >
                        {listCompetencyDefaults.map((item, index) => (
                          <MenuItem
                            value={item?.name}
                            className={styles.dropDownSelect}
                            key={index}
                          >
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </td>
                    <td className={`${styles.tableHeading2Styles} `}>
                      <span className={styles.errorText}>
                        {touched?.compentancyMapping?.[index]?.description &&
                          errors?.compentancyMapping?.[index]?.description}
                      </span>
                      <p
                        contentEditable={hasWriteAccess}
                        suppressContentEditableWarning
                        onBlur={(e) =>
                          setFieldValue(
                            `compentancyMapping.${index}.description`,
                            e.target.innerText
                          )
                        }
                        className={
                          touched?.compentancyMapping?.[index]?.description &&
                          errors?.compentancyMapping?.[index]?.description
                            ? ` ${styles.paraWidth} ${styles.inputError} ${styles.hoverEffect}`
                            : `${styles.paraStyles} ${styles.hoverEffect}`
                        }
                      >
                        {comp?.description}
                      </p>
                    </td>
                    <td className={styles.tableHeading3Styles}>
                      {/* <span className={styles.errorText}>
                        {touched?.compentancyMapping?.[index]?.score &&
                          errors?.compentancyMapping?.[index]?.score}
                      </span> */}
                      <span
                        contentEditable={hasWriteAccess}
                        suppressContentEditableWarning
                        onBlur={(e) =>
                          setFieldValue(
                            `compentancyMapping.${index}.score`,
                            e.target.innerText
                          )
                        }
                        className={`${styles.editScore}
${
  touched?.compentancyMapping?.[index]?.score &&
  errors?.compentancyMapping?.[index]?.score
    ? `${styles.inputError} ${styles.hoverEffect}`
    : `${styles.hoverEffect}`
}
`}
                      >
                        {comp?.score}
                      </span>
                    </td>
                    <td className={styles.tableHeading4Styles}>
                      {/* <span className={styles.errorText}>
                        {touched?.compentancyMapping?.[index]?.notes &&
                          errors?.compentancyMapping?.[index]?.notes}
                      </span> */}
                      <p
                        contentEditable={hasWriteAccess}
                        suppressContentEditableWarning
                        onBlur={(e) =>
                          setFieldValue(
                            `compentancyMapping.${index}.notes`,
                            e.target.innerText
                          )
                        }
                        className={`${styles.tableptag}
${
  touched?.compentancyMapping?.[index]?.notes &&
  errors?.compentancyMapping?.[index]?.notes
    ? ` ${styles.paraWidth} ${styles.inputError} ${styles.hoverEffect}`
    : `${styles.paraStyles} ${styles.hoverEffect}`
}
`}
                      >
                        {comp?.notes}
                      </p>
                    </td>
                    <td
                      className={styles.redcolor}
                      onClick={() => {
                        hasdeleteAccess && arrayHelpers.remove(index);
                      }}
                    >
                      {hasdeleteAccess && (
                        <div className={styles.redText}>
                          X Remove Competency
                        </div>
                      )}
                    </td>

                    {/* </div> */}
                  </tr>
                ));
              }}
            />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DataTable;
