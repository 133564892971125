import React, { Fragment } from "react";
import styles from "./styles.module.scss";

const EducationBlock = ({ talent, hasEducationReadAccess, setParams }) => {
  return (
    <>
      <div className={styles.textBlock}>
        <h3>Education Summary</h3>

        {talent?.education?.map(
          (edu, index) =>
            index < 2 && (
              <Fragment key={index}>
                <p>{edu?.degree}</p>
                <h4>
                  {edu?.universityName}
                  {edu?.endDateYear ? `, ${edu?.endDateYear}` : ""}
                </h4>
              </Fragment>
            )
        )}

        {talent?.education?.length > 2 && hasEducationReadAccess && (
          <div
            className={styles.view_css}
            onClick={() => setParams({ tab: "education" })}
          >
            View All
          </div>
        )}
      </div>
      <hr className={styles.horizontalLine} />
    </>
  );
};

export default EducationBlock;
