import React from "react";
import styles from "./styles.module.scss";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { IMAGES } from "../../../constants/assets";

const style = {
  position: "absolute",
  top: "50%",
  left: "48%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid white",
  boxShadow: 24,
  borderRadius: "4px",
  p: 4,
};

const BasicConfirmationModal = ({
  closeModal,
  openConfirmation,
  cloneConfirmHandler,
  data,
  buttonLabel,
}) => {
  return (
    <>
      <Modal
        disableAutoFocus={true}
        open={openConfirmation}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.container}>
          <div className={styles.redAlert}>
            <div className={styles.alertHeading}>
              <img alt="" src={IMAGES.ALERT_CAUTION} />
              Alert
            </div>
            <div className={styles.alertDescription}>{data}</div>
          </div>

          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            className={`mt-4 ${styles.stack}`}
          >
            <Button
              variant="outlined"
              onClick={closeModal}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={cloneConfirmHandler}
              className={styles.cloneButton}
            >
              {buttonLabel}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default BasicConfirmationModal;
