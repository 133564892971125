import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import FormInput from "../../../../components/forms/formInput";
import { IMAGES } from "../../../../constants/assets";
import { talentWorkSchema } from "../../../../constants/formSchema";
import { listApi, addEditApi } from "../../../../api/commonApis";
import styles from "./styles.module.scss";
import Checkbox from "../../../../components/forms/checkbox";
import { monthOption } from "../../../../constants/contants";
import { APIS } from "../../../../api/endpoints";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../router/routes";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "react-toastify";
import { saveAddNewButtonLabels } from "../../../../constants/contants";
import {
  validationError,
  yearDropDownOptions,
  order,
} from "../../../../utils/helper";
import { saveViewButtonLabels } from "../../../../constants/contants";
import LinkButton from "../../../../components/buttons/linkButton";
import CountryStateCityPicker from "../../../../components/countryStateCityPicker";
import ToastMessage from "../../../../components/layout/toast";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import { TOAST_MESSAGE } from "constants/toastMessage";
import OutLineButton from "components/buttons/outLineButton";

const initialValues = {
  designation: "",
  role: "",
  companyName: "",
  companyUrl: "",
  city: null,
  state: null,
  country: { label: "India", value: "India" },
  joinDateMonth: "",
  joinDateYear: "",
  currentlyWork: false,
  endDateMonth: "",
  endDateYear: "",
  workSynopsis: "",
  keyWork: "",
  industryType: "",
  functionalAreas: [],
  skillRoles: [],
};

const Work = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [workData, setWorkData] = useState();
  const workId = searchParams.get("workId");
  const yearOption = yearDropDownOptions();
  const [updateYear, setUpdateYear] = useState("");

  let action;

  useEffect(() => {
    if (workId) {
      listApi(APIS.TALENT_DETAILS_TYPE_WISE, {
        talentId: id,
        type: 1,
        id: workId,
      }).then((result) => {
        const data = cloneDeep(result?.data[0]);

        //// HANDLING IF COUNTRY STATE AND CITY GOD UNDEFINED
        if (data.country === undefined) {
          data.country = { label: "India", value: "India" };
        } else {
          data.country = { label: data?.country, value: data?.country };
        }
        if (data.state === undefined) {
          data.state = null;
        } else {
          data.state = { label: data?.state, value: data?.state };
        }
        if (data.city === undefined) {
          data.city = null;
        } else {
          data.city = { label: data?.city, value: data?.city };
        }

        /// HANDLING Work Synopsis and  Key Work / Initiatives

        if (!data?.workSynopsis) {
          data.workSynopsis = "";
        }
        if (!data?.keyWork) {
          data.keyWork = "";
        }
        if (!data?.companyUrl) {
          data.companyUrl = "";
        }

        ///// HANDLING MONTH AND YEARS//////

        if (!data?.joinDateMonth) {
          data.joinDateMonth = "";
        }
        if (!data?.joinDateYear) {
          data.joinDateYear = "";
        }
        if (!data?.endDateMonth) {
          data.endDateMonth = "";
        }
        if (!data?.endDateYear) {
          data.endDateYear = "";
        }
        if (!data?.currentlyWork) {
          data.currentlyWork = false;
        }

        ///HANDLING COMPANY URL////

        if (data?.industryType === undefined) {
          data.industryType = "";
        } else {
          data.industryType = {
            label: data.industryType,
            value: data.industryType,
          };
        }
        data.functionalAreas = data?.functionalAreas?.map((value) => ({
          label: value,
          value,
        }));

        data.skillRoles = data?.skillRoles?.map((value) => ({
          label: value,
          value,
        }));

        setWorkData(data);
      });
    }
  }, [workId]);

  const onSubmit = (payload, action) => {
    let data = {
      designation: payload?.designation,
      role: payload?.role,
      companyName: payload?.companyName,
      country: payload?.country?.label || "",
      city: payload?.city?.label || "",
      state: payload?.state?.label || "",
      currentlyWork: payload?.currentlyWork,
      functionalAreas: payload?.functionalAreas.map(({ value }) => value),
      skillRoles: payload?.skillRoles.map(({ value }) => value),
      industryType: payload?.industryType?.value,
      talentId: id,
    };

    if (payload?.companyUrl) {
      data.companyUrl = payload?.companyUrl;
    }
    if (payload?.keyWork) {
      data.keyWork = payload?.keyWork;
    }
    if (payload?.workSynopsis) {
      data.workSynopsis = payload?.workSynopsis;
    }
    if (payload?.joinDateMonth) {
      data.joinDateMonth = payload?.joinDateMonth;
    }
    if (payload?.joinDateYear) {
      data.joinDateYear = payload?.joinDateYear;
    }
    if (workId) {
      data.workId = workId;
    }

    if (payload?.endDateMonth) {
      data.endDateMonth = payload?.endDateMonth;
    }
    if (payload?.endDateYear) {
      data.endDateYear = payload?.endDateYear;
    }

    addEditApi(APIS.ADD_EDIT_TALENT_WORK, data).then(() => {
      if (action === "save") {
        navigate(`${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=work`);
        toast.success(
          <ToastMessage title={"Success"} message={TOAST_MESSAGE.SAVED_WORK} />
        );
      } else {
        toast.success(
          <ToastMessage title={"Success"} message={TOAST_MESSAGE.SAVED_WORK} />
        );
      }
    });
  };

  const loadOptions = (type, inputValue, resolve) => {
    listApi(APIS.ADMIN_DEFAULTS, { search: inputValue, type }).then(
      (result) => {
        const filter = result?.data
          ?.map((item) => ({
            label: item?.name,
            value: item?.name,
          }))
          .sort(order);

        resolve(filter);
      }
    );
  };

  const SkillOptions = (inputValue, resolve) =>
    loadOptions(3, inputValue, resolve);

  const IndustriesOptions = (inputValue, resolve) =>
    loadOptions(1, inputValue, resolve);

  const AreasOptions = (inputValue, resolve) =>
    loadOptions(2, inputValue, resolve);

  const loadIndustries = debounce(IndustriesOptions, 300);
  const loadSkills = debounce(SkillOptions, 300);
  const loadAreas = debounce(AreasOptions, 300);

  return (
    <>
      <div className={`tab-content ${styles.tabcontent}`}>
        <div className="fade show active">
          <Formik
            onSubmit={(value, { resetForm }) => {
              if (action === "add") {
                onSubmit(value, "add");
                resetForm();
              } else {
                onSubmit(value, "save");
              }
            }}
            enableReinitialize
            initialValues={workData || initialValues}
            validationSchema={talentWorkSchema}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              handleSubmit,
              getFieldProps,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.editprofile}>
                  <div className={styles.edithead}>
                    <div className={styles.topheading}>
                      <h3>
                        {workId
                          ? "Edit work experience history"
                          : "Add work experience history"}
                      </h3>
                      <div className="d-flex">
                        <LinkButton
                          onClick={() =>
                            navigate(
                              `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=work`
                            )
                          }
                        >
                          Back to work
                        </LinkButton>
                        <PrimaryButton
                          label={saveViewButtonLabels.workSave}
                          onClick={() => {
                            action = "save";
                            validationError(errors);
                          }}
                          disabled={!dirty}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className={styles.cardDivider} />

                  <div className={styles.formname}>
                    <div className={styles.middleCard}>
                      <FormInput
                        label="Title/Designation"
                        {...getFieldProps("designation")}
                        error={touched?.designation && errors?.designation}
                      />
                      <FormInput
                        label="Role"
                        {...getFieldProps("role")}
                        error={touched?.role && errors?.role}
                      />

                      <FormInput
                        label="Organization Name"
                        {...getFieldProps("companyName")}
                        error={touched?.companyName && errors?.companyName}
                      />

                      <FormInput
                        label="Organization URL"
                        {...getFieldProps("companyUrl")}
                        error={touched?.companyUrl && errors?.companyUrl}
                      />

                      <CountryStateCityPicker
                        isEdit={!!id}
                        country={values?.country}
                        state={values?.state}
                        city={values?.city}
                        onCountyChange={(value) => {
                          setFieldValue("country", value);
                          setFieldValue("state", null);
                          setFieldValue("city", null);
                        }}
                        onStateChange={(value) => {
                          setFieldValue("state", value);
                          setFieldValue("city", null);
                        }}
                        onCityChange={(value) => setFieldValue("city", value)}
                        countryError={touched?.country && errors?.country}
                        stateError={touched?.state && errors?.state}
                        cityError={touched?.city && errors?.city}
                      />

                      <div className={styles.dateContainer}>
                        <div className={styles.dateSection}>
                          <div className={styles.datePicker}>
                            <FormInput
                              label="Joining Date"
                              type="select"
                              value={
                                values?.joinDateMonth
                                  ? monthOption.find(
                                      (obj) =>
                                        obj.value === values?.joinDateMonth
                                    )
                                  : ""
                              }
                              options={monthOption}
                              onChange={(e) =>
                                setFieldValue("joinDateMonth", e.value)
                              }
                              error={
                                touched?.joinDateMonth && errors?.joinDateMonth
                              }
                            />
                          </div>
                          <div className={styles.datePicker}>
                            <FormInput
                              label="&nbsp;"
                              type="select"
                              value={
                                values?.joinDateYear
                                  ? yearOption.find(
                                      (obj) =>
                                        obj.value === values?.joinDateYear
                                    )
                                  : ""
                              }
                              options={yearOption}
                              onChange={(e) => {
                                setFieldValue("joinDateYear", e.value);
                                setUpdateYear(e.value);
                              }}
                              error={
                                touched?.joinDateYear && errors?.joinDateYear
                              }
                            />
                          </div>
                        </div>
                        <div className={styles.dateSection}>
                          <div className={styles.datePicker}>
                            <FormInput
                              isDisabled={
                                values?.currentlyWork === true ? true : false
                              }
                              label="End Date"
                              type="select"
                              value={
                                values?.endDateMonth
                                  ? monthOption.find(
                                      (obj) =>
                                        obj.value === values?.endDateMonth
                                    )
                                  : ""
                              }
                              options={monthOption}
                              onChange={(e) =>
                                setFieldValue("endDateMonth", e.value)
                              }
                              error={
                                touched?.endDateMonth && errors?.endDateMonth
                              }
                            />
                          </div>
                          <div className={styles.datePicker}>
                            <FormInput
                              isDisabled={
                                values?.currentlyWork === true ? true : false
                              }
                              label="&nbsp;"
                              type="select"
                              value={
                                values?.endDateYear
                                  ? yearOption.find(
                                      (obj) => obj.value === values?.endDateYear
                                    )
                                  : ""
                              }
                              options={yearDropDownOptions(updateYear)}
                              onChange={(e) =>
                                setFieldValue("endDateYear", e.value)
                              }
                              error={
                                touched?.endDateYear && errors?.endDateYear
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <Checkbox
                        id="currentWoring"
                        label="Currently working here"
                        checked={values?.currentlyWork}
                        {...getFieldProps("currentlyWork")}
                      />

                      <FormInput
                        type="editor"
                        label="Work Synopsis"
                        value={values?.workSynopsis}
                        onEditorChange={(value) =>
                          setFieldValue("workSynopsis", value)
                        }
                        error={touched?.workSynopsis && errors?.workSynopsis}
                      />

                      <FormInput
                        type="editor"
                        label="Key Work / Initiatives"
                        value={values?.keyWork}
                        onEditorChange={(value) =>
                          setFieldValue("keyWork", value)
                        }
                        error={touched?.keyWork && errors?.keyWork}
                      />

                      <FormInput
                        defaultOptions
                        label="Industry Type"
                        type="async-select"
                        value={values?.industryType}
                        loadOptions={loadIndustries}
                        onChange={(data) => setFieldValue("industryType", data)}
                        error={touched?.industryType && errors?.industryType}
                      />

                      <FormInput
                        isMulti
                        cacheOptions
                        defaultOptions
                        label="Functional Areas"
                        type="async-select"
                        value={values?.functionalAreas}
                        loadOptions={loadAreas}
                        onChange={(data) =>
                          setFieldValue("functionalAreas", data || [])
                        }
                        error={
                          touched?.functionalAreas && errors?.functionalAreas
                        }
                      />

                      <FormInput
                        isMulti
                        cacheOptions
                        defaultOptions
                        label="Skill Roles"
                        type="async-select"
                        value={values?.skillRoles}
                        loadOptions={loadSkills}
                        onChange={(data) =>
                          setFieldValue("skillRoles", data || [])
                        }
                        error={touched?.skillRoles && errors?.skillRoles}
                      />
                    </div>
                    <hr className={styles.cardDivider} />

                    <div className={styles.bottomFotter}>
                      {workId ? (
                        <div></div>
                      ) : (
                        <OutLineButton
                          type="submit"
                          icon={IMAGES.IC_TWO}
                          onClick={() => {
                            action = "add";
                            onsubmit();
                          }}
                          label={saveAddNewButtonLabels.workSave}
                        />
                      )}

                      <div className="d-flex">
                        <LinkButton
                          onClick={() =>
                            navigate(
                              `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=work`
                            )
                          }
                        >
                          Back to work
                        </LinkButton>
                        <PrimaryButton
                          label={saveViewButtonLabels.workSave}
                          onClick={() => {
                            action = "save";
                            validationError(errors);
                          }}
                          disabled={!dirty}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Work;
