import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../constants/assets";
import { listApi } from "../../../../api/commonApis";
import { APIS } from "../../../../api/endpoints";
import { useParams } from "react-router-dom";
import Pagination from "../../../../components/table/pagination";
import moment from "moment";

const ActivityLog = () => {
  const { id } = useParams();
  const [activityData, setActivityData] = useState([]);
  const [limitPerPage, setLimitPerPage] = useState(10);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    let payload = {
      assignmentId: id,
      skip: limitPerPage * page,
      limit: limitPerPage,
    };
    listApi(APIS.LIST_ACTIVITY_LOG, payload).then((result) => {
      setActivityData(result?.data?.data);
      setCount(result?.data?.count);
    });
  }, [page, limitPerPage]);

  const onPageChange = (pageNo) => {
    setPage(pageNo);
  };

  return (
    <div
      className={`tab-content ${styles.tabMainContent}`}
      id="v-pills-tabContent"
    >
      <div className="row">
        <div className="col-md-12">
          <div className={styles.searchfilter}>
            <h2 className={styles.searchbuss}>Activity Log</h2>
            <hr className={styles.divider} />
          </div>
          {activityData.length ? (
            <>
              <div className={styles.activelogs}>
                <ul>
                  {activityData?.map((data, index) => {
                    return (
                      <li key={index}>
                        <div className={styles.activelogsleft}>
                          <div
                            className={styles.activelogsimg}
                            style={{
                              backgroundColor:
                                data?.type === 1 || data?.type === 2
                                  ? "#b1ffba"
                                  : "#dedede",
                            }}
                          >
                            <img
                              src={
                                data?.type === 1 || data?.type === 2
                                  ? IMAGES.BOX_LINE
                                  : IMAGES.ACCOUNT_IDIT
                              }
                              alt="Linkdin"
                              className={styles.boxlines}
                            />
                          </div>
                        </div>
                        <div className={styles.activelogsright}>
                          <p> {moment(data?.createdAt)?.fromNow?.()}</p>
                          <h2
                            dangerouslySetInnerHTML={{ __html: data?.message }}
                          ></h2>
                          <p>
                            <img
                              src={IMAGES.TIME_ZONE}
                              alt="Linkdin"
                              className={styles.time}
                            />{" "}
                            {data?.type === 1 || data?.type === 2
                              ? `Assignment `
                              : `Candidates `}
                            Modified by{" "}
                            <span>
                              {`${data?.addedBy?.name} ${data?.addedBy?.lastName}`}
                            </span>
                          </p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <Pagination
                activityLog
                totalCount={count}
                currentPage={page}
                limitPerPage={limitPerPage}
                onPageChange={onPageChange}
                setLimitPerPage={setLimitPerPage}
              />
            </>
          ) : (
            <div className={styles.nodataFound}>No activity logs found</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ActivityLog;
