import React from "react";
import styles from "./styles.module.scss";

const ContactTable = ({ client }) => {
  return (
    <table class="table">
      <thead>
        <tr>
          <th scope="col" className={styles.thPosition}>
            Name
          </th>

          <th scope="col" className={styles.thAgeing}>
            Email
          </th>
          <th scope="col" className={styles.thAgeing}>
            Phone Number
          </th>
          <th scope="col" className={styles.thAgeing}>
            Primary
          </th>
        </tr>
      </thead>
      <tbody>
        {client?.contactPersons?.map((data, index) => {

          if(data?.name){
              return (
                  <tr key={index}>
                      <td className={styles.tData}>
                          <b>{data?.name || ""}</b>
                          <div className={styles.designationStyles}>
                              {data?.designation || ""}
                          </div>
                      </td>

                      <td className={styles.tData}>
                          <a className={styles.linkstyles} href={`mailto:${data?.email}`}>
                              {data?.email || ""}
                          </a>
                      </td>
                      <td className={styles.tData}>
                          <a
                              className={styles.linkstyles}
                              href={`tel:${data?.phoneNumber}`}
                          >
                              {data?.phoneNumber || ""}
                          </a>
                      </td>
                      <td className={styles.tData}>{data?.isPrimary ? "Yes" : "No"}</td>
                  </tr>
              );
          } else return null
        })}
      </tbody>
    </table>
  );
};

export default ContactTable;
