import React from "react";
import styles from "./styles.module.scss";

const AssignmentBlock = ({ data }) => {
  return (
    <div className={styles.assingnments}>
      <h4 className={styles.assingnmentTitle}>Recent Ongoing Assignments</h4>
      {data?.assignments?.length > 0 ? (
        data.assignments.map((title, index) => {
          return <p className={styles.ongoingAssingnment}>{title?.title}</p>;
        })
      ) : (
        <p className={styles.ongoingAssingnment}>No Assignment Assigned</p>
      )}
    </div>
  );
};

export default AssignmentBlock;
