import React from "react";
import { IMAGES } from "../../../constants/assets";
import { ROUTES } from "../../../router/routes";
import { getTalentStatus } from "../../../utils/helper";
import styles from "./styles.module.scss";
import { IMAGE_BASE_URL } from "../../../constants/contants";
import { Tooltip } from "@mui/material";

const TalentRow = ({ data: talent }) => {
  const isOffLimit = talent?.status === 4;

  return (
    <div
      className={`d-flex position-relative w-100 mb-3 ${
        isOffLimit ? styles.bgRed : styles.bgGray
      }`}
    >
      <div className={styles.tooltip}>
        <button
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          type="button"
          className={styles.usertoolbtn}
        >
          {!talent?.imageUrl ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              class="iconify iconify--ion"
              width="28"
              height="28"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 512 512"
            >
              <path d="M256 48C148.5 48 60.1 129.5 49.2 234.1c-.8 7.2-1.2 14.5-1.2 21.9 0 7.4.4 14.7 1.2 21.9C60.1 382.5 148.5 464 256 464c114.9 0 208-93.1 208-208S370.9 48 256 48zm135.8 326.1c-22.7-8.6-59.5-21.2-82.4-28-2.4-.7-2.7-.9-2.7-10.7 0-8.1 3.3-16.3 6.6-23.3 3.6-7.5 7.7-20.2 9.2-31.6 4.2-4.9 10-14.5 13.6-32.9 3.2-16.2 1.7-22.1-.4-27.6-.2-.6-.5-1.2-.6-1.7-.8-3.8.3-23.5 3.1-38.8 1.9-10.5-.5-32.8-14.9-51.3-9.1-11.7-26.6-26-58.5-28h-17.5c-31.4 2-48.8 16.3-58 28-14.5 18.5-16.9 40.8-15 51.3 2.8 15.3 3.9 35 3.1 38.8-.2.7-.4 1.2-.6 1.8-2.1 5.5-3.7 11.4-.4 27.6 3.7 18.4 9.4 28 13.6 32.9 1.5 11.4 5.7 24 9.2 31.6 2.6 5.5 3.8 13 3.8 23.6 0 9.9-.4 10-2.6 10.7-23.7 7-58.9 19.4-80 27.8C91.6 341.4 76 299.9 76 256c0-48.1 18.7-93.3 52.7-127.3S207.9 76 256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256c0 43.9-15.6 85.4-44.2 118.1z"></path>
            </svg>
          ) : (
            <img
              src={IMAGE_BASE_URL + talent?.imageUrl?.original}
              alt="image"
              className={styles.listProfileImage}
            />
          )}
        </button>

        {(talent?.email || talent?.phoneNumber?.length > 0) && (
          <div className={styles.tooltiptext}>
            {talent?.email && (
              <div className="d-flex align-items-center justify-content-between">
                <img
                  src={IMAGES.MID_EMAIL}
                  alt="mailImage"
                  className={styles.tooltipEmailImages}
                />
                <div
                  className={`${styles.tip_email}`}
                  onClick={() => window.open(`mailto:${talent?.email}`)}
                >
                  {talent?.email}
                </div>
              </div>
            )}
            {talent?.phoneNumber?.length > 0 && (
              <div className="d-flex align-items-center justify-content-between ">
                <img
                  src={IMAGES.MID_MOBILE}
                  alt="phoneImage"
                  className={`${styles.tooltipMobileImages} ${
                    talent?.email ? "" : styles.phoneStyles
                  }`}
                />

                <div className={`${styles.tip_phone}`}>
                  <div
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => window.open(`tel:${talent?.phoneNumber}`)}
                  >
                    {`+91 ${talent?.phoneNumber?.[0]}`}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className={`w-100 d-flex align-items-center justify-content-between text-capitalize
                ${styles.usersimple} ${talent?.status === 4 ? styles.red : ""}`}
        onClick={() =>
          window.open(
            `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${talent?._id}?tab=overview`,
            "_blank"
          )
        }
      >
        <div className={`d-flex align-items-center ${styles.userName}`}>
          <div className={`ms-3 `}>
            <h2>{`${talent?.firstName} ${talent?.middleName || ""} ${
              talent?.lastName
            }`}</h2>
            <p>{talent?.recentWork?.designation}</p>
          </div>
        </div>
        <div className={styles.usercity}>
          <h3>{`${talent?.city ? talent?.city : ""}`}</h3>
          <p>{`${talent?.state ? `${talent?.state},` : ""} ${
            talent?.country ? talent?.country : ""
          }`}</p>
        </div>
        <div className={styles.userexp}>
          <h3>Experience</h3>
          <p>
            {talent?.totalExperience
              ? `${talent?.totalExperience?.toFixed(0)} Years`
              : "-"}
          </p>
        </div>
        <div className={styles.usercompany}>
          <h3>Current Organization</h3>
          <p>{talent?.recentWork?.companyName || "-"}</p>
        </div>
        <div className={styles.usercompanyold}>
          <h3>Associated Organization</h3>
          <p>{talent?.recentWork?.companyName || "-"}</p>
        </div>
        <div className={styles.userstatus}>
          <div
            className={`${styles.statusButton}
          ${
            talent?.status === 2 || talent?.status === 5
              ? `${styles.statusConvertedButton}`
              : ``
          }`}
          >
            {getTalentStatus(talent?.status)}
          </div>
        </div>
        <div className={styles.usersocial}>
          {talent?.linkedinUrl ? (
            <>
              <Tooltip title={talent?.linkedinUrl}>
                <div
                  onClick={(e) => {
                    e?.stopPropagation();
                    window.open(talent?.linkedinUrl, "_blank");
                  }}
                  className={styles.socialIconStyles}
                >
                  <svg width="18.5px" viewBox="0 0 24 24">
                    <path
                      fill="#0A66C2"
                      d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278c-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387c2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549a1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"
                    ></path>
                  </svg>
                </div>
              </Tooltip>
            </>
          ) : (
            <div className={styles.blankImg}></div>
          )}

          {talent?.twitterUrl ? (
            <Tooltip title={talent?.twitterUrl}>
              <img
                src={IMAGES.SOCIAL_TWITTER}
                alt="Linkdin"
                className={styles.socialIcon + " " + styles.twitterIcon}
                onClick={(e) => {
                  e?.stopPropagation();
                  window.open(talent?.twitterUrl, "_blank");
                }}
              />
            </Tooltip>
          ) : (
            <div className={styles.blankImg}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TalentRow;
