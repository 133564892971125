import React from "react";
import styles from "./styles.module.scss";

const Skeleton = ({ height, width, ...rest }) => {
  return (
    <div
      className={`${styles.skeleton}`}
      style={{ height, width, ...rest }}
    ></div>
  );
};

export default Skeleton;
