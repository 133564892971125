import React from "react";
import styles from "./styles.module.scss";
import { MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwitchButton from "components/forms/switchButton";

const FilterRow = ({
  filterStatus,
  setFilterStatus,
  filterLevelStatus,
  setFilterPotential,
  filterPotential,
  setFilterRef,
  filterRef,
}) => {
  return (
    <div className={styles.filterContainer}>
      <div className={styles.rightContainer}>
        <label className={` ${styles.filterLabel}`}>
          Filter by :&nbsp;&nbsp;&nbsp;
        </label>
        <Select
          size="small"
          variant="standard"
          className={styles.statusSelect}
          value={filterStatus}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          onChange={(e) => setFilterStatus(e.target.value)}
          IconComponent={ExpandMoreIcon}
          sx={{
            "&	.MuiSelect-select": {
              padding: "0px 0px 0px 12px",
              fontSize: "10px",
              fontWeight: "bold",
              ":focus": {
                backgroundColor: "transparent",
              },
            },
          }}
        >
          {filterLevelStatus.map((item, index) => (
            <MenuItem
              key={index}
              value={item?.value}
              className={styles.selectItem}
            >
              {item?.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={styles.leftContainer}>
        <div></div>
        <div className="d-flex">
          <div className="d-flex">
            <label className={`${styles.filterLabel} ${styles.ml30}`}>
              Potential Hire?
            </label>
            <SwitchButton
              onChange={() => setFilterPotential(!filterPotential)}
            />
          </div>
          <div className={`d-flex ${styles.mraginLeft} `}>
            <label className={` ${styles.filterLabel} ${styles.ml30}`}>
              Ref. Check Done?
            </label>
            <SwitchButton onChange={() => setFilterRef(!filterRef)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterRow;
