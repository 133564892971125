import React, { Fragment, useState } from "react";
import moment from "moment";
import styles from "./styles.module.scss";
import CommentInput from "../commentInput";
import { IMAGE_BASE_URL } from "../../../constants/contants";
import { useSelector } from "react-redux";
import { addEditApi } from "../../../api/commonApis";
import { APIS } from "../../../api";
import ToastMessage from "../../layout/toast";
import { toast } from "react-toastify";
import ConfirmationModal from "../../modal/confirmationModal";
import { DELETE_MODAL_CONFIRMATION } from "../../../constants/contants";
import { TOAST_MESSAGE } from "constants/toastMessage";
import DeleteCommentMenu from "../DeleteCommentMenu";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: " few seconds",
    ss: "%d seconds",
    m: " minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%dh",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const CommentItem = ({
  renderAsTab,
  comment = {},
  onReply,
  allowComment,
  setPage,
  setComments,
  setSuccess,
  // hasCommentWriteAccess,
  // hasCommentDeleteAccess,
}) => {
  const [activeReply, setActiveReply] = useState("");
  const [anchorEl, setAnchorEl] = useState();
  const currentLoginId = useSelector((state) => state.auth.data._id);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const open = Boolean(anchorEl);

  const onReplyButtonClick = (id) => {
    if (activeReply === id) {
      setActiveReply("");
    } else {
      setActiveReply(id);
    }
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const deleteHandler = () => {
    // setTempId(comment?._id);
    addEditApi(APIS.DELETE_DOCUMENT_DATA, {
      id: comment?._id,
      type: 12,
    }).then((result) => {
      setPage(0);
      setComments([]);
      setSuccess((s) => !s);
      setOpenConfirmation(false);
      toast.success(
        <ToastMessage
          title={"Success"}
          message={TOAST_MESSAGE.DELETE_COMMENT}
        />
      );
    });
  };

  return (
    <>
      <Fragment>
        <div className={`mb-3 ${styles.detailsspace}`}>
          <div className={`d-flex justify-content-between ${styles.details}`}>
            <div className="d-flex align-items-center">
              <div className={styles.commenttext}>
                {comment?.addedBy?.imageUrl?.original ? (
                  <img
                    src={IMAGE_BASE_URL + comment?.addedBy.imageUrl?.original}
                    className={styles.adminThumImage}
                  />
                ) : (
                  <h5
                    style={{ textTransform: "uppercase" }}
                  >{`${comment?.addedBy?.name?.[0]}${comment?.addedBy?.lastName?.[0]}`}</h5>
                )}
              </div>
              <div
                className={`d-flex w-100 justify-content-between align-items-center ${styles.detailscontent}`}
              >
                <div>
                  <div className={styles.detailsheading}>
                    <h2>{`${comment?.addedBy?.name} ${comment?.addedBy?.lastName}`}</h2>
                  </div>
                </div>
              </div>
            </div>

            {/* {hasCommentDeleteAccess && ( */}
            <DeleteCommentMenu
              addedById={comment?.addedBy?._id}
              setOpenConfirmation={setOpenConfirmation}
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
              open={open}
            />
            {/* )} */}
          </div>
          <div className={styles.detailsheading}>
            <div
              className="commentDiv"
              dangerouslySetInnerHTML={{ __html: comment?.text }}
            ></div>
            <p className={styles.reply}>
              <div className={styles.replyTextArea}>
                <>
                  {/* {hasCommentWriteAccess && ( */}
                  <>
                    <div
                      className={styles.replyText}
                      onClick={() => onReplyButtonClick(comment?._id)}
                    >
                      {activeReply === comment?._id ? "Cancel" : "Reply"}
                    </div>
                    <div className={styles.slash}>|</div>
                  </>
                  {/* )} */}
                </>

                <div className={styles.secondText}>
                  {moment(comment?.createdAt)?.fromNow?.()}
                </div>
              </div>
            </p>

            <div className={`ps-3 ms-3 mt-4 mb-4 ${styles.replyBox}`}>
              {comment?.replies?.map((reply, index) => (
                <Fragment key={`reply-${index}`}>
                  <div
                    className={`d-flex justify-content-between ${styles.details}`}
                  >
                    <div className="d-flex align-items-center">
                      <div className={styles.commenttext}>
                        {reply?.addedBy?.imageUrl?.original ? (
                          <img
                            src={
                              IMAGE_BASE_URL + reply?.addedBy.imageUrl?.original
                            }
                            className={styles.adminThumImage}
                          />
                        ) : (
                          <h5>{`${reply?.addedBy?.name?.[0]}${reply?.addedBy?.lastName?.[0]}`}</h5>
                        )}
                      </div>

                      <div
                        className={`d-flex w-100 justify-content-between align-items-center ${styles.detailscontent}`}
                      >
                        <div>
                          <div className={styles.detailsheading}>
                            <h2>{`${reply?.addedBy?.name} ${reply?.addedBy?.lastName}`}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.detailsheading}>
                    <div
                      className="commentDiv"
                      dangerouslySetInnerHTML={{ __html: reply?.text }}
                    ></div>
                    <p className={styles.reply}>
                      <div className={styles.replyTextArea}>
                        {/* {hasCommentWriteAccess && ( */}
                        <div
                          className={styles.replyText}
                          onClick={() => onReplyButtonClick(reply?._id)}
                        >
                          {activeReply === reply?._id ? "Cancel" : "Reply"}
                        </div>
                        {/* )} */}
                        <div className={styles.slash}>|</div>
                        <div className={styles.secondText}>
                          {moment(reply?.createdAt)?.fromNow?.()}
                        </div>
                      </div>
                    </p>
                  </div>
                </Fragment>
              ))}
            </div>

            {activeReply && allowComment && (
              <CommentInput
                renderAsTab={renderAsTab}
                fromReply
                placeholder={`Post a Reply.`}
                onAddComment={(data, mentionsIds) =>
                  onReply(data, comment?._id, mentionsIds)
                }
              />
            )}
          </div>
        </div>

        <hr className={styles.divider} />
      </Fragment>
      <ConfirmationModal
        title={DELETE_MODAL_CONFIRMATION}
        open={openConfirmation}
        data={"this Comment"}
        closeModal={handleCloseConfirmation}
        onChangeHanlder={deleteHandler}
      />
    </>
  );
};

export default CommentItem;
