import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../api";
import { ROUTES } from "../../router/routes";

const DocViewer = () => {
  const { id } = useParams();
  const isLoggedIn = useSelector((state) => state?.auth?.data?.accessToken);

  if (isLoggedIn) {
    return (
      <iframe
        title="doc"
        src={`${BASE_URL}/bucket/${id}`}
        style={{ width: "100%", height: "100vh" }}
      >
        Presss me: <a href="./resources/crayola.pdf">Download PDF</a>
      </iframe>
    );
  }

  return <Navigate to={ROUTES.OVERVIEW} replace />;
};

export default DocViewer;
